import { useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import { clearErrors, deleteCareer, getAdminCareers } from '../../../actions/careerAction';
//import Rating from '@mui/material/Rating';
import { DELETE_CAREER_RESET } from '../../../constants/careerConstants';
import Actions from '../Actions';
import MetaData from '../../Layouts/MetaData';
import BackdropLoader from '../../Layouts/BackdropLoader';
import Loader from '../../Layouts/Loader';

const CareerTable = () => {

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const { careers, error } = useSelector((state) => state.careers);
    const { loading, isDeleted, error: deleteError } = useSelector((state) => state.career);

    useEffect(() => {
        if (error) {
            enqueueSnackbar(error, { variant: "error" });
            dispatch(clearErrors());
        }
        if (deleteError) {
            enqueueSnackbar(deleteError, { variant: "error" });
            dispatch(clearErrors());
        }
        if (isDeleted) {
            enqueueSnackbar("Career Deleted Successfully", { variant: "success" });
            dispatch({ type: DELETE_CAREER_RESET });
        }
        dispatch(getAdminCareers());
    }, [dispatch, error, deleteError, isDeleted, enqueueSnackbar]);

    const deleteCareerHandler = (slug) => {
        dispatch(deleteCareer(slug));
    }

    const columns = [
        
        {
            field: "name",
            headerName: "Name",
            minWidth: 150,
            flex: 0.5,
            renderCell: (params) => {
                return (
                    <div className="flex items-center gap-2">
                        <div className="w-10 h-10 rounded-full">
                            <img draggable="false" src={params.row.image} alt={params.row.name} className="w-full h-full rounded-full object-cover" />
                        </div>
                        {params.row.name}
                    </div>
                )
            },
        },
        
        {
            field: "slug",
            headerName: "Career ID",
            minWidth: 140,
            flex: 0.2,
        },
        {
            field: "description",
            headerName: "Description",
            minWidth: 240,
            flex: 1,
        },
        
        {
            field: "actions",
            headerName: "Actions",
            minWidth: 100,
            flex: 0.3,
            type: "number",
            sortable: false,
            renderCell: (params) => {
                return (
                    <Actions editRoute={"career"} deleteHandler={deleteCareerHandler} slug={params.row.slug} />
                );
            },
        },
    ];

    const rows = [];

    careers && careers.forEach((item) => {
        rows.unshift({
            slug:item.slug,
            id: item._id,
            name: item.career_name,
            image: item.career_image.url,
            description: item.description,
            
            
        });
    });

    return (
        
        <>
        
            <MetaData title="Admin Careers | Lyriclious" /> 
            <div className="flex justify-between items-center">
                <h1 className="text-lg font-medium uppercase">careers</h1>
                <Link to="/admin/new_career" className="py-2 px-4 rounded shadow font-medium text-white bg-purple-900 hover:shadow-lg">New Career</Link>
            </div>
            <div className="bg-white rounded-xl shadow-lg w-full" style={{ height: 470 }}>
            {loading ? <Loader /> : (
            <>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={10}
                    disableSelectIconOnClick
                    sx={{
                        boxShadow: 0,
                        border: 0,
                    }}
                />
                </>
            )};
            </div>
            
        </>
            
    );
};

export default CareerTable;