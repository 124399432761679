import { useEffect, useState } from 'react';
import Sidebar from './Sidebar';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Loader from '../Layouts/Loader';
import MetaData from '../Layouts/MetaData';
import ProfileTop from './AccountComponents/Profile/ProfileTop';
import About from "./AccountComponents/Profile/About";
import Languages from './AccountComponents/Profile/Languages';
import Experience from "./AccountComponents/Profile/Experience";
import Education from "./AccountComponents/Profile/Education";
import Certificates from './AccountComponents/Profile/Certificates';
import Skills from './AccountComponents/Profile/Skills';
import ComingSoon from '../Layouts/ComingSoon/ComingSoon';


const Account = ({ activeTab, children }) => {

    const navigate = useNavigate();

    const { user, loading, isAuthenticated } = useSelector(state => state.user)

    useEffect(() => {
        if (isAuthenticated === false) {
            navigate("/login")
        }
    }, [isAuthenticated, navigate]);


    const [onMobile, setOnMobile] = useState(false);
    const [toggleSidebar, setToggleSidebar] = useState(false);

    useEffect(() => {
        if (window.innerWidth < 600) {
            setOnMobile(true);
        }
    }, [])

    return (
        <>
            <MetaData title="My Profile" />

            {loading ? <Loader /> :
                <>
                    <main className="w-full sm:mt-0">

                        {/* <!-- row --> */}
                        

                        {!onMobile && <Sidebar activeTab={activeTab} />}
                {toggleSidebar && <Sidebar activeTab={activeTab} setToggleSidebar={setToggleSidebar}/>}
                         
                        
                        <div className='lg:ml-[20%]'>
                        <div className='w-3/4 mx-auto'>
                            <ComingSoon />
                        {/* <ProfileTop />
                        <About />
                        <Experience />
                        <Education />
                        <Certificates />
                        <Skills />
                        <Languages /> */}
                           
                        </div>
                        </div>
                    </main>
                </>
            }
        </>
    );
};

export default Account;
