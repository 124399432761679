import Headings from '../../Layouts/Headings/Headings.jsx';
import "../Companies/Hiring.css";
import serviceBanner from "../../../assets/images/Companies/serviceBanner.png"
const Hiring = () => {

    return (
      <>
        <Headings title={"Start Hiring"} type={"secondLevel"}/>

        <div className="grid grid-cols-8  bg-purple-900 hiringContainer">
        <span className="lg:col-start-1 lg:col-span-3 xs:col-span-8 xs:mt-4 xs:mb-2 lg:my-12 lg:ml-10 ">

           <img src= {serviceBanner} alt="servicebanner" className='lg-w-[100%] xs:w-[75%] xs:ml-auto xs:mr-auto lg:ml-10  rounded-[50%]' /> 
        </span>
        <span className='lg:col-start-5  lg:col-span-4 xs:col-span-8 text-white'>
          <h2 className='rightHeading text-white font-normal xs:mt-6 lg:mt-20'>WE RECOMMEND BEST</h2>
          <p className='px-14 py-6 my-2 font-light rightText1'>Hire the best resource for your organisation, who went through multiple
            trainings, assessments and project works.
          </p>
          <a href="/comingsoon">
          <button className='btnpost lg:mx-14 xs:px-3 lg:px-12 xs:py-2 lg:py-3 my-5 xs:mt-2 lg:mt-14 bg-white text-purple-900 rounded-[50px] font-medium align-middle'>POST JOB/INTERNSHIP  <span className='xs:ml-4 lg:ml-10 text-2xl font-normal'> &#62;&#62; </span>
          </button></a>
         

          

          <p className='btnsubheading lg:mx-14 xs:text-[0.6rem] lg:text-[0.8rem] font-thin font-small text-gray-200/75'>Post jobs/ Internships in very easy & minimal steps </p>
        </span>
     </div>
      </>
    );
  };
  
  export default Hiring;