import "./TrainingDetails.css";
import htmlCssIcon from "../../assets/images/Training/html-css.png"
import javascriptIcon from "../../assets/images/Training/javascript.png"
import reactIcon from "../../assets/images/Training/react.png"
import mongodbIcon from "../../assets/images/Training/mongodb.png"
import sqlIcon from "../../assets/images/Training/sql.png"
import apiIcon from "../../assets/images/Training/api.png"
const TrainingSkills = ({}) => {


const trainingSkills = [
    {
        icon: htmlCssIcon,
        label: "HTML & CSS",
        
    },
    {
        icon: javascriptIcon,
        label: "Javascript"
       
    },
    {
        icon: reactIcon,
        label: "React",
        
    },
    {
        icon: mongodbIcon,
        label: "MongoDB",
        
    },
    {
        icon: sqlIcon,
        label: "MySQL",
        
    },
    {
        icon: apiIcon,
        label: "APIs",
        
    },
   
   
   
];

  
    return (
        <>
       <div className="mt-20 lg:mx-auto xs:w-[100%] xs:ml-4 lg:w-[80%] ">
        <h1 className="xs:text-base lg:text-2xl xs:ml-4 font-medium">Skills You Will Learn</h1>
        <div className="flex mt-8 lg:w-[97%] lg:ml-3 grid grid-cols-9 xs:gap-2 lg:gap-8">
        {trainingSkills.map((item, index) => {
          const { icon, label} = item;
          return (
            <div className="flex lg:px-4 border-[1px] border-purple-400 rounded-lg xs:col-span-4 lg:col-span-3 lg:py-4">
                <span className="xs:px-1 xs:mx-1 lg:px-2 lg:mx-2 "><img src={icon} width="60px" /></span>
                <p className="lg:mx-2 xs:font-normal xs:text-xs lg:text-sm lg:font-medium my-auto">{label}</p>
            </div>

        )
          })
          }

            
        </div>
        </div>
         
    

        </>
    );
};

export default TrainingSkills;
