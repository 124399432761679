import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField'
import Avatar from '@mui/material/Avatar'
import { useSnackbar } from 'notistack';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearErrors, registerUser } from '../../actions/userAction';
import BackdropLoader from '../Layouts/BackdropLoader';
import MetaData from '../Layouts/MetaData';
import FormSidebar from './FormSidebar';
import jwt_decode from "jwt-decode";
import { toCamelCase } from '../../utils/functions';

const Register = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const { loading, isAuthenticated, error } = useSelector((state) => state.user);

    const [user, setUser] = useState({
        
        username: "",
        email: "",
        mobilenum:"",
        
        password: "",
        cpassword: "",
    });

    const { username, email, mobilenum,  password, cpassword } = user;

    const [avatar, setAvatar] = useState();
    const [avatarPreview, setAvatarPreview] = useState("preview.png");
    
    const handleRegister = (e) => {
        e.preventDefault();
        if (password.length < 8) {
            enqueueSnackbar("Password length must be atleast 8 characters", { variant: "warning" });
            return;
        }
        if (password !== cpassword) {
            enqueueSnackbar("Password Doesn't Match", { variant: "error" });
            return;
        }
        // if (!avatar) {
        //     enqueueSnackbar("Please select Profile Picture", { variant: "error" });
        //     return;
        // }

        const formData = new FormData();
        //formData.set("user_id",user_id);
        formData.set("user_name", toCamelCase(username));
        formData.set("email", email.toLowerCase());
        formData.set("mobilenum",mobilenum);
        //formData.set("gender", gender);
        formData.set("password", password);
        if(avatar){
            formData.set("avatar", avatar);
        }
        
        dispatch(registerUser(formData));
    }

    const handleDataChange = (e) => {
        if (e.target.name === "avatar") {
            const reader = new FileReader();

            reader.onload = () => {
                if (reader.readyState === 2) {
                    setAvatarPreview(reader.result);
                    setAvatar(reader.result);
                }
            };

            reader.readAsDataURL(e.target.files[0]);

        } else {
            setUser({ ...user, [e.target.name]: e.target.value });
        }
    }
   
     //goolge auth
function handleCallbackResponse(e){
    //console.log("Encoded JWT Token: ",e.credential);
    var userObject = jwt_decode(e.credential);
    if(userObject.name){
        const formData = new FormData();
    formData.set("user_name", toCamelCase(userObject.name));
    formData.set("email", userObject.email.toLowerCase());
    formData.set("password", "");
    formData.set("is_email_auth", true);
    formData.set("user_id",Math.random().toString(36).slice(2));
    if(userObject.picture){
        formData.set("avatar.public_id", "picfromGoogle");
        formData.set("avatar.url",userObject.picture);
    }
    //formData.set("isgoogleauth",true);
    dispatch(registerUser(formData));
    }
    
    
  }
  
  useEffect(() => {
    /* global google */
    google.accounts.id.initialize({
      client_id:"678938896955-8v8pvov2gjkfukeh8o80gtbofkl8mhdh.apps.googleusercontent.com",
      callback:handleCallbackResponse
    })
  
  google.accounts.id.renderButton(
    document.getElementById("signUpDiv"),
    {theme: "outline", size: "large", text:"signup_with", type:"standard"}
  );
  })
  
  
  //google auth end

    useEffect(() => {
        if (error) {
            enqueueSnackbar(error, { variant: "error" });
            dispatch(clearErrors());
        }
        if (isAuthenticated) {
            navigate('/')
        }
    }, [dispatch, error, isAuthenticated, navigate, enqueueSnackbar]);

    return (
        <>
            <MetaData title="Register | Lyriclious" />

            {loading && <BackdropLoader />}
            <main className="w-full mt-12 sm:pt-20 sm:mt-0">

                {/* <!-- row --> */}
                <div className="flex sm:w-4/6 sm:mt-4 m-auto mb-7 bg-white shadow-lg">
                
                    <FormSidebar
                        title="Looks like you're new here!"
                        tag="Sign up to join the Lyriclious Community"
                    />

                    {/* <!-- signup column --> */}
                    <div className="flex-1 overflow-hidden">
                    {/* <div className="g-signin2" data-onsuccess="onSignIn"></div> */}
                    
                        {/* <!-- personal info procedure container --> */}
                        <form
                            onSubmit={handleRegister}
                            encType="multipart/form-data"
                            className="p-5 sm:p-10"
                        >
                            
                            <div className="flex flex-col gap-4 items-start">
                            <p className='font-small inline text-gray-500'>Continue with</p>
                            <span id="signUpDiv" className='mb-8 w-[100%] h-0'></span>
                            <hr  />
                                <p className='font-small inline text-gray-500'>Or, sign-up with your email</p>
                                {/* <!-- input container column --> */}
                                <div className="flex flex-col w-full justify-between sm:flex-col gap-3 items-center">
                                {/* <TextField
                                        fullWidth
                                        id="user_id"
                                        label="User ID"
                                        name="user_id"
                                        value={user_id}
                                        onChange={handleDataChange}
                                        required
                                    /> */}
                                    <TextField
                                        fullWidth
                                        id="full-name"
                                        label="Full Name"
                                        name="username"
                                        value={username}
                                        onChange={handleDataChange}
                                        required
                                    />
                                    <TextField
                                        fullWidth
                                        id="email"
                                        label="Email"
                                        type="email"
                                        name="email"
                                        value={email}
                                        onChange={handleDataChange}
                                        required
                                    />
                                    <TextField
                                        fullWidth
                                        id="mobilenum"
                                        label="Mobile Number"
                                        type="tel"
                                        name="mobilenum"
                                        InputProps={{ inputProps: { min: 0 } }}
                                        value={mobilenum}
                                        onChange={handleDataChange}
                                        required
                                    />
                                </div>
                                {/* <!-- input container column --> */}

                                {/* <!-- gender input --> */}
                                {/* <div className="flex gap-4 items-center">
                                    <h2 className="text-md">Your Gender :</h2>
                                    <div className="flex items-center gap-6" id="radioInput">
                                        <RadioGroup
                                            row
                                            aria-labelledby="radio-buttons-group-label"
                                            name="radio-buttons-group"
                                        >
                                            <FormControlLabel name="gender" value="Male" onChange={handleDataChange} control={<Radio required />} label="Male" />
                                            <FormControlLabel name="gender" value="Female" onChange={handleDataChange} control={<Radio required />} label="Female" />
                                        </RadioGroup>
                                    </div>
                                </div> */}
                                {/* <!-- gender input --> */}

                                {/* <!-- input container column --> */}
                                <div className="flex flex-col w-full justify-between sm:flex-row gap-3 items-center">
                                    <TextField
                                        id="password"
                                        label="Password"
                                        type="password"
                                        name="password"
                                        value={password}
                                        onChange={handleDataChange}
                                        required
                                    />
                                    <TextField
                                        id="confirm-password"
                                        label="Confirm Password"
                                        type="password"
                                        name="cpassword"
                                        value={cpassword}
                                        onChange={handleDataChange}
                                        required
                                    />
                                </div>
                                {/* <!-- input container column --> */}

                                <div className="flex flex-col w-full justify-between sm:flex-row gap-3 items-center">
                                    <Avatar
                                        alt="Avatar Preview"
                                        src={avatarPreview}
                                        sx={{ width: 56, height: 56 }}
                                    />
                                    <label className="rounded font-medium bg-gray-400 text-center cursor-pointer text-white w-full py-2 px-2.5 shadow hover:shadow-lg">
                                        <input
                                            type="file"
                                            name="avatar"
                                            accept="image/*"
                                            onChange={handleDataChange}
                                            className="hidden"
                                        />
                                        Choose File
                                    </label>
                                </div>
                                <button type="submit" className="text-white py-3 w-full bg-purple-900 shadow hover:shadow-lg rounded-sm font-medium">Sign Up</button>
                                <p className='text-sm m-auto'>Already have an account? <Link to="/login" className="text-purple-900 text-center font-normal"> Sign in</Link></p>
                                <p className='text-xs m-auto text-gray-400'>By proceeding, you are agreeing to the <p className='text-purple-900 inline'>Terms & Conditions </p></p>
                                
                            </div>

                        </form>
                        {/* <!-- personal info procedure container --> */}

                    </div>
                    {/* <!-- signup column --> */}
                </div>
                {/* <!-- row --> */}

            </main>
        </>
    );
};

export default Register;
