import "../Headings/Headings.css"
const Heading = ({ title, type ,id}) => {

const headingType = type;
 
   
    return (
      <>
     
      {
      headingType === "firstLevel" && 
      <div className="flex-wrap headingstyleContainer px-auto">
      <div><h1 className="firstHeading" id={id}>{title}</h1></div>
      </div>
      }
      
      {
      headingType === "secondLevel" && 
      <div className="flex-wrap headingstyleContainer px-auto">
      <div><h2 className="secondHeading" id={id}>{title}</h2></div>
      </div>
      }
       


      </>
    );
  };
  
  export default Heading;