// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("require(\"../../../../../assets/images/Companies/hiring_mobile.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hiringContainer {
    width: 100%;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: bottom;
    background-size: 60%;
}

.serviceImg{
    border-radius: 50%;
    
}

.rightHeading{
    font-size: 26px;
    font-family: 'Roboto';
    margin-left: 8%;
}

@media (min-width: 200px) and (max-width: 640px){
    .rightHeading{
        font-size:1.2rem;
        text-align: center;
        margin-left: 0;
        font-weight: 500;
    }
    .rightText1{
        font-size: 0.8rem;
       text-align: justify;
        width: 100%;
        margin-left: 5%;
    }
    .hiringContainer{
        padding-bottom: 15%;
        background-size: 90%;
    }
    .btnsubheading{
        text-align: center;
    }
    .btnpost {
        margin-left: 20%;
    }

}
.rightText1{
    font-family: 'Roboto';
    width: 90%;
}


.serviceTitle1{
    overflow-wrap: break-word;
    
}`, "",{"version":3,"sources":["webpack://./src/components/Home/Companies/Hiring.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,yDAA0F;IAC1F,4BAA4B;IAC5B,4BAA4B;IAC5B,6BAA6B;IAC7B,oBAAoB;AACxB;;AAEA;IACI,kBAAkB;;AAEtB;;AAEA;IACI,eAAe;IACf,qBAAqB;IACrB,eAAe;AACnB;;AAEA;IACI;QACI,gBAAgB;QAChB,kBAAkB;QAClB,cAAc;QACd,gBAAgB;IACpB;IACA;QACI,iBAAiB;OAClB,mBAAmB;QAClB,WAAW;QACX,eAAe;IACnB;IACA;QACI,mBAAmB;QACnB,oBAAoB;IACxB;IACA;QACI,kBAAkB;IACtB;IACA;QACI,gBAAgB;IACpB;;AAEJ;AACA;IACI,qBAAqB;IACrB,UAAU;AACd;;;AAGA;IACI,yBAAyB;;AAE7B","sourcesContent":[".hiringContainer {\n    width: 100%;\n    background-image: url('require(\"../../../../../assets/images/Companies/hiring_mobile.png');\n    background-repeat: no-repeat;\n    background-position-x: right;\n    background-position-y: bottom;\n    background-size: 60%;\n}\n\n.serviceImg{\n    border-radius: 50%;\n    \n}\n\n.rightHeading{\n    font-size: 26px;\n    font-family: 'Roboto';\n    margin-left: 8%;\n}\n\n@media (min-width: 200px) and (max-width: 640px){\n    .rightHeading{\n        font-size:1.2rem;\n        text-align: center;\n        margin-left: 0;\n        font-weight: 500;\n    }\n    .rightText1{\n        font-size: 0.8rem;\n       text-align: justify;\n        width: 100%;\n        margin-left: 5%;\n    }\n    .hiringContainer{\n        padding-bottom: 15%;\n        background-size: 90%;\n    }\n    .btnsubheading{\n        text-align: center;\n    }\n    .btnpost {\n        margin-left: 20%;\n    }\n\n}\n.rightText1{\n    font-family: 'Roboto';\n    width: 90%;\n}\n\n\n.serviceTitle1{\n    overflow-wrap: break-word;\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
