// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar::-webkit-scrollbar {
    width: 1px;
    display : none;
}
   
.sidebar::-webkit-scrollbar-track {
    width: 0px;
    display: none;
    background-color: #323490;
}
   
.sidebar::-webkit-scrollbar-thumb {
    background-color: #ffffff;
}

.sidebar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  
}

.txtrounded { /* For Firefox/Gecko Engine */ /* For Safari + Chrome*/
    border-radius: 10px; /* For IE 9+*/
    background: #ddd;
    width: 19px;
    color:darkslategray;
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/User/Sidebar.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,cAAc;AAClB;;AAEA;IACI,UAAU;IACV,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;EACE,wBAAwB,GAAG,gBAAgB;EAC3C,qBAAqB;AACvB;;AAEA,cAC8B,6BAA6B,EAC1B,uBAAuB;IACpD,mBAAmB,EAAE,aAAa;IAClC,gBAAgB;IAChB,WAAW;IACX,mBAAmB;IACnB,kBAAkB;AACtB","sourcesContent":[".sidebar::-webkit-scrollbar {\n    width: 1px;\n    display : none;\n}\n   \n.sidebar::-webkit-scrollbar-track {\n    width: 0px;\n    display: none;\n    background-color: #323490;\n}\n   \n.sidebar::-webkit-scrollbar-thumb {\n    background-color: #ffffff;\n}\n\n.sidebar {\n  -ms-overflow-style: none;  /* IE and Edge */\n  scrollbar-width: none;  \n}\n\n.txtrounded {\n    -moz-border-radius: 10px; /* For Firefox/Gecko Engine */\n    -webkit-border-radius: 10px; /* For Safari + Chrome*/\n    border-radius: 10px; /* For IE 9+*/\n    background: #ddd;\n    width: 19px;\n    color:darkslategray;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
