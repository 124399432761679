import axios from "axios";
import { backendurl } from "../utils/constants";
import { ADD_TO_WISHLIST, REMOVE_FROM_WISHLIST } from "../constants/wishlistConstants";

// Add To Wishlist
export const addToWishlist = (id) => async (dispatch, getState) => {
    const { data } = await axios.get(backendurl+`/api/v1/training/${id}`);

    dispatch({
        type: ADD_TO_WISHLIST,
        payload: {
            training: data.training._id,
            training_name: data.training.name,
            price: data.training.price,
            offerPrice: data.training.cuttedPrice,
            image: data.training.image.url,
        },
    });

    localStorage.setItem('wishlistItems', JSON.stringify(getState().wishlist.wishlistItems))
}

// Remove From Wishlist
export const removeFromWishlist = (id) => async (dispatch, getState) => {

    dispatch({
        type: REMOVE_FROM_WISHLIST,
        payload: id,
    });

    localStorage.setItem('wishlistItems', JSON.stringify(getState().wishlist.wishlistItems))
}