import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {  useNavigate } from 'react-router-dom';
import CollegePic from "../../../../assets/images/Profile/collegePic.png"
import EditIcon from '@mui/icons-material/Edit'
import AddIcon from "@mui/icons-material/Add"

const Languages = ({ activeTab, children }) => {

    const navigate = useNavigate();

    const { user, loading, isAuthenticated } = useSelector(state => state.user)

    useEffect(() => {
        if (isAuthenticated === false) {
            navigate("/login")
        }
    }, [isAuthenticated, navigate]);

    

    

    return (
        <> 
                        {/* <!-- row --> */}
                        <div className="pt-4 gap-3.5 sm:w-11/12 sm:mt-4  mb-7 border-gray-300 border-[1.2px] mx-auto rounded-xl ">
                           <span >
                            <h1 className='inline-block mx-[2%]  text-xl'>Experience</h1>
                            <AddIcon className='ml-[71%] text-gray-600'  />
                            <EditIcon className='ml-[2%] text-gray-600' />
                           </span>
                            
                            <div className='flex mx-[2%] my-4'>
    
                            <img  src={CollegePic} alt='collegePic' className='w-[6%] h-[4%] inline-block rounded-[50%]'/>
                                        <div className='ml-4'>
                                       <p className='font-medium text-sm text-gray-600'> Training and Placement Representative  </p>
                                       <p className='font-normal text-xs text-gray-500'> Rajkiya Engineering College Sonbhadra . Full Time</p>
                                       <p className='font-normal text-xs text-gray-400'> Sep 2019 - Sep 2021 . 2 yrs 1 mo</p>
                                       <p className='font-normal text-xs text-gray-400'>India</p>

                                       </div>

                          
                        </div>

                        <hr className='mx-4' />
                        <div className='flex mx-[2%] my-4'>
    
                            <img  src={CollegePic} alt='collegePic' className='w-[6%] h-[4%] inline-block rounded-[50%]'/>
                                        <div className='ml-4'>
                                       <p className='font-medium text-sm text-gray-600'> Dr. A.P.J. Adbul Kalam Technical University  </p>
                                       <p className='font-normal text-xs text-gray-500'> 4 yrs 2 mos</p>
                                       <p className='font-normal text-xs text-gray-400'>India</p>

                                       </div>
                        </div>

                        <div className='flex mx-[2%] my-4'>
                            <ul class="list-none">
                                <li className='ml-4'>
                                    <div className='inline-block ' >
                                    <span className='inline-block w-[10px] h-[10px] bg-gray-300 rounded-[50%] mt-0 pt-0'></span>
                                    
                                    <span className='block w-[2px] h-[35px] bg-gray-300 mx-auto'></span>
                                    </div>

                                <div className='inline-block ml-8'>
                                    <p className='font-medium text-sm text-gray-600'> Training and Placement Representative</p>
                                    <p className='font-normal text-xs text-gray-500'>Aug 2019 - Sep 2021 .2 yrs 2 mos</p>
                                </div>
                                
                                </li>
                            </ul>
                        </div>

                        <div className='flex mx-[2%] my-4'>
                            <ul class="list-none">
                                <li className='ml-4'>
                                    <span className='inline-block w-[10px] h-[10px] bg-gray-300 rounded-[50%] mb-[5%] '></span>
                                <div className='inline-block ml-8'>
                                    <p className='font-medium text-sm text-gray-600'> Secretary of Web Design and Media Council</p>
                                    <p className='font-normal text-xs text-gray-500'>Feb 2018 - Sep 2021 .3 yrs 8 mos</p>
                                </div>
                                </li>
                            </ul>
                        </div>
                    


                        <hr className='mx-4' />
                        <div className='flex mx-[2%] my-4'>
    
                            <img  src={CollegePic} alt='collegePic' className='w-[6%] h-[4%] inline-block rounded-[50%]'/>
                                        <div className='ml-4'>
                                       <p className='font-medium text-sm text-gray-600'> Web Development and Designing  </p>
                                       <p className='font-normal text-xs text-gray-500'> The Sparks Foundation .Internship</p>
                                       <p className='font-normal text-xs text-gray-400'>Jan 2021 .1 mo</p>
                                       <p className='font-normal text-xs text-gray-400'>India</p>

                                       </div>

                          
                        </div>
                    
                    </div>
                   
            
        </>
    );
};

export default Languages;
