import React from 'react';
import "../SubHeader/SubHeader.css"
const SubHeader = () => {
    return (
        <>
<div className="container mx-auto grid gap-7 xs:gap-4 grid-cols-12 lg:px-[10rem] xs:mt-7 md:mt-0 lg:max-w-none subheaderBtn " >

    <a href="#learners" className="cursor-pointer grid grid-cols-12 lg:col-span-3 md:col-span-3 sm:col-span-6 xs:col-span-6 bg-transparent text-purple-900 text-left py-3 pl-4 pr-3 border-[3px] border-purple-900 rounded-2xl lg:my-10 transition ease-in-out delay-10 hover:-translate-y-0.5 duration-300">
        <div className="col-span-11 maintext ">For <br /> Learners </div>
        <div className="col-span-1 box mt-2">
            <span></span>
            <span></span>  
        </div>
    </a>

    <a href="#internshipseekers"  className="cursor-pointer grid grid-cols-12 lg:col-span-3 md:col-span-3 sm:col-span-6 xs:col-span-6 bg-transparent text-purple-900 text-left py-3 pl-4 pr-3 border-[3px] border-purple-900  rounded-2xl lg:my-10  transition ease-in-out delay-10 hover:-translate-y-0.5 duration-300">
        <div className="col-span-11 maintext ">For <br /> Internship Seekers </div>
        <div className="col-span-1 box mt-2">
            <span></span>
            <span></span>  
        </div>
    </a>

    <a href="#jobseekers"  className="cursor-pointer grid grid-cols-12 lg:col-span-3 md:col-span-3 sm:col-span-6 xs:col-span-6 bg-transparent text-purple-900 text-left py-3 pl-4 pr-3 border-[3px] border-purple-900  rounded-2xl lg:my-10  transition ease-in-out delay-10 hover:-translate-y-0.5 duration-300">
        <div className="col-span-11 maintext ">For <br />Job Seekers</div>
        <div className="col-span-1 box mt-2">
            <span></span>
            <span></span>  
        </div>
    </a>

    <a href="#companies"  className="cursor-pointer grid grid-cols-12 lg:col-span-3 md:col-span-3 sm:col-span-6 xs:col-span-6 bg-transparent text-purple-900 text-left py-3 pl-4 pr-3 border-[3px] border-purple-900  rounded-2xl lg:my-10  transition ease-in-out delay-10 hover:-translate-y-0.5 duration-300">
        <div className="col-span-11 maintext ">For <br /> Companies </div>
        <div className="col-span-1 box mt-2">
            <span></span>
            <span></span>  
        </div>
    </a>

        </div>
        </>
    );
};

export default SubHeader;