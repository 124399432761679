import LearnersCard from '../Home/Learners/LearnersCard.jsx';
import ViewAll from '../Layouts/ViewAll/ViewAll.jsx';
import { getRandomTrainings } from '../../utils/functions';
import { useSelector } from 'react-redux';
    

const PopularTraining = ({ title }) => {

  const { loading, trainings } = useSelector((state) => state.trainings);
    return (
      <>
       <div className="mt-36 mx-auto xs:w-[95%] lg:w-[85%]">
       <h1 className="xs:text-base lg:text-2xl font-medium lg:mx-8">Other Popular Internship & Training Programs To Prefer</h1>

        <div className="container mx-auto grid grid-cols-12 py-6 xs:px-4 lg:px-9 xs:gap-2 lg:gap-6">
        
        {loading ? null : 
          <>

        {trainings && getRandomTrainings(trainings, 4).map((training) => (
        <LearnersCard type="training" {...training} key={training._id} />
        ))}
                 
                
           </> 
          }
          </div>
        <ViewAll title={"Training Programs"} url={"#"} />
</div>
      </>
    );
  };
  
  export default PopularTraining;