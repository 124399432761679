import TextField from '@mui/material/TextField';
import { useState, useEffect } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import MenuItem from '@mui/material/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { NEW_TRAINING_RESET } from '../../../constants/trainingConstants';
import { createTraining, clearErrors } from '../../../actions/trainingAction';
import ImageIcon from '@mui/icons-material/Image';
//import { categories } from '../../../utils/constants';
import { trainingStatuses } from '../../../utils/constants';
import { durationConstants } from '../../../utils/constants';
import MetaData from '../../Layouts/MetaData';
import BackdropLoader from '../../Layouts/BackdropLoader';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { getAdminSkills } from '../../../actions/skillAction';
import { getAdminCareers } from '../../../actions/careerAction';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};



function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}


const NewTraining = () => {

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const { loading, success, error } = useSelector((state) => state.newTraining);
    const [training_name, setTrainingName] = useState("");
    const [slug, setSlug] = useState("");
    const [description, setDescription]= useState("");
    const [headline, setHeadline] = useState("");
    const [status, setStatus] = useState("");
    const [price, setPrice] = useState(0);
    const [offerPrice, setOfferPrice] = useState(0);
    const [extra_images, setExtraImages] = useState([]);
    const [ExtraImagesPreview, setExtraImagesPreview] = useState([]);
    const [demo_view_url, setDemoViewUrl]=useState();
    const [trainingImg, setTrainingImg] = useState("");
    const [trainingImgPreview, setTrainingImgPreview] = useState("");
    const [coverImg, setCoverImg] = useState("");
    const [coverImgPreview, setCoverImgPreview] = useState("");
   

    // syllabus
    const [syllabus, setSyllabus] = useState([]);
    const [syllabusInput, setSyllabusInput] = useState({
        topic: "",
        sub_topics: ""
    });
    const handleSyllabusChange = (e) => {
        setSyllabusInput({ ...syllabusInput, [e.target.name]: e.target.value });
    }
    const addSyllabus = () => {
        if (!syllabusInput.topic.trim() || !syllabusInput.sub_topics.trim()) return;
        setSyllabus([...syllabus, syllabusInput]);
        setSyllabusInput({ topic: "", sub_topics: "" });
    }
    const deleteSyllabus = (index) => {
        setSyllabus(syllabus.filter((s, i) => i !== index))
    }

    //faqs
    const [faqs, setFaqs] = useState([]);
    const [faqsInput, setFaqsInput] = useState({
        question: "",
        answer: ""
    });
    const handleFaqsChange = (e) => {
        setFaqsInput({ ...faqsInput, [e.target.name]: e.target.value });
    }
    const addFaqs = () => {
        if (!faqsInput.question.trim() || !faqsInput.answer.trim()) return;
        setFaqs([...faqs, faqsInput]);
        setFaqsInput({ question: "", answer: "" });
    }
    const deleteFaqs = (index) => {
        setFaqs(faqs.filter((s, i) => i !== index))
    }
    const [duration, setDuration] = useState({
        d_num:0,
        d_type: ""
    });
    const handleDnumChange = (e) => {
        setDuration({ ...duration, d_num: e });
        //console.log(duration);
    }
    const handleDtypeChange = (e) => {
        setDuration({ ...duration, d_type: e });
    }
   
    const { skills } = useSelector((state) => state.skills);
    const [selectedSkills,setSelectedSkills]=useState([]);
    const skillshandleChange = (event) => {
        const {
          target: { value },
        } = event;
        
        setSelectedSkills((typeof value === 'string') ? value.split(',') : value);
        
      };
      

    const { careers } = useSelector((state) => state.careers);
    const [selectedCareers,setSelectedCareers]=useState([]);
    const careershandleChange = (event) => {
            const {
              target: { value },
            } = event;
            
            setSelectedCareers((typeof value === 'string') ? value.split(',') : value);
            
          };
  
    

    const handleTrainingImgChange = (e) => {
        const reader = new FileReader();

        reader.onload = () => {
            if (reader.readyState === 2) {
                setTrainingImgPreview(reader.result);
                setTrainingImg(reader.result);
            }
        };

        reader.readAsDataURL(e.target.files[0]);
    }
    const handleCoverImgChange = (e) => {
        const reader = new FileReader();

        reader.onload = () => {
            if (reader.readyState === 2) {
                setCoverImgPreview(reader.result);
                setCoverImg(reader.result);
            }
        };

        reader.readAsDataURL(e.target.files[0]);
    }

    const handleExtraTrainingImagesChange = (e) => {
        const files = Array.from(e.target.files);

        setExtraImages([]);
        setExtraImagesPreview([]);

        files.forEach((file) => {
            const reader = new FileReader();

            reader.onload = () => {
                if (reader.readyState === 2) {
                    setExtraImagesPreview((oldExtraImages) => [...oldExtraImages, reader.result]);
                    setExtraImages((oldExtraImages) => [...oldExtraImages, reader.result]);
                }
            }
            reader.readAsDataURL(file);
        });
    }


    const theme = useTheme();
  
  //const [category, setCategory]=useState([]);
  //const [acategories, setCategories]=useState([]);
//   const categorieshandleChange = (event) => {
//     const {
//       target: { value },
//     } = event;
    
//     setCategory((typeof value === 'string') ? value.split(',') : value);
    
//   };
  
    const newTrainingSubmitHandler = (e) => {
        e.preventDefault();

       
        if (faqs.length < 1) {
            enqueueSnackbar("Add Minimum 2 FAQs", { variant: "warning" });
            return;
        }
        

        const formData = new FormData();
        const modifiedDnum=parseInt((duration.d_num),10);
        let modifiedDtype=duration.d_type;
        if(modifiedDnum==1){
            modifiedDtype=modifiedDtype.substring(0,modifiedDtype.length-1);
        }
        
        formData.set("training_name", training_name);
        formData.set("headline", headline);
        formData.set("slug", slug);
        formData.set("description", description);
        formData.set("price", price);
        formData.set("offerPrice", offerPrice);
        formData.set("status", status);
        formData.set("duration.d_num",modifiedDnum);
        formData.set("duration.d_type",modifiedDtype);
        formData.set("demo_view_url",demo_view_url)
        formData.set("image",trainingImg);
        formData.set("cover_image",coverImg);
        extra_images.forEach((image) => {
            formData.append("extra_image", image);
        });

       
        syllabus.forEach((h) => {
            formData.append("syllabus", JSON.stringify(h));
        });
        
        selectedCareers.forEach((c) => {  
                formData.append("careers", JSON.stringify((careers.find((career) => career.career_name === c))._id));  
        });
        selectedSkills.forEach((s) => {  
            formData.append("skills", JSON.stringify((skills.find((skill) => skill.skill_name === s))._id));  
    });

        faqs.forEach((s) => {
            formData.append("faqs", JSON.stringify(s));
        });

        dispatch(createTraining(formData));
    }

    useEffect(() => {
        if(skills=="" || skills==null || careers=="" || careers==null){
            dispatch(getAdminSkills());
            dispatch(getAdminCareers());
            //console.log(skills);
            }
        if (error) {
            enqueueSnackbar(error, { variant: "error" });
            dispatch(clearErrors());
        }
        if (success) {
            enqueueSnackbar("Training Created", { variant: "success" });
            dispatch({ type: NEW_TRAINING_RESET });
            navigate("/admin/trainings");
        }
    }, [dispatch, error, success, navigate, enqueueSnackbar]);

    return (
        <>
            <MetaData title="Admin: New Training | Lyriclious"/>

            {loading && <BackdropLoader />}
            <form onSubmit={newTrainingSubmitHandler} encType="multipart/form-data" className="flex flex-col sm:flex-row bg-white rounded-lg shadow p-4" id="mainform">

                <div className="flex flex-col gap-3 m-2 sm:w-1/2">
                    <TextField
                        label="Name"
                        variant="outlined"
                        size="small"
                        required
                        value={training_name}
                        onChange={(e) => setTrainingName(e.target.value)}
                    />
                    <TextField
                        label="Slug"
                        variant="outlined"
                        size="small"
                        required
                        value={slug}
                        onChange={(e) => setSlug(e.target.value)}
                    />
                    <TextField
                        label="Headline"
                        variant="outlined"
                        size="small"
                        required
                        value={headline}
                        onChange={(e) => setHeadline(e.target.value)}
                    />
                    <TextField
                        label="Demo Video URL"
                        variant="outlined"
                        size="small"
                        required
                        value={demo_view_url}
                        onChange={(e) => setDemoViewUrl(e.target.value)}
                    />
                    <TextField
                        label="Description"
                        multiline
                        rows={3}
                        required
                        variant="outlined"
                        size="small"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                    <div className="flex justify-between">
                        <TextField
                            label="Price"
                            type="number"
                            variant="outlined"
                            size="small"
                            InputProps={{
                                inputProps: {
                                    min: 0
                                }
                            }}
                            required
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                        />
                        <TextField
                            label="Offer Price"
                            type="number"
                            variant="outlined"
                            size="small"
                            InputProps={{
                                inputProps: {
                                    min: 0
                                }
                            }}
                            required
                            value={offerPrice}
                            onChange={(e) => setOfferPrice(e.target.value)}
                        />
                    </div>
                    <div className="flex justify-between gap-4">        
        <Select
          label="Careers"
          multiple
          fullWidth
          placeholder='Careers'
          variant="outlined"
          size="small"
          required
          value={selectedCareers}
          onChange={careershandleChange}
          input={<OutlinedInput label="Careers" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {careers.length && careers.map((career) => (
            <MenuItem
              key={career.career_name}
              value={career.career_name}
              //style={getStyles(career.career_name, career, theme)}
            >
              {career.career_name}
            </MenuItem>
          ))}
        </Select>
        </div>
                    <div className="flex justify-between gap-4">
        <Select
          label="Skills"
          multiple
          fullWidth
          
          placeholder='Skills'
        variant="outlined"
        size="small"
        required
          value={selectedSkills}
          onChange={skillshandleChange}
          input={<OutlinedInput label="Skills" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {skills.length && skills.map((skill) => (
            <MenuItem
              key={skill.skill_name}
              value={skill.skill_name}
              //style={getStyles(skill.skill_name, skill, theme)}
            >
              {skill.skill_name}
            </MenuItem>
          ))}
        </Select>
        </div>
                    <div className="flex justify-between gap-4">   
                        <TextField
                            label="Status"
                            select
                            fullWidth
                            variant="outlined"
                            size="small"
                            required
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                        >
                            {trainingStatuses.map((el, i) => (
                                <MenuItem value={el} key={i}>
                                    {el}
                                </MenuItem>
                            ))}
                        </TextField>
                        </div>
                        <div className="flex justify-between gap-4">
                        <TextField
                            label="Duration"
                            type="number"
                            variant="outlined"
                            size="small"
                            InputProps={{
                                inputProps: {
                                    min: 0
                                }
                            }}
                            required
                            value={duration.d_num}
                            onChange={(e) => handleDnumChange(e.target.value)}
                        />                      
                        <TextField
                            label="Duration Unit"
                            select
                            fullWidth
                            variant="outlined"
                            size="small"
                            required
                            value={duration.d_type}
                            onChange={(e) => handleDtypeChange(e.target.value)}
                        >
                            {durationConstants.map((el, i) => (
                                <MenuItem value={el} key={i}>
                                    {el}
                                </MenuItem>
                            ))}
                        </TextField>
                        
                    </div>

                   


                    <h2 className="font-medium">Images</h2>
                    <div> {/* training image start */}
                    <div className="w-24 h-10 flex items-center justify-center border rounded-lg">
                            {!trainingImgPreview ? <ImageIcon /> :
                                <img draggable="false" src={trainingImgPreview} alt="Training Image" className="w-full h-full object-contain" />
                            }
                        </div>
                        <label className="rounded bg-gray-400 text-center cursor-pointer text-white py-2 px-2.5 shadow hover:shadow-lg">
                            <input
                                type="file"
                                name="TrainingImage"
                                accept="image/*"
                                onChange={handleTrainingImgChange}
                                className="hidden"
                            />
                            Choose Training Image
                        </label>
                        </div> {/* training image end */}

                        <div> {/* cover image start */}
                    <div className="w-24 h-10 flex items-center justify-center border rounded-lg">
                            {!coverImgPreview ? <ImageIcon /> :
                                <img draggable="false" src={coverImgPreview} alt="Cover Image" className="w-full h-full object-contain" />
                            }
                        </div>
                        <label className="rounded bg-gray-400 text-center cursor-pointer text-white py-2 px-2.5 shadow hover:shadow-lg">
                            <input
                                type="file"
                                name="CoverImage"
                                accept="image/*"
                                onChange={handleCoverImgChange}
                                className="hidden"
                            />
                            Choose Cover Image
                        </label>
                        </div> {/* cover image end */}

                    

                </div>

                <div className="flex flex-col gap-2 m-2 sm:w-1/2">
                    <h2 className="font-medium">Syllabus</h2>

                    <div className="flex justify-evenly gap-2 items-center">
                        <TextField value={syllabusInput.topic} onChange={handleSyllabusChange} name="topic" label="Topic" placeholder="Topic" variant="outlined" size="small" />
                        <TextField value={syllabusInput.sub_topics} onChange={handleSyllabusChange} name="sub_topics" label="Sub Topics" placeholder="Sub Topics" variant="outlined" size="small" />
                        <span onClick={() => addSyllabus()} className="py-2 px-6 bg-purple-900 text-white rounded hover:shadow-lg cursor-pointer">Add</span>
                    </div>

                    <div className="flex flex-col gap-1.5">
                        {syllabus.map((sylla, i) => (
                            <div className="flex justify-between items-center text-sm rounded bg-blue-50 py-1 px-2">
                                <p className="text-gray-500 font-medium">{sylla.topic}</p>
                                <p>{sylla.sub_topics}</p>
                                <span onClick={() => deleteSyllabus(i)} className="text-red-600 hover:bg-red-200 bg-red-100 p-1 rounded-full cursor-pointer">
                                    <DeleteIcon />
                                </span>
                            </div>
                        ))}
                    </div>
                    <h2 className="font-medium">FAQs</h2>

                    <div className="flex justify-evenly gap-2 items-center">
                        <TextField value={faqsInput.question} onChange={handleFaqsChange} name="question" label="Question" placeholder="Question" variant="outlined" size="small" />
                        <TextField value={faqsInput.answer} onChange={handleFaqsChange} name="answer" label="Answer" placeholder="Answer" variant="outlined" size="small" />
                        <span onClick={() => addFaqs()} className="py-2 px-6 bg-purple-900 text-white rounded hover:shadow-lg cursor-pointer">Add</span>
                    </div>

                    <div className="flex flex-col gap-1.5">
                        {faqs.map((faq, i) => (
                            <div className="flex justify-between items-center text-sm rounded bg-blue-50 py-1 px-2">
                                <p className="text-gray-500 font-medium">{faq.question}</p>
                                <p>{faq.answer}</p>
                                <span onClick={() => deleteFaqs(i)} className="text-red-600 hover:bg-red-200 bg-red-100 p-1 rounded-full cursor-pointer">
                                    <DeleteIcon />
                                </span>
                            </div>
                        ))}
                    </div>

                    <h2 className="font-medium">Extra Images</h2>
                    <div className="flex gap-2 overflow-x-auto h-32 border rounded">
                        {ExtraImagesPreview.map((image, i) => (
                            <img draggable="false" src={image} alt="Training" key={i} className="w-full h-full object-contain" />
                        ))}
                    </div>
                    <label className="rounded font-medium bg-gray-400 text-center cursor-pointer text-white p-2 shadow hover:shadow-lg my-2">
                        <input
                            type="file"
                            name="extraimages"
                            accept="extraimage/*"
                            multiple
                            onChange={handleExtraTrainingImagesChange}
                            className="hidden"
                        />
                        Choose Files
                    </label>

                    <div className="flex justify-end">
                        <input form="mainform" type="submit" className="bg-purple-900 uppercase w-1/3 p-3 text-white font-medium rounded shadow hover:shadow-lg cursor-pointer" value="Submit" />
                    </div>

                </div>

            </form>
        </>
    );
};

export default NewTraining;
