import './Loader.css';

const Loader = () => {
  return (
      <div className="min-h-screen min-w-full flex items-center justify-center" id="loaderbody">
          <div class="boxes">
    <div class="box">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
    <div class="box">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
    <div class="box">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
    <div class="box">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</div>
      </div>
  );
};

export default Loader;
