import { useEffect,} from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import { clearErrors, deleteTrainingschedule, getAdminTrainingschedules } from '../../../actions/trainingscheduleAction';
//import Rating from '@mui/material/Rating';
import { DELETE_TRAININGSCHEDULE_RESET } from '../../../constants/trainingscheduleConstants';
import Actions from '../Actions';
import MetaData from '../../Layouts/MetaData';
import BackdropLoader from '../../Layouts/BackdropLoader';
import Loader from '../../Layouts/Loader';
import { getAllUsers } from '../../../actions/userAction';
import { getAdminTrainings } from '../../../actions/trainingAction';
import { Avatar } from '@mui/material';

const TrainingscheduleTable = () => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { trainingschedules, error } = useSelector((state) => state.trainingschedules );
    const { users } = useSelector((state) => state.users);
    const { trainings } = useSelector((state) => state.trainings);
    const { loading, isDeleted, error: deleteError } = useSelector((state) => state.trainingschedule);
    
//     How to select multiple states ::

// const [trainingschedules,error,users,trainings] = useSelector((state) => [
//     state.trainingschedules,
//     state.trainingschedules,
//     state.users,
//     state.trainings,
//   ], shallowEqual);
            
    useEffect(() => {

        if (error) {
            enqueueSnackbar(error, { variant: "error" });
            dispatch(clearErrors());
        }
        if (deleteError) {
            enqueueSnackbar(deleteError, { variant: "error" });
            dispatch(clearErrors());
        }
        if (isDeleted) {
            enqueueSnackbar("Trainingschedule Deleted Successfully", { variant: "success" });
            dispatch({ type: DELETE_TRAININGSCHEDULE_RESET });
        }
        dispatch(getAdminTrainingschedules());
        dispatch(getAllUsers());
        dispatch(getAdminTrainings());
    }, [dispatch, error, deleteError, isDeleted, enqueueSnackbar]);

    const deleteTrainingscheduleHandler = (slug) => {
        dispatch(deleteTrainingschedule(slug));
    }

    const columns = [   
        {
            field: "slug",
            headerName: "Training Code",
            minWidth: 30,
            flex: 0.3,
        },
        {
            field: "start_date",
            headerName: "Start Date",
            minWidth: 30,
            flex: 0.2,
        },
        {
            field: "trainingAndInternship",
            headerName: "Training and Internship",
            minWidth: 150,
            flex: 0.4,
            align: "left",
            headerAlign: "left",
            renderCell: (params) => {
                return (
                    <div className="flex items-center gap-2">
                        <Avatar
                    alt="Avatar"
                    src={params.row.trainingImg}
                />
                        {params.row.trainingAndInternship}
                    </div>
                )
            },
            
        },
        
        {
            field: "instructor",
            headerName: "Instructor Name",
            minWidth: 150,
            flex: 0.2,
            align: "left",
            headerAlign: "left",
            renderCell: (params) => {
                return (
                    <div className="flex items-center gap-2">
                        <Avatar
                    alt="Avatar"
                    src={params.row.instructorImg}
                />
                        {params.row.instructor}
                    </div>
                )
            },
            
        },
       
        {
            field: "status",
            headerName: "Status",
            minWidth: 50,
            flex: 0.2,
        },
      
        {
            field: "actions",
            headerName: "Actions",
            minWidth: 100,
            flex: 0.2,
            type: "number",
            sortable: false,
            renderCell: (params) => {
                return (
                    <Actions editRoute={"trainingschedule"} deleteHandler={deleteTrainingscheduleHandler} slug={params.row.slug} />
                );
            },
        },
    ];

    const rows = [];

    (users.length && trainings.length && trainingschedules.length) && trainingschedules.forEach((item) => {
        rows.unshift({
            slug:item.slug,
            id: item._id,
            start_date:item.start_date.substr(0,10),
            status: item.status,
            trainingAndInternship:(trainings.find((training) => training._id === item.trainingandinternship)).training_name,
            trainingImg:(trainings.find((training) => training._id === item.trainingandinternship)).image.url,
            instructor:(users.find((user) => user._id === item.instructor)).user_name,
            instructorImg:(users.find((user) => user._id === item.instructor)).avatar.url,
        });
        
    }
    );

    return (
        
        <>
        
            <MetaData title="Admin Training Schedules | Lyriclious" /> 
            <div className="flex justify-between items-center">
                <h1 className="text-lg font-medium uppercase">Training Schedules</h1>
                <Link to="/admin/new_trainingschedule" className="py-2 px-4 rounded shadow font-medium text-white bg-purple-900 hover:shadow-lg">New Training Schedule</Link>
            </div>
           
            {loading ? <Loader /> : (
            <>
             <div className="bg-white rounded-xl shadow-lg w-full" style={{ height: 470 }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={10}
                    disableSelectIconOnClick
                    sx={{
                        boxShadow: 0,
                        border: 0,
                    }}
                />
                </div>
                </>
            )};
           
            
        </>
            
    );
};

export default TrainingscheduleTable;