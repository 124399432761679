import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {  useParams } from 'react-router-dom';
import { clearErrors, getTrainingDetails, getSimilarTrainings } from '../../actions/trainingAction';
import axios from 'axios';
import Loader from '../Layouts/Loader';
import { baseurl, backendurl } from '../../utils/constants';
import MetaData from '../Layouts/MetaData';
import TrainingTop from './TrainingTop';
import TrainingSkills from './TrainingSkills';
import CareerOptions from "./CareerOptions.jsx";
import CertificateSample from './CertificateSample';
import TrainingTopics from './TrainingTopics';
import PopularTraining from './PopularTraining';
import React from 'react';


const TrainingInternshipDetails = () => {

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const params = useParams();
    //const navigate = useNavigate();

    const [open, setOpen] = useState(false);
    
    
    const { training, loading, error } = useSelector((state) => state.trainingDetails);
    const { wishlistItems } = useSelector((state) => state.wishlist);
    

    // const settings = {
    //     autoplay: true,
    //     autoplaySpeed: 2000,
    //     dots: true,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     prevArrow: <PreviousBtn />,
    //     nextArrow: <NextBtn />,
    // };

    const trainingId = params.id;
    const itemInWishlist = wishlistItems.some((i) => i.training === trainingId);
    
 
    useEffect(() => {
        if (error) {
            enqueueSnackbar(error, { variant: "error" });
            dispatch(clearErrors());
        }
       
        dispatch(getTrainingDetails(trainingId));
    }, [dispatch, trainingId , error, enqueueSnackbar]);

    useEffect(() => {
        dispatch(getSimilarTrainings(training?.category));
    }, [dispatch, training, training.category]);
    
    
    return (
        <>
            {loading ? <Loader /> : (
                <>
                    <MetaData title={training.name} />
                    
                    <main className="mt-12 sm:mt-0">
                       
                     
                        <TrainingTop {...training} />
                        <TrainingSkills skillArray={training.skills}/>
                        <CareerOptions careerArray={training.careers} trainingname={training.training_name}/>
                        <CertificateSample certiTitle={training.training_name}/>
                        
                        <TrainingTopics trainingSyllabus={training.syllabus}/>
                        <PopularTraining />
                    </main>
                </>
            )}
        </>
    );
};

export default TrainingInternshipDetails;