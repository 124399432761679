import { useEffect, useState } from 'react';
import Sidebar from '../../Sidebar';
import { useSelector } from 'react-redux';
import {  useNavigate } from 'react-router-dom';
import Loader from '../../../Layouts/Loader';
import MetaData from '../../../Layouts/MetaData';
import axios from "axios";
import React from "react";
import { baseurl, backendurl } from "../../../../utils/constants";
import TrainingandInternshipCard from './TrainingandInternshipCard';



const Mytrainingandinternships = ({ activeTab, children }) => {

    const navigate = useNavigate();
    const { user, loading, isAuthenticated } = useSelector(state => state.user)
    const config = {
        headers: {
            "Content-Type": "application/json",
            "Accept":"application/json"
        },
        withCredentials : true
    }
    

    useEffect(() => {
        if (isAuthenticated === false) {
            navigate("/login")
        }
    }, [isAuthenticated, navigate]);

    

    const [onMobile, setOnMobile] = useState(false);
    const [toggleSidebar, setToggleSidebar] = useState(false);
    const [boughtTrainings, setBoughtTrainings]=useState([]);
    useEffect(() => {
        if (window.innerWidth < 600) {
            setOnMobile(true);
        }
    }, [])
  

    React.useEffect(() => {
        if(user)
         axios.get(backendurl+'/api/v1/profile/trainingandinternships',config).then((response) => {  
              setBoughtTrainings(response.data.allproducts);
        })

   
       
      }, []);
    

    return (
        <>
            <MetaData title="Enrolled Training and Internships" />

            {loading && boughtTrainings ? <Loader /> :
                <>
                   
                    <main className="w-full mt-12 sm:mt-0">

                        {/* <!-- row --> */}
                        <div className="w-[20%] sm:w-11/12 sm:mt-4 m-auto mb-7">

                        {!onMobile && <Sidebar activeTab={activeTab} />}
                {toggleSidebar && <Sidebar activeTab={activeTab} setToggleSidebar={setToggleSidebar}/>}
                         
                        </div>
                        <div className='ml-[20%]'>
                        <div className=' mx-auto'>
                        <div className="container mx-auto grid grid-cols-12 xs:gap-2 lg:gap-6 py-6 lg:px-9 xs:px-0 ">
        
        {loading ? null : 
          <>

        { boughtTrainings.map((t) => (
          <TrainingandInternshipCard trainingandinternship={t}  />
        ))}
                 
                
           </> 
          }

        
       
        </div>
                        </div>
                        </div>
                    </main>
                </>
            }
        </>
    );
};

export default Mytrainingandinternships;
;
