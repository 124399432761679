export const ALL_SKILLS_REQUEST = "ALL_SKILLS_REQUEST";
export const ALL_SKILLS_SUCCESS = "ALL_SKILLS_SUCCESS";
export const ALL_SKILLS_FAIL = "ALL_SKILLS_FAIL";

export const SKILL_DETAILS_REQUEST = "SKILL_DETAILS_REQUEST";
export const SKILL_DETAILS_SUCCESS = "SKILL_DETAILS_SUCCESS";
export const SKILL_DETAILS_FAIL = "SKILL_DETAILS_FAIL";

export const ADMIN_SKILLS_REQUEST = "ADMIN_SKILLS_REQUEST";
export const ADMIN_SKILLS_SUCCESS = "ADMIN_SKILLS_SUCCESS";
export const ADMIN_SKILLS_FAIL = "ADMIN_SKILLS_FAIL";

export const NEW_SKILL_REQUEST = "NEW_SKILL_REQUEST";
export const NEW_SKILL_SUCCESS = "NEW_SKILL_SUCCESS";
export const NEW_SKILL_RESET = "NEW_SKILL_RESET";
export const NEW_SKILL_FAIL = "NEW_SKILL_FAIL";

export const UPDATE_SKILL_REQUEST = "UPDATE_SKILL_REQUEST";
export const UPDATE_SKILL_SUCCESS = "UPDATE_SKILL_SUCCESS";
export const UPDATE_SKILL_RESET = "UPDATE_SKILL_RESET";
export const UPDATE_SKILL_FAIL = "UPDATE_SKILL_FAIL";

export const DELETE_SKILL_REQUEST = "DELETE_SKILL_REQUEST";
export const DELETE_SKILL_SUCCESS = "DELETE_SKILL_SUCCESS";
export const DELETE_SKILL_RESET = "DELETE_SKILL_RESET";
export const DELETE_SKILL_FAIL = "DELETE_SKILL_FAIL";


export const SLIDER_SKILLS_REQUEST = "SLIDER_SKILLS_REQUEST";
export const SLIDER_SKILLS_SUCCESS = "SLIDER_SKILLS_SUCCESS";
export const SLIDER_SKILLS_FAIL = "SLIDER_SKILLS_FAIL";

export const REMOVE_SKILL_DETAILS = "REMOVE_SKILL_DETAILS";

export const CLEAR_ERRORS = "CLEAR_ERRORS";