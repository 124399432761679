import "../Companies/Hiring.css"

const ServicesCard = ({ title , desc, imgsrc,redirect}) => {


  
    return (
        <>
       
       <a href={redirect} className="grid grid-cols-8 lg:col-span-3 xs:col-span-6 px-4 border-[1.5px] border-purple-300 rounded-lg col-span-3 py-5 mb-4 shadow-md shadow-purple-200 cursor-pointer">
                <span className="col-span-4 lg:px-2  mx-2 ">{imgsrc} </span>
                <span className="col-span-4">
                <p className="mx-2 font-medium text-lg my-auto pb-4">{title}</p>
                <p className="mx-2 font-normal text-sm">{desc}Lorum ipsum text here Lorum ipsum text here.Lorum ipsum text here Lorum ipsum text here.</p>
                <a href={redirect} className="block text-blue-500 text-sm font-small pt-6 text-right">Know More &#62;</a>
                </span>
                
                
                
            </a>


            
    

        </>
    );
};



  export default ServicesCard;