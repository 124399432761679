import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {  useNavigate } from 'react-router-dom';
import CollegePic from "../../../../assets/images/Profile/collegePic.png"
import EditIcon from '@mui/icons-material/Edit'
import AddIcon from "@mui/icons-material/Add"

const Education = ({ activeTab, children }) => {

    const navigate = useNavigate();

    const { user, loading, isAuthenticated } = useSelector(state => state.user)

    useEffect(() => {
        if (isAuthenticated === false) {
            navigate("/login")
        }
    }, [isAuthenticated, navigate]);

    

    

    return (
        <> 
                        {/* <!-- row --> */}
                        <div className="pt-4 gap-3.5 sm:w-11/12 sm:mt-4  mb-7 border-gray-300 border-[1.2px] mx-auto rounded-xl ">
                           <span >
                            <h1 className='inline-block mx-[2%]  text-xl'>Education</h1>
                            <AddIcon className='ml-[71%] text-gray-600'  />
                            <EditIcon className='ml-[2%] text-gray-600' />
                           </span>
                            
                            <div className='flex mx-[2%] my-4'>
    
                            <img  src={CollegePic} alt='collegePic' className='w-[6%] h-[4%] inline-block rounded-[50%]'/>
                                        <div className='ml-4'>
                                       <p className='font-medium text-sm text-gray-600'> Dr. A.P.J. Adbul Kalam Technical University  </p>
                                       <p className='font-normal text-xs text-gray-500'> Bachelor of Technology - B.Tech, Computer Science and Engineering</p>
                                       <p className='font-normal text-xs text-gray-400'> June 2017 - Aug 2021</p>
                                       <p className='font-normal text-xs text-gray-500 mt-2'>Activities and socities :Secretary@ Web Design & Media Council</p>

                                       </div>

                          
                        </div>

                        <hr className='mx-4' />
                     
                      

                      
                        <div className='flex mx-[2%] my-4'>
    
                            <img  src={CollegePic} alt='collegePic' className='w-[6%] h-[4%] inline-block rounded-[50%]'/>
                                        <div className='ml-4'>
                                       <p className='font-medium text-sm text-gray-600'> Delhi Public School </p>
                                       <p className='font-normal text-xs text-gray-500'> Intermediate, Science</p>
                                       <p className='font-normal text-xs text-gray-400'>2014-2016</p>
                                       <p className='font-normal text-xs text-gray-500 mt-2'>Grade:87%</p>
                                       <p className='font-normal text-xs text-gray-500 mt-2'>Scored 99% in Information Practices ( Java + MySQL) Subject.</p>


                                       </div>

                          
                        </div>
                    
                    </div>
                   
            
        </>
    );
};

export default Education;
