import "./TrainingDetails.css";

const TrainingTopics = ({syllabus}) => {
//const trainingSkills=training.syllabus;
const syll=syllabus;
  
    return (
        <>
       <div className="mt-20 mx-auto w-[85%]">
        <h1 className="xs:text-base lg:text-2xl font-medium">Topics To Be Covered</h1>
        <div className="flex sm:grid-cols-12 mt-8 w-[97%]  grid lg:grid-cols-6  xs:gap-2 lg:gap-8">
        {syll && syll.map((item, index) => {
          const { topic, sub_topics} = item;
          return (
            <div className="border-[1px] border-purple-900 rounded-lg col-span-3 pb-4 pt-8">
                <span className="bg-purple-900 px-2 py-3">.</span>
                <span className="mx-6  lg:font-medium mt-4">{topic}</span>
                <p className="xs:mx-2 lg:mx-10 xs:text-sm font-normal mt-5">{sub_topics}</p>
            </div>

        )
          })
          }

            
        </div>
        </div>
         
    

        </>
    );
};

export default TrainingTopics;
