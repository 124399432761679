import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {  useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit'
import AddIcon from "@mui/icons-material/Add"

const Languages = ({ activeTab, children }) => {

    const navigate = useNavigate();

    const { user, loading, isAuthenticated } = useSelector(state => state.user)

    useEffect(() => {
        if (isAuthenticated === false) {
            navigate("/login")
        }
    }, [isAuthenticated, navigate]);

    

    

    return (
        <> 
                        {/* <!-- row --> */}
                        <div className="pt-4 gap-3.5 sm:w-11/12 sm:mt-4  mb-7 border-gray-300 border-[1.2px] mx-auto rounded-xl ">
                           <span >
                            <h1 className='inline-block mx-[2%]  text-xl'>Languages</h1>
                            <AddIcon className='ml-[69%] text-gray-600'  />
                            <EditIcon className='ml-[2%] text-gray-600' />
                           </span>
                            
                            <div className=' mx-[2%] my-4'>
    
                            <p className='text-gray-700 font-normal'> English</p>
                            <p className='text-gray-500 text-sm font-thin'> Professional working proficiency</p>
                          
                        </div>
                        <hr className='mx-4' />
                        <div className=' mx-[2%] my-4'>
    
                            <p className='text-gray-700 font-normal '> Hindi</p>
                            <p className='text-gray-500 text-sm font-thin'> Fulll Professional proficiency</p>
                          
                        </div>
                    
                    </div>
                   
            
        </>
    );
};

export default Languages;
