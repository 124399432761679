export const ALL_CAREERS_REQUEST = "ALL_CAREERS_REQUEST";
export const ALL_CAREERS_SUCCESS = "ALL_CAREERS_SUCCESS";
export const ALL_CAREERS_FAIL = "ALL_CAREERS_FAIL";

export const CAREER_DETAILS_REQUEST = "CAREER_DETAILS_REQUEST";
export const CAREER_DETAILS_SUCCESS = "CAREER_DETAILS_SUCCESS";
export const CAREER_DETAILS_FAIL = "CAREER_DETAILS_FAIL";

export const ADMIN_CAREERS_REQUEST = "ADMIN_CAREERS_REQUEST";
export const ADMIN_CAREERS_SUCCESS = "ADMIN_CAREERS_SUCCESS";
export const ADMIN_CAREERS_FAIL = "ADMIN_CAREERS_FAIL";

export const NEW_CAREER_REQUEST = "NEW_CAREER_REQUEST";
export const NEW_CAREER_SUCCESS = "NEW_CAREER_SUCCESS";
export const NEW_CAREER_RESET = "NEW_CAREER_RESET";
export const NEW_CAREER_FAIL = "NEW_CAREER_FAIL";

export const UPDATE_CAREER_REQUEST = "UPDATE_CAREER_REQUEST";
export const UPDATE_CAREER_SUCCESS = "UPDATE_CAREER_SUCCESS";
export const UPDATE_CAREER_RESET = "UPDATE_CAREER_RESET";
export const UPDATE_CAREER_FAIL = "UPDATE_CAREER_FAIL";

export const DELETE_CAREER_REQUEST = "DELETE_CAREER_REQUEST";
export const DELETE_CAREER_SUCCESS = "DELETE_CAREER_SUCCESS";
export const DELETE_CAREER_RESET = "DELETE_CAREER_RESET";
export const DELETE_CAREER_FAIL = "DELETE_CAREER_FAIL";


export const SLIDER_CAREERS_REQUEST = "SLIDER_CAREERS_REQUEST";
export const SLIDER_CAREERS_SUCCESS = "SLIDER_CAREERS_SUCCESS";
export const SLIDER_CAREERS_FAIL = "SLIDER_CAREERS_FAIL";

export const REMOVE_CAREER_DETAILS = "REMOVE_CAREER_DETAILS";

export const CLEAR_ERRORS = "CLEAR_ERRORS";