import { useEffect } from 'react';
// import Categories from '../Layouts/Categories';
import Banner from './Banner/Banner';
import DealSlider from './DealSlider/DealSlider';
import { useDispatch, useSelector } from 'react-redux';
// import { clearErrors, getSliderProducts } from '../../actions/productAction';
import { clearErrors, getSliderTrainings } from '../../actions/trainingAction';
import { useSnackbar } from 'notistack';
import MetaData from '../Layouts/MetaData';
import SubHeader from './SubHeader/SubHeader';
import TechStack from './TechStack/TechStack';
import Learners from './Learners/Learners';
import Companies from './Companies/Companies';
import Internships from './Internships/Internships';
import Jobs from './Jobs/Jobs';
import Loader from '../Layouts/Loader';
import jwt_decode from "jwt-decode";


const Home = () => {

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { error, loading } = useSelector((state) => state.trainings);

  

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: "error" });
      dispatch(clearErrors());
    }
    //dispatch(getSliderProducts());
    dispatch(getSliderTrainings());
  }, [dispatch, error, enqueueSnackbar]);

  return (
    <>
    
      <MetaData title="Lyriclious EdTech | IT Services" />
     { /* <Categories /> */ }
      <main className="flex flex-col gap-3">
        <Banner />
        
        <SubHeader /> 
        { /* {!loading && <ProductSlider title={"Suggested for You"} tagline={"Based on Your Activity"} />}  */}
        <TechStack />
        {loading ? <Loader /> : (
                <>
        <Learners />
        {/* <DealSlider title={"Discounts for You"} /> */}
        
        </>)}
        <Internships />
        <Jobs />
        <Companies />
        { /* <TrainingSlider title={"Trainings"} tagline={"hello"} /> */ }
      </main>
     
    </>
  );
};

export default Home;
