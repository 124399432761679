import WebTrainingIcon from "../../assets/images/Training/WebTraining.jsx"
import "./TrainingDetails.css";
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import LaptopChromebookIcon from '@mui/icons-material/LaptopChromebook';
import { addItemsToCart } from '../../actions/cartAction';
import axios from "axios";
import React from "react";
import { baseurl, backendurl } from "../../utils/constants.js";
import {getDiscount} from "../../utils/functions.js"


const TrainingTop = ({_id,slug, training_name, description, price, offerPrice, duration}) => {
    const dispatch = useDispatch();
    const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  const d = new Date();
  
  const { cartItems } = useSelector((state) => state.cart);
  const { wishlistItems } = useSelector((state) => state.wishlist);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [viewAll, setViewAll] = useState(false);
  const [enrollstatus , setEnrollStatus]=useState();
  const { isAuthenticated, user } = useSelector((state) => state.user);
  const [startDate,setStartDate]=useState("");
  const [scheduleDays,setScheduleDays]=useState([]);
  const [scheduleDaysOverall,setScheduleDaysOverall]=useState("");
  const [trainingScheduleSlug, setTrainingScheduleSlug]=useState();
  const addToCartHandler = () => {
    trainingScheduleSlug && dispatch(addItemsToCart("trainingschedule" , trainingScheduleSlug));
    enqueueSnackbar("Product Added To Cart", { variant: "success" });
}
React.useEffect(() => {
    if(_id){
        axios.get(backendurl+'/api/v1/order/isproductbought/'+_id).then((response) => {
            setEnrollStatus(response.data.isordered);
            if(response.data.trainingschedule){
                var date = new Date(response.data.trainingschedule.start_date)
                setStartDate(date.getDate() +  " " + date.toLocaleString('default', { month: 'long' }) + " " + date.getFullYear());
                setScheduleDays(response.data.trainingschedule.scheduled_days);
                setTrainingScheduleSlug(response.data.trainingschedule.slug);
        
                // Needs optimization
                const weekends=["Sunday","Saturday"];
                const weekdays=["Monday","Tuesday","Wednesday","Thursday","Friday"];
                let isweekend = weekends.every(i => response.data.trainingschedule.scheduled_days.includes(i));
                let isweekdays = weekdays.every(i => response.data.trainingschedule.scheduled_days.includes(i));
                if(isweekdays && response.data.trainingschedule.scheduled_days.length==5)
                {setScheduleDaysOverall("Weekdays"); } 
                else if(isweekend && response.data.trainingschedule.scheduled_days.length==2)
                {setScheduleDaysOverall("Weekends");}
                else if(response.data.trainingschedule.scheduled_days.length==7){
                    setScheduleDaysOverall("Daily");
                }
                else{
                    setScheduleDays(response.data.trainingschedule.scheduled_days.map(function (i) { return i.substr(0,3) }));
                    setScheduleDaysOverall("random");
                }
                // Needs optimization
            }
            else{
                setStartDate("All Batches Full");
            }
           
    
           
          });
    }
   
  }, [enrollstatus,user,isAuthenticated]);

const handleDialogClose = () => {
    setOpen(!open);
}

const itemInCart = cartItems.some((i) => i.product === _id);

const goToCart = () => {
    navigate('/cart');
}
const getdisablestatus=()=>{
    if(enrollstatus || startDate=="All Batches Full"){
         return true;
    }
    else {
        return false;
    }
}
const buyNow = () => {
    addToCartHandler();
    navigate('/shipping');
}
    return (
        <> 
                        {/* <!-- row --> */}
                        
                            <div className="flex  grid grid-cols-12  bg-purple-900">
                            <div className="xs:col-start-2 xs:col-span-10 sm:col-start-2 sm:col-span-10  lg:col-start-2 lg:col-span-5  text-white  my-8 ">
                                    {/* <h4 className="mt-4 mb-4 font-extralight">Get Trained in</h4> */}
                                    <h2 className="text-3xl mb-4">{training_name}</h2>
                                    <button className="rounded-full border-[1px] border-white mb-12 px-4 py-2 font-extralight text-xs">
                                      Training and Internship Program
                                      <CheckCircleOutlineIcon className="ml-2 font-[2px]"/></button>
                                    <p className="text-slate-50 font-thin text-sm">{(description && description.length>300)?(description.substr(0,300))+"...":description}</p>
                                </div>
                                <div className="xs:col-span-5 sm:col-span-8 lg:col-start-8 lg:col-span-5 lg:float-right">
                                    <WebTrainingIcon  height={'350px'}  width={'450px'}  />
                                </div>
                            </div>

                            <div className="grid grid-cols-8 xs:w-[90%] lg:w-[1200px] xs:h-[120px] mx-auto rounded-lg bg-white pl-8 mt-[-3.5%] shadow-xl">
                                <span className="flex xs:col-span-2 lg:col-span-2 m-auto">
                                <span className=" bg-purple-300 w-[50px] h-[50px] rounded-lg mx-2"><p className="text-center m-[12px]"><LaptopChromebookIcon className="text-lg"/></p></span>
                                    <span>
                                    <p className="text-gray-700  xs:text-sm lg:text-base font-medium">ONLINE {scheduleDays.length?(duration && (" ( "+duration.d_num+" "+duration.d_type+" ) ")):""}</p>
                                    <p className="text-gray-600 xs:text-xs lg:text-sm">{scheduleDaysOverall?(scheduleDaysOverall=="random"?scheduleDays.join(', '):scheduleDaysOverall):(duration && (duration.d_num+" "+duration.d_type))}</p>
                                    </span>
                                </span>

                                <span className="flex xs:col-span-2 lg:col-span-2 m-auto">
                                <span className=" bg-purple-300 w-[50px] h-[50px] rounded-lg mx-2"><p className="text-center m-[12px]"><LaptopChromebookIcon className="text-lg"/></p></span>
                                    <span>
                                    <p className="text-gray-700 xs:text-sm lg:text-base font-medium">Upcoming Batch </p>
                                    <p className={`${startDate=="All Batches Full"?"text-red-400":"text-gray-600"} xs:text-xs lg:text-sm`}>{startDate}</p>
                                    <p></p>
                                    </span>
                                </span>

                                <span className="flex xs:col-span-2 lg:col-span-2 m-auto">
                                <span className=" bg-purple-300 w-[50px] h-[50px] rounded-lg mx-2 "><p className="text-center m-[12px]"><LaptopChromebookIcon className="text-lg"/></p></span>
                                    <span>
                                    <p className="text-gray-700 xs:text-sm lg:text-base font-medium">Training Charges </p>
                                    <div className="flex items-baseline gap-2 text-xl font-medium">
                        <span className="text-sm  font-normal">₹{offerPrice}</span>
                        <span className="text-sm text-gray-500 line-through font-normal">₹{price}</span>
                        <span className="text-sm text-primary-green">{getDiscount(price, offerPrice)}%&nbsp;off</span>
                        </div>
                        <p class="text-gray-400 text-xs font-normal">( excl. GST )</p>
                                </span>
                                </span>

                                <span className="xs:col-span-3 lg:col-span-2 m-auto">
                                    <button onClick={buyNow} className={`${getdisablestatus()?"bg-gray-600":"bg-purple-900"} text-white rounded-lg xs:mx-auto xs:px-8 lg:px-8 xs:py-2 lg:py-4 xs:font-normal lg:font-medium xs:text-base lg:text-lg`} disabled={getdisablestatus()}>{getdisablestatus()?(enrollstatus?"Already Applied":"Batches Full"):"Apply Now"}</button>
                                </span>

                            </div>
                              
        </>
    );
};

export default TrainingTop;
