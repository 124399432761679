import "./TrainingDetails.css";
import { getSliderSkills ,clearErrors} from '../../actions/skillAction';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
const TrainingSkills = (props) => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();


const { loading, skills, error } = useSelector((state) => state.skills);
const getSkillName=(e)=>{
    return (skills && skills.find((skill) => skill._id === e).skill_name);
}
const getSkillImage=(e)=>{

    return (skills && skills.find((skill) => skill._id === e).skill_image.url);
}
useEffect(() => {
    if(skills=="" || skills==null ){
        dispatch(getSliderSkills());
        }
    if (error) {
        enqueueSnackbar(error, { variant: "error" });
        dispatch(clearErrors());
    }
   
}, [dispatch,error, enqueueSnackbar]);


  
    return (
        <>
        
        <div className="mt-20 lg:mx-auto xs:w-[100%] xs:ml-4 lg:w-[80%] ">
        <h1 className="xs:text-base lg:text-2xl xs:ml-4 font-medium">Skills You Will Learn</h1>
        <div className="flex mt-8 lg:w-[97%] lg:ml-3 grid grid-cols-9 xs:gap-2 lg:gap-8">
        { skills.length && props.skillArray && props.skillArray.map((item, index) => {
          
          if(skills && skills.find((skill) => skill._id === item)){
          return (
            <div className="flex lg:px-4 border-[1px] border-purple-400 rounded-lg xs:col-span-4 lg:col-span-3 lg:py-4">
                <span className="xs:px-1 xs:mx-1 lg:px-2 lg:mx-2 "><img src={getSkillImage(item)} width="60px" /></span>
                <p className="lg:mx-2 xs:font-normal xs:text-xs lg:text-sm lg:font-medium my-auto">{getSkillName(item)}</p>
            </div>

        )
          }})
          }

            
        </div>
        </div>
         
    

        </>
    );
};

export default TrainingSkills;
