import Headings from '../../Layouts/Headings/Headings.jsx';
import "../TechStack/TechStack.css"
import Services from './Services.jsx';
import Hiring from './Hiring.jsx';

const Companies = () => {

    return (
      <>
        <Headings title={"For Companies"} type={"firstLevel"} id={"companies"}/>
        <Hiring />
        <Services />
      </>
    );
  };
  
  export default Companies;