import WebFont from 'webfontloader';
import React, {Component} from "react";
import Footer from './components/Layouts/Footer/Footer';
import Header from './components/Layouts/Header/Header';
import Login from './components/User/Login';
import Register from './components/User/Register';
import { Routes, Route, useLocation } from 'react-router-dom';
import { loadUser } from './actions/userAction';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import UpdateProfile from './components/User/UpdateProfile';
import UpdatePassword from './components/User/UpdatePassword';
import ForgotPassword from './components/User/ForgotPassword';
import ResetPassword from './components/User/ResetPassword';
import Account from './components/User/Account';
import ProtectedRoute from './Routes/ProtectedRoute';
import Home from './components/Home/Home';
import Cart from './components/Cart/Cart';
import Shipping from './components/Cart/Shipping';
import OrderConfirm from './components/Cart/OrderConfirm';
import Payment from './components/Cart/Payment';
import OrderStatus from './components/Cart/OrderStatus';
import OrderSuccess from './components/Cart/OrderSuccess';
import MyOrders from './components/Order/MyOrders';
import OrderDetails from './components/Order/OrderDetails';
import Dashboard from './components/Admin/Dashboard';
import MainData from './components/Admin/MainData';
import OrderTable from './components/Admin/OrderTable';
import UpdateOrder from './components/Admin/UpdateOrder';
import ProductTable from './components/Admin/ProductTable';
import NewProduct from './components/Admin/NewProduct';
import UpdateProduct from './components/Admin/UpdateProduct';
import TrainingDetails from './components/TrainingDetails/TrainingDetails';
import Trainings from './components/Trainings/Trainings';
import TrainingTable from './components/Admin/Training/TrainingTable';
import NewTraining from './components/Admin/Training/NewTraining';
import UpdateTraining from './components/Admin/Training/UpdateTraining';
import SkillTable from './components/Admin/Skill/SkillTable';
import NewSkill from './components/Admin/Skill/NewSkill';
import UpdateSkill from './components/Admin/Skill/UpdateSkill';
import CareerTable from './components/Admin/Career/CareerTable';
import NewCareer from './components/Admin/Career/NewCareer';
import UpdateCareer from './components/Admin/Career/UpdateCareer';
import UserTable from './components/Admin/UserTable';
import UpdateUser from './components/Admin/UpdateUser';
import ReviewsTable from './components/Admin/ReviewsTable';
import Wishlist from './components/Wishlist/Wishlist';
import NotFound from './components/NotFound';
import AllCertificates from './components/User/AccountComponents/Certificates/AllCertificates';
import AppliedInternships from './components/User/AccountComponents/AppliedInternships/AppliedInternships';
import AppliedJobs from './components/User/AccountComponents/AppliedJobs/AppliedJobs';
import MockInterviews from './components/User/AccountComponents/MockInterviews/MockInterviews';
import Assessments from './components/User/AccountComponents/Assessments/Assessments';
import MyNotes from './components/User/AccountComponents/MyNotes/MyNotes';
import GiveFeedback from './components/User/AccountComponents/GiveFeedback/GiveFeedback';
import RefferalEarning from './components/User/AccountComponents/ReferralEarning/ReferralEarning';
import Loader from './components/Layouts/Loader';
import TrainingInternshipDetail from './components/TrainingInternshipDetails/TrainingInternshipDetails';
import TrainingandInternships from './components/TrainingInternships/TrainingandInternships';
import TrainingPayments from './components/Payments/TrainingPayments';
import jwt_decode from "jwt-decode";
import Certificate from './components/CertificateFormat/Certificate';
import NewTrainingschedule from './components/Admin/TrainingSchedule/NewTrainingSchedule';
import UpdateTrainingschedule from './components/Admin/TrainingSchedule/UpdateTrainingSchedule';
import TrainingscheduleTable from './components/Admin/TrainingSchedule/TrainingScheduleTable';
import ComingSoon from './components/Layouts/ComingSoon/ComingSoon';
import AllTrainingandInternships from './components/TrainingInternshipDetails/AllTrainingandInternships';
import Mytrainingandinternships from './components/User/AccountComponents/TrainingAndInternships/Mytrainingandinternships';
import MyTrainings from './components/User/AccountComponents/Trainings/MyTrainings';
import TrainingandInternshipScheduleDetails from './components/User/AccountComponents/TrainingAndInternships/TrainingandInternshipScheduleDetails';

import CookieConsent from './components/Layouts/CookieConsent';
import AboutUs from './components/Pages/AboutUs';
import TermsandConditions from './components/Pages/TermsandConditions';
import RefundPolicy from './components/Pages/RefundPolicy';
import PrivacyPolicy from './components/Pages/PrivacyPolicy';
import Disclaimer from './components/Pages/Disclaimer';


function App() {

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  
  // const [stripeApiKey, setStripeApiKey] = useState("");

  // async function getStripeApiKey() {
  //   const { data } = await axios.get('/api/v1/stripeapikey');
  //   setStripeApiKey(data.stripeApiKey);
  // }
  
 //github login for future 
    //https://www.youtube.com/watch?v=rRn2EisxPl4
    //github login ends
    

function handleCallbackResponse(response){
  console.log("Encoded JWT Token: ",response.credential);
  var userObject = jwt_decode(response.credential);
  console.log(userObject);
}

// useEffect(() => {
//   /* global google */
//   google.accounts.id.initialize({
//     client_id:"678938896955-8v8pvov2gjkfukeh8o80gtbofkl8mhdh.apps.googleusercontent.com",
//     callback:handleCallbackResponse
//   })

// google.accounts.id.prompt();
// })



  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Roboto:300,400,500,600,700"]
      },
    });
  });

  useEffect(() => {
    dispatch(loadUser());
    // getStripeApiKey();
  }, [dispatch]);

  // always scroll to top on route/path change
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, [pathname])

  // disable right click
  // window.addEventListener("contextmenu", (e) => e.preventDefault());
  // window.addEventListener("keydown", (e) => {
  //   if (e.keyCode == 123) e.preventDefault();
  //   if (e.ctrlKey && e.shiftKey && e.keyCode === 73) e.preventDefault();
  //   if (e.ctrlKey && e.shiftKey && e.keyCode === 74) e.preventDefault();
  // });
  
  return (
    <>
    
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />

        {/* <Route path="/product/:id" element={<ProductDetails />} />
        <Route path="/products" element={<Products />} />
        <Route path="/products/:keyword" element={<Products />} /> */}

        <Route path="/training/:id" element={<TrainingDetails />} />
        <Route path="/trainings" element={<Trainings />} />
        <Route path="/trainings/:keyword" element={<Trainings />} />

        
        <Route path="/trainingandinternship/:id" element={<TrainingInternshipDetail />} />
        <Route path="/trainingandinternships" element={<AllTrainingandInternships />} />
        <Route path="/trainingandinternships/:keyword" element={<TrainingandInternships />} />

        <Route path="/cart" element={<Cart />} />

        <Route path="/certificate" element={<Certificate />} />
        <Route path="/comingsoon" element={<ComingSoon />} />

        <Route path="/trainingPayments" element={<TrainingPayments />} />

        {/* order process */}
        <Route path="/shipping" element={
          <ProtectedRoute>
            <Shipping />
          </ProtectedRoute>
        } ></Route>

        <Route path="/order/confirm" element={
          <ProtectedRoute>
            <OrderConfirm />
          </ProtectedRoute>
        } ></Route>

        <Route path="/process/payment" element={
          <ProtectedRoute>
            {/* // stripeApiKey && ( */}
            {/* // <Elements stripe={loadStripe(stripeApiKey)}> */}
            <Payment />
            {/* // </Elements> */}
            {/* ) */}
          </ProtectedRoute>
        } ></Route>

        <Route path="/orders/success" element={<OrderSuccess success={true} />} />
        <Route path="/orders/failed" element={<OrderSuccess success={false} />} />
        {/* order process */}

        <Route path="/order/:id" element={
          <ProtectedRoute>
            <OrderStatus />
          </ProtectedRoute>
        } ></Route>

        <Route path="/orders" element={
          <ProtectedRoute>
            <MyOrders />
          </ProtectedRoute>
        }></Route>

        <Route path="/order_details/:id" element={
          <ProtectedRoute>
            <OrderDetails />
          </ProtectedRoute>
        }></Route>

        <Route path="/account" element={
          <ProtectedRoute>
            <Account />
          </ProtectedRoute>
        } ></Route>

          <Route path="/profile/mycertificates" element={
          <ProtectedRoute>
            <AllCertificates />
          </ProtectedRoute>
        } ></Route>


          <Route path="/profile/trainingandinternships" element={
          <ProtectedRoute>
            <Mytrainingandinternships />
          </ProtectedRoute>
        } ></Route>

        <Route path="/trainingandinternshipschedule/:slug" element={
          <ProtectedRoute>
            <TrainingandInternshipScheduleDetails />
          </ProtectedRoute>
        } ></Route>

          <Route path="/profile/appliedinternships" element={
          <ProtectedRoute>
            <AppliedInternships />
          </ProtectedRoute>
        } ></Route>

          <Route path="/profile/appliedjobs" element={
          <ProtectedRoute>
            <AppliedJobs />
          </ProtectedRoute>
        } ></Route>

          <Route path="/profile/mockinterviews" element={
          <ProtectedRoute>
            <MockInterviews />
          </ProtectedRoute>
        } ></Route>
        <Route path="/profile/assessments" element={
          <ProtectedRoute>
            <Assessments />
          </ProtectedRoute>
        } ></Route>
         <Route path="/profile/mytrainings" element={
          <ProtectedRoute>
            <MyTrainings />
          </ProtectedRoute>
        } ></Route>
           <Route path="/profile/mynotes" element={
          <ProtectedRoute>
            <MyNotes />
          </ProtectedRoute>
        } ></Route>
         <Route path="/profile/givefeedback" element={
          <ProtectedRoute>
            <GiveFeedback />
          </ProtectedRoute>
        } ></Route>
        <Route path="/profile/refferalearning" element={
          <ProtectedRoute>
            <RefferalEarning />
          </ProtectedRoute>
        } ></Route>
        <Route path="/account/update" element={
          <ProtectedRoute>
            <UpdateProfile />
          </ProtectedRoute>
        } ></Route>

        <Route path="/password/update" element={
          <ProtectedRoute>
            <UpdatePassword />
          </ProtectedRoute>
        } ></Route>

        <Route path="/password/forgot" element={<ForgotPassword />} />

        <Route path="/password/reset/:token" element={<ResetPassword />} />

        <Route path="/wishlist" element={
          <ProtectedRoute>
            <Wishlist />
          </ProtectedRoute>
        } ></Route>

        <Route path="/admin/dashboard" element={
          <ProtectedRoute isAdmin={true}>
            <Dashboard activeTab={0}>
              <MainData />
            </Dashboard>
          </ProtectedRoute>
        } ></Route>

        <Route path="/admin/orders" element={
          <ProtectedRoute isAdmin={true}>
            <Dashboard activeTab={1}>
              <OrderTable />
            </Dashboard>
          </ProtectedRoute>
        } ></Route>

        <Route path="/admin/order/:id" element={
          <ProtectedRoute isAdmin={true}>
            <Dashboard activeTab={1}>
              <UpdateOrder />
            </Dashboard>
          </ProtectedRoute>
        } ></Route>

        <Route path="/admin/products" element={
          <ProtectedRoute isAdmin={true}>
            <Dashboard activeTab={2}>
              <ProductTable />
            </Dashboard>
          </ProtectedRoute>
        } ></Route>

        <Route path="/admin/new_product" element={
          <ProtectedRoute isAdmin={true}>
            <Dashboard activeTab={3}>
              <NewProduct />
            </Dashboard>
          </ProtectedRoute>
        } ></Route>

        <Route path="/admin/product/:id" element={
          <ProtectedRoute isAdmin={true}>
            <Dashboard activeTab={2}>
              <UpdateProduct />
            </Dashboard>
          </ProtectedRoute>
        } ></Route>

        <Route path="/admin/trainings" element={
          <ProtectedRoute isAdmin={true}>
            <Dashboard activeTab={2}>
              <TrainingTable />
            </Dashboard>
          </ProtectedRoute>
        } ></Route>

        <Route path="/admin/new_training" element={
          <ProtectedRoute isAdmin={true}>
            <Dashboard activeTab={2}>
              <NewTraining />
            </Dashboard>
          </ProtectedRoute>
        } ></Route>

        <Route path="/admin/training/:id" element={
          <ProtectedRoute isAdmin={true}>
            <Dashboard activeTab={2}>
              <UpdateTraining />
            </Dashboard>
          </ProtectedRoute>
        } ></Route>


        <Route path="/admin/trainingschedules" element={
          <ProtectedRoute isAdmin={true}>
            <Dashboard activeTab={3}>
              <TrainingscheduleTable />
            </Dashboard>
          </ProtectedRoute>
        } ></Route>

        <Route path="/admin/new_trainingschedule" element={
          <ProtectedRoute isAdmin={true}>
            <Dashboard activeTab={3}>
              <NewTrainingschedule />
            </Dashboard>
          </ProtectedRoute>
        } ></Route>

          <Route path="/admin/trainingschedule/:id" element={
          <ProtectedRoute isAdmin={true}>
            <Dashboard activeTab={3}>
              <UpdateTrainingschedule />
            </Dashboard>
          </ProtectedRoute>
        } ></Route>

          <Route path="/admin/skills" element={
          <ProtectedRoute isAdmin={true}>
            <Dashboard activeTab={4}>
              <SkillTable />
            </Dashboard>
          </ProtectedRoute>
        } ></Route>

        <Route path="/admin/new_skill" element={
          <ProtectedRoute isAdmin={true}>
            <Dashboard activeTab={4}>
              <NewSkill />
            </Dashboard>
          </ProtectedRoute>
        } ></Route>

          <Route path="/admin/skill/:id" element={
          <ProtectedRoute isAdmin={true}>
            <Dashboard activeTab={4}>
              <UpdateSkill />
            </Dashboard>
          </ProtectedRoute>
        } ></Route>


        <Route path="/admin/careers" element={
          <ProtectedRoute isAdmin={true}>
            <Dashboard activeTab={5}>
              <CareerTable />
            </Dashboard>
          </ProtectedRoute>
        } ></Route>

        <Route path="/admin/new_career" element={
          <ProtectedRoute isAdmin={true}>
            <Dashboard activeTab={5}>
              <NewCareer />
            </Dashboard>
          </ProtectedRoute>
        } ></Route>

          <Route path="/admin/career/:id" element={
          <ProtectedRoute isAdmin={true}>
            <Dashboard activeTab={5}>
              <UpdateCareer />
            </Dashboard>
          </ProtectedRoute>
        } ></Route>


        <Route path="/admin/users" element={
          <ProtectedRoute isAdmin={true}>
            <Dashboard activeTab={6}>
              <UserTable />
            </Dashboard>
          </ProtectedRoute>
        } ></Route>

        <Route path="/admin/user/:id" element={
          <ProtectedRoute isAdmin={true}>
            <Dashboard activeTab={6}>
              <UpdateUser />
            </Dashboard>
          </ProtectedRoute>
        } ></Route>

        <Route path="/admin/reviews" element={
          <ProtectedRoute isAdmin={true}>
            <Dashboard activeTab={7}>
              <ReviewsTable />
            </Dashboard>
          </ProtectedRoute>
        } ></Route>

        {/* Pages */}
        <Route path="/aboutus" element={<AboutUs />} ></Route>
        <Route path="/terms" element={<TermsandConditions />} ></Route>
        <Route path="/refundpolicy" element={<RefundPolicy />} ></Route>
        <Route path="/privacypolicy" element={<PrivacyPolicy />} ></Route>
        <Route path="/disclaimer" element={<Disclaimer />} ></Route>


        <Route path="loader" element={<Loader />}></Route>
        <Route path="*" element={<NotFound />}></Route>

      </Routes>
      <CookieConsent />
      <Footer />
    </>
  );
}

export default App;
