import {
    ADMIN_SKILLS_FAIL,
    ADMIN_SKILLS_REQUEST,
    ADMIN_SKILLS_SUCCESS,
    ALL_SKILLS_FAIL,
    ALL_SKILLS_REQUEST,
    ALL_SKILLS_SUCCESS,
    CLEAR_ERRORS,
    DELETE_SKILL_FAIL,
    DELETE_SKILL_REQUEST,
    DELETE_SKILL_RESET,
    DELETE_SKILL_SUCCESS,
    NEW_SKILL_FAIL,
    NEW_SKILL_REQUEST,
    NEW_SKILL_RESET,
    NEW_SKILL_SUCCESS,
    SKILL_DETAILS_FAIL,
    SKILL_DETAILS_REQUEST,
    SKILL_DETAILS_SUCCESS,
    UPDATE_SKILL_FAIL,
    UPDATE_SKILL_REQUEST,
    UPDATE_SKILL_RESET,
    UPDATE_SKILL_SUCCESS,
    REMOVE_SKILL_DETAILS,
    SLIDER_SKILLS_FAIL,
    SLIDER_SKILLS_REQUEST,
    SLIDER_SKILLS_SUCCESS,
} from "../constants/skillConstants";

export const skillsReducer = (state = { skills: [] }, { type, payload }) => {

    switch (type) {
        case ALL_SKILLS_REQUEST:
        case ADMIN_SKILLS_REQUEST:
        case SLIDER_SKILLS_REQUEST:
            return {
                loading: true,
                skills: [],
            };
        case ALL_SKILLS_SUCCESS:
            return {
                loading: false,
                skills: payload.skills,
                skillsCount: payload.skillsCount,
                resultPerPage: payload.resultPerPage,
                filteredSkillsCount: payload.filteredSkillsCount,
            };
        case ADMIN_SKILLS_SUCCESS:
        case SLIDER_SKILLS_SUCCESS:
            return {
                loading: false,
                skills: payload,
            };
        case ALL_SKILLS_FAIL:
        case ADMIN_SKILLS_FAIL:
        case SLIDER_SKILLS_FAIL:
            return {
                loading: false,
                error: payload,
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
}

export const skillDetailsReducer = (state = { skill: {} }, { type, payload }) => {

    switch (type) {
        case SKILL_DETAILS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SKILL_DETAILS_SUCCESS:
            return {
                loading: false,
                skill: payload,
            };
        case SKILL_DETAILS_FAIL:
            return {
                loading: false,
                error: payload,
            };
        case REMOVE_SKILL_DETAILS:
            return {
                ...state,
                skill: {},
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
}



// New Skill Reducer
export const newSkillReducer = (state = { skill: {} }, { type, payload }) => {
    switch (type) {
        case NEW_SKILL_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case NEW_SKILL_SUCCESS:
            return {
                loading: false,
                success: payload.success,
                skill: payload.skill,
            };
        case NEW_SKILL_FAIL:
            return {
                ...state,
                loading: false,
                error: payload,
            };
        case NEW_SKILL_RESET:
            return {
                ...state,
                success: false,
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
}

// Skill Reducer
export const skillReducer = (state = {}, { type, payload }) => {
    switch (type) {
        case UPDATE_SKILL_REQUEST:
        case DELETE_SKILL_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_SKILL_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdated: payload,
            };
        case DELETE_SKILL_SUCCESS:
            return {
                ...state,
                loading: false,
                isDeleted: payload,
            };
        case UPDATE_SKILL_FAIL:
        case DELETE_SKILL_FAIL:
            return {
                ...state,
                loading: false,
                error: payload,
            };
        case UPDATE_SKILL_RESET:
            return {
                ...state,
                isUpdated: false,
            };
        case DELETE_SKILL_RESET:
            return {
                ...state,
                isDeleted: false,
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
}

