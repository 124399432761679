import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import certificateThumbnail from '../../../../assets/images/Profile/certificatethumbnail.png'
import getMoreIcon from '../../../../assets/images/Profile/getmore.png'
import ZoomIcon from '@mui/icons-material/ZoomIn'
import DownloadIcon from '@mui/icons-material/Download'
import "./Certificates.css"

const InternshipCertificates = ({ activeTab, children }) => {

const allInternshipCertificates = [
    {
        icon: certificateThumbnail,
        label: "Frontend Training",
        date:"23 March 2023",
        ref: "/account",
    },
    {
        icon: certificateThumbnail,
        label: "Machine Learning and AI",
        date:"23 March 2023",
        ref: "/allcertificates",
    },
    {
        icon: certificateThumbnail,
        label: "Frontend Training",
        date:"23 March 2023",
        ref: "/account",
    },
    {
        icon: certificateThumbnail,
        label: "Frontend Training",
        date:"23 March 2023",
        ref: "/account",
    },
    {
        icon: certificateThumbnail,
        label: "Frontend Training",
        date:"23 March 2023",
        ref: "/account",
    },
   
   
];


    const navigate = useNavigate();

    const { user, loading, isAuthenticated } = useSelector(state => state.user)

    useEffect(() => {
        if (isAuthenticated === false) {
            navigate("/login")
        }
    }, [isAuthenticated, navigate]);

    

    

    return (
        <> 
        {/* <!-- row --> */}
        <div className="sm:w-11/12 sm:mt-4  mb-7 border-gray-300 border-[1.2px] rounded-xl ">
           <span className='inline-block rounded-t-xl py-[2%] bg-purple-200 w-[100%]'>
            <h1 className='inline-block mx-[2%]  text-md text-purple-900'>Internship Certificates</h1>
            <h1 className='inline-block ml-[54%] text-sm text-gray-600'>Achieved {allInternshipCertificates.length} certificates</h1>
           </span>
            
            <div className='grid grid-cols-4 gap-[3%] pt-[3%] mx-[2%]'>

            {allInternshipCertificates.map((item, index) => {
                    const { icon, label, date, ref} = item;
                    return (
                        <div>
                            
                           
                                <Link to={ref} className="gap-3 items-center py-3 px-4 font-normal text-sm">
                                     
                                    <div className='relative certicontainer'>
                                    <div className='iconcontainer absolute grid grid-cols-4 w-[0%] h-[0%] bg-gray-900/70 text-white text-lg'>
                                       <span class="iconItem col-start-2 col-span-1 hidden my-auto text-center">
                                       <ZoomIcon className='text-center'/>
                                       <p className='text-xs text-center'>ZoomIn</p>
                                        </span> 
                                        <span class="iconItem col-span-1 my-auto hidden text-center">
                                        <DownloadIcon />
                                        <p className='text-xs text-center'>Download</p>
                                        </span>
                                    </div>
                                    <img src={icon} />
                                    </div>
                                    <div className='w-3/4 mx-auto mt-2'>
                                    <p className='text-gray-500 text-xs truncate text-center'> {label}</p>
                                    <p className='text-gray-400 text-[9px] text-center'> Issue Date:{date}</p>
                                   
                                    </div>
                                </Link>
                          
                        </div>
                    )
                }
                )}
                  <Link  to={'#'} className={`${allInternshipCertificates.length === 0 ? "col-start-2 col-span-2 mb-6": "font-normal text-sm"}`}>
                                    <img src={getMoreIcon} alt="get more" className={`${allInternshipCertificates.length === 0 ? "w-1/4 mx-auto mt-[15%] text-purple-300": "w-1/2 mx-auto mt-[15%] text-purple-300"}`} />
                                    
                                    <p className='mx-auto block text-purple-300 text-xs truncate text-center mt-4'>
                                        Achieve More
                                    </p>
                                    
                                </Link>
               

            </div>

    </div>
   

</>
    );
};

export default InternshipCertificates;
