import LearnersCard from '../Home/Learners/LearnersCard.jsx';
import ViewAll from '../Layouts/ViewAll/ViewAll.jsx';
import { getRandomTrainings } from '../../utils/functions';
import { useSelector } from 'react-redux';

    

const PopularTraining = ({ title }) => {

  const { loading, trainings } = useSelector((state) => state.trainings);
    return (
      <>
       
       <div className="mt-20 lg:mx-auto xs:w-[100%] xs:ml-4 lg:w-[80%] ">
       <h1 className="xs:text-base xs:ml-4 lg:ml-0 lg:text-2xl font-medium mt-12">Other Popular Internship & Training Programs</h1>
        <div className="container mx-auto grid grid-cols-12 xs:gap-2 lg:gap-6 py-6 lg:px-9 xs:px-0  xs:w-[92%] lg:w-[100%]">
        
        {loading ? null : 
          <>

        {trainings && getRandomTrainings(trainings, 4).map((training) => (
        <LearnersCard type="internship" {...training} key={training.slug} />
        ))}    
           </> 
          }
          </div>
        <ViewAll title={"Training Programs"} url={"#"} />
          </div>
      </>
    );
  };
  
  export default PopularTraining;