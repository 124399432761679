import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {  useNavigate } from 'react-router-dom';
import CollegePic from "../../../../assets/images/Profile/collegePic.png"
import EditIcon from '@mui/icons-material/Edit'
import AddIcon from "@mui/icons-material/Add"

const Certificates = ({ activeTab, children }) => {

    const navigate = useNavigate();

    const { user, loading, isAuthenticated } = useSelector(state => state.user)

    useEffect(() => {
        if (isAuthenticated === false) {
            navigate("/login")
        }
    }, [isAuthenticated, navigate]);

    

    

    return (
        <> 
                        {/* <!-- row --> */}
                        <div className="pt-4 gap-3.5 sm:w-11/12 sm:mt-4  mb-7 border-gray-300 border-[1.2px] mx-auto rounded-xl ">
                           <span >
                            <h1 className='inline-block mx-[2%]  text-xl'>Licenses & certification</h1>
                            <AddIcon className='ml-[55%] text-gray-600'  />
                            <EditIcon className='ml-[2%] text-gray-600' />
                           </span>
                            
                            <div className='flex mx-[2%] my-4'>
    
                            <img  src={CollegePic} alt='collegePic' className='w-[6%] h-[4%] inline-block rounded-[50%]'/>
                                        <div className='ml-2'>
                                       <p className='font-medium text-sm text-gray-600 ml-2' >The Bits and Byte of Computer Networking  </p>
                                       <p className='font-normal text-xs text-gray-500 ml-2'>Google</p>
                                       <p className='font-normal text-xs text-gray-400 ml-2'>Issued Jul 2021</p>
                                       <button class="rounded-full border-2 border-gray-400 text-gray-500 font-normal text-sm px-4 py-[1%] mt-2 mx-auto">Show Credential</button>

                                       </div>

                             </div>

                            <hr className='mx-4' />

                            <div className='flex mx-[2%] my-4'>
    
    <img  src={CollegePic} alt='collegePic' className='w-[6%] h-[4%] inline-block rounded-[50%]'/>
                <div className='ml-2'>
               <p className='font-medium text-sm text-gray-600 ml-2' >The Bits and Byte of Computer Networking  </p>
               <p className='font-normal text-xs text-gray-500 ml-2'>Google</p>
               <p className='font-normal text-xs text-gray-400 ml-2'>Issued Jul 2021</p>
               <button class="rounded-full border-2 border-gray-400 text-gray-500 font-normal text-sm px-4 py-[1%] mt-2 mx-auto">Show Credential</button>

               </div>

     </div>

                     
                    </div>
                   
            
        </>
    );
};

export default Certificates;
