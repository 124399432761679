import { useEffect, useState } from 'react';
import Twitter from '@mui/icons-material/Twitter';
import Instagram from '@mui/icons-material/Instagram';
import Youtube from '@mui/icons-material/YouTube';
import Linkedin from '@mui/icons-material/LinkedIn';
import Whatsapp from '@mui/icons-material/WhatsApp';
import FacebookRoundedIcon from '@mui/icons-material/Facebook';
import React from 'react'


import paymentMethods from '../../../assets/images/payment-methods.svg';
import { useLocation } from 'react-router-dom';
import { baseurl } from '../../../utils/constants';

const footerLinks = [
  {
    title: "about",
    links: [
      {
        name: "Contact Us",
        redirect: baseurl+"/helpcentre",
      },
      {
        name: "About Us",
        redirect: baseurl+"/aboutus",
      },
      {
        name: "Careers",
        redirect: "https://career.lyriclious.com",
      },
      {
        name: "Lyriclious Stories",
        redirect: "https://stories.lyriclious.com",
      },
      {
        name: "Press",
        redirect: "https://stories.lyriclious.com/category/top-stories/news",
      },
     
      {
        name: "Corporate Information",
        redirect: baseurl+"/corporate-information",
      },
    ]
  },
  {
    title: "help",
    links: [
      {
        name: "Payments",
        redirect: baseurl+"/pages/payments",
      },
      {
        name: "Shipping",
        redirect: baseurl+"/pages/shipping",
      },
      {
        name: "Cancellation & Returns",
        redirect: baseurl+"/refundpolicy",
      },
      {
        name: "FAQ",
        redirect: baseurl+"/helpcentre?catalog=55c9c8e2b0000023002c1702&view=CATALOG",
      }
    ]
  },
  {
    title: "policy",
    links: [
      {
        name: "About Us",
        redirect: baseurl+"/aboutus",
      },
      {
        name: "Refund & Cancellation",
        redirect: baseurl+"/refundpolicy",
      },
      {
        name: "Terms & Conditions",
        redirect: baseurl+"/terms",
      },
      
      {
        name: "Privacy Policy",
        redirect: baseurl+"/privacypolicy",
      },
      {
        name: "Disclaimer",
        redirect: baseurl+"/disclaimer",
      },
    ]
  },
  {
    title: "social",
    links: [
      {
        name: "Facebook",
        redirect: "https://www.facebook.com/lyriclious",
      },
      {
        name: "Twitter",
        redirect: "https://twitter.com/lyriclious",
      },
      {
        name: "YouTube",
        redirect: "https://www.youtube.com/lyriclious",
      }
    ]
  }
]

const Footer = () => {

  const location = useLocation();
  const [adminRoute, setAdminRoute] = useState(false);

  useEffect(() => {
    setAdminRoute(location.pathname.split("/", 2).includes("admin"))
  }, [location]);

  return (
    <>
      {!adminRoute && (
        <>
          <footer className="mt-20 w-full py-1 sm:py-4 px-4 sm:px-12 bg-purple-900 text-white text-xs border-gray-600 flex flex-col sm:flex-row overflow-hidden">
            <div className=" lg:w-7/12 w-full flex flex-col xs:flex-row">

              {footerLinks.map((el, i) => (
                <div className="xs:w-1/2 lg:w-1/5 flex flex-col gap-2 my-3 xs:my-6 ml-5" key={i}>
                  <h3 className="text-gray-400 mb-2 uppercase">{el.title}</h3>
                  {el.links.map((item, i) => (
                    <a href={item.redirect}  rel="noreferrer" className="hover:underline" key={i}>{item.name}</a>
                  ))}

                </div>
              ))}

            </div>

            <div className="border-gray-600 h-36 w-1 border-l mr-5 mt-6 hidden sm:block"></div>
            <div className="lg:w-5/12 my-6 mx-5 sm:mx-0 flex flex-col xs:flex-row gap-2 sm:gap-0 justify-between">
              <div className="xs:w-1/2 sm:w-1/2 w-full ">
                <h3 className="text-gray-400">MAIL US:</h3>
                <p className="mt-2 leading-5">Lyriclious - EdTech<br />
                Flat No.- 158, Block No.-1, Pocket-E,<br />
                 Madhuban Bapudham Scheme<br />
                  Ghaziabad, 201002,<br />
                  Uttar Pradesh, India <br />
                </p>
              </div>

              <div className="xs:w-1/2 sm:w-1/2">
                <h3 className="text-gray-400">REGISTERED OFFICE ADDRESS:</h3>
                <p className="mt-2 leading-5">Lyriclious - EdTech<br />
                Flat No.- 158, Block No.-1, Pocket-E,<br />
                 Madhuban Bapudham Scheme<br />
                  Ghaziabad, 201002,<br />
                  Uttar Pradesh, India <br />
                  Telephone: <a className="text-purple-900" href="tel:18002029898">7678663270</a>
                </p>
              </div>
            </div>

          </footer>
          {/* <!-- footer ends --> */}

          <div className="lg:px-16 py-6 w-full bg-[#350c6e] sm:flex justify-between items-center text-sm text-white xs:left text-center">

          <div className="justify-start items-center text-sm text-white xs:my-2 lg:my-0 ">
          <a href="https://www.linkedin.com/company/42785515" target="_blank" rel="noreferrer" className=" items-center gap-2 px-1">
            <Linkedin fontSize='small'/>
            </a>
            <a href="https://www.instagram.com/lyriclious/" target="_blank" rel="noreferrer" className=" items-center gap-2 px-1 font-[100]">
            <Instagram fontSize='small'/>
            </a>
            <a href="https://twitter.com/lyriclious" target="_blank" rel="noreferrer" className=" items-center gap-2 px-1">
            <Twitter fontSize='small'/>
            </a>
            <a href="https://www.facebook.com/lyriclious" target="_blank" rel="noreferrer" className=" items-center gap-2 pr-1">
              <FacebookRoundedIcon fontSize='small'/>
            </a>
            <a href="https://www.youtube.com/lyriclious/" target="_blank" rel="noreferrer" className=" items-center gap-2 px-1">
            <Youtube fontSize='small'/>
            </a>
            <a href="https://api.whatsapp.com/send?phone=7678663270" rel="noreferrer" target="_blank" className=" items-center gap-2 px-1">
            <Whatsapp  fontSize='small'/>
            </a>
            </div>

            <span className='xs:pb-4 lg:my-1'>&copy; 2020-{new Date().getFullYear()} Lyriclious.com</span>
            <img draggable="false" src={paymentMethods} alt="Card Payment" className='xs:ml-8 mt-3'/>
          </div>
        </>
      )}
    </>
  )
};

export default Footer;
