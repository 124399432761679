
import MetaData from '../Layouts/MetaData.jsx';


import React from 'react';


const RefundPolicy = () => {
 
    
    return (
        
                <>
                    <MetaData title={"About Us"} />
                    
                    <main className="mt-12 sm:mt-0">

                    <div >
   <div>
    <h1 className='text-4xl font-bold'>Refund and Cancellation Policy</h1>
    <br /><br />
<p >
        
Our focus is complete customer satisfaction. In the course and trainings, if you are displeased with the course/training’s timings provided we will shift you to the upcoming batches, provided the reasons are genuine and proved after investigation. Please read the fine prints of each deal before buying/enrolling it, it provides all the details about the services you purchase.
<br />
In case of dissatisfaction from our services, clients have the liberty to skip the ongoing batch and request us to shift their batch by mailing us at support@lyriclious.com along with a genuine reason. Our Policy for the cancellation and refund will be as follows:
 Cancellation Policy.
 <br />
 
 
  <h4 >For Cancellations please contact us via Contact Us link.</h4>

 
<p >
Requests received 5 business days before starting of any of the service period will be considered for cancellation ( after deduction of 20% paid amount ) only if some genuine reason is provided by the end user.
</p>

 <h4 >Refund Policy</h4>

<p >
    We will try our best to create the suitable design concepts for our clients.

In case any client is not completely satisfied with our services we can provide them with a shift in their ongoing batches to a time they are suitable in. 
</p>

<p >
<b>Note :</b> If any trainee is found misbehaving with our instructors or batchmates during the training, the company will instantly cancel their complete course or training and the company will not liable for providing any reason and refund.
</p>

    </p>
    
</div>
</div>


                    </main>
                </>
           
    );
};

export default RefundPolicy;