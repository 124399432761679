import Headings from '../../Layouts/Headings/Headings.jsx';
import ServicesCard from '../../Home/Companies/ServicesCard.jsx';
import GraphicDesignIcon from "../../../assets/images/Companies/GraphicDesignIcon";
import WebDevIcon from "../../../assets/images/Companies/WebDevIcon"
import MobileAppIcon from "../../../assets/images/Companies/MobileAppIcon";
import LogoDesingIcon from "../../../assets/images/Companies/LogoDesingIcon";
const Services = () => {

    return (
      <>
    
        <Headings title={"Services we Provide"} type={"secondLevel"}/>

        <div className=" lg:mx-auto lg:w-[86%]">
        <div className="mt-8 lg:w-[97%] ml-3 grid grid-cols-6 gap-8">
        
        { /* Map function will be used to populate card */ }
        <ServicesCard title={"Web Development"} desc={"Lorum ipsum text here Lorum ipsum text here."} imgsrc={<WebDevIcon height={180} width={180}/>} redirect={"/comingsoon"} />
        <ServicesCard title={"Mobile App Development"} desc={"Lorum ipsum text here Lorum ipsum text here"} num={1214} duration={"4 Weeks"} imgsrc={<MobileAppIcon height={180} width={180}/>} redirect={"/comingsoon"}/>
        <ServicesCard title={"Logo Designing"} desc={"Lorum ipsum text here Lorum ipsum text here"} num={1214} duration={"4 Weeks"} imgsrc={<LogoDesingIcon height={180} width={180}/>} redirect={"/comingsoon"}/>
        <ServicesCard title={"UX/UI Designing"} desc={"Lorum ipsum text here Lorum ipsum text here"} num={1214} duration={"4 Weeks"} imgsrc={<GraphicDesignIcon height={180} width={180}/>} redirect={"/comingsoon"}/>
        </div>
        </div>
        {/* <ViewAll title={"Services"} url={"#"} /> */}

      </>
    );
  };
  
  export default Services;