// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 600px) {
    .desktop-navbar .desktop-nav-item{
        display: none;
    }
    .nav-menu-icon{
        display: inline-block !important;
    }
    
    }

    @media screen and (min-width: 600px) {
        
        .nav-menu-icon{
            display: none !important;
        }
        
        }`, "",{"version":3,"sources":["webpack://./src/components/Layouts/Header/Header.css"],"names":[],"mappings":"AAAA;IACI;QACI,aAAa;IACjB;IACA;QACI,gCAAgC;IACpC;;IAEA;;IAEA;;QAEI;YACI,wBAAwB;QAC5B;;QAEA","sourcesContent":["@media screen and (max-width: 600px) {\n    .desktop-navbar .desktop-nav-item{\n        display: none;\n    }\n    .nav-menu-icon{\n        display: inline-block !important;\n    }\n    \n    }\n\n    @media screen and (min-width: 600px) {\n        \n        .nav-menu-icon{\n            display: none !important;\n        }\n        \n        }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
