import ComingsoonIcon from "../../../assets/images/ComingsoonIcon.jsx"
import LoadingIcon from '@mui/icons-material/DataUsage'
const ComingSoon = ({title}) => {
    return (
        
         <div className="mx-auto w-3/4 grid grid-cols-2 ">
           <div className=" mt-6 h-[100%]">
           <a href={'#'} className="text-gray-500 text-sm font-small ">
                <h4 className="text-gray-700 mt-6">{title}</h4>
                <h1 className="text-[#FF6884] text-5xl font-medium mt-2 mb-[30%]">Coming Soon</h1>
                <p className="text-purple-900 my-4">“Something Big is Coming Your Way”, “Be The First To Know”, or “Drop By Again Soon”</p>
                <button type="button" class="bg-[#FF6884] rounded-3xl p-[4.5px] px-6 text-white align-middle mt-12 mb-3 text-md" disabled>
                <LoadingIcon className="animate-spin mr-4" />
                Get Back
                </button>
                <p className="text-gray-400 text-xs">to explore the available opportunities</p>
            </a>
           </div>

           <div className="mt-4 h-[100%]">
            <ComingsoonIcon height={"100%"} width={"100%"} />
            </div>
        </div>
       
    );
};


export default ComingSoon;
