import { Helmet } from "react-helmet";
import { baseurl, backendurl } from "../../utils/constants";

const MetaData = ({ title, desc,pageurl={baseurl}, sitename="Lyriclious - EdTech", imageurl}) => {
    const imgurl="something";
    return (
        <Helmet>
            <title>{title}</title>
            <meta property="og:title" content={title} />
            <meta property="og:description" content={desc} />
            <meta property="og:url" content={pageurl} />
            <meta name="image" property="og:image" content={imageurl && imageurl.url} />
            <meta property="og:image:type" content="image/png" />
            <meta property="og:image:height" content="200" />
            <meta property="og:image:width" content="200" />
            <meta property="og:site_name" content={sitename} />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="edtech" />


            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:title" content={title}/>
            <meta name="twitter:description" content={desc}/>
            <meta name="twitter:site" content={sitename} />
            <meta name="twitter:image" content={imageurl}/>
            <meta name="twitter:creator" content="Lyriclious - EdTech"/>


            { /* Google adsense Verification Script */ }
            <script data-ad-client="ca-pub-6667782625776602" async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
                        
                            
            { /* Google webmaster verification */ }    
             <meta name="google-site-verification" content="8A8ryGHmtlEuNYRvspop8L7JbL8GtHt02ex1z31y9Sw" /> 
                        
             { /* Google login auth */ }
             <script src="https://apis.google.com/js/platform.js" async defer></script>
             <meta name="google-signin-client_id" content="678938896955-8v8pvov2gjkfukeh8o80gtbofkl8mhdh.apps.googleusercontent.com"></meta>

                { /* Global site tag (gtag.js) - Google Analytics 
                <script async src="https://www.googletagmanager.com/gtag/js?id="></script>
                <script>
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments)}
                gtag('js', new Date());
                gtag('config', '', { 'optimize_id': 'OPT-KW79ZSC'});
                </script>

                // Google Tag Manager //
                <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','GTM-MKFK9DX');</script>
                // End Google Tag Manager //


                // Google Optimization //
                <script>(function(a,s,y,n,c,h,i,d,e){s.className+=' '+y;h.start=1*new Date;
                h.end=i=function(){s.className=s.className.replace(RegExp(' ?'+y),'')};
                (a[n]=a[n]||[]).hide=h;setTimeout(function(){i();h.end=null},c);h.timeout=c;
                })(window,document.documentElement,'async-hide','dataLayer',4000,
                {'OPT-KW79ZSC':true});</script>
                <script async src="https://www.googleoptimize.com/optimize.js?id=OPT-KW79ZSC" onerror="dataLayer.hide.end && dataLayer.hide.end()"></script>  
            */ }

                { /* Pinterest claim */ }
                <meta name="p:domain_verify" content="81d7cc1733cc53cd7832a044968fb813"/>
                  
</Helmet>
    );
};

export default MetaData;
