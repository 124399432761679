import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
//import Searchbar from './Searchbar';
import Avatar from '@mui/material/Avatar';
import AddIcon from '@mui/icons-material/Add';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import HomeIcon from '@mui/icons-material/Home';
import DescriptionIcon from '@mui/icons-material/Description';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import PhotoIcon from '@mui/icons-material/Photo';
import LogoutIcon from '@mui/icons-material/Logout';
import logo from '../../../assets/images/lylogo-long-color.png';
import PrimaryDropDownMenu from './PrimaryDropDownMenu';
import SecondaryDropDownMenu from './SecondaryDropDownMenu';
import SecondaryJobsDropDownMenu from './SecondaryJobsDropDownMenu';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import "./Header.css";
import MenuIcon from '@mui/icons-material/Menu';

const Header = () => {
  
  const { isAuthenticated, loading, user } = useSelector((state) => state.user);

  const { cartItems } = useSelector(state => state.cart);

  const [togglePrimaryDropDown, setTogglePrimaryDropDown] = useState(false);
  const [toggleSecondaryDropDown, setToggleSecondaryDropDown] = useState(false);
  const [toggleSecondaryJobsDropDown, setToggleSecondaryJobsDropDown] = useState(false);
  const [mobnavvisible, setMobNavVisible] = useState(false);

  return (

    <header className="top-0 py-2.5 w-full z-50 mb-[30px]">
      
      {/* <!-- desktop navbar container starts--> */}
      <div className="desktop-navbar w-full sm:w-9/12 px-1 sm:px-4 m-auto flex justify-between items-center absolute">
      <MenuIcon className='nav-menu-icon hidden absolute flex-1' onClick={() => setMobNavVisible(!mobnavvisible)}></MenuIcon>
        {/* <!-- logo & search container --> */}
        <div className="flex items-center flex-1">
          <Link className="h-8 xs:m-auto lg:m-0" to="/">
            <img draggable="false" className="h-full object-contain" src={logo} alt="Lyriclious Logo" />
          </Link>

          { /* <Searchbar />  */ }
        </div>
        {/* <!-- logo & search container --> */}

        {/* <!-- right navs --> */}
        <div className="flex items-center justify-between ml-1 sm:ml-0 gap-0.5 sm:gap-7 relative desktop-nav-item">
    {console.log(loading)}
          {isAuthenticated ? 
          (
            <span className="userDropDown flex items-center text-gray-700 font-small text-sm gap-1 cursor-pointer" onClick={() => setTogglePrimaryDropDown(!togglePrimaryDropDown)}>{user && user.user_name && user.user_name.split(" ", 1)}
              <span>{togglePrimaryDropDown ? <ExpandLessIcon sx={{ fontSize: "16px" }} /> : <ExpandMoreIcon sx={{ fontSize: "16px" }} />}</span>
            </span>
          )
          :
            <Link to="/login" className="px-3 sm:px-9 py-0.5 text-white bg-purple-900 border font-small text-sm rounded-sm cursor-pointer">Login</Link>
  
          }

          {togglePrimaryDropDown && <PrimaryDropDownMenu setTogglePrimaryDropDown={setTogglePrimaryDropDown} user={user} />}

          {/* <span className="moreDropDown hidden sm:flex items-center text-gray-700 font-small text-sm gap-1 cursor-pointer" onClick={() => setToggleSecondaryDropDown(!toggleSecondaryDropDown)}>Internships
            <span>{toggleSecondaryDropDown ? <ExpandLessIcon sx={{ fontSize: "16px" }} /> : <ExpandMoreIcon sx={{ fontSize: "16px" }} />}</span>
          </span>

          {toggleSecondaryDropDown && <SecondaryDropDownMenu />}
          
          <span className="moreDropDown hidden sm:flex items-center text-gray-700 font-small text-sm gap-1 cursor-pointer" onClick={() => setToggleSecondaryJobsDropDown(!toggleSecondaryJobsDropDown)}>Jobs
            <span>{toggleSecondaryJobsDropDown ? <ExpandLessIcon sx={{ fontSize: "16px" }} /> : <ExpandMoreIcon sx={{ fontSize: "16px" }} />}</span>
          </span>

          {toggleSecondaryJobsDropDown && <SecondaryJobsDropDownMenu />} */}

          {/* <Link to="/cart" className="flex items-center text-gray-700 text-sm font-small gap-2 relative"> */}
            { /* <span className="text-[10px]"><ShoppingCartIcon fontSize='small'/></span> */}
            {/* Blogs */}
            
          {/* </Link> */}


          <Link to="/cart" className="flex items-center text-gray-700 text-sm font-small gap-2 relative">
            { /* <span className="text-[10px]"><ShoppingCartIcon fontSize='small'/></span> */}
            {cartItems.length > 0 &&
              <div className="w-5 h-5 p-2 bg-red-500 text-xs text-white rounded-full absolute -top-2 left-3 flex justify-center items-center border">
                {cartItems.length}
              </div>
            }
            <span className="text-[10px]"><ShoppingCartIcon fontSize='small'/></span>
          </Link>
        </div>
        {/* <!-- right navs --> */}

      </div>
      {/* <!-- desktop navbar container ends--> */}


      {/* <!-- Mobile navbar container starts --> */}
      {mobnavvisible && 
      <div className="mobile-navbar w-full h-full fixed flex justify-between items-center z-10 bg-gray-500/50">

        <div className='bg-white h-full w-5/6 absolute'> 
        <div className='mt-6 w-[27px] rounded-full ml-60 bg-gray-200' onClick={() => setMobNavVisible(!mobnavvisible)}>
          <ArrowLeftIcon className='text-sm '></ArrowLeftIcon>
          </div>
          <div className="mt-4 ">
          <Link className="h-8 mr-1 sm:mr-4" to="/">
            <img draggable="false" className="w-[120px] mx-auto" src={logo} alt="Lyriclious Logo" />
          </Link>

          { /* <Searchbar />  */ }
        </div>
       

       <div className='ml-6 mt-4'>
        <ul>
          <li className='py-2 '>
            <span className='text-sm'>
              <HomeIcon className='text-sm mr-2'></HomeIcon>
              <span className='ml-2'>Home</span></span>
          </li>
          <li className='py-2 '>
            <span className='text-sm'>
              <DescriptionIcon className='text-sm mr-2'></DescriptionIcon>
              <span className='ml-2'>Trainings</span>
              <span className='ml-24'><AddIcon className='text-sm mr-2'></AddIcon></span>
              </span>
          </li>
          <li className='py-2 '>
            <span className='text-sm'>
              <BusinessCenterIcon className='text-sm mr-2'></BusinessCenterIcon>
              <span className='ml-2'>Career</span></span>
          </li>
          <li className='py-2 '>
            <span className='text-sm'>
              <PhotoIcon className='text-sm mr-2'></PhotoIcon>
              <span className='ml-2'>Blogs</span></span>
          </li>
          {isAuthenticated === true ?(
            <li className='py-2 '>
            <span className='text-sm'>
              <LogoutIcon className='text-sm mr-2'></LogoutIcon>
              <span className='ml-2'>Logout</span></span>
          </li>
          ):(null)}
          
        </ul>
       </div>
      
       { isAuthenticated === true ?
      ( <div className='relative bottom-[-150px] grid grid-cols-5'>
      <span className="w-[240px] flex gap-1 bg-purple-800 p-2 rounded-lg shadow-lg ml-4 my-4 col-span-4">
                <Avatar
                    alt="Avatar"
                    src={user.avatar.url}
                />
                <div className="flex flex-col gap-0 ">
                    <span className="font-medium text-gray-200 text-md">{user.user_name}</span>
                    <span className="text-gray-300 text-sm">{user.email}</span>
                </div>
               
        </span>
        <span className='col-span-1 m-auto'>
          <ShoppingCartIcon></ShoppingCartIcon>
        </span>
        
      </div> )
:(<div className='relative bottom-[-150px] grid grid-cols-5'>
<span className="w-[240px] flex gap-1 bg-purple-800 p-2 rounded-lg shadow-lg ml-4 my-4 col-span-4">
<ShoppingCartIcon></ShoppingCartIcon>
          <div className="flex flex-col gap-0 ">
              <span className="font-medium text-gray-200 text-md">
                <Link to="/login" className="px-3 sm:px-9 py-0.5 text-white bg-purple-900 border font-small text-sm rounded-sm cursor-pointer">Login</Link>
                </span>
              
          </div>
         
  </span>
  <span className='col-span-1 m-auto'>
    <ShoppingCartIcon></ShoppingCartIcon>
  </span>
  
</div>) }
 
        </div>
        

      </div>
}
      {/* <!-- mobile navbar container ends --> */}
    </header>
  )
};

export default Header;
