import { useEffect } from 'react';
import Chart from 'chart.js/auto'
import { Doughnut, Line, Pie, Bar } from 'react-chartjs-2';
import { getAdminProducts } from '../../actions/productAction';
import { getAdminTrainings } from '../../actions/trainingAction';
import { getAdminTrainingschedules } from '../../actions/trainingscheduleAction';
import { useSelector, useDispatch } from 'react-redux';
import { getAllOrders } from '../../actions/orderAction';
import { getAllUsers } from '../../actions/userAction';
import { categories } from '../../utils/constants';
import { roles } from '../../utils/constants';
import MetaData from '../Layouts/MetaData';
import { getAdminSkills } from '../../actions/skillAction';
import { getAdminCareers } from '../../actions/careerAction';
import { trainingStatuses, trainingScheduleConstants } from '../../utils/constants';

const MainData = () => {

    const dispatch = useDispatch();
    const {trainings} = useSelector((state) => state.trainings);
    const {trainingschedules} = useSelector((state) => state.trainingschedules);
    const { products } = useSelector((state) => state.products);
    const { orders } = useSelector((state) => state.allOrders);
    const { users } = useSelector((state) => state.users);
    const { skills } = useSelector((state) => state.skills);
    const { careers } = useSelector((state) => state.careers);
    var dynamicColors = function() {
        var r = Math.floor(Math.random() * 255);
        var g = Math.floor(Math.random() * 255);
        var b = Math.floor(Math.random() * 255);
        return "rgb(" + r + "," + g + "," + b + ")";
     };
    let outOfStock = 0;
    
        products?.forEach((item) => {
        if (item.stock === 0) {
            outOfStock += 1;
        }
    });

    useEffect(() => {
        dispatch(getAdminTrainings());
        dispatch(getAdminTrainingschedules());
        dispatch(getAdminProducts());
        dispatch(getAllOrders());
        dispatch(getAllUsers());
        dispatch(getAdminCareers());
        dispatch(getAdminSkills());
        
    }, [dispatch]);
    
    let totalAmount = orders?.reduce((total, order) => total + order.totalPrice, 0);

    let careernames=[];
    careers.map((c)=>careernames.push(c.career_name));

    let trainingschedulestatuses=[];
    let trainingschedulecolors=[];
    trainingschedules.map((t)=>{trainingschedulestatuses.push(t.status); trainingschedulecolors.push(dynamicColors())});

    let skillnames=[];
    let skillcolors=[];
    skills.map((s)=>{skillnames.push(s.skill_name); skillcolors.push(dynamicColors())});

    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    const date = new Date();
    const lineState = {
        labels: months,
        datasets: [
            {
                label: `Sales in ${date.getFullYear() - 3}`,
                borderColor: '#8A39E1',
                backgroundColor: '#8A39E1',
                data: months.map((m, i) => orders?.filter((od) => new Date(od.createdAt).getMonth() === i && new Date(od.createdAt).getFullYear() === date.getFullYear() - 3).reduce((total, od) => total + od.totalPrice, 0)),
            },
            {
                label: `Sales in ${date.getFullYear() - 2}`,
                borderColor: 'orange',
                backgroundColor: 'orange',
                data: months.map((m, i) => orders?.filter((od) => new Date(od.createdAt).getMonth() === i && new Date(od.createdAt).getFullYear() === date.getFullYear() - 2).reduce((total, od) => total + od.totalPrice, 0)),
            },
            {
                label: `Sales in ${date.getFullYear() - 1}`,
                borderColor: '#EF4444',
                backgroundColor: '#EF4444',
                data: months.map((m, i) => orders?.filter((od) => new Date(od.createdAt).getMonth() === i && new Date(od.createdAt).getFullYear() === date.getFullYear() - 1).reduce((total, od) => total + od.totalPrice, 0)),
            },
            {
                label: `Sales in ${date.getFullYear()}`,
                borderColor: '#4ade80',
                backgroundColor: '#4ade80',
                data: months.map((m, i) => orders?.filter((od) => new Date(od.createdAt).getMonth() === i && new Date(od.createdAt).getFullYear() === date.getFullYear()).reduce((total, od) => total + od.totalPrice, 0)),
            },
        ],
    };

    

    const pieState = {
        labels: trainingStatuses,
        datasets: [
            {
                backgroundColor: ['#9333ea', '#facc15', '#4ade80'],
                hoverBackgroundColor: ['#a855f7', '#fde047', '#86efac'],
                data: trainingStatuses.map((s) => trainings?.filter((item) => item.status === s).length),
            },
        ],
    };

    const skillspieState = {
        labels: skillnames,
        datasets: [
            {
                backgroundColor: skillcolors,
                hoverBackgroundColor: skillcolors,
                data: skills.map((skill) => trainings?.filter((train) => train.skills.indexOf(skill._id)>=0).length),
            },
        ],
    };
    const trainingSchdedulePieState={
        labels: trainingScheduleConstants,
        datasets: [
            {
                backgroundColor: ['#9333ea', '#facc15', '#4ade80','#ef4444', '#226599'],
                hoverBackgroundColor: ['#9333ff', '#faccff', '#4adeff','#ef44ff', '#2265ff'],
                data: trainingScheduleConstants.map((t) => trainingschedules?.filter((train) => train.status.indexOf(t)>=0).length),
            },
        ],
    }

    const stockdoughnutState = {
        labels: ['Out of Stock', 'In Stock'],
        datasets: [
            {
                backgroundColor: ['#ef4444', '#22c55e'],
                hoverBackgroundColor: ['#dc2626', '#16a34a'],
                data: [outOfStock, products.length - outOfStock],
            },
        ],
    };

    const barState = {
        labels: roles,
        datasets: [
            {
                label: "Users",
                borderColor: '#9333ea',
                backgroundColor: '#9333ea',
                hoverBackgroundColor: '#6b21a8',
                data: roles.map((role) => users?.filter((user) => user.role.toUpperCase() === role.toUpperCase()).length),
            },
        ],
    };

    const careerbarState = {
        labels: careernames,
        datasets: [
            {
                label: "careers",
                borderColor: '#F99E0B',
                backgroundColor: '#F59E0B',
                hoverBackgroundColor: '#e09004',
                data: careers.map((career) => trainings?.filter((train) => train.careers.indexOf(career._id)>=0).length),
            },
        ],
    };
    const skillbarState = {
        labels: skillnames,
        datasets: [
            {
                label: "skills",
                borderColor: '#9333ea',
                backgroundColor: '#EF4444',
                hoverBackgroundColor: '#EF4433',
                data: skills.map((skill) => trainings?.filter((train) => train.skills.indexOf(skill._id)>=0).length),
            },
        ],
    };

    

    return (
        <>
            <MetaData title="Admin Dashboard | Lyriclious" />

            <div className="grid grid-cols-2 sm:grid-cols-4 gap-2 sm:gap-6">
                <div className="flex flex-col bg-purple-600 text-white gap-2 rounded-xl shadow-lg hover:shadow-xl p-6">
                    <h4 className="text-gray-100 font-medium">Total Sales Amount</h4>
                    <h2 className="text-2xl font-bold">₹{totalAmount?.toLocaleString()}</h2>
                </div>
                <div className="flex flex-col bg-red-500 text-white gap-2 rounded-xl shadow-lg hover:shadow-xl p-6">
                    <h4 className="text-gray-100 font-medium">Total Skill</h4>
                    <h2 className="text-2xl font-bold">{skills?.length}</h2>
                </div>
                <div className="flex flex-col bg-yellow-500 text-white gap-2 rounded-xl shadow-lg hover:shadow-xl p-6">
                    <h4 className="text-gray-100 font-medium">Total Trainings</h4>
                    <h2 className="text-2xl font-bold">{trainings?.length}</h2>
                </div>
                <div className="flex flex-col bg-green-500 text-white gap-2 rounded-xl shadow-lg hover:shadow-xl p-6">
                    <h4 className="text-gray-100 font-medium">Total Users</h4>
                    <h2 className="text-2xl font-bold">{users?.length}</h2>
                </div>
            </div>

            <div className="flex flex-col sm:flex-row justify-between gap-3 sm:gap-8 min-w-full">
                <div className="bg-white rounded-xl h-auto w-full shadow-lg p-2">
                    <Line data={lineState} />
                </div>

                <div className="bg-white rounded-xl shadow-lg p-4 text-center">
                    <span className="font-medium uppercase text-gray-800">Trainings Status</span>
                    <Pie data={pieState} />
                </div>
            </div>

            <div className="flex flex-col sm:flex-row justify-between gap-3 sm:gap-8 min-w-full mb-6">
                <div className="bg-white rounded-xl h-auto w-full shadow-lg p-2">
                    <Bar data={barState} />
                </div>

                <div className="bg-white rounded-xl shadow-lg p-4 text-center">
                    <span className="font-medium uppercase text-gray-800">Technologies covered</span>
                    <Pie data={skillspieState} />
                </div>
            </div>

            <div className="flex flex-col sm:flex-row justify-between gap-3 sm:gap-8 min-w-full mb-6">
                <div className="bg-white rounded-xl h-auto w-full shadow-lg p-2">
                    <Bar data={careerbarState} />
                </div>

                <div className="bg-white rounded-xl shadow-lg p-4 text-center">
                    <span className="font-medium uppercase text-gray-800">Stock Status</span>
                    <Doughnut data={stockdoughnutState} />
                </div>
            </div>
            <div className="flex flex-col sm:flex-row justify-between gap-3 sm:gap-8 min-w-full mb-6">
                <div className="bg-white rounded-xl h-auto w-full shadow-lg p-2">
                    <Bar data={skillbarState} />
                </div>

                <div className="bg-white rounded-xl shadow-lg p-4 text-center">
                    <span className="font-medium uppercase text-gray-800">Training Schedules Status</span>
                    <Pie data={trainingSchdedulePieState} />
                </div>
            </div>
        </>
    );
};

export default MainData;
