import React from "react";

function DelhiIcon({height,width}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsSvgjs="http://svgjs.com/svgjs"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
        height={height}
      version="1.1"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 100 100"
      >
        <g className="color000 svgShape" transform="translate(18 27)">
          <path
            fill="#d6d6d6"
            d="M11 19.5v44h16v-23a5 5 0 0110 0v23h16v-44H11z"
            className="colorfdd3b0 svgShape"
          ></path>
          <path
            fill="#d8d8d8"
            d="M16 7.5h32v9H16z"
            className="colorfed9b3 svgShape"
          ></path>
          <path
            fill="#c2c2c2"
            d="M37.5 12h-11a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h1a.5.5 0 01.5.5.5.5 0 00.5.5h7a.5.5 0 00.5-.5.5.5 0 01.5-.5h1a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5z"
            className="colorf0b895 svgShape"
          ></path>
          <circle
            cx="22"
            cy="14.02"
            r="2"
            fill="#c2c2c2"
            className="colorf0b895 svgShape"
          ></circle>
          <circle
            cx="42"
            cy="14.02"
            r="2"
            fill="#c2c2c2"
            className="colorf0b895 svgShape"
          ></circle>
          <path
            fill="#d8d8d8"
            d="M22 5.5h20v2H22zm-9 11h38v3H13z"
            className="colorfed9b3 svgShape"
          ></path>
          <path
            fill="#f8f8f8"
            d="M51 19.5h2v44h-2z"
            className="colorfcf2e8 svgShape"
          ></path>
          <path
            fill="#d8d8d8"
            d="M28 .5h8v2h-8z"
            className="colorfed9b3 svgShape"
          ></path>
          <path
            fill="#f8f8f8"
            d="M21 29.5h2v34h-2z"
            className="colorfcf2e8 svgShape"
          ></path>
          <path
            fill="#c2c2c2"
            d="M11 29.5h12V31H11zm11.875 3v31H27v-23a5 5 0 0110 0v23h4.125v-31h-18.25zM41 29.5h12V31H41z"
            className="colorf0b895 svgShape"
          ></path>
          <path
            fill="#7250a9"
            d="M32 33.5a7 7 0 00-7 7h2a5 5 0 0110 0h2a7 7 0 00-7-7z"
            className="colorbc856e svgShape"
          ></path>
          <path
            fill="#4e327b"
            d="M39.5 41h-3v-.5c0-2.481-2.019-4.5-4.5-4.5a4.505 4.505 0 00-4.5 4.5v.5h-3v-.5c0-4.136 3.364-7.5 7.5-7.5s7.5 3.364 7.5 7.5v.5zm-2.022-1h1.004c-.257-3.352-3.065-6-6.481-6a6.508 6.508 0 00-6.481 6h1.004c.253-2.8 2.613-5 5.478-5 2.865 0 5.223 2.2 5.476 5z"
            className="color594454 svgShape"
          ></path>
          <path
            fill="#7250a9"
            d="M23 32.5h18V34H23z"
            className="colorbc856e svgShape"
          ></path>
          <path
            fill="#7250a9"
            d="M39.5 32.5h2v31h-2zm-17 0h2v31h-2z"
            className="colorbc856e svgShape"
          ></path>
          <path
            fill="#a886df"
            d="M15 42.5h4v3h-4z"
            className="colordea683 svgShape"
          ></path>
          <path
            fill="#c2c2c2"
            d="M13 40.5h8v2h-8zm30 0h8v2h-8zm-31.917 10H21v2h-9.917zm30.042 0H51v2h-9.875z"
            className="colorf0b895 svgShape"
          ></path>
          <path
            fill="#a886df"
            d="M45 42.5h4v3h-4zm-9-29h2v-3H26v3h2v1h8z"
            className="colordea683 svgShape"
          ></path>
          <circle
            cx="22"
            cy="12.52"
            r="2"
            fill="#a886df"
            className="colordea683 svgShape"
          ></circle>
          <circle
            cx="17"
            cy="33"
            r="1.5"
            fill="#a886df"
            className="colordea683 svgShape"
          ></circle>
          <circle
            cx="47"
            cy="33"
            r="1.5"
            fill="#a886df"
            className="colordea683 svgShape"
          ></circle>
          <circle
            cx="42"
            cy="12.52"
            r="2"
            fill="#a886df"
            className="colordea683 svgShape"
          ></circle>
          <path
            fill="#a886df"
            d="M45 60.5h4v3h-4z"
            className="colordea683 svgShape"
          ></path>
          <path
            fill="#f8f8f8"
            d="M49 16.5h2v3h-2zm-3-9h2v9h-2zm-6-2h2v2h-2zm-6-5h2v2h-2z"
            className="colorfcf2e8 svgShape"
          ></path>
          <path
            fill="#d8d8d8"
            d="M39 5.5H25a3 3 0 013-3h8a3 3 0 013 3z"
            className="colorfed9b3 svgShape"
          ></path>
          <path
            fill="#4e327b"
            d="M39.5 6h-15v-.5C24.5 3.57 26.07 2 28 2h8c1.93 0 3.5 1.57 3.5 3.5V6zM25.55 5h12.9A2.504 2.504 0 0036 3h-8c-1.207 0-2.218.86-2.45 2z"
            className="color594454 svgShape"
          ></path>
          <path
            fill="#f8f8f8"
            d="M36 2.5h-2a3 3 0 013 3h2a3 3 0 00-3-3z"
            className="colorfcf2e8 svgShape"
          ></path>
          <path
            fill="#4e327b"
            d="M53 64H11a.5.5 0 01-.5-.5v-44a.5.5 0 01.5-.5h42a.5.5 0 01.5.5v44a.5.5 0 01-.5.5zm-41.5-1h41V20h-41v43z"
            className="color594454 svgShape"
          ></path>
          <path
            fill="#4e327b"
            d="M51 20H13a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5h38a.5.5 0 01.5.5v3a.5.5 0 01-.5.5zm-37.5-1h37v-2h-37v2z"
            className="color594454 svgShape"
          ></path>
          <path
            fill="#4e327b"
            d="M48 17H16a.5.5 0 01-.5-.5v-9A.5.5 0 0116 7h32a.5.5 0 01.5.5v9a.5.5 0 01-.5.5zm-31.5-1h31V8h-31v8z"
            className="color594454 svgShape"
          ></path>
          <path
            fill="#4e327b"
            d="M42 8H22a.5.5 0 01-.5-.5v-2A.5.5 0 0122 5h20a.5.5 0 01.5.5v2a.5.5 0 01-.5.5zM22.5 7h19V6h-19v1zM36 15h-8a.5.5 0 01-.5-.5V14H26a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5h12a.5.5 0 01.5.5v3a.5.5 0 01-.5.5h-1.5v.5a.5.5 0 01-.5.5zm-7.5-1h7v-.5a.5.5 0 01.5-.5h1.5v-2h-11v2H28a.5.5 0 01.5.5v.5z"
            className="color594454 svgShape"
          ></path>
          <path
            fill="#4e327b"
            d="M39.5 6h-15v-.5C24.5 3.57 26.07 2 28 2h8c1.93 0 3.5 1.57 3.5 3.5V6zM25.55 5h12.9A2.504 2.504 0 0036 3h-8c-1.207 0-2.218.86-2.45 2z"
            className="color594454 svgShape"
          ></path>
          <path
            fill="#4e327b"
            d="M36 3h-8a.5.5 0 01-.5-.5v-2A.5.5 0 0128 0h8a.5.5 0 01.5.5v2a.5.5 0 01-.5.5zm-7.5-1h7V1h-7v1zM39 41a.5.5 0 01-.5-.5c0-3.584-2.916-6.5-6.5-6.5a6.508 6.508 0 00-6.5 6.5.5.5 0 01-1 0c0-4.136 3.364-7.5 7.5-7.5s7.5 3.364 7.5 7.5a.5.5 0 01-.5.5zm14 23H41a.5.5 0 01-.5-.5v-34a.5.5 0 01.5-.5h12a.5.5 0 01.5.5v34a.5.5 0 01-.5.5zm-11.5-1h11V30h-11v33zM23 64H11a.5.5 0 01-.5-.5v-34a.5.5 0 01.5-.5h12a.5.5 0 01.5.5v34a.5.5 0 01-.5.5zm-11.5-1h11V30h-11v33z"
            className="color594454 svgShape"
          ></path>
          <path
            fill="#a886df"
            d="M13 36.5h2.5v2H13zm5.5 0H21v2h-2.5zm24.5 0h2.5v2H43zm5.5 0H51v2h-2.5z"
            className="colordea683 svgShape"
          ></path>
          <path
            fill="#4e327b"
            d="M21 64h-8a.5.5 0 01-.5-.5v-27a.5.5 0 01.5-.5h8a.5.5 0 01.5.5v27a.5.5 0 01-.5.5zm-7.5-1h7V37h-7v26z"
            className="color594454 svgShape"
          ></path>
          <path
            fill="#4e327b"
            d="M18.5 39h-3a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5h3a.5.5 0 01.5.5v2a.5.5 0 01-.5.5zM16 38h2v-1h-2v1zm3 8h-4a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5h4a.5.5 0 01.5.5v3a.5.5 0 01-.5.5zm-3.5-1h3v-2h-3v2zm7 16H10a.5.5 0 010-1h12.5a.5.5 0 010 1z"
            className="color594454 svgShape"
          ></path>
          <path
            fill="#4e327b"
            d="M22.5 43H11a.5.5 0 010-1h11.5a.5.5 0 010 1zM21 39h-8a.5.5 0 010-1h8a.5.5 0 010 1zm27.5 0h-3a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5h3a.5.5 0 01.5.5v2a.5.5 0 01-.5.5zM46 38h2v-1h-2v1z"
            className="color594454 svgShape"
          ></path>
          <path
            fill="#4e327b"
            d="M51 39h-8a.5.5 0 010-1h8a.5.5 0 010 1zM22 15.021a2.502 2.502 0 01-2.5-2.5c0-1.379 1.121-2.5 2.5-2.5s2.5 1.121 2.5 2.5-1.121 2.5-2.5 2.5zm0-4c-.827 0-1.5.673-1.5 1.5s.673 1.5 1.5 1.5 1.5-.673 1.5-1.5-.673-1.5-1.5-1.5zM17 35c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zm0-3a1 1 0 100 2 1 1 0 000-2z"
            className="color594454 svgShape"
          ></path>
          <path
            fill="#4e327b"
            d="M51 64h-8a.5.5 0 01-.5-.5v-27a.5.5 0 01.5-.5h8a.5.5 0 01.5.5v27a.5.5 0 01-.5.5zm-7.5-1h7V37h-7v26z"
            className="color594454 svgShape"
          ></path>
          <path
            fill="#4e327b"
            d="M49 64h-4a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5h4a.5.5 0 01.5.5v3a.5.5 0 01-.5.5zm-3.5-1h3v-2h-3v2zM49 46h-4a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5h4a.5.5 0 01.5.5v3a.5.5 0 01-.5.5zm-3.5-1h3v-2h-3v2z"
            className="color594454 svgShape"
          ></path>
          <path
            fill="#4e327b"
            d="M54 61H41.25a.5.5 0 010-1H54a.5.5 0 010 1zm-1.5-18H41a.5.5 0 010-1h11.5a.5.5 0 010 1zM47 35c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zm0-3a1 1 0 100 2 1 1 0 000-2zm-5-16.979a2.502 2.502 0 01-2.5-2.5c0-1.379 1.121-2.5 2.5-2.5s2.5 1.121 2.5 2.5-1.121 2.5-2.5 2.5zm0-4c-.827 0-1.5.673-1.5 1.5s.673 1.5 1.5 1.5 1.5-.673 1.5-1.5-.673-1.5-1.5-1.5z"
            className="color594454 svgShape"
          ></path>
          <path
            fill="#a886df"
            d="M8 23.5h48l-3 6H11z"
            className="colordea683 svgShape"
          ></path>
          <path
            fill="#a886df"
            d="M55 25.5l-45.999.002L11 29.5h42z"
            className="colordea683 svgShape"
          ></path>
          <path
            fill="#7250a9"
            d="M9.001 25.502l.75 1.5L54.25 27l.75-1.5z"
            className="colorbc856e svgShape"
          ></path>
          <path
            fill="#4e327b"
            d="M14 29.766a.5.5 0 01-.5-.5V25.5a.5.5 0 011 0v3.766a.5.5 0 01-.5.5zm-3 0a.5.5 0 01-.5-.5V25.5a.5.5 0 011 0v3.766a.5.5 0 01-.5.5zm6 0a.5.5 0 01-.5-.5V25.5a.5.5 0 011 0v3.766a.5.5 0 01-.5.5zm3 0a.5.5 0 01-.5-.5V25.5a.5.5 0 011 0v3.766a.5.5 0 01-.5.5zm3 0a.5.5 0 01-.5-.5V25.5a.5.5 0 011 0v3.766a.5.5 0 01-.5.5zm3 0a.5.5 0 01-.5-.5V25.5a.5.5 0 011 0v3.766a.5.5 0 01-.5.5zm3 0a.5.5 0 01-.5-.5V25.5a.5.5 0 011 0v3.766a.5.5 0 01-.5.5zm3 0a.5.5 0 01-.5-.5V25.5a.5.5 0 011 0v3.766a.5.5 0 01-.5.5zm3 0a.5.5 0 01-.5-.5V25.5a.5.5 0 011 0v3.766a.5.5 0 01-.5.5zm3 0a.5.5 0 01-.5-.5V25.5a.5.5 0 011 0v3.766a.5.5 0 01-.5.5zm3 0a.5.5 0 01-.5-.5V25.5a.5.5 0 011 0v3.766a.5.5 0 01-.5.5zm3 0a.5.5 0 01-.5-.5V25.5a.5.5 0 011 0v3.766a.5.5 0 01-.5.5zm3 0a.5.5 0 01-.5-.5V25.5a.5.5 0 011 0v3.766a.5.5 0 01-.5.5zm3 0a.5.5 0 01-.5-.5V25.5a.5.5 0 011 0v3.766a.5.5 0 01-.5.5zm3 0a.5.5 0 01-.5-.5V25.5a.5.5 0 011 0v3.766a.5.5 0 01-.5.5z"
            className="color594454 svgShape"
          ></path>
          <g fill="#d6d6d6" className="colorfdd3b0 svgShape">
            <path
              fill="#000"
              d="M56 23.5l-1 2-45.999.002L8 23.5z"
              className="color000 svgShape"
            ></path>
            <path
              fill="#000"
              d="M8.692 26.002L7.191 23h49.617l-1.5 3-46.616.002zM8.809 24l.501 1.002L54.691 25l.5-1H8.809z"
              className="color000 svgShape"
            ></path>
          </g>
          <path
            fill="#4e327b"
            d="M54.5 26H9.29a.5.5 0 010-1H54.5a.5.5 0 010 1z"
            className="color594454 svgShape"
          ></path>
          <path
            fill="#4e327b"
            d="M53 30H11a.5.5 0 01-.447-.276l-3-6A.502.502 0 018 23h48a.498.498 0 01.447.723l-3 6A.5.5 0 0153 30zm-41.691-1h41.383l2.5-5H8.809l2.5 5z"
            className="color594454 svgShape"
          ></path>
          <path
            fill="#a886df"
            d="M9.063 47.5H25l-2 3H11.063zm30 0H55l-2 3H41.063z"
            className="colordea683 svgShape"
          ></path>
          <path
            fill="#ddd"
            d="M53 50.5l2-3h-2l-2 3zm-30 0l2-3h-2l-2 3z"
            className="coloreee1cc svgShape"
          ></path>
          <path
            fill="#4e327b"
            d="M23 51H11.063a.501.501 0 01-.416-.223l-2-3A.499.499 0 019.063 47H25a.5.5 0 01.416.778l-2 3A.503.503 0 0123 51zm-11.67-1h11.402l1.333-2H9.997l1.333 2zM53 51H41.063a.501.501 0 01-.416-.223l-2-3a.499.499 0 01.416-.777H55a.5.5 0 01.416.778l-2 3A.503.503 0 0153 51zm-11.67-1h11.402l1.333-2H39.997l1.333 2z"
            className="color594454 svgShape"
          ></path>
          <path
            fill="#a886df"
            d="M15 60.5h4v3h-4z"
            className="colordea683 svgShape"
          ></path>
          <path
            fill="#4e327b"
            d="M19 64h-4a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5h4a.5.5 0 01.5.5v3a.5.5 0 01-.5.5zm-3.5-1h3v-2h-3v2zM37 64H27a.5.5 0 01-.5-.5v-23c0-3.032 2.468-5.5 5.5-5.5s5.5 2.468 5.5 5.5v23a.5.5 0 01-.5.5zm-9.5-1h9V40.5c0-2.481-2.019-4.5-4.5-4.5a4.505 4.505 0 00-4.5 4.5V63z"
            className="color594454 svgShape"
          ></path>
          <path
            fill="#4e327b"
            d="M28 48h-3.75a.5.5 0 010-1H28a.5.5 0 010 1zm11.75 0H36a.5.5 0 010-1h3.75a.5.5 0 010 1zM28 41H10a.5.5 0 010-1h18a.5.5 0 010 1zm26 0H36a.5.5 0 010-1h18a.5.5 0 010 1z"
            className="color594454 svgShape"
          ></path>
          <path
            fill="#d6d6d6"
            d="M33 32.5h8v-3H23v3h8v4h2z"
            className="colorfdd3b0 svgShape"
          ></path>
          <path
            fill="#c2c2c2"
            d="M23 29.5h18V31H23z"
            className="colorf0b895 svgShape"
          ></path>
          <path
            fill="#4e327b"
            d="M33 37h-2a.5.5 0 01-.5-.5V33H23a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5h18a.5.5 0 01.5.5v3a.5.5 0 01-.5.5h-7.5v3.5a.5.5 0 01-.5.5zm-1.5-1h1v-3.5a.5.5 0 01.5-.5h7.5v-2h-17v2H31a.5.5 0 01.5.5V36zm26 28h-51a.5.5 0 010-1h51a.5.5 0 010 1z"
            className="color594454 svgShape"
          ></path>
        </g>
      </svg>
    </svg>
  );
}

export default DelhiIcon;