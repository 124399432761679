import { useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import { clearErrors, deleteSkill, getAdminSkills } from '../../../actions/skillAction';
//import Rating from '@mui/material/Rating';
import { DELETE_SKILL_RESET } from '../../../constants/skillConstants';
import Actions from '../Actions';
import MetaData from '../../Layouts/MetaData';
import BackdropLoader from '../../Layouts/BackdropLoader';
import Loader from '../../Layouts/Loader';

const SkillTable = () => {

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const { skills, error } = useSelector((state) => state.skills);
    const { loading, isDeleted, error: deleteError } = useSelector((state) => state.skill);

    useEffect(() => {
        if (error) {
            enqueueSnackbar(error, { variant: "error" });
            dispatch(clearErrors());
        }
        if (deleteError) {
            enqueueSnackbar(deleteError, { variant: "error" });
            dispatch(clearErrors());
        }
        if (isDeleted) {
            enqueueSnackbar("Skill Deleted Successfully", { variant: "success" });
            dispatch({ type: DELETE_SKILL_RESET });
        }
        dispatch(getAdminSkills());
    }, [dispatch, error, deleteError, isDeleted, enqueueSnackbar]);

    const deleteSkillHandler = (slug) => {
        dispatch(deleteSkill(slug));
    }

    const columns = [
        
        {
            field: "name",
            headerName: "Name",
            minWidth: 160,
            flex: 0.1,
            renderCell: (params) => {
                return (
                    <div className="flex items-center gap-2">
                        <div className="w-10 h-10 rounded-full">
                            <img draggable="false" src={params.row.image?params.row.image:""} alt={params.row.name} className="w-full h-full rounded-full object-cover" />
                        </div>
                        {params.row.name}
                    </div>
                )
            },
        },
        {
            field: "slug",
            headerName: "Skill ID",
            minWidth: 120,
            flex: 0.2,
        },
        {
            field: "headline",
            headerName: "Headline",
            minWidth: 160,
            flex: 0.1,
           
        },
    
       
        {
            field: "description",
            headerName: "Description",
            type: "number",
            minWidth: 220,
            flex: 1,
            align: "left",
            headerAlign: "left"
            
        },
        {
            field: "actions",
            headerName: "Actions",
            minWidth: 100,
            flex: 0.3,
            type: "number",
            sortable: false,
            renderCell: (params) => {
                return (
                    <Actions editRoute={"skill"} deleteHandler={deleteSkillHandler} slug={params.row.slug} />
                );
            },
        },
    ];

    const rows = [];

    skills && skills.forEach((item) => {
        rows.unshift({
            slug:item.slug,
            id: item._id,
            status: item.skill_name,
            name: item.skill_name,
            image: item.skill_image.url,
            headline:item.headline,
            description:item.description,
            duration: item.skill_name + " " +item.skill_name
        });
    });

    return (
        
        <>
        
            <MetaData title="Admin Skills | Lyriclious" /> 
            <div className="flex justify-between items-center">
                <h1 className="text-lg font-medium uppercase">skills</h1>
                <Link to="/admin/new_skill" className="py-2 px-4 rounded shadow font-medium text-white bg-purple-900 hover:shadow-lg">New Skill</Link>
            </div>
            <div className="bg-white rounded-xl shadow-lg w-full" style={{ height: 470 }}>
            {loading ? <Loader /> : (
            <>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={10}
                    disableSelectIconOnClick
                    sx={{
                        boxShadow: 0,
                        border: 0,
                    }}
                />
                </>
            )};
            </div>
            
        </>
            
    );
};

export default SkillTable;