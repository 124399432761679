// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.internshipTitle {
    text-align: center;
    display: flex;
    justify-content: center;
}

.cityTitle{
    text-align: center;
    display: flex;
    justify-content: center;
}

svg {
    display: block;
    vertical-align: middle;
    margin: 0 auto;

}


.scrollbarhide::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .scrollbarhide {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }`, "",{"version":3,"sources":["webpack://./src/components/Home/Internships/Internships.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,cAAc;IACd,sBAAsB;IACtB,cAAc;;AAElB;;;AAGA;IACI,aAAa;EACf;;EAEA,4CAA4C;EAC5C;IACE,wBAAwB,GAAG,gBAAgB;IAC3C,qBAAqB,GAAG,YAAY;EACtC","sourcesContent":[".internshipTitle {\n    text-align: center;\n    display: flex;\n    justify-content: center;\n}\n\n.cityTitle{\n    text-align: center;\n    display: flex;\n    justify-content: center;\n}\n\nsvg {\n    display: block;\n    vertical-align: middle;\n    margin: 0 auto;\n\n}\n\n\n.scrollbarhide::-webkit-scrollbar {\n    display: none;\n  }\n  \n  /* Hide scrollbar for IE, Edge and Firefox */\n  .scrollbarhide {\n    -ms-overflow-style: none;  /* IE and Edge */\n    scrollbar-width: none;  /* Firefox */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
