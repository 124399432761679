// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.viewLink{
    display: flex;
    justify-content: flex-end;
    margin-right: 8%;
    margin-top:-1%;
    float: right;
}`, "",{"version":3,"sources":["webpack://./src/components/Layouts/ViewAll/ViewAll.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB;IACzB,gBAAgB;IAChB,cAAc;IACd,YAAY;AAChB","sourcesContent":[".viewLink{\n    display: flex;\n    justify-content: flex-end;\n    margin-right: 8%;\n    margin-top:-1%;\n    float: right;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
