import "./TrainingDetails.css";
import sampleCertificate from "../../assets/images/Training/sampleCertificate.png"

const CertificateSample = (props) => {




  
    return (
        <>
       <div className="mt-40 mx-auto w-[90%]">
       
        <div className="flex mt-12 w-[97%] ml-3 grid grid-cols-4 ">
       
            <div className="px-4 rounded-lg lg:col-span-2 xs:col-span-4 pt-20">
                <p className="mx-2 text-2xl font-medium">Industry-recognized {props.certiTitle} Certification</p>
                <p className="mx-2 font-thin mt-6 xs:mb-8 ">Upon successful completion of the program, you will receive a Full Stack Development Internship certificate from Lyriclious.</p>
               
            </div>

            <div className="p-8 rounded-lg xs:col-span-4 lg:col-span-2 certificateImg">
               <img src={sampleCertificate}  />
            </div>

       

            
        </div>
        </div>
         
    

        </>
    );
};

export default CertificateSample;
