import { useEffect, useState } from 'react';
import Sidebar from '../../Sidebar';
import { useSelector } from 'react-redux';
import {  useNavigate } from 'react-router-dom';
import Loader from '../../../Layouts/Loader';
import MetaData from '../../../Layouts/MetaData';
import ComingSoon from '../../../Layouts/ComingSoon/ComingSoon';


const MyNotes = ({ activeTab, children }) => {

    const navigate = useNavigate();

    const { user, loading, isAuthenticated } = useSelector(state => state.user)

    useEffect(() => {
        if (isAuthenticated === false) {
            navigate("/login")
        }
    }, [isAuthenticated, navigate]);

    

    const [onMobile, setOnMobile] = useState(false);
    const [toggleSidebar, setToggleSidebar] = useState(false);

    useEffect(() => {
        if (window.innerWidth < 600) {
            setOnMobile(true);
        }
    }, [])

    return (
        <>
            <MetaData title="My Profile" />

            {loading ? <Loader /> :
                <>
                   
                    <main className="w-full mt-12 sm:mt-0">

                        {/* <!-- row --> */}
                        <div className="w-[20%] sm:w-11/12 sm:mt-4 m-auto mb-7">

                        {!onMobile && <Sidebar activeTab={activeTab} />}
                {toggleSidebar && <Sidebar activeTab={activeTab} setToggleSidebar={setToggleSidebar}/>}
                         
                        </div>
                        <div className='ml-[20%]'>
                        <div className='w-3/4 mx-auto'>
                          <ComingSoon title={"My Notes"} />
                        </div>
                        </div>
                    </main>
                </>
            }
        </>
    );
};

export default MyNotes;
