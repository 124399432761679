import TextField from '@mui/material/TextField';
import { useState, useEffect } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import MenuItem from '@mui/material/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';
import { REMOVE_SKILL_DETAILS, UPDATE_SKILL_RESET } from '../../../constants/skillConstants';
import { clearErrors, getSkillDetails, updateSkill } from '../../../actions/skillAction';
import ImageIcon from '@mui/icons-material/Image';
import BackdropLoader from '../../Layouts/BackdropLoader';
import { categories } from '../../../utils/constants';
import MetaData from '../../Layouts/MetaData';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useTheme } from '@mui/material/styles';
import { trainingStatuses } from '../../../utils/constants';
import { durationConstants } from '../../../utils/constants';



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

const UpdateSkill = () => {

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const params = useParams();

    const { loading, skill, error } = useSelector((state) => state.skillDetails);
    const { loading: updateLoading, isUpdated, error: updateError } = useSelector((state) => state.skill);

    
    const [slug, setSlug] = useState("");
    const [skill_name, setSkillName] = useState("");
    const [assessment, setAssessment]= useState([]);
    const [description, setDescription]= useState("");
    const [headline, setHeadline]=useState("");
    const [skillImg, setSkillImg] = useState("");
    const [skillImgPreview, setSkillImgPreview] = useState("");
    const [coverImg, setCoverImg] = useState("");
    const [coverImgPreview, setCoverImgPreview] = useState("");
    
    
    const [extra_images, setExtraImages] = useState([]);
    
    const [ExtraImagesPreview, setExtraImagesPreview] = useState([]);
    //Updates
  


  
   
   
   
   
    const handleSkillImgChange = (e) => {
        const reader = new FileReader();

        reader.onload = () => {
            if (reader.readyState === 2) {
                setSkillImgPreview(reader.result);
                setSkillImg(reader.result);
            }
        };

        reader.readAsDataURL(e.target.files[0]);
    }
    const handleCoverImgChange = (e) => {
        const reader = new FileReader();

        reader.onload = () => {
            if (reader.readyState === 2) {
                setCoverImgPreview(reader.result);
                setCoverImg(reader.result);
            }
        };

        reader.readAsDataURL(e.target.files[0]);
    }

    const handleExtraSkillImagesChange = (e) => {
        const files = Array.from(e.target.files);

        setExtraImages([]);
        setExtraImagesPreview([]);

        files.forEach((file) => {
            const reader = new FileReader();

            reader.onload = () => {
                if (reader.readyState === 2) {
                    setExtraImagesPreview((oldExtraImages) => [...oldExtraImages, reader.result]);
                    setExtraImages((oldExtraImages) => [...oldExtraImages, reader.result]);
                }
            }
            reader.readAsDataURL(file);
        });
    }


    const theme = useTheme();
//     const [category, setCategory]=useState([]);
  
//   const categorieshandleChange = (event) => {
//     const {
//       target: { value },
//     } = event;
    
//     setCategory((typeof value === 'string') ? value.split(',') : value);
    
   
//   };
  
    const newSkillSubmitHandler = (e) => {
        e.preventDefault();

       
        const formData = new FormData();
        //const modifiedDnum=parseInt((duration.d_num),10);
        formData.set("slug",slug);
        formData.set("skill_name", skill_name);
        formData.set("headline", headline);
        formData.set("description",description);
        formData.set("id",params.id);
        // formData.set("cover_image",coverImg);
        if(skillImg && !skillImg.public_id){
            formData.set("skill_image",skillImg);
        }
         if(coverImg && !coverImg.public_id){
            formData.set("cover_image",coverImg);
        }
        // extra_images.forEach((image) => {
        //     formData.append("extra_image", image);
        // });

       


        dispatch(updateSkill(params.id, formData));
    }

    const skillId = params.id;
    
    useEffect(() => {

        if (skill && skill.slug !== skillId) {
            dispatch(getSkillDetails(skillId));
        } else {
        setSlug(skill.slug);
        setSkillName(skill.skill_name);
        setHeadline(skill.headline);
        setAssessment(skill.assessment);
        setSkillImg(skill.skill_image);
        setSkillImgPreview(skill.skill_image);
        setCoverImg(skill.cover_image);
        setCoverImgPreview(skill.cover_image);
        setExtraImages(skill.extra_image);
        setDescription(skill.description);
        setCoverImg(skill.cover_image);
        setSkillImg(skill.skill_image);



            
        }
        if (error) {
            enqueueSnackbar(error, { variant: "error" });
            dispatch(clearErrors());
        }
        if (updateError) {
            enqueueSnackbar(updateError, { variant: "error" });
            dispatch(clearErrors());
        }
        if (isUpdated) {
            enqueueSnackbar("Skill Updated Successfully", { variant: "success" });
            dispatch({ type: UPDATE_SKILL_RESET });
            dispatch({ type: REMOVE_SKILL_DETAILS });
            navigate('/admin/skills');
        }
    }, [dispatch, error, updateError, isUpdated, skill, skillId, navigate, enqueueSnackbar]); //skill is removed due to continuous refresh

    return (
        <>
            <MetaData title="Admin: Update Skill | Lyriclious" />

            {loading && <BackdropLoader />}
            {updateLoading && <BackdropLoader />}
            <form onSubmit={newSkillSubmitHandler} encType="multipart/form-data" className="flex flex-col sm:flex-row bg-white rounded-lg shadow p-4" id="mainform">

                <div className="flex flex-col gap-3 m-2 sm:w-1/2">
                <TextField
                        label="Slug"
                        variant="outlined"
                        size="small"
                        required
                        value={slug}
                        onChange={(e) => setSlug(e.target.value)}
                    />

                    <TextField
                        label="Skill Name"
                        variant="outlined"
                        size="small"
                        required
                        value={skill_name}
                        onChange={(e) => setSkillName(e.target.value)}
                    />
                    
                    <TextField
                        label="Assessment"
                        variant="outlined"
                        size="small"
                        value={assessment}
                        onChange={(e) => setAssessment(e.target.value)}
                    />

                    <TextField
                        label="Headline"
                        variant="outlined"
                        size="small"
                        required
                        value={headline}
                        onChange={(e) => setHeadline(e.target.value)}
                    />
                  

                    <TextField
                        label="Description"
                        multiline
                        rows={3}
                        required
                        variant="outlined"
                        size="small"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                    
                
                    
                      
                    </div>
                    <div className="flex justify-between gap-4">
                        
            
                        
        {/* <Select
          label="Categories"
          multiple
          fullWidth
          placeholder='Categories'
          variant="outlined"
          size="small"
          required
          value={category}
          onChange={categorieshandleChange}
          input={<OutlinedInput label="Categories" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {categories.map((name) => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, category, theme)}
            >
              {name}
            </MenuItem>
          ))}
        </Select> */}


                        
                        
                        </div>
                        <div className="flex justify-between gap-4">
                        
                        
                        
                        
                    </div>

                    


                    <h2 className="font-medium">Images</h2>
                    <div> {/* skill image start */}
                    <div className="w-24 h-10 flex items-center justify-center border rounded-lg">
                            {!skillImgPreview ? <ImageIcon /> :
                                <img draggable="false" src={skillImgPreview.url || skillImgPreview} alt="Skill Image" className="w-full h-full object-contain" />
                            }
                        </div>
                        <label className="rounded bg-gray-400 text-center cursor-pointer text-white py-2 px-2.5 shadow hover:shadow-lg">
                            <input
                                type="file"
                                name="skill_image"
                                accept="image/*"
                                onChange={handleSkillImgChange}
                                className="hidden"
                            />
                            Choose Skill Image
                        </label>
                        </div> {/* skill image end */}

                        <div> {/* cover image start */}
                    <div className="w-24 h-10 flex items-center justify-center border rounded-lg">
                            {!coverImgPreview ? <ImageIcon /> :
                                <img draggable="false" src={coverImgPreview.url || coverImgPreview} alt="Cover Image" className="w-full h-full object-contain" />
                            }
                        </div>
                        <label className="rounded bg-gray-400 text-center cursor-pointer text-white py-2 px-2.5 shadow hover:shadow-lg">
                            <input
                                type="file"
                                name="CoverImage"
                                accept="image/*"
                                onChange={handleCoverImgChange}
                                className="hidden"
                            />
                            Choose Cover Image
                        </label>
                        </div> {/* cover image end */}

                    

                

                <div className="flex flex-col gap-2 m-2 sm:w-1/2">
                    

                   
                    

                    <h2 className="font-medium">Extra Images</h2>
                    <div className="flex gap-2 overflow-x-auto h-32 border rounded">
                        {ExtraImagesPreview.map((image, i) => (
                            <img draggable="false" src={image} alt="Skill" key={i} className="w-full h-full object-contain" />
                        ))}
                    </div>
                    <label className="rounded font-medium bg-gray-400 text-center cursor-pointer text-white p-2 shadow hover:shadow-lg my-2">
                        <input
                            type="file"
                            name="extraimages"
                            accept="extraimage/*"
                            multiple
                            onChange={handleExtraSkillImagesChange}
                            className="hidden"
                        />
                        Choose Files
                    </label>

                    <div className="flex justify-end">
                        <input form="mainform" type="submit" className="bg-purple-900 uppercase w-1/3 p-3 text-white font-medium rounded shadow hover:shadow-lg cursor-pointer" value="Submit" />
                    </div>

                </div>

            </form>
        </>
    );
};

export default UpdateSkill;