import {
    ADMIN_TRAININGSCHEDULES_FAIL,
    ADMIN_TRAININGSCHEDULES_REQUEST,
    ADMIN_TRAININGSCHEDULES_SUCCESS,
    ALL_TRAININGSCHEDULES_FAIL,
    ALL_TRAININGSCHEDULES_REQUEST,
    ALL_TRAININGSCHEDULES_SUCCESS,
    CLEAR_ERRORS,
    DELETE_TRAININGSCHEDULE_FAIL,
    DELETE_TRAININGSCHEDULE_REQUEST,
    DELETE_TRAININGSCHEDULE_RESET,
    DELETE_TRAININGSCHEDULE_SUCCESS,
    NEW_TRAININGSCHEDULE_FAIL,
    NEW_TRAININGSCHEDULE_REQUEST,
    NEW_TRAININGSCHEDULE_RESET,
    NEW_TRAININGSCHEDULE_SUCCESS,
    TRAININGSCHEDULE_DETAILS_FAIL,
    TRAININGSCHEDULE_DETAILS_REQUEST,
    TRAININGSCHEDULE_DETAILS_SUCCESS,
    UPDATE_TRAININGSCHEDULE_FAIL,
    UPDATE_TRAININGSCHEDULE_REQUEST,
    UPDATE_TRAININGSCHEDULE_RESET,
    UPDATE_TRAININGSCHEDULE_SUCCESS,
    REMOVE_TRAININGSCHEDULE_DETAILS,
    SLIDER_TRAININGSCHEDULES_FAIL,
    SLIDER_TRAININGSCHEDULES_REQUEST,
    SLIDER_TRAININGSCHEDULES_SUCCESS,
} from "../constants/trainingscheduleConstants";

export const trainingschedulesReducer = (state = { trainingschedules: [] }, { type, payload }) => {

    switch (type) {
        case ALL_TRAININGSCHEDULES_REQUEST:
        case ADMIN_TRAININGSCHEDULES_REQUEST:
        case SLIDER_TRAININGSCHEDULES_REQUEST:
            return {
                loading: true,
                trainingschedules: [],
            };
        case ALL_TRAININGSCHEDULES_SUCCESS:
            return {
                loading: false,
                trainingSchedules: payload.trainingschedules,
                trainingschedulesCount: payload.trainingschedulesCount,
                resultPerPage: payload.resultPerPage,
                filteredTrainingschedulesCount: payload.filteredTrainingschedulesCount,
            };
        case ADMIN_TRAININGSCHEDULES_SUCCESS:
        case SLIDER_TRAININGSCHEDULES_SUCCESS:
            return {
                loading: false,
                trainingschedules: payload,
            };
        case ALL_TRAININGSCHEDULES_FAIL:
        case ADMIN_TRAININGSCHEDULES_FAIL:
        case SLIDER_TRAININGSCHEDULES_FAIL:
            return {
                loading: false,
                error: payload,
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
}

export const trainingscheduleDetailsReducer = (state = { trainingschedule: {} }, { type, payload }) => {

    switch (type) {
        case TRAININGSCHEDULE_DETAILS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case TRAININGSCHEDULE_DETAILS_SUCCESS:
            return {
                loading: false,
                trainingschedule: payload,
            };
        case TRAININGSCHEDULE_DETAILS_FAIL:
            return {
                loading: false,
                error: payload,
            };
        case REMOVE_TRAININGSCHEDULE_DETAILS:
            return {
                ...state,
                trainingschedule: {},
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
}



// New Trainingschedule Reducer
export const newTrainingscheduleReducer = (state = { trainingschedule: {} }, { type, payload }) => {
    switch (type) {
        case NEW_TRAININGSCHEDULE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case NEW_TRAININGSCHEDULE_SUCCESS:
            return {
                loading: false,
                success: payload.success,
                trainingschedule: payload.trainingschedule,
            };
        case NEW_TRAININGSCHEDULE_FAIL:
            return {
                ...state,
                loading: false,
                error: payload,
            };
        case NEW_TRAININGSCHEDULE_RESET:
            return {
                ...state,
                success: false,
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
}

// Trainingschedule Reducer
export const trainingscheduleReducer = (state = {}, { type, payload }) => {
    switch (type) {
        case UPDATE_TRAININGSCHEDULE_REQUEST:
        case DELETE_TRAININGSCHEDULE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_TRAININGSCHEDULE_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdated: payload,
            };
        case DELETE_TRAININGSCHEDULE_SUCCESS:
            return {
                ...state,
                loading: false,
                isDeleted: payload,
            };
        case UPDATE_TRAININGSCHEDULE_FAIL:
        case DELETE_TRAININGSCHEDULE_FAIL:
            return {
                ...state,
                loading: false,
                error: payload,
            };
        case UPDATE_TRAININGSCHEDULE_RESET:
            return {
                ...state,
                isUpdated: false,
            };
        case DELETE_TRAININGSCHEDULE_RESET:
            return {
                ...state,
                isDeleted: false,
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
}

