import React from "react";

function BangloreIcon({height,width}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsSvgjs="http://svgjs.com/svgjs"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      version="1.1"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={width}
        height={height}
        viewBox="0 0 100 100"
      >
        <path
          fill="#e1cfff"
          d="M78.7 89.8h-9v-31h9"
          className="colorffe9cf svgShape"
        ></path>
        <path
          fill="#4c4459"
          d="M78.7 90.3h-9.5v-32h9.5v1h-8.5v30h8.5z"
          className="color594454 svgShape"
        ></path>
        <path
          fill="#fff"
          d="M69.7 58.8h2v31h-2z"
          className="colorfff svgShape"
          opacity="0.5"
        ></path>
        <path
          fill="#e1cfff"
          d="M67.7 58.8h10v-4h-10v2.5z"
          className="colorffe9cf svgShape"
        ></path>
        <path
          fill="#b5a8ca"
          d="M67.7 54.8h2v4h-2z"
          className="colorcab5a8 svgShape"
        ></path>
        <path
          fill="#e1cfff"
          d="M32.7 58.8h-10v-4h10v3z"
          className="colorffe9cf svgShape"
        ></path>
        <path
          fill="#fff"
          d="M30.7 54.8h2v4h-2z"
          className="colorfff svgShape"
          opacity="0.5"
        ></path>
        <path
          fill="#e1cfff"
          d="M30.7 89.8h-9v-31h9"
          className="colorffe9cf svgShape"
        ></path>
        <path
          fill="#4c4459"
          d="M30.7 90.3h-9.5v-32h9.5v1h-8.5v30h8.5z"
          className="color594454 svgShape"
        ></path>
        <path
          fill="#e1cfff"
          d="M77.8 54.8l.1-.2c1-1.3.8-3.2-.5-4.2l-4.6-3.6-4.6 3.6c-1.3 1-1.5 2.9-.5 4.2l.1.2h10z"
          className="colorffe9cf svgShape"
        ></path>
        <path
          fill="#4c4459"
          d="M78.1 55.3H67.6l-.3-.3c-.6-.7-.8-1.7-.7-2.6.1-.9.6-1.8 1.3-2.3l4.9-3.8 4.9 3.8c.7.6 1.2 1.4 1.3 2.3.1.9-.1 1.8-.7 2.6l-.2.3zm-10-1h9.5c.4-.5.6-1.2.5-1.8-.1-.7-.4-1.3-.9-1.7l-4.3-3.4-4.3 3.4c-.5.4-.9 1-.9 1.7-.2.7 0 1.3.4 1.8z"
          className="color594454 svgShape"
        ></path>
        <path
          fill="#e1cfff"
          d="M30.7 57.8h3.1v4h-3.1zm0 10h3.1v22h-3.1z"
          className="colorffe9cf svgShape"
        ></path>
        <path
          fill="#b5a8ca"
          d="M70.7 50.5l3.4-2.6-1.3-1-4.6 3.6c-1.3 1-1.5 2.9-.5 4.2l.1.2h2.5l-.1-.2c-1-1.3-.8-3.2.5-4.2z"
          className="colorcab5a8 svgShape"
        ></path>
        <path
          fill="#fff"
          d="M74.9 50.5l-3.4-2.6 1.3-1 4.6 3.6c1.3 1 1.5 2.9.5 4.2l-.1.2h-2.5l.1-.2c1.1-1.3.8-3.2-.5-4.2z"
          className="colorfff svgShape"
          opacity="0.5"
        ></path>
        <path
          fill="#e1cfff"
          d="M46.7 58.8h-5.9l1.9-3h15l2.1 3h-6.1"
          className="colorffe9cf svgShape"
        ></path>
        <path
          fill="#4c4459"
          d="M60.8 59.3h-7.1v-1h5.2l-1.4-2H43l-1.3 2h5v1h-6.8l2.6-4H58z"
          className="color594454 svgShape"
        ></path>
        <path
          fill="#e1cfff"
          d="M57.8 51.8h-15l-2-3h19z"
          className="colorffe9cf svgShape"
        ></path>
        <path
          fill="#4c4459"
          d="M58.1 52.3H42.5l-2.7-4h20.9l-2.6 4zm-15-1h14.5l1.3-2H41.7l1.4 2z"
          className="color594454 svgShape"
        ></path>
        <path
          fill="#e1cfff"
          d="M42.7 46.8h15v2h-15z"
          className="colorffe9cf svgShape"
        ></path>
        <path
          fill="#e1cfff"
          d="M52.2 37.8l4.2 2.2c2.3 1.3 3.2 4.2 1.5 6.7l-.1.1h-15l-.1-.1c-1.9-2.5-.7-5.4 1.5-6.7l4-2.1.2-.1"
          className="colorffe9cf svgShape"
        ></path>
        <path
          fill="#b5a8ca"
          d="M50.4 37.8h-2l-.2.2-4 2.1c-2.2 1.3-3.4 4.2-1.5 6.7h2c-1.6-5 2.3-6.1 5.7-9z"
          className="colorcab5a8 svgShape"
        ></path>
        <path
          fill="#fff"
          d="M50.3 37.8h2l.2.1 4 2.1c2.2 1.3 3.4 4.2 1.5 6.7h-2c1.6-4.9-2.3-6-5.7-8.9z"
          className="colorfff svgShape"
          opacity="0.5"
        ></path>
        <path
          fill="#e1cfff"
          d="M48.2 55.8h-5.5v-4h15v4h-5.5"
          className="colorffe9cf svgShape"
        ></path>
        <path
          fill="#b5a8ca"
          d="M43.8 58.8h-3l1.9-3h3zm2-7h-3l-2-3h3zm-1.9 4h-1.2v-4h3.1v4h-1.1z"
          className="colorcab5a8 svgShape"
        ></path>
        <path
          fill="#fff"
          d="M56.8 48.8h3l-1.9 3h-3zm-2 7h3l2 3h-3zm2-4h1.1v4h-3.1v-4h1.1z"
          className="colorfff svgShape"
          opacity="0.5"
        ></path>
        <path
          fill="#e1cfff"
          d="M47.7 35.8h5v2h-5zm1-.5v-1c0-.8.7-1.5 1.5-1.5s1.5.7 1.5 1.5v1m2.8 23.5h12.2v3H33.8v-3H46m20.7-1h3v4h-3z"
          className="colorffe9cf svgShape"
        ></path>
        <path
          fill="#c39eff"
          d="M53.7 61.8h-7v-5l3.5-2 3.5 2z"
          className="colorffd79e svgShape"
        ></path>
        <path
          fill="#e1cfff"
          d="M22.8 54.8l-.1-.2c-1-1.3-.8-3.2.5-4.2l4.6-3.6 4.6 3.6c1.3 1 1.5 2.9.5 4.2l-.1.2h-10z"
          className="colorffe9cf svgShape"
        ></path>
        <path
          fill="#b5a8ca"
          d="M25.7 50.5l3.4-2.6-1.3-1-4.6 3.6c-1.3 1-1.5 2.9-.5 4.2l.1.2h2.5l-.1-.2c-1-1.3-.8-3.2.5-4.2z"
          className="colorcab5a8 svgShape"
        ></path>
        <path
          fill="#fff"
          d="M29.9 50.5l-3.4-2.6 1.3-1 4.6 3.6c1.3 1 1.5 2.9.5 4.2l-.1.2h-2.5l.1-.2c1.1-1.3.8-3.2-.5-4.2z"
          className="colorfff svgShape"
          opacity="0.5"
        ></path>
        <path
          fill="#b5a8ca"
          d="M21.7 58.8h2v31h-2zm7 0h2v31h-2z"
          className="colorcab5a8 svgShape"
        ></path>
        <path
          fill="#fff"
          d="M75.7 54.8h2v4h-2zm1 4h2v31h-2z"
          className="colorfff svgShape"
          opacity="0.5"
        ></path>
        <path
          fill="#b5a8ca"
          d="M22.7 54.8h2v4h-2z"
          className="colorcab5a8 svgShape"
        ></path>
        <circle
          cx="26.3"
          cy="71.3"
          r="1.5"
          fill="#706a7a"
          className="color7a6a77 svgShape"
        ></circle>
        <circle
          cx="74.3"
          cy="71.3"
          r="1.5"
          fill="#706a7a"
          className="color7a6a77 svgShape"
        ></circle>
        <path
          fill="#706a7a"
          d="M72.7 75.8h3v6h-3zm0 9h3v2h-3zm-48-9h3v6h-3zm0 9h3v2h-3z"
          className="color7a6a77 svgShape"
        ></path>
        <path
          fill="#e1cfff"
          d="M32.7 76.8h35v4h-35zm0-9h37v4h-37z"
          className="colorffe9cf svgShape"
        ></path>
        <path
          fill="#706a7a"
          d="M33.8 71.8h32.9v5H33.8zm0 9h32.9v9H33.8z"
          className="color7a6a77 svgShape"
        ></path>
        <path
          fill="#4c4459"
          d="M66.7 72.3H33.8c-.3 0-.5-.2-.5-.5v-4c0-.3.2-.5.5-.5h32.9c.3 0 .5.2.5.5v4c0 .3-.2.5-.5.5zm-32.4-1h31.9v-3H34.3v3zm32.4 10H33.8c-.3 0-.5-.2-.5-.5v-4c0-.3.2-.5.5-.5h32.9c.3 0 .5.2.5.5v4c0 .3-.2.5-.5.5zm-32.4-1h31.9v-3H34.3v3z"
          className="color594454 svgShape"
        ></path>
        <path
          fill="#e1cfff"
          d="M28.7 64.8h43v3h-43zm45.1 0h-47l3-3h41z"
          className="colorffe9cf svgShape"
        ></path>
        <path
          fill="#b5a8ca"
          d="M28.7 64.8h3v3h-3zm1.1-3l-3 3h3l3-3z"
          className="colorcab5a8 svgShape"
        ></path>
        <path
          fill="#fff"
          d="M68.6 64.8h3v3h-3zm1.9-3l3 3h-3l-3-3z"
          className="colorfff svgShape"
          opacity="0.5"
        ></path>
        <path
          fill="#4c4459"
          d="M31.2 90.3h-9.5c-.3 0-.5-.2-.5-.5v-31c0-.3.2-.5.5-.5h9c.3 0 .5.2.5.5s-.2.5-.5.5h-8.5v30h9c.3 0 .5.2.5.5s-.2.5-.5.5zm47.5 0H68.2c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h10v-30h-8c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h8.5c.3 0 .5.2.5.5v31c0 .3-.2.5-.5.5z"
          className="color594454 svgShape"
        ></path>
        <path
          fill="#4c4459"
          d="M33.7 62.3h-3c-.3 0-.5-.2-.5-.5v-4c0-.3.2-.5.5-.5h3c.3 0 .5.2.5.5v4c0 .3-.2.5-.5.5zm-2.5-1h2v-3h-2v3zm38.5 1h-3c-.3 0-.5-.2-.5-.5v-4c0-.3.2-.5.5-.5h3c.3 0 .5.2.5.5v4c0 .3-.2.5-.5.5zm-2.5-1h2v-3h-2v3z"
          className="color594454 svgShape"
        ></path>
        <path
          fill="#4c4459"
          d="M66.7 62.3H33.8c-.3 0-.5-.2-.5-.5v-3c0-.3.2-.5.5-.5H46c.3 0 .5.2.5.5s-.2.5-.5.5H34.3v2h31.9v-2H54.5c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h12.2c.3 0 .5.2.5.5v3c0 .3-.2.5-.5.5z"
          className="color594454 svgShape"
        ></path>
        <path
          fill="#4c4459"
          d="M46.7 59.3h-5.9c-.2 0-.4-.1-.4-.3s-.1-.4 0-.5l1.9-3c.2-.2.5-.3.7-.2.2.1.3.5.2.7l-1.4 2.2h5c.3 0 .5.2.5.5s-.3.6-.6.6zm13.1 0h-6.1c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h5.1l-1.5-2.2c-.2-.2-.1-.5.1-.7.2-.2.5-.1.7.1l2.1 3c.1.2.1.4 0 .5s-.2.3-.4.3zm-2-7h-15c-.2 0-.3-.1-.4-.2l-2-3c-.1-.2-.1-.4 0-.5.1-.2.3-.3.4-.3h19c.2 0 .4.1.4.3.1.2.1.4 0 .5l-2 3c-.1.2-.2.2-.4.2zm-14.7-1h14.5l1.3-2H41.7l1.4 2z"
          className="color594454 svgShape"
        ></path>
        <path
          fill="#4c4459"
          d="M57.7 49.3h-15c-.3 0-.5-.2-.5-.5v-2c0-.3.2-.5.5-.5h15c.3 0 .5.2.5.5v2c0 .3-.2.5-.5.5zm-14.5-1h14v-1h-14v1z"
          className="color594454 svgShape"
        ></path>
        <path
          fill="#4c4459"
          d="M57.8 47.3h-15c-.2 0-.3-.1-.4-.2l-.1-.1c-.9-1.1-1.2-2.5-1-3.8.3-1.4 1.2-2.8 2.6-3.6l4.2-2.2c.2-.1.5 0 .7.2.1.2 0 .5-.2.7l-4.2 2.2c-1.1.7-1.9 1.8-2.1 2.9-.2 1 .1 2 .7 2.9h14.5c.7-1 .9-2.1.7-3.2-.3-1.1-1-2.1-2-2.6L52 38.3c-.2-.1-.3-.4-.2-.7.1-.2.4-.3.7-.2l4.2 2.2c1.3.7 2.2 1.9 2.5 3.3.3 1.4 0 2.9-.9 4.1l-.1.1c-.1.2-.2.2-.4.2zm-.1 9h-5.5c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h5v-3h-14v3h5c.3 0 .5.2.5.5s-.2.5-.5.5h-5.5c-.3 0-.5-.2-.5-.5v-4c0-.3.2-.5.5-.5h15c.3 0 .5.2.5.5v4c0 .3-.2.5-.5.5zm-30.5 3h-4.5c-.3 0-.5-.2-.5-.5v-4c0-.3.2-.5.5-.5h10c.3 0 .5.2.5.5v3c0 .3-.2.5-.5.5s-.5-.2-.5-.5v-2.5h-9v3h4c.3 0 .5.2.5.5s-.2.5-.5.5zm44.5 9h-43c-.3 0-.5-.2-.5-.5v-3c0-.3.2-.5.5-.5h43c.3 0 .5.2.5.5v3c0 .3-.2.5-.5.5zm-42.5-1h42v-2h-42v2z"
          className="color594454 svgShape"
        ></path>
        <path
          fill="#4c4459"
          d="M73.8 65.3h-47c-.2 0-.4-.1-.5-.3s0-.4.1-.5l3-3c.1-.1.2-.1.4-.1h41c.1 0 .3.1.4.1l3 3c.1.1.2.4.1.5s-.3.3-.5.3zm-45.8-1h44.6l-2-2H30l-2 2z"
          className="color594454 svgShape"
        ></path>
        <path
          fill="#4c4459"
          d="M53.7 62.3h-7c-.3 0-.5-.2-.5-.5v-5c0-.2.1-.3.3-.4l3.5-2c.2-.1.3-.1.5 0l3.5 2c.2.1.3.3.3.4v5c-.1.3-.3.5-.6.5zm-6.5-1h6v-4.2l-3-1.7-3 1.7v4.2zm-14.4-6h-10c-.2 0-.3-.1-.4-.2l-.1-.1c-.6-.7-.8-1.7-.7-2.6.1-.9.6-1.8 1.3-2.3l4.6-3.6c.2-.1.4-.1.6 0l4.6 3.6c.7.6 1.2 1.4 1.3 2.3.1.9-.1 1.8-.7 2.6l-.1.2c-.1.1-.2.1-.4.1zm-9.7-1h9.5c.4-.5.6-1.2.5-1.8-.1-.7-.4-1.3-.9-1.7l-4.3-3.4-4.3 3.4c-.5.4-.9 1-.9 1.7-.2.7 0 1.3.4 1.8zm54.6 5h-4.5c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h4v-3h-9v2c0 .3-.2.5-.5.5s-.5-.2-.5-.5v-2.5c0-.3.2-.5.5-.5h10c.3 0 .5.2.5.5v4c0 .3-.2.5-.5.5z"
          className="color594454 svgShape"
        ></path>
        <path
          fill="#4c4459"
          d="M77.8 55.3h-10c-.2 0-.3-.1-.4-.2l-.1-.1c-.6-.7-.8-1.7-.7-2.6.1-.9.6-1.8 1.3-2.3l4.6-3.6c.2-.1.4-.1.6 0l4.6 3.6c.7.6 1.2 1.4 1.3 2.3.1.9-.1 1.8-.7 2.6l-.1.2c-.1.1-.2.1-.4.1zm-9.7-1h9.5c.4-.5.6-1.2.5-1.8-.1-.7-.4-1.3-.9-1.7l-4.3-3.4-4.3 3.4c-.5.4-.9 1-.9 1.7-.2.7 0 1.3.4 1.8zm-41.8 19c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm0-3c-.6 0-1 .4-1 1s.4 1 1 1 1-.4 1-1-.4-1-1-1zm1.4 12h-3c-.3 0-.5-.2-.5-.5v-6c0-.3.2-.5.5-.5h3c.3 0 .5.2.5.5v6c0 .3-.2.5-.5.5zm-2.5-1h2v-5h-2v5zm2.5 6h-3c-.3 0-.5-.2-.5-.5v-2c0-.3.2-.5.5-.5h3c.3 0 .5.2.5.5v2c0 .3-.2.5-.5.5zm-2.5-1h2v-1h-2v1z"
          className="color594454 svgShape"
        ></path>
        <path
          fill="#4c4459"
          d="M28.7 76.3h-5c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h5c.3 0 .5.2.5.5s-.2.5-.5.5zm45.6-3c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm0-3c-.6 0-1 .4-1 1s.4 1 1 1 1-.4 1-1-.4-1-1-1zm1.4 12h-3c-.3 0-.5-.2-.5-.5v-6c0-.3.2-.5.5-.5h3c.3 0 .5.2.5.5v6c0 .3-.2.5-.5.5zm-2.5-1h2v-5h-2v5zm2.5 6h-3c-.3 0-.5-.2-.5-.5v-2c0-.3.2-.5.5-.5h3c.3 0 .5.2.5.5v2c0 .3-.2.5-.5.5zm-2.5-1h2v-1h-2v1z"
          className="color594454 svgShape"
        ></path>
        <path
          fill="#4c4459"
          d="M76.7 76.3h-5c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h5c.3 0 .5.2.5.5s-.2.5-.5.5zm-24-38h-5c-.3 0-.5-.2-.5-.5v-2c0-.3.2-.5.5-.5h5c.3 0 .5.2.5.5v2c0 .3-.2.5-.5.5zm-4.5-1h4v-1h-4v1z"
          className="color594454 svgShape"
        ></path>
        <path
          fill="#4c4459"
          d="M51.7 35.8c-.3 0-.5-.2-.5-.5v-1c0-.5-.4-1-1-1s-1 .4-1 1v1c0 .3-.2.5-.5.5s-.5-.2-.5-.5v-1c0-1.1.9-2 2-2s2 .9 2 2v1c0 .3-.2.5-.5.5z"
          className="color594454 svgShape"
        ></path>
        <defs>
          <path
            id="a"
            fill="#000"
            d="M27.8 46.8v-1.5"
            className="color000 svgShape"
          ></path>
        </defs>
        <clipPath id="b">
          <use overflow="visible" xlinkHref="#a"></use>
        </clipPath>
        <circle
          cx="27.8"
          cy="45.3"
          r="1.1"
          fill="#9258f0"
          className="colorf05877 svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="1.1"
          fill="#9359f0"
          className="colorf05978 svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="1.1"
          fill="#935af0"
          className="colorf05a79 svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="1"
          fill="#945bf0"
          className="colorf05b79 svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="1"
          fill="#955cf0"
          className="colorf05c7a svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="1"
          fill="#955df0"
          className="colorf05d7b svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="1"
          fill="#965ef0"
          className="colorf05e7c svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="1"
          fill="#965ef0"
          className="colorf05e7d svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="1"
          fill="#975ff0"
          className="colorf05f7e svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="1"
          fill="#9760f0"
          className="colorf0607e svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="1"
          fill="#9861f0"
          className="colorf0617f svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.9"
          fill="#9862f0"
          className="colorf06280 svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.9"
          fill="#9963f0"
          className="colorf06381 svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.9"
          fill="#9963f0"
          className="colorf06382 svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.9"
          fill="#9a64f0"
          className="colorf06483 svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.9"
          fill="#9a65f0"
          className="colorf06583 svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.9"
          fill="#9b66f0"
          className="colorf06684 svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.9"
          fill="#9c67f0"
          className="colorf06785 svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.9"
          fill="#9c67f0"
          className="colorf06786 svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.8"
          fill="#9c68f0"
          className="colorf06887 svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.8"
          fill="#9d69f0"
          className="colorf06988 svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.8"
          fill="#9d6af0"
          className="colorf06a88 svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.8"
          fill="#9e6bf0"
          className="colorf06b89 svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.8"
          fill="#9e6bf0"
          className="colorf06b8a svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.8"
          fill="#9f6cf0"
          className="colorf06c8b svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.8"
          fill="#9f6df0"
          className="colorf06d8c svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.8"
          fill="#a06ef0"
          className="colorf06e8d svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.7"
          fill="#a06ef0"
          className="colorf06e8d svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.7"
          fill="#a06ff0"
          className="colorf06f8e svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.7"
          fill="#a170f1"
          className="colorf1708f svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.7"
          fill="#a271f1"
          className="colorf17190 svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.7"
          fill="#a271f1"
          className="colorf17191 svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.7"
          fill="#a372f1"
          className="colorf17291 svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.7"
          fill="#a373f1"
          className="colorf17392 svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.7"
          fill="#a373f1"
          className="colorf17393 svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.6"
          fill="#a474f1"
          className="colorf17494 svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.6"
          fill="#a575f1"
          className="colorf17595 svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.6"
          fill="#a576f1"
          className="colorf17695 svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.6"
          fill="#a576f1"
          className="colorf17696 svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.6"
          fill="#a677f1"
          className="colorf17797 svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.6"
          fill="#a678f1"
          className="colorf17898 svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.6"
          fill="#a678f1"
          className="colorf17899 svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.6"
          fill="#a779f1"
          className="colorf17999 svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.5"
          fill="#a779f1"
          className="colorf1799a svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.5"
          fill="#a87af1"
          className="colorf17a9b svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.5"
          fill="#a87bf1"
          className="colorf17b9c svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.5"
          fill="#a87bf1"
          className="colorf17b9d svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.5"
          fill="#a97cf1"
          className="colorf17c9d svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.5"
          fill="#a97df1"
          className="colorf17d9e svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.5"
          fill="#a97df1"
          className="colorf17d9f svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.5"
          fill="#aa7ef1"
          className="colorf17ea0 svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.4"
          fill="#aa7ef1"
          className="colorf17ea1 svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.4"
          fill="#ab7ff1"
          className="colorf17fa1 svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.4"
          fill="#ab80f1"
          className="colorf180a2 svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.4"
          fill="#ab80f1"
          className="colorf180a3 svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.4"
          fill="#ac81f1"
          className="colorf181a4 svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.4"
          fill="#ac81f1"
          className="colorf181a4 svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.4"
          fill="#ad82f1"
          className="colorf182a5 svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.4"
          fill="#ad83f1"
          className="colorf183a6 svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.3"
          fill="#ad83f1"
          className="colorf183a7 svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.3"
          fill="#ae84f1"
          className="colorf184a8 svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.3"
          fill="#ae84f1"
          className="colorf184a8 svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.3"
          fill="#ae85f1"
          className="colorf185a9 svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.3"
          fill="#ae85f1"
          className="colorf185aa svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.3"
          fill="#af86f1"
          className="colorf186ab svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.3"
          fill="#b087f1"
          className="colorf187ab svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.3"
          fill="#b087f1"
          className="colorf187ac svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.2"
          fill="#b088f1"
          className="colorf188ad svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.2"
          fill="#b088f1"
          className="colorf188ad svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.2"
          fill="#b189f1"
          className="colorf189ae svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.2"
          fill="#b189f1"
          className="colorf189af svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.2"
          fill="#b18af1"
          className="colorf18ab0 svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.2"
          fill="#b18af1"
          className="colorf18ab0 svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.2"
          fill="#b28bf1"
          className="colorf18bb1 svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.2"
          fill="#b28bf1"
          className="colorf18bb2 svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.1"
          fill="#b38cf1"
          className="colorf18cb2 svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.1"
          fill="#b38cf1"
          className="colorf18cb3 svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.1"
          fill="#b38df1"
          className="colorf18db4 svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.1"
          fill="#b38df1"
          className="colorf18db4 svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.1"
          fill="#b48ef1"
          className="colorf18eb5 svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.1"
          fill="#b48ef1"
          className="colorf18eb6 svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.1"
          fill="#b48ef1"
          className="colorf18eb6 svgShape"
          clipPath="url(#b)"
        ></circle>
        <circle
          cx="27.8"
          cy="45.3"
          r="0.1"
          fill="#b58ff1"
          className="colorf18fb7 svgShape"
          clipPath="url(#b)"
        ></circle>
        <path
          fill="#4c4459"
          d="M27.8 47.3c-.3 0-.5-.2-.5-.5v-1.6c0-.3.2-.5.5-.5s.5.2.5.5v1.6c0 .3-.2.5-.5.5z"
          className="color594454 svgShape"
        ></path>
        <defs>
          <path
            id="c"
            fill="#000"
            d="M72.8 46.8v-1.5"
            className="color000 svgShape"
          ></path>
        </defs>
        <clipPath id="d">
          <use overflow="visible" xlinkHref="#c"></use>
        </clipPath>
        <circle
          cx="72.8"
          cy="45.3"
          r="1.1"
          fill="#9258f0"
          className="colorf05877 svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="1.1"
          fill="#9359f0"
          className="colorf05978 svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="1.1"
          fill="#935af0"
          className="colorf05a79 svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="1"
          fill="#945bf0"
          className="colorf05b79 svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="1"
          fill="#955cf0"
          className="colorf05c7a svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="1"
          fill="#955df0"
          className="colorf05d7b svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="1"
          fill="#965ef0"
          className="colorf05e7c svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="1"
          fill="#965ef0"
          className="colorf05e7d svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="1"
          fill="#975ff0"
          className="colorf05f7e svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="1"
          fill="#9760f0"
          className="colorf0607e svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="1"
          fill="#9861f0"
          className="colorf0617f svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.9"
          fill="#9862f0"
          className="colorf06280 svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.9"
          fill="#9963f0"
          className="colorf06381 svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.9"
          fill="#9963f0"
          className="colorf06382 svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.9"
          fill="#9a64f0"
          className="colorf06483 svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.9"
          fill="#9a65f0"
          className="colorf06583 svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.9"
          fill="#9b66f0"
          className="colorf06684 svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.9"
          fill="#9c67f0"
          className="colorf06785 svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.9"
          fill="#9c67f0"
          className="colorf06786 svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.8"
          fill="#9c68f0"
          className="colorf06887 svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.8"
          fill="#9d69f0"
          className="colorf06988 svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.8"
          fill="#9d6af0"
          className="colorf06a88 svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.8"
          fill="#9e6bf0"
          className="colorf06b89 svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.8"
          fill="#9e6bf0"
          className="colorf06b8a svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.8"
          fill="#9f6cf0"
          className="colorf06c8b svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.8"
          fill="#9f6df0"
          className="colorf06d8c svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.8"
          fill="#a06ef0"
          className="colorf06e8d svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.7"
          fill="#a06ef0"
          className="colorf06e8d svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.7"
          fill="#a06ff0"
          className="colorf06f8e svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.7"
          fill="#a170f1"
          className="colorf1708f svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.7"
          fill="#a271f1"
          className="colorf17190 svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.7"
          fill="#a271f1"
          className="colorf17191 svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.7"
          fill="#a372f1"
          className="colorf17291 svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.7"
          fill="#a373f1"
          className="colorf17392 svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.7"
          fill="#a373f1"
          className="colorf17393 svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.6"
          fill="#a474f1"
          className="colorf17494 svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.6"
          fill="#a575f1"
          className="colorf17595 svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.6"
          fill="#a576f1"
          className="colorf17695 svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.6"
          fill="#a576f1"
          className="colorf17696 svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.6"
          fill="#a677f1"
          className="colorf17797 svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.6"
          fill="#a678f1"
          className="colorf17898 svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.6"
          fill="#a678f1"
          className="colorf17899 svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.6"
          fill="#a779f1"
          className="colorf17999 svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.5"
          fill="#a779f1"
          className="colorf1799a svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.5"
          fill="#a87af1"
          className="colorf17a9b svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.5"
          fill="#a87bf1"
          className="colorf17b9c svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.5"
          fill="#a87bf1"
          className="colorf17b9d svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.5"
          fill="#a97cf1"
          className="colorf17c9d svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.5"
          fill="#a97df1"
          className="colorf17d9e svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.5"
          fill="#a97df1"
          className="colorf17d9f svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.5"
          fill="#aa7ef1"
          className="colorf17ea0 svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.4"
          fill="#aa7ef1"
          className="colorf17ea1 svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.4"
          fill="#ab7ff1"
          className="colorf17fa1 svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.4"
          fill="#ab80f1"
          className="colorf180a2 svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.4"
          fill="#ab80f1"
          className="colorf180a3 svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.4"
          fill="#ac81f1"
          className="colorf181a4 svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.4"
          fill="#ac81f1"
          className="colorf181a4 svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.4"
          fill="#ad82f1"
          className="colorf182a5 svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.4"
          fill="#ad83f1"
          className="colorf183a6 svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.3"
          fill="#ad83f1"
          className="colorf183a7 svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.3"
          fill="#ae84f1"
          className="colorf184a8 svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.3"
          fill="#ae84f1"
          className="colorf184a8 svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.3"
          fill="#ae85f1"
          className="colorf185a9 svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.3"
          fill="#ae85f1"
          className="colorf185aa svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.3"
          fill="#af86f1"
          className="colorf186ab svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.3"
          fill="#b087f1"
          className="colorf187ab svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.3"
          fill="#b087f1"
          className="colorf187ac svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.2"
          fill="#b088f1"
          className="colorf188ad svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.2"
          fill="#b088f1"
          className="colorf188ad svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.2"
          fill="#b189f1"
          className="colorf189ae svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.2"
          fill="#b189f1"
          className="colorf189af svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.2"
          fill="#b18af1"
          className="colorf18ab0 svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.2"
          fill="#b18af1"
          className="colorf18ab0 svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.2"
          fill="#b28bf1"
          className="colorf18bb1 svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.2"
          fill="#b28bf1"
          className="colorf18bb2 svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.1"
          fill="#b38cf1"
          className="colorf18cb2 svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.1"
          fill="#b38cf1"
          className="colorf18cb3 svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.1"
          fill="#b38df1"
          className="colorf18db4 svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.1"
          fill="#b38df1"
          className="colorf18db4 svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.1"
          fill="#b48ef1"
          className="colorf18eb5 svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.1"
          fill="#b48ef1"
          className="colorf18eb6 svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.1"
          fill="#b48ef1"
          className="colorf18eb6 svgShape"
          clipPath="url(#d)"
        ></circle>
        <circle
          cx="72.8"
          cy="45.3"
          r="0.1"
          fill="#b58ff1"
          className="colorf18fb7 svgShape"
          clipPath="url(#d)"
        ></circle>
        <path
          fill="#4c4459"
          d="M72.8 47.3c-.3 0-.5-.2-.5-.5v-1.6c0-.3.2-.5.5-.5s.5.2.5.5v1.6c0 .3-.2.5-.5.5zm-29 12c-.1 0-.2 0-.3-.1-.2-.1-.3-.5-.2-.7l1.8-2.9.1-3.7-1.9-2.9c-.2-.2-.1-.5.1-.7.2-.2.5-.1.7.1l2 3c.1.1.1.2.1.3l-.1 4c0 .1 0 .2-.1.3l-1.9 3c0 .3-.1.3-.3.3zm13 0c-.2 0-.3-.1-.4-.2l-2-3c-.1-.1-.1-.2-.1-.3l.1-4c0-.1 0-.2.1-.3l1.9-3c.1-.2.5-.3.7-.2.2.1.3.5.2.7l-1.9 3-.1 3.7 1.9 2.9c.2.2.1.5-.1.7h-.3z"
          className="color594454 svgShape"
        ></path>
        <path
          fill="#e1cfff"
          d="M30.7 67.8h3.1v22h-3.1z"
          className="colorffe9cf svgShape"
        ></path>
        <path
          fill="#4c4459"
          d="M34.3 90.3h-4.1v-23h4.1v23zm-3.1-1h2.1v-21h-2.1v21z"
          className="color594454 svgShape"
        ></path>
        <path
          fill="#e1cfff"
          d="M43.6 67.8h3.1v22h-3.1z"
          className="colorffe9cf svgShape"
        ></path>
        <path
          fill="#4c4459"
          d="M47.2 90.3h-4.1v-23h4.1v23zm-3.1-1h2.1v-21h-2.1v21z"
          className="color594454 svgShape"
        ></path>
        <path
          fill="#e1cfff"
          d="M37.2 67.8h3.1v22h-3.1z"
          className="colorffe9cf svgShape"
        ></path>
        <path
          fill="#4c4459"
          d="M40.8 90.3h-4.1v-23h4.1v23zm-3.1-1h2.1v-21h-2.1v21z"
          className="color594454 svgShape"
        ></path>
        <path
          fill="#e1cfff"
          d="M66.7 67.8h3.1v22h-3.1z"
          className="colorffe9cf svgShape"
        ></path>
        <path
          fill="#4c4459"
          d="M70.3 90.3h-4.1v-23h4.1v23zm-3.1-1h2.1v-21h-2.1v21z"
          className="color594454 svgShape"
        ></path>
        <path
          fill="#e1cfff"
          d="M60.2 67.8h3.1v22h-3.1z"
          className="colorffe9cf svgShape"
        ></path>
        <path
          fill="#4c4459"
          d="M63.8 90.3h-4.1v-23h4.1v23zm-3.1-1h2.1v-21h-2.1v21z"
          className="color594454 svgShape"
        ></path>
        <path
          fill="#e1cfff"
          d="M53.7 67.8h3.1v22h-3.1z"
          className="colorffe9cf svgShape"
        ></path>
        <path
          fill="#4c4459"
          d="M57.3 90.3h-4.1v-23h4.1v23zm-3.1-1h2.1v-21h-2.1v21zM51.7 47c-.3 0-.5-.2-.5-.5v-1.3c0-.5-.3-1-.7-1.3l-.3-.2-.3.2c-.4.3-.7.7-.7 1.3v1.3c0 .3-.2.5-.5.5s-.5-.2-.5-.5v-1.3c0-.9.4-1.6 1.1-2.1l.6-.4c.2-.1.4-.1.5 0l.6.4c.7.5 1.2 1.3 1.2 2.1v1.3c0 .3-.2.5-.5.5z"
          className="color594454 svgShape"
        ></path>
        <path
          fill="#4c4459"
          d="M80.8 90.3h-61c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h61c.3 0 .5.2.5.5s-.3.5-.5.5z"
          className="color594454 svgShape"
        ></path>
      </svg>
    </svg>
  );
}

export default BangloreIcon;