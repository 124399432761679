import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { clearErrors, getTrainingDetails, getSimilarTrainings } from '../../actions/trainingAction';
import Loader from '../Layouts/Loader';
import MetaData from '../Layouts/MetaData';
import TrainingTop from './TrainingTop';
import TrainingSkills from './TrainingSkills';
import CareerOptions from "./CareerOptions.jsx";
import CertificateSample from './CertificateSample';
import TrainingTopics from './TrainingTopics';
import PopularTraining from './PopularTraining';
import { addItemsToCart } from '../../actions/cartAction';
import { getDeliveryDate, getDiscount } from '../../utils/functions';
import { addToWishlist, removeFromWishlist } from '../../actions/wishlistAction';

import Twitter from '@mui/icons-material/Twitter';
import Instagram from '@mui/icons-material/Instagram';
import Youtube from '@mui/icons-material/YouTube';
import Linkedin from '@mui/icons-material/LinkedIn';
import Whatsapp from '@mui/icons-material/WhatsApp';
import FacebookRoundedIcon from '@mui/icons-material/Facebook';


const TrainingDetails = () => {

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const params = useParams();
    const navigate = useNavigate();

    const [open, setOpen] = useState(false);
    // const [viewAll, setViewAll] = useState(false);
    // const [rating, setRating] = useState(0);
    // const [comment, setComment] = useState("");

    const { training, loading, error } = useSelector((state) => state.trainingDetails);
    const { cartItems } = useSelector((state) => state.cart);
    const { wishlistItems } = useSelector((state) => state.wishlist);

    // const settings = {
    //     autoplay: true,
    //     autoplaySpeed: 2000,
    //     dots: true,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     prevArrow: <PreviousBtn />,
    //     nextArrow: <NextBtn />,
    // };

    const slug = params.id;
    const itemInWishlist = wishlistItems.some((i) => i.training === slug);
    
    const addToWishlistHandler = () => {
        if (itemInWishlist) {
            dispatch(removeFromWishlist(slug));
            enqueueSnackbar("Remove From Wishlist", { variant: "success" });
        } else {
            dispatch(addToWishlist(slug));
            enqueueSnackbar("Added To Wishlist", { variant: "success" });
        }
    }

    // const reviewSubmitHandler = () => {
    //     if (rating === 0 || !comment.trim()) {
    //         enqueueSnackbar("Empty Review", { variant: "error" });
    //         return;
    //     }
    //     const formData = new FormData();
    //     formData.set("rating", rating);
    //     formData.set("comment", comment);
    //     formData.set("trainingId", trainingId);
    //    // dispatch(newReview(formData));
    //     setOpen(false);
    // }

    const addToCartHandler = () => {
        dispatch(addItemsToCart(slug));
        enqueueSnackbar("Training Added To Cart", { variant: "success" });
    }

    const handleDialogClose = () => {
        setOpen(!open);
    }

   const itemInCart = cartItems.some((i) => i.training === slug);

    const goToCart = () => {
        navigate('/cart');
    }

    const buyNow = () => {
        addToCartHandler();
        navigate('/shipping');
    }

    useEffect(() => {
        if (error) {
            enqueueSnackbar(error, { variant: "error" });
            dispatch(clearErrors());
        }
       
        dispatch(getTrainingDetails(slug));
    }, [dispatch, slug, error, enqueueSnackbar]);

    useEffect(() => {
        dispatch(getSimilarTrainings(training?.category));
    }, [dispatch, training, training.category]);

    return (
        <>
            {loading ? <Loader /> : (
                <>
                    <MetaData title={training.training_name} imageurl={training.image} />
                    
                    <main className="w-full mt-12 sm:mt-0 grid grid-cols-12">

                        {/* <TrainingTop {...training} />
                        <TrainingSkills />
                        <CareerOptions />
                        <CertificateSample training_name={training.training_name}/>
                         
                         <TrainingTopics syllabus={training.syllabus} /> 
                        <PopularTraining /> */}
                        <div className='xs:col-span-12 md:col-span-8 lg:col-span-6 p-7 border-2 border-red-300'>
                        <h2 className="xs:text-xl lg:text-2xl font-normal xs:truncate text-gray-600 text-center p-3">Password Reset</h2>
                        <div className="text-center text-gray-600 trancate xs:text-[13px] lg:text-md p-3 xs:w-[90%] lg:w-[55%] mx-auto">If you've lost your password or wish to regret it, use the link below to get started.</div>
                        <div className="xs:w-[150px] lg:w-[180px] bg-purple-900 mb-0 text-center rounded-xs xs:p-1 lg:p-4 my-4 xs:text-[10px]  lg:text-xs font-small text-gray-100 leading-7 mx-auto">RESET YOUR PASSWORD </div> 
                        <div className="text-center text-gray-400 trancate xs:text-[11px] lg:text-sm p-3 lg:py-8 xs:py-6 xs:w-[98%] lg:w-[88%] mx-auto"> You can safely ignore this email, if you didn't requested for password reset.Only a person with access to your email can reset your account password</div>
                        <div className="text-center text-gray-600 trancate xs:text-[10px] lg:text-sm p-3 lg:py-8 xs:py-6 xs:w-[98%] lg:w-[88%] mx-auto"> If you have any questions, please mail us at <a className='text-purple-900'>help@lyriclious.com</a> or visit our <a className='text-purple-900'>FAQs.</a></div>
                        <hr className='w-[50%] mx-auto ' />
                        <div className='text-gray-700 text-center p-2 grid grid-cols-4 gap-1 w-[30%] mx-auto'>
                            <FacebookRoundedIcon></FacebookRoundedIcon>
                            <Instagram></Instagram>
                            <Twitter></Twitter>
                            <Youtube></Youtube>
                        </div>
                        <div className="text-center text-gray-400 trancate xs:text-[13px] lg:text-md p-1 xs:w-[90%] lg:w-[65%] mx-auto">You have receieved this email as a registered user of lyriclious.com</div>
                        <div className="text-center text-gray-400 trancate xs:text-[13px] lg:text-md p-1 xs:w-[90%] lg:w-[65%] mx-auto">You can unsubscribe from these email <a>here.</a></div>
                        <div className="text-center text-gray-400 trancate xs:text-[13px] lg:text-md p-1 xs:w-[90%] lg:w-[65%] mx-auto">Flat No.- 158, Block No.-1, Pocket-E,Madhuban Bapudham SchemeGhaziabad, 201002, Uttar Pradesh, India</div>
                        <div className="text-center text-gray-400 trancate xs:text-[13px] lg:text-md p-1 xs:w-[90%] lg:w-[65%] mx-auto">Lyriclious.com</div>
                        </div>





                        <button className="btn" onClick={()=>document.getElementById('my_modal_2').showModal()}>open modal</button>
<dialog id="my_modal_2" className="modal">
  <div className="modal-box">
    <h3 className="font-bold text-lg">Hello!</h3>
    <p className="py-4">Press ESC key or click outside to close</p>
  </div>
  <form method="dialog" className="modal-backdrop">
    <button>close</button>
  </form>
</dialog>

                    </main>
                </>
            )}
        </>
    );
};

export default TrainingDetails;
