// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/CertificateFormats/golden_purple_design.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.certificate-back{
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover;
    width : 1123px;
    height: 794px;
    
}`, "",{"version":3,"sources":["webpack://./src/components/CertificateFormat/Certificate.css"],"names":[],"mappings":"AAAA;IACI,yDAAsF;IACtF,2BAA2B;IAC3B,4BAA4B;IAC5B,sBAAsB;IACtB,cAAc;IACd,aAAa;;AAEjB","sourcesContent":[".certificate-back{\n    background-image: url(../../assets/images/CertificateFormats/golden_purple_design.png);\n    background-position: center; \n    background-repeat: no-repeat; \n    background-size: cover;\n    width : 1123px;\n    height: 794px;\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
