import {
    ADMIN_CAREERS_FAIL,
    ADMIN_CAREERS_REQUEST,
    ADMIN_CAREERS_SUCCESS,
    ALL_CAREERS_FAIL,
    ALL_CAREERS_REQUEST,
    ALL_CAREERS_SUCCESS,
    CLEAR_ERRORS,
    DELETE_CAREER_FAIL,
    DELETE_CAREER_REQUEST,
    DELETE_CAREER_RESET,
    DELETE_CAREER_SUCCESS,
    NEW_CAREER_FAIL,
    NEW_CAREER_REQUEST,
    NEW_CAREER_RESET,
    NEW_CAREER_SUCCESS,
    CAREER_DETAILS_FAIL,
    CAREER_DETAILS_REQUEST,
    CAREER_DETAILS_SUCCESS,
    UPDATE_CAREER_FAIL,
    UPDATE_CAREER_REQUEST,
    UPDATE_CAREER_RESET,
    UPDATE_CAREER_SUCCESS,
    REMOVE_CAREER_DETAILS,
    SLIDER_CAREERS_FAIL,
    SLIDER_CAREERS_REQUEST,
    SLIDER_CAREERS_SUCCESS,
} from "../constants/careerConstants";

export const careersReducer = (state = { careers: [] }, { type, payload }) => {

    switch (type) {
        case ALL_CAREERS_REQUEST:
        case ADMIN_CAREERS_REQUEST:
        case SLIDER_CAREERS_REQUEST:
            return {
                loading: true,
                careers: [],
            };
        case ALL_CAREERS_SUCCESS:
            return {
                loading: false,
                careers: payload.careers,
                careersCount: payload.careersCount,
                resultPerPage: payload.resultPerPage,
                filteredCareersCount: payload.filteredCareersCount,
            };
        case ADMIN_CAREERS_SUCCESS:
        case SLIDER_CAREERS_SUCCESS:
            return {
                loading: false,
                careers: payload,
            };
        case ALL_CAREERS_FAIL:
        case ADMIN_CAREERS_FAIL:
        case SLIDER_CAREERS_FAIL:
            return {
                loading: false,
                error: payload,
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
}

export const careerDetailsReducer = (state = { career: {} }, { type, payload }) => {

    switch (type) {
        case CAREER_DETAILS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CAREER_DETAILS_SUCCESS:
            return {
                loading: false,
                career: payload,
            };
        case CAREER_DETAILS_FAIL:
            return {
                loading: false,
                error: payload,
            };
        case REMOVE_CAREER_DETAILS:
            return {
                ...state,
                career: {},
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
}



// New Career Reducer
export const newCareerReducer = (state = { career: {} }, { type, payload }) => {
    switch (type) {
        case NEW_CAREER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case NEW_CAREER_SUCCESS:
            return {
                loading: false,
                success: payload.success,
                career: payload.career,
            };
        case NEW_CAREER_FAIL:
            return {
                ...state,
                loading: false,
                error: payload,
            };
        case NEW_CAREER_RESET:
            return {
                ...state,
                success: false,
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
}

// Career Reducer
export const careerReducer = (state = {}, { type, payload }) => {
    switch (type) {
        case UPDATE_CAREER_REQUEST:
        case DELETE_CAREER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_CAREER_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdated: payload,
            };
        case DELETE_CAREER_SUCCESS:
            return {
                ...state,
                loading: false,
                isDeleted: payload,
            };
        case UPDATE_CAREER_FAIL:
        case DELETE_CAREER_FAIL:
            return {
                ...state,
                loading: false,
                error: payload,
            };
        case UPDATE_CAREER_RESET:
            return {
                ...state,
                isUpdated: false,
            };
        case DELETE_CAREER_RESET:
            return {
                ...state,
                isDeleted: false,
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
}

