import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {  useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit'

const About = ({ activeTab, children }) => {

    const navigate = useNavigate();

    const { user, loading, isAuthenticated } = useSelector(state => state.user)

    useEffect(() => {
        if (isAuthenticated === false) {
            navigate("/login")
        }
    }, [isAuthenticated, navigate]);

    

    

    return (
        <> 
                        {/* <!-- row --> */}
                        <div className="pt-4 gap-3.5 sm:w-11/12 sm:mt-4  mb-7 border-gray-300 border-[1.2px] mx-auto rounded-xl ">
                           <span >
                            <h1 className='inline-block mx-[2%]  text-xl'>About</h1>
                            <EditIcon className='ml-[83%] text-gray-600' />
                           </span>
                            
                            <div className='flex mx-[2%] my-2'>
                            
                            <p className='text-gray-500 text-sm font-thin'>
                            {user.about}Ever since I was a kid little kid, Software Development has been my passion. I still remember the days when everyone in my class used to tell their dream jobs to be pilot, doctor, engineer, police etc. but at the same time I was very specific that "I want to be a Software Engineer". 
                            So after all hustles, ups and downs here I am as a Full Stack Developer with the demanding skills set along with good project works. 
                           </p>
                        </div>
                    </div>
                   
            
        </>
    );
};

export default About;
