import React from "react";

function LogoDesignIcon({height,width}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      data-name="Layer 1"
      viewBox="0 0 588.698 669.944"
    >
      <path
        fill="#e6e6e6"
        d="M423.816 264.028H751.957V266.028H423.816z"
        transform="rotate(-35.382 254.746 686.645)"
      ></path>
      <path fill="#f2f2f2" d="M136.576 54.743H421.475V56.743H136.576z"></path>
      <path fill="#f2f2f2" d="M136.576 100.743H421.475V102.743H136.576z"></path>
      <path fill="#f2f2f2" d="M136.576 146.743H421.475V148.743H136.576z"></path>
      <path fill="#f2f2f2" d="M136.576 192.743H421.475V194.743H136.576z"></path>
      <path fill="#f2f2f2" d="M136.576 238.743H421.475V240.743H136.576z"></path>
      <path
        fill="#e6e6e6"
        d="M447.233 287.655H135.176V0h2v203.383a82.273 82.273 0 0082.272 82.272h227.785z"
      ></path>
      <circle cx="284.176" cy="160" r="85" fill="#6c63ff"></circle>
      <path
        fill="#ffb6b6"
        d="M407.338 154.958l64.938 50.254a13.254 13.254 0 0018.457-2.26 13.254 13.254 0 00-2.693-19.045l-69.055-50.133-40.676-32.231a11.566 11.566 0 10-12.22 8.427z"
      ></path>
      <path
        fill="#3f3d56"
        d="M305.176 227a86 86 0 1186-86 86.098 86.098 0 01-86 86zm0-170c6.513 32.23-84 37.682-84 84a84.095 84.095 0 0084 84c46.318 0 84.961-37.692 84-84-.963-46.39-90.514-116.23-84-84z"
      ></path>
      <circle cx="461.152" cy="119.449" r="32.408" fill="#ffb6b6"></circle>
      <path
        fill="#ffb6b6"
        d="M365.677 646.876L354.199 646.875 348.739 602.605 365.679 602.605 365.677 646.876z"
      ></path>
      <path
        fill="#2f2e41"
        d="M331.034 663.81a4.882 4.882 0 004.851 4.861h21.606l.67-1.387 3.056-6.307 1.184 6.307.262 1.387h8.15l-.117-1.397-2.174-26.06-2.842-.174-12.283-.728-3.017-.184v7.78c-2.29 2.436-20.258 11.643-19.346 15.902z"
      ></path>
      <path
        fill="#ffb6b6"
        d="M570.695 596.294L561.935 603.71 529.163 573.45 542.092 562.505 570.695 596.294z"
      ></path>
      <path
        fill="#2f2e41"
        d="M555.198 631.603a4.882 4.882 0 006.843.576l16.49-13.961-.385-1.492-1.743-6.787 4.978 4.048 1.097.89 6.22-5.266-.992-.991-18.497-18.485-2.282 1.703-9.844 7.381-2.422 1.81 5.027 5.938c-.174 3.338-7.938 21.975-4.49 24.636z"
      ></path>
      <path
        fill="#f2f2f2"
        d="M271.221 652.257a2.27 2.27 0 01-1.644-3.933l.155-.618q-.03-.075-.062-.149a6.094 6.094 0 00-11.241.042c-1.839 4.428-4.18 8.864-4.756 13.546a18.029 18.029 0 00.317 6.2 72.282 72.282 0 01-6.576-30.02 69.766 69.766 0 01.433-7.783q.359-3.178.995-6.31a73.119 73.119 0 0114.5-30.99 19.459 19.459 0 008.093-8.397 14.843 14.843 0 001.35-4.056c-.394.052-1.486-5.949-1.188-6.317-.55-.833-1.532-1.247-2.132-2.06-2.982-4.044-7.091-3.338-9.236 2.157-4.582 2.313-4.627 6.148-1.815 9.837 1.789 2.347 2.034 5.522 3.604 8.035-.162.206-.33.407-.49.613a73.591 73.591 0 00-7.682 12.169 30.592 30.592 0 00-1.826-14.21c-1.749-4.217-5.025-7.769-7.91-11.415a6.28 6.28 0 00-11.185 3.084l-.017.16q.643.363 1.259.77a3.078 3.078 0 01-1.242 5.602l-.062.01a30.628 30.628 0 00.807 4.58c-3.702 14.315 4.29 19.53 15.702 19.764.251.129.497.258.749.38a75.115 75.115 0 00-4.043 19.029 71.243 71.243 0 00.051 11.503l-.02-.136a18.826 18.826 0 00-6.426-10.87c-4.945-4.063-11.933-5.559-17.268-8.824a3.533 3.533 0 00-5.411 3.437l.022.142a20.675 20.675 0 012.318 1.118q.643.362 1.259.77a3.078 3.078 0 01-1.241 5.601l-.063.01-.129.02a30.654 30.654 0 005.638 8.829c2.315 12.497 12.256 13.682 22.89 10.043h.007a75.093 75.093 0 005.045 14.726h18.02c.064-.2.123-.407.18-.607a20.475 20.475 0 01-4.986-.297c1.337-1.64 2.674-3.294 4.011-4.934a1.122 1.122 0 00.084-.097c.679-.84 1.363-1.673 2.041-2.513a29.99 29.99 0 00-.878-7.64z"
      ></path>
      <path
        fill="#ccc"
        d="M217.63 668.754a1.186 1.186 0 001.19 1.19h253.29a1.19 1.19 0 100-2.38H218.82a1.187 1.187 0 00-1.19 1.19z"
      ></path>
      <path
        fill="#3f3d56"
        d="M305.879 116.644a.757.757 0 00-1.408 0 64.293 64.293 0 01-7.149 13.203.762.762 0 00.878 1.164l5.975-2.162v36.984h2V128.85l5.976 2.162a.762.762 0 00.878-1.164 64.352 64.352 0 01-7.15-13.203z"
      ></path>
      <path
        fill="#2f2e41"
        d="M473.922 306.697l-78.314-20.264S357.176 335 355.176 393c-.103 2.97 1.464 6.69-.268 10.345s2.314 10.561-.21 13.608-3.674 5.66-.098 9.353-6.235 162.41-9.914 168.379c-4.51 7.315 3.922 13.008-.294 16.161S344.176 623 344.176 623h22l31.446-116.192s19.82-53.935 16.444-60.757 2.576-9.518 2.576-9.518 5.144-2.185 2.095-7.744S425.176 405 425.176 405c-4.273-.555 2.049 56.024 27.538 106.982 18.647 37.277 50.715 47.324 52.462 54.018 3.87 14.83 22.697-1.409 17 10s12 10 12 10l20-16-61-75-6.57-79.754s-3.495-1.093-1.463-5.17-4.754-3.608-2.36-8.342-3.988-4.5-2.298-8.117-1.99-7.033-1.99-7.033 9.624-32.698-8.098-56.391z"
      ></path>
      <path
        fill="#e6e6e6"
        d="M374.69 322.23s14-32 18-36S410.175 275 407.175 272s-8.961-1.213-3.98-6.606 8.81-15.874 8.81-15.874l-4.317-50.29 23-29L445.176 156l35.34-2.291 1.98 20.296s32.645 16.877 25.162 49.436-20.969 36.789-16.969 76.789c.446 4.455-17.308-1.127-13.513.77 2 1 11.173 18.972 5.173 22.972a56.58 56.58 0 01-12 6c-30.72-24.262-62.148-35.354-95.66-7.742z"
      ></path>
      <path
        fill="#ffb6b6"
        d="M358.525 222.308l78.795-23.1a13.254 13.254 0 009-16.273 13.254 13.254 0 00-17.003-8.993l-81.113 26.505-49.966 14.028a11.566 11.566 0 10-.346 14.839z"
      ></path>
      <path
        fill="#2f2e41"
        d="M497.824 98.705c4.51-16.44-44.633-30.536-54.183-17.298-1.328-1.882-6.235-3.025-8.487-2.448a17.56 17.56 0 00-5.993 3.418c-2.54 1.952-5.173 3.972-6.732 6.778-1.57 2.795-1.813 6.605.3 9.03 1.675 1.928 4.617 12.175 7.134 12.683 1.755.358 3.233.647 4.503.855 1.132-1.651 4.018-3.725 3.81-5.723 1.709 1.132 1.084 3.122.74 5.157-1.147 6.776-26.996 59.225-12.191 43.77a31.027 31.027 0 005.369 2.413 67.519 67.519 0 008.372-18.244l.013.122a14.384 14.384 0 0010.387 12.036c22.223 6.438 40.2-3.01 46.383-20.045-2.264-4.596-3.25-4.097-3.047-4.233a5.32 5.32 0 017.924 2.525c.358.938.684 1.74.966 2.343 3.221-11.524 7.053-9.86-5.268-33.14z"
      ></path>
      <path
        fill="#2f2e41"
        d="M482.493 86.778l-2.27-11.606a4.63 4.63 0 01.068-2.925 3.139 3.139 0 013.282-1.437c1.264.182 2.21 1.337 3.468 1.56 4.372.772 10.153-3.547 11.72 7.38.653 4.556 7.922 5.037 10.35 8.947s2.726 9.553-.588 12.747c-2.646 2.552-6.895 2.846-10.332 1.543s-6.195-3.936-8.605-6.712-4.591-5.78-7.37-8.185"
      ></path>
      <path
        fill="#6c63ff"
        d="M499.555 104.43c-8.867-1.24-14.912-4.465-17.965-9.586-3.996-6.703-1.32-14.085-1.204-14.396l1.875.697c-.025.068-2.453 6.825 1.057 12.692 2.731 4.565 8.287 7.462 16.514 8.612z"
      ></path>
      <path
        fill="#f2f2f2"
        d="M29.157 446.334c5.519-11.454 19.838-17.834 32.045-14.276 6.876 2.003 12.644 6.608 18.966 9.972s14.167 5.455 20.528 2.161c10.932-5.66 10.265-22.562 19.774-30.382 6.33-5.205 15.616-4.961 23.439-2.521s14.976 6.748 22.85 9.019a47.437 47.437 0 0042.964-9.165q.175-.147.344-.297a8.771 8.771 0 0114.597 6.791l-1.475 53.594h-193z"
      ></path>
      <path
        fill="#3f3d56"
        d="M198.539 436.676a.757.757 0 000-1.407 64.293 64.293 0 01-13.204-7.15.762.762 0 00-1.164.879l2.162 5.974h-36.984v2h36.984l-2.162 5.977a.762.762 0 001.164.878 64.351 64.351 0 0113.204-7.151zM115.332 427.043a.757.757 0 00-.852-1.12 64.293 64.293 0 01-14.836 2.305.762.762 0 00-.395 1.403l5.338 3.446-29.435 22.393 1.211 1.592 29.435-22.393 1.898 6.066a.762.762 0 001.458-.006 64.352 64.352 0 016.178-13.686z"
      ></path>
      <path
        fill="#e6e6e6"
        d="M230.79 348.352H22.16A22.175 22.175 0 000 370.502v113.33a22.192 22.192 0 0022.16 22.17h208.63a22.186 22.186 0 0022.16-22.17v-113.33a22.169 22.169 0 00-22.16-22.15zm19.72 135.48a19.728 19.728 0 01-19.72 19.72H56.19a53.08 53.08 0 01-7.17-.48h-.01c-.18-.02-.37-.05-.55-.08-26.03-3.73-46.02-103.13-46.02-130.19v-2.3a19.745 19.745 0 0119.72-19.71h208.63a19.739 19.739 0 0119.72 19.71z"
      ></path>
      <circle cx="216.539" cy="407.065" r="17.143" fill="#6c63ff"></circle>
      <circle
        cx="132.539"
        cy="407.065"
        r="17.143"
        fill="#6c63ff"
        opacity="0.7"
      ></circle>
      <circle
        cx="56.539"
        cy="423.065"
        r="17.143"
        fill="#6c63ff"
        opacity="0.25"
      ></circle>
    </svg>
  );
}

export default LogoDesignIcon;