import "../ViewAll/ViewAll.css"

const ViewAll = ({title, url}) => {
    return (
        <>
         <div className="flex-wrap viewLink">
            <a href={url} className="text-blue-500 leading-0.5 text-sm font-small">
                <h4>View All {title}...</h4>
            </a>
        </div>
        </>
    );
};


export default ViewAll;
