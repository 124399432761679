import WebTrainingIcon from "../../assets/images/Training/WebTraining.jsx"
import "./TrainingDetails.css";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import LaptopChromebookIcon from '@mui/icons-material/LaptopChromebook';
const TrainingTop = ({training_name, description, price, offerPrice, duration,image}) => {
    //const { training, loading, error } = useSelector((state) => state.trainingDetails);
    const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  const d = new Date();
  
    return (
        <> 
                        {/* <!-- row --> */}
                      
                            <div className="flex  grid grid-cols-12  bg-purple-900">
                                <div className="xs:col-start-2 xs:col-span-10 lg:col-start-2 lg:col-span-5  text-white  my-8 ">
                                    <h4 className="mt-4 mb-4 font-extralight">Get Trained in</h4>
                                    <h2 className="text-3xl mb-4">{training_name}</h2>
                                    <button className="rounded-full border-[1px] border-white mb-12 px-4 py-2 font-extralight text-xs">
                                      Training Program
                                      <CheckCircleOutlineIcon className="ml-2 font-[2px]"/></button>
                                    <p className="text-gray-50 font-thin text-sm">{(description && description.length>400)?(description.substr(0,400))+"...":description}</p>
                                </div>
                                <div className=" xs:col-span-5 lg:col-start-8 lg:col-span-5 lg:float-right ">
                                    {/* <img src={image && image.url} /> */}
                                    <WebTrainingIcon  height={'400px'}  width={'500px'}  />
                                </div>
                            </div>

                            <div className="grid grid-cols-8 xs:w-[90%] lg:w-[1200px] xs:h-[120px] mx-auto rounded-lg bg-white pl-8 mt-[-3.5%] shadow-xl">
                                <span className="flex xs:col-span-2 lg:col-span-2 m-auto">
                                <span className=" bg-purple-300 w-[50px] h-[50px] rounded-lg mx-2"><p className="text-center m-[12px]"><LaptopChromebookIcon className="text-lg"/></p></span>
                                    <span>
                                    <p className="text-gray-700  xs:text-sm lg:text-base">ONLINE</p>
                                    <p className="text-gray-700 xs:text-xs lg:text-sm">{duration && (duration.d_num+" "+duration.d_type)}</p>
                                    </span>
                                </span>

                                <span className="flex xs:col-span-2 lg:col-span-2 m-auto">
                                <span className=" bg-purple-300 w-[50px] h-[50px] rounded-lg mx-2"><p className="text-center m-[12px]"><LaptopChromebookIcon className="text-lg"/></p></span>
                                    <span>
                                    <p className="text-gray-700 xs:text-sm lg:text-base">Upcoming Batch </p>
                                    <p className="text-gray-700 xs:text-xs lg:text-sm">1 {monthNames[(d.getMonth()+1)%12]} {(d.getMonth()==11)?d.getFullYear()+1:d.getFullYear()}</p>
                                    <p></p>
                                    </span>
                                </span>

                                <span className="flex xs:col-span-2 lg:col-span-2 m-auto">
                                <span className=" bg-purple-300 w-[50px] h-[50px] rounded-lg mx-2 "><p className="text-center m-[12px]"><LaptopChromebookIcon className="text-lg"/></p></span>
                                    <span>
                                    <p className="text-gray-700 xs:text-sm lg:text-base">Training Charges </p>
                                    <span className="text-gray-400 xs:text-xs lg:text-sm">&#8377; <s>{price}</s>-  </span><span className="text-gray-700 xs:text-xs lg:text-sm">&#8377;{offerPrice}</span>
                                </span>
                                </span>

                                <span className="xs:col-span-3 lg:col-span-2 m-auto">
                                    <button className="bg-purple-900 text-white rounded-lg xs:mx-auto xs:px-8 lg:px-8 xs:py-2 lg:py-4 xs:font-normal lg:font-medium xs:text-base lg:text-lg">Apply Now</button>
                                </span>

                            </div>
                    
                   
            
        </>
    );
};

export default TrainingTop;
