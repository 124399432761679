import React from "react";

function GraphicDesignIcon({height,width}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
        height={height}
      data-name="Layer 1"
      viewBox="0 0 563.232 596.817"
    >
      <circle cx="215.615" cy="68.266" r="26.701" fill="#fd6584"></circle>
      <path
        fill="#e6e6e6"
        d="M275.373 55.623a13.235 13.235 0 00-11.17-9.29 1.08 1.08 0 00-.18 0 1.418 1.418 0 00-.22-.05c-8.94-1.16-18.88 1.66-25.57 7.8a23.958 23.958 0 00-5.83 8.04 12.917 12.917 0 00-10.83-5.28 13.184 13.184 0 00-11.8 9.51 1.526 1.526 0 00.69 1.69 6.673 6.673 0 002.79 1.42c2.21.47 4.44.9 6.67 1.29q6.54 1.14 13.15 1.85 4.365.465 8.75.74c6.04.38 12.11.47 18.16.28 3-.1 5.88-.2 8.62-1.52a12.798 12.798 0 004.95-4.31 14.548 14.548 0 001.82-12.17zM220.443 94.523a13.652 13.652 0 00-.56-3.3 13.26 13.26 0 00-11.18-9.29.965.965 0 00-.17 0 1.887 1.887 0 00-.23-.05 33.194 33.194 0 00-14.69 1.51c-7.26 2.43-13.63 7.33-16.7 14.33a12.922 12.922 0 00-10.84-5.28 13.183 13.183 0 00-11.8 9.51 1.555 1.555 0 00.69 1.69 6.733 6.733 0 002.79 1.42c2.22.47 4.44.9 6.68 1.29q6.54 1.14 13.14 1.85a195.164 195.164 0 0026.92 1.02c2.99-.1 5.88-.2 8.62-1.52a12.77 12.77 0 004.94-4.31 14.264 14.264 0 002.39-8.87z"
      ></path>
      <path
        fill="#f2f2f2"
        d="M418.13 431.48h-24.34V51.51c-1.64-1.21-3.29-2.41-4.96-3.58l-.99-.69a288.322 288.322 0 00-62.01-32.94l-4.72 65.1v.004h-30.37V3.39c-1.98-.49-3.96-.95-5.96-1.38v77.394H170.1V0c-1.99.37-3.98.75-5.96 1.16v78.244h-31.37V79.4l-4.95-68.27a287.687 287.687 0 00-60.78 29.46c-.66.42-1.32.84-1.98 1.28-1.33.86-2.66 1.74-3.97 2.63v386.98H26.82a26.803 26.803 0 00-24.89 36.79 25.54 25.54 0 001.75 3.56 27.095 27.095 0 003.02 4.18 26.76 26.76 0 0020.12 9.11h391.31a26.82 26.82 0 000-53.64zM314.57 169.782L295.64 431.48h-137.4l-18.93-261.698zm6.11-84.419l-4.67 64.556h-25.27V85.363zm-35.9 0v64.556h-45.632a10.916 10.916 0 00-9.723-5.96h-5.959a10.916 10.916 0 00-9.723 5.96H170.1V85.363zm-120.64 64.556h-26.27l-4.67-64.556h30.94z"
      ></path>
      <path
        fill="#3f3d56"
        d="M2.368 474.727a289.004 289.004 0 0028.12 28.95l379.58 1a289.003 289.003 0 0028.12-28.95z"
      ></path>
      <path
        fill="#2f2e41"
        d="M194.058 377.917v102.56h71.85v-102.56a3.448 3.448 0 00-2.43-3.3 3.175 3.175 0 00-1.03-.16h-64.92a3.463 3.463 0 00-3.47 3.46zm28.04 35.24a7.67 7.67 0 0115.34 0v11.9a7.67 7.67 0 01-15.34 0z"
      ></path>
      <path
        fill="#3f3d56"
        d="M193.838 477.197v9.89a1.946 1.946 0 001.93 1.94h68.43a1.94 1.94 0 001.93-1.94v-9.89zM81.028 223.397v187.26a9.007 9.007 0 009 9h277.5a9.007 9.007 0 009-9v-187.26a9.011 9.011 0 00-9-8.99h-277.5a9.012 9.012 0 00-9 8.99z"
      ></path>
      <path
        fill="#6c63ff"
        d="M87.538 225.727v182.62a4.818 4.818 0 004.81 4.81h272.87a4.818 4.818 0 004.81-4.81v-182.62a4.82 4.82 0 00-4.81-4.82H92.348a4.82 4.82 0 00-4.81 4.82z"
      ></path>
      <path
        fill="#2f2e41"
        d="M143.178 491.357a2.948 2.948 0 002.32 1.11h162.34a2.987 2.987 0 002.92-3.6l-2.52-11.97a2.993 2.993 0 00-1.86-2.17 2.894 2.894 0 00-1.06-.2h-157.31a2.894 2.894 0 00-1.06.2 2.992 2.992 0 00-1.86 2.17l-2.52 11.97a2.985 2.985 0 00.61 2.49z"
      ></path>
      <path
        fill="#fff"
        d="M148.008 477.027a.395.395 0 00-.145.025.508.508 0 00-.327.353l-2.522 11.977a.478.478 0 00.102.395l.01.014a.449.449 0 00.372.175h162.34a.467.467 0 00.37-.174.46.46 0 00.106-.398l-2.522-11.983a.498.498 0 00-.304-.349.436.436 0 00-.17-.035z"
      ></path>
      <path
        fill="#3f3d56"
        d="M101.121 246.063h255.324a1.016 1.016 0 000-2.03H101.12a1.016 1.016 0 000 2.03z"
      ></path>
      <ellipse
        cx="339.064"
        cy="232.765"
        fill="#3f3d56"
        rx="5.95"
        ry="6.083"
      ></ellipse>
      <ellipse
        cx="318.508"
        cy="232.765"
        fill="#3f3d56"
        rx="5.95"
        ry="6.083"
      ></ellipse>
      <ellipse
        cx="297.952"
        cy="232.765"
        fill="#3f3d56"
        rx="5.95"
        ry="6.083"
      ></ellipse>
      <path
        fill="#3f3d56"
        d="M112.41 229.297a1.095 1.095 0 001.09 1.1h14.6a1.105 1.105 0 000-2.21h-14.6a1.103 1.103 0 00-1.09 1.11zM112.41 233.447a1.095 1.095 0 001.09 1.1h14.6a1.105 1.105 0 000-2.21h-14.6a1.103 1.103 0 00-1.09 1.11zM112.41 237.587a1.103 1.103 0 001.09 1.11h14.6a1.105 1.105 0 000-2.21h-14.6a1.095 1.095 0 00-1.09 1.1zM318.401 277.057H139.164a8.892 8.892 0 00-8.881 8.882v87.237a8.892 8.892 0 008.881 8.881h179.237a8.892 8.892 0 008.882-8.881v-87.237a8.892 8.892 0 00-8.882-8.882zm6.882 8.882v25.451h-63v-32.333H318.4a6.89 6.89 0 016.882 6.882zm-128 59.785V313.39h63v32.334zm63 2v32.333h-63v-32.333zm-65-2h-63V313.39h63zm2-34.334v-32.333h63v32.333zm65 2h63v32.334h-63zm-123.119-34.333h56.119v32.333h-63V285.94a6.89 6.89 0 016.881-6.882zm-6.881 94.119v-25.452h63v32.333h-56.119a6.89 6.89 0 01-6.881-6.881zm186.118 6.881h-56.118v-32.333h63v25.452a6.89 6.89 0 01-6.882 6.881z"
      ></path>
      <path
        fill="#fff"
        d="M219.673 299.206c-1.59-2.631-3.475-5.44-6.427-6.299-3.418-.995-6.93.982-9.908 2.932a288.726 288.726 0 00-25.695 18.931l.01.102 19.173-1.322c4.618-.318 9.394-.68 13.475-2.865 1.548-.83 3.058-1.936 4.812-2.014 2.18-.095 4.081 1.427 5.575 3.018 8.837 9.41 11.394 23.8 21.308 32.066a314.616 314.616 0 00-22.323-44.55z"
      ></path>
      <path
        fill="#fff"
        d="M273.99 379.017c-.977-1.234-1.377-1.523-2.34-2.768q-11.773-15.227-22.131-31.481-7.036-11.04-13.373-22.503-3.018-5.45-5.87-10.986-2.213-4.292-4.327-8.635c-.403-.827-.795-1.658-1.184-2.491-.919-1.958-1.82-3.926-2.788-5.858a16.114 16.114 0 00-4.47-5.989 6.098 6.098 0 00-3.176-1.222 8.862 8.862 0 00-4.6 1.317 148.048 148.048 0 00-29.111 20.076 151.56 151.56 0 00-23.652 26.254c-.209.299-.703.013-.492-.287q1.246-1.762 2.542-3.487a152.397 152.397 0 0138.907-36.291q3.784-2.453 7.711-4.675a111.08 111.08 0 013.993-2.177 9.728 9.728 0 014.234-1.312c5.137-.08 7.797 5.738 9.624 9.653q.861 1.848 1.743 3.684 3.334 6.973 6.925 13.819 2.216 4.226 4.529 8.403 7.198 13.008 15.3 25.488c7.157 11.022 14.256 20.758 22.409 31.065.224.285-.177.692-.403.403zM180.112 305.604c-.299-.333-.595-.666-.896-.998-2.368-2.62-4.91-5.21-8.163-6.712a11.844 11.844 0 00-4.963-1.15 14.161 14.161 0 00-5.152 1.124c-.776.31-1.534.661-2.282 1.036-.853.43-1.692.888-2.526 1.35q-2.35 1.3-4.64 2.715-4.554 2.815-8.823 6.06-2.213 1.682-4.335 3.475-1.973 1.665-3.867 3.42c-.27.249-.673-.154-.403-.403.333-.31.67-.618 1.006-.922q1.426-1.29 2.897-2.529 2.684-2.264 5.506-4.354 4.39-3.25 9.08-6.061 2.345-1.403 4.754-2.688c.484-.259.975-.512 1.471-.751a23.46 23.46 0 013.499-1.424 12.445 12.445 0 015.283-.52 13.756 13.756 0 014.875 1.687c3.194 1.785 5.671 4.546 8.082 7.24.243.274-.158.679-.403.405z"
      ></path>
      <path
        fill="#fff"
        d="M245.557 332.753l7.654-2.8 3.8-1.39a38.862 38.862 0 013.752-1.291 5.919 5.919 0 013.396.043 7.827 7.827 0 012.648 1.642 21.495 21.495 0 012.173 2.32c.827.994 1.642 1.999 2.461 3q5.07 6.2 10.072 12.453 5.002 6.252 9.938 12.558 4.948 6.322 9.828 12.696l1.194 1.563c.22.288.716.004.493-.288q-4.916-6.439-9.9-12.824-4.997-6.401-10.062-12.748-5.066-6.346-10.2-12.638-1.279-1.567-2.562-3.131c-.722-.88-1.435-1.769-2.227-2.587a8.025 8.025 0 00-5.44-2.867 10.501 10.501 0 00-3.701.814c-1.28.463-2.556.934-3.834 1.402l-7.707 2.819-1.927.704c-.342.125-.195.677.151.55zM170.045 301.191a12.357 12.357 0 00-12.444.89 177.498 177.498 0 0120.194 4.713c-2.755-1.614-4.91-4.143-7.75-5.603zM157.562 302.074l-.748.609c.253-.213.516-.413.787-.602z"
      ></path>
      <path
        fill="#fff"
        d="M266.52 332.781a6.371 6.371 0 00-2.696-2.25l-1.052.042a119.342 119.342 0 0031.787 36.34z"
      ></path>
      <circle cx="261.597" cy="311.227" r="6.059" fill="#fff"></circle>
      <path
        fill="#3f3d56"
        d="M349.522 378.557a4.507 4.507 0 004.5-4.5v-93a4.5 4.5 0 10-9 0v93a4.507 4.507 0 004.5 4.5z"
      ></path>
      <path
        fill="#2f2e41"
        d="M349.522 378.557a4.507 4.507 0 004.5-4.5v-19.72a5.58 5.58 0 00-9 0v19.72a4.507 4.507 0 004.5 4.5z"
      ></path>
      <path
        fill="#6c63ff"
        d="M349.522 359.557a4.847 4.847 0 004.84-4.84V333.51a6.002 6.002 0 00-9.68 0v21.209a4.847 4.847 0 004.84 4.84z"
      ></path>
      <path
        fill="#3f3d56"
        d="M349.522 355.701a3.535 3.535 0 003.53-3.53v-15.469a4.378 4.378 0 00-7.06 0v15.47a3.535 3.535 0 003.53 3.53z"
      ></path>
      <path
        fill="#6c63ff"
        d="M210.699 483.036a4.004 4.004 0 01-.317-5.648l39.315-43.992a4 4 0 015.965 5.331l-39.315 43.992a4.004 4.004 0 01-5.648.317z"
      ></path>
      <path
        fill="#e6e6e6"
        d="M536.85 555.466c0 7.29-1.72 12.87-1.51 17.33a287.268 287.268 0 01-36.3 13.02c-1.4.4-2.8.78-4.21 1.16-7.63-23.36-15.27-44.75-18.28-47.02-6.89-5.17-10.34 16.22-10.34 11.05 0-5.17 3.45-5.17-5.16-12.06-8.62-6.89-3.45-37.9-3.45-37.9l-12.06-31.01 13.49-49.46 17.52-64.25h17.23s.51.37 1.36 1.06c4.31 3.52 17.31 15.28 15.87 28.23-.43 3.86.53 15.08 2.2 30.02 5.08 45.11 16.75 94.15 16.75 107.09 0 17.23 6.89 22.4 6.89 32.74z"
      ></path>
      <path
        fill="#3f3d56"
        d="M522.402 415.467c-.02-.05-.03-.09-.05-.13a26.64 26.64 0 00-1.35-3.04c-2.91-5.47.17-13.87-3.11-23.15-5.3-15.04-11.76-23.91-21.23-31.86-.28-.24-.56-.47-.85-.71l-.01-.01c-6.32-5.19-13.94-10.04-23.4-16.08-1.97-1.26-4.03-2.57-6.17-3.95-2.76-1.77-2.61-7.67-2.45-13.91.18-6.93.37-14.11-3.03-18.59l-.22-.29-.35-.09a150.437 150.437 0 00-35.42-2.73h-.01a69.659 69.659 0 00-14.93 2.19c-.36.1-.72.2-1.07.32-.27.07-.54.16-.81.25l-.22.08-.17.16c-3.29 3.29-4.05 9.19-4.41 15.34v.01q-.105 1.68-.18 3.36c-.28 5.98-.54 11.63-2.74 14.08a57.788 57.788 0 01-17.47 13.44c-10.33 5.1-18.5 4.94-18.62 4.94l-.95-.03-.09.94c-6.86 13.07-6.6 46.79-6.81 82.9q-.015 3.015-.04 6.06c-.12 16.82-.44 33.93-1.7 49.52-.55 6.7-1.26 13.11-2.21 19.1-4.62 29.17-25.55 4.95-35.56 24.36a24.526 24.526 0 001.65 5.55c1.15.68 2.33 1.34 3.5 2q7.785 4.335 15.86 8.09c.3.14.6.28.9.41a236.736 236.736 0 0030.46 11.62c2.03.62 4.06 1.21 6.11 1.77a233.586 233.586 0 0055.61 8.33 1.701 1.701 0 01.23-.01 288.585 288.585 0 0065.84-9.49l.9-.24c1.41-.38 2.81-.76 4.21-1.16a287.28 287.28 0 0036.3-13.02 9.238 9.238 0 00.13 1.31 3.03 3.03 0 00.06.42c.02-.16.04-.32.05-.48.06-.47.11-.95.16-1.43 6.12-59.68-6.59-110.35-16.34-136.15z"
      ></path>
      <path
        fill="#ffb6b6"
        d="M384.968 269.2a55.983 55.983 0 10-2.845-47.693c-.256.64-.487 1.281-.717 1.948a55.906 55.906 0 003.562 45.745z"
      ></path>
      <path
        fill="#2f2e41"
        d="M376.92 223.455c2.41-6.74 1.359-3.23 3.794-9.944-1.59 5.946.922 9.277.692 9.944h66.658v-38.75c-19.529-7.74-38.621-14.326-50.154 0a38.75 38.75 0 00-38.75 38.75z"
      ></path>
      <path
        fill="#2f2e41"
        d="M400.55 331.168c.05-.717.128-1.46.23-2.178.718-5.28 2.512-10.354 4.331-15.377 1.923-5.407 3.87-10.84 5.818-16.248a46.519 46.519 0 002.178 29.523c11.25-8.764 4.152-28.805 18.862-28.805 35.367 0 71.758-28.678 71.758-64.07 0-35.366-15.171-61.814-50.538-61.814-35.392 0-64.095 16.197-64.787 51.563 43.567 10.251 3.921 103.818 12.148 107.406z"
      ></path>
      <circle cx="499.308" cy="187.588" r="41.004" fill="#2f2e41"></circle>
      <path
        fill="#2f2e41"
        d="M563.232 422.747q-.21 3.165-.37 6.74c-2.12 44.89-.29 105.32-14.58 138.11q-4.8 2.625-9.7 5.02-.195.09-.36.18c-.88.43-1.76.85-2.64 1.25a.3.3 0 01-.11.06 238.808 238.808 0 01-29.81 11.71c-1.25.4-2.51.78-3.77 1.16l-.81.24a233.934 233.934 0 01-59.04 9.49c-1.04.03-2.06.05-3.05.07-.35.01-.69.02-1.03.02-1.12.01-2.23.02-3.35.02q-3.39 0-6.75-.1a233.586 233.586 0 01-55.61-8.33c-2.05-.56-4.08-1.15-6.11-1.77a236.736 236.736 0 01-30.46-11.62c-.3-.13-.6-.27-.9-.41q-8.07-3.75-15.86-8.09c-1.17-.66-2.35-1.32-3.5-2a24.526 24.526 0 01-1.65-5.55c-2.27-10.91-4.09-3.55-5.39-26.25-1.19-20.81-1.95-41.68-2.23-53.61-.09-3.18-.12-5.73-.13-7.47 1.06-7.58 18.1-16.18 42.95-24.35 2.35-.77 4.78-1.54 7.27-2.3 6.72-2.07 13.9-4.1 21.4-6.06 19.1-5 40.31-9.59 61.36-13.38 10.39-1.86 20.74-3.53 30.79-4.95 17.63-2.5 34.31-4.23 48.58-4.94 1.37-.07 2.71-.13 4.03-.17 1.42-.06 2.81-.1 4.17-.13 20.56-.45 34.6 1.69 36.66 7.41z"
      ></path>
      <path
        d="M563.232 422.747q-.21 3.165-.37 6.74c-15.58-16.78-183.42 58.18-254.48 103.21-1.19-20.81-1.95-41.68-2.23-53.61-.09-3.18-.12-5.73-.13-7.47 1.06-7.58 18.1-16.18 42.95-24.35 2.35-.77 4.78-1.54 7.27-2.3 6.72-2.07 13.9-4.1 21.4-6.06 19.1-5 40.31-9.59 61.36-13.38 10.39-1.86 20.74-3.53 30.79-4.95 17.63-2.5 34.31-4.23 48.58-4.94 1.37-.07 2.71-.13 4.03-.17 1.42-.06 2.81-.1 4.17-.13 20.56-.45 34.6 1.69 36.66 7.41z"
        opacity="0.2"
      ></path>
      <path
        fill="#ffb6b6"
        d="M221.9 474.76a17.324 17.324 0 0026.556.706l56.948 23.389-6.145-31.397-53.137-17.397a17.418 17.418 0 00-24.221 24.7z"
      ></path>
      <path
        fill="#3f3d56"
        d="M411.335 344.02l-.312-.033-33.209 2.41a29.807 29.807 0 00-21.56 11.676 29.215 29.215 0 00-3.076 4.997l-.008.006a29.804 29.804 0 00-2.457 18.887l15.635 78.703-37.618-3.418-13.732 2.347-16.205-6.538-33.252.566-.77 2.549-6.976 23.123-.547 1.817 50.775 31.997 14.72-4.478 6.936 5.327.333.008 13.752.272 58.648 1.15 16.03-28.401 2.506-4.453c18.618-41.71 23.212-70.826 21.755-90.885-.764-10.6-3.215-18.656-6.18-24.727-9.174-18.763-24.542-22.744-25.188-22.902z"
      ></path>
      <path
        fill="#6c63ff"
        d="M504.494 223.992a47.347 47.347 0 01-34.173-30.182c-2.406-6.978-3.314-14.64-7.352-20.85a111.467 111.467 0 0014.654-27.542c1.382-3.802 4.618-10.705-.868-12.797-4.37-1.666-9.335 2.356-11.926 5.53a21.057 21.057 0 00-4.133 8.23l-2.68-11.835a1.536 1.536 0 00-1.845-1.047c-7.322 1.248-12.632 8.027-13.555 15.168a15.454 15.454 0 002.356 10.522c2.352 3.578 5.797 6.143 9.074 8.81 3.486 2.836 6.44 5.856 8.315 10.003 1.947 4.31 2.824 9.026 4.116 13.552a50.208 50.208 0 0037.22 35.331c1.881.412 2.685-2.48.797-2.893zm-41.347-71.715a17.411 17.411 0 012.837-10.665 16.892 16.892 0 013.854-4.174c1.432-1.094 3.545-2.543 5.44-2.141 2.458.521 1.469 3.265.947 4.92q-1.033 3.273-2.272 6.478a106.456 106.456 0 01-5.59 12.107 108.394 108.394 0 01-3.496 5.995l-2.534-11.188a1.256 1.256 0 00.814-1.332zm-17.52.597a14.814 14.814 0 019.817-16.11l7.144 31.54c-.492.725-.999 1.439-1.507 2.152-.133-.153-.255-.311-.393-.462-4.994-5.435-13.703-9.14-15.06-17.12z"
      ></path>
    </svg>
  );
}

export default GraphicDesignIcon;