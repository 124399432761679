export const getDiscount = (price, offerPrice) => {
    return (((price - offerPrice) / price) * 100).toFixed();
}

export const getDeliveryDate = () => {
    const deliveryDate = new Date();
    deliveryDate.setDate(new Date().getDate() + 7)
    return deliveryDate.toUTCString().substring(0, 11);
}

export const formatDate = (dt) => {
    return new Date(dt).toUTCString().substring(0,16);
}

export const getRandomProducts = (prodsArray, n) => {
    return prodsArray.sort(() => 0.5 - Math.random()).slice(0, n)
}


export const getRandomTrainings = (trainingsArray, n) => {
    return trainingsArray.sort(() => 0.5 - Math.random()).slice(0, n)
}

export const toCamelCase = (str)=>{
    return str.split(' ').map(function(word,index){
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }).join(' ');
  }

export const daystoGeneralForm=(daysArr)=>{
    const weekends=["Sunday","Saturday"];
    const weekdays=["Monday","Tuesday","Wednesday","Thursday","Friday"];
    let isweekend = weekends.every(i => daysArr.includes(i));
    let isweekdays = weekdays.every(i => daysArr.includes(i));
    if(isweekdays && daysArr.length==5)
    {return "Weekdays"; } 
    else if(isweekend && daysArr.length==2)
    {return "Weekends";}
    else if(daysArr.length==7){
        return "Daily";
    }
    else{
        return (daysArr.map(function (i) { return i.substr(0,3) }));
        
    }
}
  

export const getFormatedDate=((inputdate)=>{
    var date = new Date(inputdate);
  return (date.getDate() +  " " + date.toLocaleString('default', { month: 'long' }) + " " + date.getFullYear());
  })