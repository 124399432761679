import "./TrainingDetails.css";
import frontendDeveloperIcon from "../../assets/images/Training/frontendDeveloper.png"
import backendDeveloperIcon from "../../assets/images/Training/backendDeveloper.png"
import fullStackDeveloperIcon from "../../assets/images/Training/fullStackDeveloper.png"
import softwareDevelopmentIcon from "../../assets/images/Training/softwareDevelopment.png"

const TrainingSkills = ({}) => {


const trainingSkills = [
    {
        icon: fullStackDeveloperIcon,
        label: "Full Stack Developer",
        desciption:"Be a jack of all trades and create an entire website by yourself or manage teams who do!",
        
    },
    {
        icon: softwareDevelopmentIcon,
        label: "Software Development Engineer",
        desciption:"Design, develop, and maintain software solutions.",
       
    },
    {
        icon: frontendDeveloperIcon,
        label: "Front-end Developer",
        desciption:"Develop all the visual elements of a website that you see and interact with (like this page!).",
        
    },
    {
        icon: backendDeveloperIcon,
        label: "Back-end Developer",
        desciption:"Manage the behind-the-scenes of a website. Keep the website running efficiently by managing servers.",
        
    },
  
];

  
    return (
        <>
       <div className="mt-20 xs:ml-4 lg:mx-auto xs:w-[100%] lg:w-[80%]">
        <h1 className="xs:text-base xs:ml-4 lg:text-2xl font-medium">Top career options in Full Stack Development </h1>
        <div className="flex mt-12 xs:w-[100%] lg:w-[97%] lg:ml-3 grid grid-cols-12 xs:gap-2 lg:gap-4">
        {trainingSkills.map((item, index) => {
          const { icon, label, desciption} = item;
          return (
            <div className="xs:px-2 lg:px-4 border-[1px] border-purple-300 rounded-lg xs:col-span-5 lg:col-span-3 pb-4 shadow-md shadow-purple-200">
                <span className="px-2 mx-2 "><img src={icon} width="" /></span>
                <p className="mx-2  xs:text-sm font-medium my-auto">{label}</p>
                <p className="mx-2 xs:mt-1 lg:mt-3 xs:text-sm xs:font-normal">{desciption}</p>
            </div>

        )
          })
          }

            
        </div>
        </div>
         
    

        </>
    );
};

export default TrainingSkills;
