
import MetaData from '../Layouts/MetaData.jsx';


import React from 'react';


const AboutUs = () => {
 
    
    return (
        
                <>
                    <MetaData title={"About Us"} />
                    
                    <main className="mt-12 sm:mt-0">

                    <div >
    <h1 className='text-4xl font-bold'>About Us</h1>
    
    <p >
        
        "India's learning platform" <br/>
An E-learning platform , providing Training, Internship and courses along with projects in many professional and technical courses. The company also provides Web Development, App Development, Graphic Designing and
Portfolio Services. Classroom education in India has stifled many brilliant minds. With Lyriclious learning and education platform, we are changing that. If you want to learn something then come on Lyriclious and learn with us.

<div >
    
  <div ><b>Website</b></div>
  <div >https://www.lyriclious.com/</div>
  
  <div ></div>
  <div  ><b>Industry</b></div>
  <div   >IT Services & E-learning </div>
  
  <div ></div>
  <div ><b>Tagline</b></div>
  <div >Learn with us, Build with us, Teach with us </div>
  
  <div ></div>
  <div  ><b>Company size</b></div>
  <div >51-200 employees</div>
  
  <div ></div>
  <div ><b>Office</b></div>
  <div >Ghaziabad, Uttar Pradesh</div>
  
  
  <div ></div>
  <div ><b>Specialtiese</b></div>
  <div >Website Desinging,<br /> Application Development,<br /> Graphic Designing,<br /> Mobile Application Development,<br /> Trainnigs,<br /> Internships,<br /> E-Learning,<br /> Data Science,<br /> Machine Learning,<br /> Robotics,<br /> Embedded Systems,<br /> IOT and much more </div>
</div>

</p>
</div>



                    </main>
                </>
           
    );
};

export default AboutUs;