// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.box span {
	width: 14px;
	height: 14px;
  display: block;
	border-right: 2px solid #46208F;
	border-bottom: 2px solid #46208F;
	transform: rotate(45deg);
	margin: -10px;
	animation: animate 2s infinite;
}

.box span:nth-child(2) {
	animation-delay: -0.2s;
}

.box span:nth-child(3) {
	animation-delay: -0.4s;
}

@keyframes animate {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 1;
		transform: rotate(45deg) translate(12px, 12px);
	}
	100% {
		opacity: 0;
	}
}

.maintext , .box {
    display: inline-block;
}

@media  (min-width: 200px) and (max-width: 640px) {
	.subheaderBtn {
	  font-size: 0.85rem;
	  font-weight: 500;
	  width: 95%;
	}
.subheaderBtn a{
	border-width: 2.5px;
}
  }
`, "",{"version":3,"sources":["webpack://./src/components/Home/SubHeader/SubHeader.css"],"names":[],"mappings":";AACA;CACC,WAAW;CACX,YAAY;EACX,cAAc;CACf,+BAA+B;CAC/B,gCAAgC;CAChC,wBAAwB;CACxB,aAAa;CACb,8BAA8B;AAC/B;;AAEA;CACC,sBAAsB;AACvB;;AAEA;CACC,sBAAsB;AACvB;;AAEA;CACC;EACC,UAAU;CACX;CACA;EACC,UAAU;EACV,8CAA8C;CAC/C;CACA;EACC,UAAU;CACX;AACD;;AAEA;IACI,qBAAqB;AACzB;;AAEA;CACC;GACE,kBAAkB;GAClB,gBAAgB;GAChB,UAAU;CACZ;AACD;CACC,mBAAmB;AACpB;EACE","sourcesContent":["\n.box span {\n\twidth: 14px;\n\theight: 14px;\n  display: block;\n\tborder-right: 2px solid #46208F;\n\tborder-bottom: 2px solid #46208F;\n\ttransform: rotate(45deg);\n\tmargin: -10px;\n\tanimation: animate 2s infinite;\n}\n\n.box span:nth-child(2) {\n\tanimation-delay: -0.2s;\n}\n\n.box span:nth-child(3) {\n\tanimation-delay: -0.4s;\n}\n\n@keyframes animate {\n\t0% {\n\t\topacity: 0;\n\t}\n\t50% {\n\t\topacity: 1;\n\t\ttransform: rotate(45deg) translate(12px, 12px);\n\t}\n\t100% {\n\t\topacity: 0;\n\t}\n}\n\n.maintext , .box {\n    display: inline-block;\n}\n\n@media  (min-width: 200px) and (max-width: 640px) {\n\t.subheaderBtn {\n\t  font-size: 0.85rem;\n\t  font-weight: 500;\n\t  width: 95%;\n\t}\n.subheaderBtn a{\n\tborder-width: 2.5px;\n}\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
