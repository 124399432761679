import React from "react";

function AhmedabadIcon({height,width}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsSvgjs="http://svgjs.com/svgjs"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
        height={height}
      version="1.1"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 100 100"
      >
        <path
          fill="#847e8d"
          d="M81.7 68.5c0-1.7-1.2-3.3-3.1-4.3l-4.9-2.4-4.9 2.4c-1.9.9-3.1 2.5-3.1 4.3"
          className="color8d7e8a svgShape"
        ></path>
        <path
          fill="#6f687b"
          d="M68.8 64.2l4.9-2.4 1.2.6-3.7 1.8c-1.9.9-3.1 2.5-3.1 4.3h13.5-16c.1-1.8 1.3-3.4 3.2-4.3z"
          className="color7b6876 svgShape"
        ></path>
        <path
          fill="#847e8d"
          d="M43.5 64.3v-.8c0-1.8.9-3.4 2.5-4.4l4-2.5 4 2.4c1.6 1 2.5 2.6 2.5 4.4v.9"
          className="color8d7e8a svgShape"
        ></path>
        <path
          fill="#6f687b"
          d="M46 59.1l4-2.4 1 .6-3 1.8c-1.6 1-2.5 2.6-2.5 4.4v.9h-2v-.9c0-1.8 1-3.5 2.5-4.4z"
          className="color7b6876 svgShape"
        ></path>
        <path
          fill="#847e8d"
          d="M18.7 68.5c0-1.7 1.2-3.3 3.1-4.3l4.9-2.4 4.9 2.4c1.9.9 3.1 2.5 3.1 4.3"
          className="color8d7e8a svgShape"
        ></path>
        <path
          fill="#6f687b"
          d="M21.8 64.2l4.9-2.4 1.2.6-3.7 1.8c-1.9.9-3.1 2.5-3.1 4.3h13.5-16c.1-1.8 1.3-3.4 3.2-4.3z"
          className="color7b6876 svgShape"
        ></path>
        <path
          fill="#cdb0fd"
          d="M14 71h23v3H14z"
          className="colorfdd3b0 svgShape"
        ></path>
        <path
          fill="#e2d0ff"
          d="M37.5 70.5h-23v3H16V72h21.5z"
          className="colorffeed0 svgShape"
        ></path>
        <path
          fill="#cdb0fd"
          d="M62.5 73.5h22v17h-22z"
          className="colorfdd3b0 svgShape"
        ></path>
        <path
          fill="#9186a2"
          d="M68.5 90.5v-7c0-2.3 1.2-4.4 3.1-5.6l1.9-1.2 1.9 1.2c1.9 1.2 3.1 3.3 3.1 5.6v7.1"
          className="colora28a86 svgShape"
        ></path>
        <path
          fill="#cdb0fd"
          d="M85.51 73.506h-23v-3h23zM15.5 73.5h22v17h-22z"
          className="colorfdd3b0 svgShape"
        ></path>
        <path
          fill="#e2d0ff"
          d="M15.5 73.5H17v17h-1.5z"
          className="colorffeed0 svgShape"
        ></path>
        <path
          fill="#b997f0"
          d="M36 73.5h1.5v17H36z"
          className="colorf0b997 svgShape"
        ></path>
        <path
          fill="#9186a2"
          d="M21.5 90.5v-7c0-2.3 1.2-4.4 3.1-5.6l1.9-1.2 1.9 1.2c1.9 1.2 3.1 3.3 3.1 5.6v7.1"
          className="colora28a86 svgShape"
        ></path>
        <path
          fill="#a89dba"
          d="M26.6 77.9l.9-.6-1-.6-1.9 1.2c-1.9 1.2-3.1 3.3-3.1 5.6v7h2v-7c0-2.3 1.2-4.4 3.1-5.6z"
          className="colorbaa69d svgShape"
        ></path>
        <path
          fill="#7e748d"
          d="M26.6 77.9l.9-.6-1-.6-1.9 1.2c-1.9 1.2-3.1 3.3-3.1 5.6v7h2v-7c0-2.3 1.2-4.4 3.1-5.6z"
          className="color8d7674 svgShape"
        ></path>
        <path
          fill="#afa3c2"
          d="M26.4 77.9l-.9-.6 1-.6 1.9 1.2c1.9 1.2 3.1 3.3 3.1 5.6v7h-2v-7c0-2.3-1.1-4.4-3.1-5.6z"
          className="colorc2ada3 svgShape"
        ></path>
        <path
          fill="#7e748d"
          d="M73.6 77.9l.9-.6-1-.6-1.9 1.2c-1.9 1.2-3.1 3.3-3.1 5.6v7h2v-7c0-2.3 1.2-4.4 3.1-5.6z"
          className="color8d7674 svgShape"
        ></path>
        <path
          fill="#afa3c2"
          d="M73.4 77.9l-.9-.6 1-.6 1.9 1.2c1.9 1.2 3.1 3.3 3.1 5.6v7h-2v-7c0-2.3-1.1-4.4-3.1-5.6z"
          className="colorc2ada3 svgShape"
        ></path>
        <path
          fill="#b997f0"
          d="M15.5 73.5h22V75h-22z"
          className="colorf0b997 svgShape"
        ></path>
        <path
          fill="#cdb0fd"
          d="M42.5 69.5h15v21h-15z"
          className="colorfdd3b0 svgShape"
        ></path>
        <path
          fill="#b997f0"
          d="M42.5 69.5H44v21h-1.5zm13.5 0h1.5v21H56z"
          className="colorf0b997 svgShape"
        ></path>
        <path
          fill="#b997f0"
          d="M42.5 69.5h15V71h-15z"
          className="colorf0b997 svgShape"
        ></path>
        <path
          fill="#9186a2"
          d="M42.5 90.4v-7.6c0-3.4 1.7-6.6 4.6-8.4l2.9-1.9 2.9 1.8c2.9 1.8 4.6 5 4.6 8.4v7.7"
          className="colora28a86 svgShape"
        ></path>
        <path
          fill="#7e748d"
          d="M45 90.4v-7.6c0-3.4 1.7-6.6 4.6-8.4l1.7-1.1-1.3-.8-2.9 1.9c-2.9 1.8-4.6 5-4.6 8.4v7.6l15 .1-12.5-.1z"
          className="color8d7674 svgShape"
        ></path>
        <path
          fill="#afa3c2"
          d="M55 90.4v-7.6c0-3.4-1.7-6.6-4.6-8.4l-1.7-1.1 1.3-.8 2.9 1.9c2.9 1.8 4.6 5 4.6 8.4v7.6l-15 .1 12.5-.1z"
          className="colorc2ada3 svgShape"
        ></path>
        <path
          fill="#cdb0fd"
          d="M15.5 68.5h22v2h-22zm69.01 2.006h-22v-2h22z"
          className="colorfdd3b0 svgShape"
        ></path>
        <path
          fill="#96919e"
          d="M31.6 64.2l-4.9-2.4-1.2.6 3.7 1.8c1.9.9 3.1 2.5 3.1 4.3H18.8h16c-.1-1.8-1.3-3.4-3.2-4.3zm47 0l-4.9-2.4-1.2.6 3.7 1.8c1.9.9 3.1 2.5 3.1 4.3H65.8h16c-.1-1.8-1.3-3.4-3.2-4.3zM54 59.1l-4-2.4-1 .6 3 1.8c1.6 1 2.5 2.6 2.5 4.4v.9h2v-.9c0-1.8-.9-3.5-2.5-4.4z"
          className="color9e919c svgShape"
        ></path>
        <path
          fill="#cdb0fd"
          d="M42.5 66.5h15v3h-15zm0-2h15v2h-15zm17.5-28c-.8 0-1.5.7-1.5 1.5v1.5h3V38c0-.8-.7-1.5-1.5-1.5zm-20 0c-.8 0-1.5.7-1.5 1.5v1.5h3V38c0-.8-.7-1.5-1.5-1.5zm-2.5 28h5v26h-5zm-1-3h7v3h-7z"
          className="colorfdd3b0 svgShape"
        ></path>
        <path
          fill="#b997f0"
          d="M36 70.5h1.5v3H36zm0-2h1.5v2H36z"
          className="colorf0b997 svgShape"
        ></path>
        <path
          fill="#e2d0ff"
          d="M15.5 68.5H17v2h-1.5z"
          className="colorffeed0 svgShape"
        ></path>
        <path
          fill="#b997f0"
          d="M62.5 70.5H64v3h-1.5zm0-2H64v2h-1.5z"
          className="colorf0b997 svgShape"
        ></path>
        <path
          fill="#e2d0ff"
          d="M83 68.5h1.5v2H83zm0 5h1.5v17H83z"
          className="colorffeed0 svgShape"
        ></path>
        <path
          fill="#b997f0"
          d="M62.5 73.5H64v17h-1.5z"
          className="colorf0b997 svgShape"
        ></path>
        <path
          fill="#b997f0"
          d="M62.5 73.5h22V75h-22z"
          className="colorf0b997 svgShape"
        ></path>
        <path
          fill="#e2d0ff"
          d="M62.5 70.5h23v3H84V72H62.5z"
          className="colorffeed0 svgShape"
        ></path>
        <path
          fill="#cdb0fd"
          d="M57.5 64.5h5v26h-5zm-1-3h7v3h-7zm0-10h7v3h-7zm1-7h5v2h-5zm0-5h5v2h-5zm0 15h5v7h-5zm1-8h3v5h-3zm0-5h3v3h-3zm-22 10h7v3h-7zm1-7h5v2h-5zm0-5h5v2h-5zm0 15h5v7h-5zm1-8h3v5h-3zm0-5h3v3h-3z"
          className="colorfdd3b0 svgShape"
        ></path>
        <path
          fill="#e2d0ff"
          d="M40 36.5c-.3 0-.5.1-.7.2.4.3.8.7.8 1.3v1.5h1.5V38c-.1-.8-.8-1.5-1.6-1.5zm1 28h1.5v26H41zm1-3h1.5v3H42zm0-10h1.5v3H42zm-1-7h1.5v2H41zm0-5h1.5v2H41zm0 15h1.5v7H41zm-1-8h1.5v5H40zm0-5h1.5v3H40zm20-5c-.3 0-.5.1-.7.2.4.3.8.7.8 1.3v1.5h1.5V38c-.1-.8-.8-1.5-1.6-1.5zm1 28h1.5v26H61zm1-3h1.5v3H62zm0-10h1.5v3H62zm-1-7h1.5v2H61zm0-5h1.5v2H61zm0 15h1.5v7H61zm-1-8h1.5v5H60zm0-5h1.5v3H60z"
          className="colorffeed0 svgShape"
        ></path>
        <path
          fill="#4c4459"
          d="M57.5 91c-.3 0-.5-.2-.5-.5v-7.7c0-3.3-1.6-6.2-4.4-8L50 73.1l-2.6 1.7c-2.7 1.8-4.4 4.7-4.4 8v7.6c0 .3-.2.5-.5.5s-.5-.2-.5-.5v-7.6c0-3.6 1.8-6.9 4.8-8.8l2.9-1.9c.2-.1.4-.1.5 0l2.9 1.8c3 1.9 4.9 5.2 4.9 8.8v7.7c0 .4-.2.6-.5.6zM18.7 69c-.3 0-.5-.2-.5-.5 0-1.9 1.3-3.7 3.3-4.7l4.9-2.4c.1-.1.3-.1.4 0l4.9 2.4c2.1 1 3.4 2.8 3.4 4.7 0 .3-.2.5-.5.5s-.5-.2-.5-.5c0-1.5-1-3-2.8-3.8l-4.6-2.3-4.7 2.3c-1.7.9-2.8 2.3-2.8 3.8 0 .3-.2.5-.5.5z"
          className="color594454 svgShape"
        ></path>
        <path
          fill="#4d4559"
          d="M56.5 64.9c-.3 0-.5-.2-.5-.5v-.9c0-1.6-.8-3.1-2.3-4L50 57.2l-3.7 2.3c-1.4.9-2.3 2.3-2.3 3.9v.8c0 .3-.2.5-.5.5s-.5-.2-.5-.5v-.8c0-2 1-3.7 2.7-4.8l4-2.5c.2-.1.4-.1.5 0l4 2.4c1.7 1.1 2.8 2.9 2.8 4.8v.9c0 .4-.2.7-.5.7z"
          className="color594555 svgShape"
        ></path>
        <path
          fill="#4c4459"
          d="M31.5 91c-.3 0-.5-.2-.5-.5v-7.1c0-2.1-1.1-4.1-2.9-5.2l-1.6-1-1.7 1.1C23 79.4 22 81.4 22 83.5v7c0 .3-.2.5-.5.5s-.5-.2-.5-.5v-7c0-2.5 1.2-4.7 3.3-6l1.9-1.2c.2-.1.4-.1.5 0l1.9 1.2c2.1 1.3 3.3 3.6 3.3 6v7.1c.1.2-.1.4-.4.4zm11 0h-5c-.3 0-.5-.2-.5-.5v-26c0-.3.2-.5.5-.5h5c.3 0 .5.2.5.5v26c0 .3-.2.5-.5.5zM38 90h4V65h-4v25z"
          className="color594454 svgShape"
        ></path>
        <path
          fill="#4c4459"
          d="M37.5 91h-22c-.3 0-.5-.2-.5-.5v-17c0-.3.2-.5.5-.5h22c.3 0 .5.2.5.5v17c0 .3-.2.5-.5.5zM16 90h21V74H16v16zm27.5-25h-7c-.3 0-.5-.2-.5-.5v-3c0-.3.2-.5.5-.5h7c.3 0 .5.2.5.5v3c0 .3-.2.5-.5.5zM37 64h6v-2h-6v2zm.5 7h-22c-.3 0-.5-.2-.5-.5v-2c0-.3.2-.5.5-.5h22c.3 0 .5.2.5.5v2c0 .3-.2.5-.5.5zM16 70h21v-1H16v1z"
          className="color594454 svgShape"
        ></path>
        <path
          fill="#4c4459"
          d="M37.5 74h-23c-.3 0-.5-.2-.5-.5v-3c0-.3.2-.5.5-.5h23c.3 0 .5.2.5.5v3c0 .3-.2.5-.5.5zM15 73h22v-2H15v2zm42.5-3h-15c-.3 0-.5-.2-.5-.5v-3c0-.3.2-.5.5-.5h15c.3 0 .5.2.5.5v3c0 .3-.2.5-.5.5zM43 69h14v-2H43v2zm13.5-4H43.2c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h13.3c.3 0 .5.2.5.5s-.2.5-.5.5z"
          className="color594454 svgShape"
        ></path>
        <path
          fill="#4c4459"
          d="M57.5 67h-15c-.3 0-.5-.2-.5-.5v-1.7c0-.3.2-.5.5-.5s.5.2.5.5V66h14v-1.2c0-.3.2-.5.5-.5s.5.2.5.5v1.7c0 .3-.2.5-.5.5z"
          className="color594454 svgShape"
        ></path>
        <path
          fill="#4c4459"
          d="M45.5 67c-.3 0-.5-.2-.5-.5V65c0-.3.2-.5.5-.5s.5.2.5.5v1.5c0 .3-.2.5-.5.5zm-22 3.5c-.3 0-.5-.2-.5-.5v-1c0-.3.2-.5.5-.5s.5.2.5.5v1c0 .3-.2.5-.5.5zm-3 0c-.3 0-.5-.2-.5-.5v-1c0-.3.2-.5.5-.5s.5.2.5.5v1c0 .3-.2.5-.5.5zm-3 0c-.3 0-.5-.2-.5-.5v-1c0-.3.2-.5.5-.5s.5.2.5.5v1c0 .3-.2.5-.5.5zm9 0c-.3 0-.5-.2-.5-.5v-1c0-.3.2-.5.5-.5s.5.2.5.5v1c0 .3-.2.5-.5.5zm3 0c-.3 0-.5-.2-.5-.5v-1c0-.3.2-.5.5-.5s.5.2.5.5v1c0 .3-.2.5-.5.5zm6 0c-.3 0-.5-.2-.5-.5v-1c0-.3.2-.5.5-.5s.5.2.5.5v1c0 .3-.2.5-.5.5zm-3 0c-.3 0-.5-.2-.5-.5v-1c0-.3.2-.5.5-.5s.5.2.5.5v1c0 .3-.2.5-.5.5zm16-3.5c-.3 0-.5-.2-.5-.5V65c0-.3.2-.5.5-.5s.5.2.5.5v1.5c0 .3-.2.5-.5.5zm3 0c-.3 0-.5-.2-.5-.5V65c0-.3.2-.5.5-.5s.5.2.5.5v1.5c0 .3-.2.5-.5.5zm3 0c-.3 0-.5-.2-.5-.5V65c0-.3.2-.5.5-.5s.5.2.5.5v1.5c0 .3-.2.5-.5.5zm8 24h-5c-.3 0-.5-.2-.5-.5v-26c0-.3.2-.5.5-.5h5c.3 0 .5.2.5.5v26c0 .3-.2.5-.5.5zM58 90h4V65h-4v25z"
          className="color594454 svgShape"
        ></path>
        <path
          fill="#4c4459"
          d="M63.5 65h-7c-.3 0-.5-.2-.5-.5v-3c0-.3.2-.5.5-.5h7c.3 0 .5.2.5.5v3c0 .3-.2.5-.5.5zM57 64h6v-2h-6v2zm6.5-9h-7c-.3 0-.5-.2-.5-.5v-3c0-.3.2-.5.5-.5h7c.3 0 .5.2.5.5v3c0 .3-.2.5-.5.5zM57 54h6v-2h-6v2zm5.5-7h-5c-.3 0-.5-.2-.5-.5v-2c0-.3.2-.5.5-.5h5c.3 0 .5.2.5.5v2c0 .3-.2.5-.5.5zM58 46h4v-1h-4v1zm4.5-4h-5c-.3 0-.5-.2-.5-.5v-2c0-.3.2-.5.5-.5h5c.3 0 .5.2.5.5v2c0 .3-.2.5-.5.5zM58 41h4v-1h-4v1z"
          className="color594454 svgShape"
        ></path>
        <path
          fill="#4c4459"
          d="M62.5 62h-5c-.3 0-.5-.2-.5-.5v-7c0-.3.2-.5.5-.5h5c.3 0 .5.2.5.5v7c0 .3-.2.5-.5.5zM58 61h4v-6h-4v6zm3.5-9h-3c-.3 0-.5-.2-.5-.5v-5c0-.3.2-.5.5-.5h3c.3 0 .5.2.5.5v5c0 .3-.2.5-.5.5zM59 51h2v-4h-2v4zm2.5-6h-3c-.3 0-.5-.2-.5-.5v-3c0-.3.2-.5.5-.5h3c.3 0 .5.2.5.5v3c0 .3-.2.5-.5.5zM59 44h2v-2h-2v2zm2.5-4h-3c-.3 0-.5-.2-.5-.5V38c0-.3.2-.5.5-.5s.5.2.5.5v1h2v-1c0-.3.2-.5.5-.5s.5.2.5.5v1.5c0 .3-.2.5-.5.5z"
          className="color594454 svgShape"
        ></path>
        <path
          fill="#4c4459"
          d="M61.5 38.5c-.3 0-.5-.2-.5-.5 0-.6-.4-1-1-1s-1 .4-1 1c0 .3-.2.5-.5.5s-.5-.2-.5-.5c0-1.1.9-2 2-2s2 .9 2 2c0 .3-.2.5-.5.5z"
          className="color594454 svgShape"
        ></path>
        <path
          fill="#4c4459"
          d="M60 37c-.3 0-.5-.2-.5-.5v-1c0-.3.2-.5.5-.5s.5.2.5.5v1c0 .3-.2.5-.5.5zM43.5 55h-7c-.3 0-.5-.2-.5-.5v-3c0-.3.2-.5.5-.5h7c.3 0 .5.2.5.5v3c0 .3-.2.5-.5.5zM37 54h6v-2h-6v2zm5.5-7h-5c-.3 0-.5-.2-.5-.5v-2c0-.3.2-.5.5-.5h5c.3 0 .5.2.5.5v2c0 .3-.2.5-.5.5zM38 46h4v-1h-4v1zm4.5-4h-5c-.3 0-.5-.2-.5-.5v-2c0-.3.2-.5.5-.5h5c.3 0 .5.2.5.5v2c0 .3-.2.5-.5.5zM38 41h4v-1h-4v1z"
          className="color594454 svgShape"
        ></path>
        <path
          fill="#4c4459"
          d="M42.5 62h-5c-.3 0-.5-.2-.5-.5v-7c0-.3.2-.5.5-.5h5c.3 0 .5.2.5.5v7c0 .3-.2.5-.5.5zM38 61h4v-6h-4v6zm3.5-9h-3c-.3 0-.5-.2-.5-.5v-5c0-.3.2-.5.5-.5h3c.3 0 .5.2.5.5v5c0 .3-.2.5-.5.5zM39 51h2v-4h-2v4zm2.5-6h-3c-.3 0-.5-.2-.5-.5v-3c0-.3.2-.5.5-.5h3c.3 0 .5.2.5.5v3c0 .3-.2.5-.5.5zM39 44h2v-2h-2v2zm2.5-4h-3c-.3 0-.5-.2-.5-.5V38c0-.3.2-.5.5-.5s.5.2.5.5v1h2v-1c0-.3.2-.5.5-.5s.5.2.5.5v1.5c0 .3-.2.5-.5.5z"
          className="color594454 svgShape"
        ></path>
        <path
          fill="#4c4459"
          d="M41.5 38.5c-.3 0-.5-.2-.5-.5 0-.6-.4-1-1-1s-1 .4-1 1c0 .3-.2.5-.5.5s-.5-.2-.5-.5c0-1.1.9-2 2-2s2 .9 2 2c0 .3-.2.5-.5.5z"
          className="color594454 svgShape"
        ></path>
        <path
          fill="#4c4459"
          d="M40 37c-.3 0-.5-.2-.5-.5v-1c0-.3.2-.5.5-.5s.5.2.5.5v1c0 .3-.2.5-.5.5zM26.7 62c-.3 0-.5-.2-.5-.5v-1c0-.3.2-.5.5-.5s.5.2.5.5v1c0 .3-.2.5-.5.5zM50 57c-.3 0-.5-.2-.5-.5v-1c0-.3.2-.5.5-.5s.5.2.5.5v1c0 .3-.2.5-.5.5zm7.3 34h-15c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h15c.3 0 .5.2.5.5s-.2.5-.5.5zm-37.8-4h-2c-.3 0-.5-.2-.5-.5v-1.6c0-.6.3-1.2.8-1.5l.4-.2c.2-.1.4-.1.5 0l.4.2c.5.3.8.9.8 1.5v1.6c.1.3-.1.5-.4.5zM18 86h1v-1.1c0-.3-.1-.5-.4-.7l-.1-.1-.1.1c-.2.2-.4.4-.4.7V86zm17.5 1h-2c-.3 0-.5-.2-.5-.5v-1.6c0-.6.3-1.2.8-1.5l.4-.2c.2-.1.4-.1.5 0l.4.2c.5.3.8.9.8 1.5v1.6c.1.3-.1.5-.4.5zM34 86h1v-1.1c0-.3-.1-.5-.4-.7l-.1-.1-.1.1c-.2.2-.4.4-.4.7V86zm47.7-17c-.3 0-.5-.2-.5-.5 0-1.5-1-3-2.8-3.8l-4.7-2.3-4.7 2.3c-1.8.9-2.8 2.3-2.8 3.8 0 .3-.2.5-.5.5s-.5-.2-.5-.5c0-1.9 1.3-3.7 3.4-4.7l4.9-2.4c.1-.1.3-.1.4 0l4.9 2.4c2.1 1 3.3 2.8 3.3 4.7.1.3-.1.5-.4.5z"
          className="color594454 svgShape"
        ></path>
        <path
          fill="#4c4459"
          d="M84.5 71h-22c-.3 0-.5-.2-.5-.5v-2c0-.3.2-.5.5-.5h22c.3 0 .5.2.5.5v2c0 .3-.2.5-.5.5zM63 70h21v-1H63v1z"
          className="color594454 svgShape"
        ></path>
        <path
          fill="#4c4459"
          d="M85.5 74h-23c-.3 0-.5-.2-.5-.5v-3c0-.3.2-.5.5-.5h23c.3 0 .5.2.5.5v3c0 .3-.2.5-.5.5zM63 73h22v-2H63v2z"
          className="color594454 svgShape"
        ></path>
        <path
          fill="#4c4459"
          d="M76.5 70.5c-.3 0-.5-.2-.5-.5v-1c0-.3.2-.5.5-.5s.5.2.5.5v1c0 .3-.2.5-.5.5zm3 0c-.3 0-.5-.2-.5-.5v-1c0-.3.2-.5.5-.5s.5.2.5.5v1c0 .3-.2.5-.5.5zm3 0c-.3 0-.5-.2-.5-.5v-1c0-.3.2-.5.5-.5s.5.2.5.5v1c0 .3-.2.5-.5.5zm-9 0c-.3 0-.5-.2-.5-.5v-1c0-.3.2-.5.5-.5s.5.2.5.5v1c0 .3-.2.5-.5.5zm-3 0c-.3 0-.5-.2-.5-.5v-1c0-.3.2-.5.5-.5s.5.2.5.5v1c0 .3-.2.5-.5.5zm-6 0c-.3 0-.5-.2-.5-.5v-1c0-.3.2-.5.5-.5s.5.2.5.5v1c0 .3-.2.5-.5.5zm3 0c-.3 0-.5-.2-.5-.5v-1c0-.3.2-.5.5-.5s.5.2.5.5v1c0 .3-.2.5-.5.5zm6.2-8.5c-.3 0-.5-.2-.5-.5v-1c0-.3.2-.5.5-.5s.5.2.5.5v1c0 .3-.2.5-.5.5zM21.2 78.3c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5 1.5.7 1.5 1.5-.7 1.5-1.5 1.5zm0-2c-.3 0-.5.2-.5.5s.2.5.5.5.5-.2.5-.5-.2-.5-.5-.5zm10 2c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5 1.5.7 1.5 1.5-.7 1.5-1.5 1.5zm0-2c-.3 0-.5.2-.5.5s.2.5.5.5.5-.2.5-.5-.2-.5-.5-.5zM78.5 91c-.3 0-.5-.2-.5-.5v-7.1c0-2.1-1.1-4.1-2.9-5.2l-1.6-1-1.7 1.1C70 79.4 69 81.4 69 83.5v7c0 .3-.2.5-.5.5s-.5-.2-.5-.5v-7c0-2.5 1.2-4.7 3.3-6l1.9-1.2c.2-.1.4-.1.5 0l1.9 1.2c2.1 1.3 3.3 3.6 3.3 6v7.1c.1.2-.1.4-.4.4z"
          className="color594454 svgShape"
        ></path>
        <path
          fill="#4c4459"
          d="M84.5 91h-22c-.3 0-.5-.2-.5-.5v-17c0-.3.2-.5.5-.5h22c.3 0 .5.2.5.5v17c0 .3-.2.5-.5.5zM63 90h21V74H63v16z"
          className="color594454 svgShape"
        ></path>
        <path
          fill="#4c4459"
          d="M66.5 87h-2c-.3 0-.5-.2-.5-.5v-1.6c0-.6.3-1.2.8-1.5l.4-.2c.2-.1.4-.1.5 0l.4.2c.5.3.8.9.8 1.5v1.6c.1.3-.1.5-.4.5zM65 86h1v-1.1c0-.3-.1-.5-.4-.7l-.1-.1-.1.1c-.2.2-.4.4-.4.7V86zm17.5 1h-2c-.3 0-.5-.2-.5-.5v-1.6c0-.6.3-1.2.8-1.5l.4-.2c.2-.1.4-.1.5 0l.4.2c.5.3.8.9.8 1.5v1.6c.1.3-.1.5-.4.5zM81 86h1v-1.1c0-.3-.1-.5-.4-.7l-.1-.1-.1.1c-.2.2-.4.4-.4.7V86zm-12.8-7.7c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5 1.5.7 1.5 1.5-.7 1.5-1.5 1.5zm0-2c-.3 0-.5.2-.5.5s.2.5.5.5.5-.2.5-.5-.2-.5-.5-.5zm10.5 2c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5 1.5.7 1.5 1.5-.7 1.5-1.5 1.5zm0-2c-.3 0-.5.2-.5.5s.2.5.5.5.5-.2.5-.5-.2-.5-.5-.5zM87.433 91H12.568c-.34 0-.568-.2-.568-.5s.227-.5.568-.5h74.865c.34 0 .568.2.568.5s-.227.5-.568.5z"
          className="color594454 svgShape"
        ></path>
      </svg>
    </svg>
  );
}

export default AhmedabadIcon;