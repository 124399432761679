import { useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import { clearErrors, deleteTraining, getAdminTrainings } from '../../../actions/trainingAction';
//import Rating from '@mui/material/Rating';
import { DELETE_TRAINING_RESET } from '../../../constants/trainingConstants';
import Actions from '../Actions';
import MetaData from '../../Layouts/MetaData';
import BackdropLoader from '../../Layouts/BackdropLoader';
import Loader from '../../Layouts/Loader';

const TrainingTable = () => {

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const { trainings, error } = useSelector((state) => state.trainings);
    const { loading, isDeleted, error: deleteError } = useSelector((state) => state.training);

    useEffect(() => {
        if (error) {
            enqueueSnackbar(error, { variant: "error" });
            dispatch(clearErrors());
        }
        if (deleteError) {
            enqueueSnackbar(deleteError, { variant: "error" });
            dispatch(clearErrors());
        }
        if (isDeleted) {
            enqueueSnackbar("Training Deleted Successfully", { variant: "success" });
            dispatch({ type: DELETE_TRAINING_RESET });
        }
        dispatch(getAdminTrainings());
    }, [dispatch, error, deleteError, isDeleted, enqueueSnackbar]);

    const deleteTrainingHandler = (slug) => {
        dispatch(deleteTraining(slug));
    }

    const columns = [
        {
            field: "status",
            headerName: "Status",
            minWidth: 200,
            flex: 0.1,
        },
        {
            field: "name",
            headerName: "Name",
            minWidth: 200,
            flex: 1,
            renderCell: (params) => {
                return (
                    <div className="flex items-center gap-2">
                        <div className="w-10 h-10 rounded-full">
                            <img draggable="false" src={params.row.image} alt={params.row.name} className="w-full h-full rounded-full object-cover" />
                        </div>
                        {params.row.name}
                    </div>
                )
            },
        },
        
        
        {
            field: "price",
            headerName: "Price",
            type: "number",
            minWidth: 100,
            headerAlign: "left",
            align: "left",
            flex: 0.2,
            renderCell: (params) => {
                return (
                    <span>₹{params.row.price.toLocaleString()}</span>
                );
            },
        },
        {
            field: "cprice",
            headerName: "Offer Price",
            type: "number",
            minWidth: 100,
            headerAlign: "left",
            align: "left",
            flex: 0.2,
            renderCell: (params) => {
                return (
                    <span>₹{params.row.cprice.toLocaleString()}</span>
                );
            },
        },
        {
            field: "duratoin",
            headerName: "Duration",
            type: "number",
            minWidth: 100,
            flex: 0.1,
            align: "left",
            headerAlign: "left",
            renderCell: (params) => {
                return <span>{params.row.duration.toLocaleString()}</span>
            }
        },
        {
            field: "actions",
            headerName: "Actions",
            minWidth: 100,
            flex: 0.3,
            type: "number",
            sortable: false,
            renderCell: (params) => {
                return (
                    <Actions editRoute={"training"} deleteHandler={deleteTrainingHandler} slug={params.row.slug} />
                );
            },
        },
    ];

    const rows = [];

    trainings && trainings.forEach((item) => {
        rows.unshift({
            slug:item.slug,
            id: item._id,
            status: item.status,
            name: item.training_name,
            image: item.image.url,
            //category: item.category,
            stock: item.stock,
            price: item.price,
            cprice: item.offerPrice,
            rating: item.duration.d_num,
            duration: item.duration.d_num + " " +item.duration.d_type
        });
    });

    return (
        
        <>
        
            <MetaData title="Admin Trainings | Lyriclious" /> 
            <div className="flex justify-between items-center">
                <h1 className="text-lg font-medium uppercase">trainings</h1>
                <Link to="/admin/new_training" className="py-2 px-4 rounded shadow font-medium text-white bg-purple-900 hover:shadow-lg">New Training</Link>
            </div>
            <div className="bg-white rounded-xl shadow-lg w-full" style={{ height: 470 }}>
            {loading ? <Loader /> : (
            <>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={10}
                    disableSelectIconOnClick
                    sx={{
                        boxShadow: 0,
                        border: 0,
                    }}
                />
                </>
            )};
            </div>
            
        </>
            
    );
};

export default TrainingTable;