import Headings from '../../Layouts/Headings/Headings.jsx';
import coding from "../../../assets/images/Categories/coding.png"
import designing from "../../../assets/images/Categories/designing.png"
import android from "../../../assets/images/Categories/android.png"
import machineLearning from "../../../assets/images/Categories/machinelearning.png"
import humanResource from "../../../assets/images/Categories/humanresource.png"
import marketing from "../../../assets/images/Categories/marketing.png"
import { useEffect, useState } from 'react';

import "../Internships/Internships.css"
import  DelhiIcon  from '../../../assets/images/Cities/DelhiIcon';
import AhmedabadIcon from '../../../assets/images/Cities/AhmedabadIcon.jsx';
import BangloreIcon from "../../../assets/images/Cities/BangloreIcon"
import LucknowIcon from "../../../assets/images/Cities/LucknowIcon"
import MumbaiIcon from '../../../assets/images/Cities/MumbaiIcon.jsx';
import HyderabadIcon from "../../../assets/images/Cities/HyderabadIcon.jsx";

const Jobs = () => {
  const [onMobile, setOnMobile] = useState(false);
  useEffect(() => {
    if (window.innerWidth < 600) {
        setOnMobile(true);
        console.log(window.innerWidth);
    }
    else{
        setOnMobile(false);
        console.log(window.innerWidth);
    }
}, [window.innerWidth])

    return (
      <>
        <Headings title={"For Job Seekers"} type={"firstLevel"} id={"jobseekers"}/>
        <Headings title={"Job in Popular Cities"} type={"secondLevel"}/>

        <div className="w-full overflow-auto scrollbarhide">
        <div className="container mx-auto flex flex-nowrap md:px-[150px]">
          
{ /* use https://svg2jsx.com/ this website to convert svg to jsx and then pass heigth and width */}

        <a href="/comingsoon" className="flex-1 lg:w-30 sm:w-20 border-[1px] border-white hover:border-[1px] hover:border-gray-200 cursor-pointer rounded-xl hover:shadow-md" >
        <DelhiIcon height={onMobile?120:140} width={onMobile?120:150}/>
        <span className='cityTitle'>Delhi</span>
        </a> 

        <a href="/comingsoon" className="flex-1 lg:w-30 border-[1px] border-white hover:border-[1px] hover:border-gray-200 cursor-pointer rounded-xl hover:shadow-md" >
        <BangloreIcon height={onMobile?120:140} width={onMobile?120:150}/>
        <span className='cityTitle'>Banglore</span>
        </a> 

        <a href="/comingsoon" className="flex-1 lg:w-30 border-[1px] border-white hover:border-[1px] hover:border-gray-200 cursor-pointer rounded-xl hover:shadow-md" >
        <LucknowIcon height={onMobile?120:140} width={onMobile?120:150}/>
        <span className='cityTitle'>Lucknow</span>
        </a> 

        <a href="/comingsoon" className="flex-1 lg:w-30 border-[1px] border-white hover:border-[1px] hover:border-gray-200 cursor-pointer rounded-xl hover:shadow-md" >
        <AhmedabadIcon height={onMobile?120:140} width={onMobile?120:150}/>
        <span className='cityTitle'>Ahemdabad</span>
        </a> 

        <a href="/comingsoon" className="flex-1 lg:w-30 border-[1px] border-white hover:border-[1px] hover:border-gray-200 cursor-pointer rounded-xl hover:shadow-md" >
        <MumbaiIcon height={onMobile?120:140} width={onMobile?120:150}/>
        <span className='cityTitle'>Mumbai</span>
        </a> 

        <a href="/comingsoon" className="flex-1 lg:w-30 border-[1px] border-white hover:border-[1px] hover:border-gray-200 cursor-pointer rounded-xl hover:shadow-md" >
        <HyderabadIcon height={onMobile?120:140} width={onMobile?120:150}/>
        <span className='cityTitle'>Hyderabad</span>
        </a> 


        </div>
        </div>


     <Headings title={"Popular Job Categories"} type={"secondLevel"}/>
        
     <div className="w-full overflow-auto scrollbarhide">
        <div className="container mx-auto flex flex-nowrap md:px-[150px]">
        
        { /* Map function will be used to populate card */ }
        <a href="/comingsoon" className="flex-1 w-30 mx-2 px-2 internship border-[1px] border-white hover:border-[1px] hover:border-gray-200 cursor-pointer rounded-xl hover:shadow-md " >
        <div className='h-[90px]'>
        <img src={coding} alt="Web Development" className='mx-auto w-20' />
        </div>
        <span className='internshipTitle'>Web <br /> Development</span>
         </a>


         <a href="/comingsoon" className="flex-1 w-30 mx-2 px-2 internship border-[1px] border-white hover:border-[1px] hover:border-gray-200 cursor-pointer rounded-xl hover:shadow-md" >
         <div className='h-[90px]'>
            <img src={designing} alt="UX/UI Designing" className='mx-auto w-20'/> 
            </div>
            <span className='internshipTitle'>UX/UI <br />Designing</span>
        </a>

        <a href="/comingsoon" className="flex-1 w-30 mx-2 px-2 internship border-[1px] border-white hover:border-[1px] hover:border-gray-200 cursor-pointer rounded-xl hover:shadow-md" >
        <div className='h-[90px]'>
            <img src={android} alt="App Development" className='mx-auto w-20' />
            </div>
            <span className='internshipTitle'>App <br />Development</span>
        </a>

        <a href="/comingsoon" className="flex-1 w-30 mx-2  px-2 internship border-[1px] border-white hover:border-[1px] hover:border-gray-200 cursor-pointer rounded-xl hover:shadow-md" >
        <div className='h-[90px]'>
            <img src={machineLearning} alt="Machine Learning" className='mx-auto w-20' />
            </div>
            <span className='internshipTitle'>Machine <br />Learning</span>
        </a>

        <a href="/comingsoon" className="flex-1 w-30 mx-4  px-2 internship border-[1px] border-white hover:border-[1px] hover:border-gray-200 cursor-pointer rounded-xl hover:shadow-md" >
        <div className='h-[90px]'>
            <img src={humanResource} alt="Human Resource" className='mx-auto w-20'/>
            </div>
            <span className='internshipTitle'>Human <br />Resource</span>
        </a>

        <a href="/comingsoon" className="flex-1 w-30 mx-4 px-2 internship border-[1px] border-white hover:border-[1px] hover:border-gray-200 cursor-pointer rounded-xl hover:shadow-md" >
        <div className='h-[90px]'>
            <img src={marketing} alt="Digital Marketing" className='mx-auto w-20' /> 
            </div>
            <span className='internshipTitle'>Digital <br />Marketing</span>
        </a>

       </div>
        </div>
        {/* <ViewAll title={"Internships"} url={"#"} /> */}

      </>
    );
  };
  
  export default Jobs;