import React from "react";

function MumbaiIcon({height,width}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsSvgjs="http://svgjs.com/svgjs"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      version="1.1"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 100 100"
      >
        <path
          fill="#d0b3fe"
          d="M41.3 65.5v25h18v-25h-18zm16 24.5h-14V79.1c0-3.2 1.6-6.1 4.3-7.9l2.7-1.7 2.7 1.7c2.7 1.7 4.3 4.7 4.3 7.9V90z"
          className="colorfedab3 svgShape"
        ></path>
        <path
          fill="#cdaffd"
          d="M64.3 68.5v22h4.6v-7c0-2.3 2.1-3 2.1-3s1.9.7 1.9 3v7h5.5v-22H64.3zm-42.1 0v22h5.5v-7c0-2.3 2.1-3 2.1-3s1.9.7 1.9 3v7h4.5v-22h-14z"
          className="colorfdd2af svgShape"
        ></path>
        <path
          fill="#d0b3fe"
          d="M64.3 57.5h15v3h-58v-3h15"
          className="colorfedab3 svgShape"
        ></path>
        <path
          fill="#b694ee"
          d="M21.3 57.5h2v3h-2zm13 0h2v3h-2z"
          className="coloreeb694 svgShape"
        ></path>
        <path
          fill="#fff"
          d="M77.3 57.5h2v3h-2z"
          className="colorfff svgShape"
          opacity="0.7"
        ></path>
        <path
          fill="#d0b3fe"
          d="M41.3 53.5h18v7h-18z"
          className="colorfedab3 svgShape"
        ></path>
        <path
          fill="#b694ee"
          d="M41.3 53.5v2h15.9v5h2v-7z"
          className="coloreeb694 svgShape"
        ></path>
        <path
          fill="#cdaffd"
          d="M22.3 65.5v-5h56.1v5"
          className="colorfdd2af svgShape"
        ></path>
        <path
          fill="#b694ee"
          d="M22.3 60.5h56.1v2H22.3zm42 8h14.1v2H64.3zm-42 0h14.1v2H22.3zm19-3h18v2h-18z"
          className="coloreeb694 svgShape"
        ></path>
        <path
          fill="#fff"
          d="M41.3 65.5h2v25h-2z"
          className="colorfff svgShape"
          opacity="0.7"
        ></path>
        <path
          fill="#b694ee"
          d="M57.3 65.5h2v25h-2z"
          className="coloreeb694 svgShape"
        ></path>
        <path
          fill="#b595e8"
          d="M41.3 60.5h18v5h-18z"
          className="colore8b795 svgShape"
        ></path>
        <path
          fill="#9578c5"
          d="M41.3 60.5h18v2h-18z"
          className="colorc59878 svgShape"
        ></path>
        <path
          fill="#4c4459"
          d="M79.3 61h-58c-.3 0-.5-.2-.5-.5v-3c0-.3.2-.5.5-.5h15c.3 0 .5.2.5.5s-.2.5-.5.5H21.8v2h57v-2H64.3c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h14.9c.3 0 .5.2.5.5v3c.1.3-.1.5-.4.5z"
          className="color594454 svgShape"
        ></path>
        <path
          fill="#a481dd"
          d="M59.8 65.5h21.5l-1 3H59.8"
          className="colordda581 svgShape"
        ></path>
        <path
          fill="#fff"
          d="M76.4 60.5h2v5h-2zm0 8h2v22h-2zm2.9-3l-1 3h2l1-3z"
          className="colorfff svgShape"
          opacity="0.7"
        ></path>
        <path
          fill="#d0b3fe"
          d="M59.3 53.5h5v37h-5z"
          className="colorfedab3 svgShape"
        ></path>
        <path
          fill="#a481dd"
          d="M40.8 68.5H20.3l-1-3h22"
          className="colordda581 svgShape"
        ></path>
        <path
          fill="#b694ee"
          d="M22.2 68.5h2v22h-2zm12 0h2v22h-2zm-11.9-8h2v5h-2zm12 0h2v5h-2z"
          className="coloreeb694 svgShape"
        ></path>
        <path
          fill="#896cb7"
          d="M19.3 65.5l1 3h2l-1-3z"
          className="colorb7856c svgShape"
        ></path>
        <path
          fill="#d0b3fe"
          d="M36.3 53.5h5v37h-5z"
          className="colorfedab3 svgShape"
        ></path>
        <path
          fill="#fff"
          d="M62.3 53.5h2v37h-2zm-23 0h2v37h-2z"
          className="colorfff svgShape"
          opacity="0.7"
        ></path>
        <path
          fill="#b595e8"
          d="M36.3 65.5h5v3h-5z"
          className="colore8b795 svgShape"
        ></path>
        <path
          fill="#fff"
          d="M39.3 65.5h2v3h-2z"
          className="colorfff svgShape"
          opacity="0.7"
        ></path>
        <path
          fill="#c19dfc"
          d="M59.3 65.5h5v3h-5z"
          className="colorfcc49d svgShape"
        ></path>
        <path
          fill="#fff"
          d="M62.3 65.5h2v3h-2z"
          className="colorfff svgShape"
          opacity="0.7"
        ></path>
        <path
          fill="#a481dd"
          d="M25.8 78.6c0-2.5 1.4-4.8 3.7-6 .2-.1.4-.1.6 0 2.3 1.1 3.7 3.4 3.7 6m33 0c0-2.5 1.4-4.8 3.7-6 .2-.1.4-.1.6 0 2.3 1.1 3.7 3.4 3.7 6"
          className="colordda581 svgShape"
        ></path>
        <path
          fill="#896cb7"
          d="M29.5 74.6c.2-.1.4-.1.6 0 1.6.8 2.8 2.3 3.4 4h.3c0-2.5-1.4-4.8-3.7-6-.2-.1-.4-.1-.6 0-2.3 1.1-3.7 3.4-3.7 6h.3c.5-1.7 1.7-3.2 3.4-4zm41 0c.2-.1.4-.1.6 0 1.6.8 2.8 2.3 3.4 4h.3c0-2.5-1.4-4.8-3.7-6-.2-.1-.4-.1-.6 0-2.3 1.1-3.7 3.4-3.7 6h.3c.5-1.7 1.7-3.2 3.4-4z"
          className="colorb7856c svgShape"
        ></path>
        <path
          fill="#d0b3fe"
          d="M37.3 47.5h3v6h-3zm3 3h20v3h-20z"
          className="colorfedab3 svgShape"
        ></path>
        <path
          fill="#b694ee"
          d="M58.3 50.5h2v3h-2z"
          className="coloreeb694 svgShape"
        ></path>
        <path
          fill="#fff"
          d="M40.3 50.5h2v3h-2z"
          className="colorfff svgShape"
          opacity="0.7"
        ></path>
        <path
          fill="#c19dfc"
          d="M37.3 48v-2c0-.8.7-1.5 1.5-1.5s1.5.7 1.5 1.5v2"
          className="colorfcc49d svgShape"
        ></path>
        <path
          fill="#d0b3fe"
          d="M60.3 47.5h3v6h-3z"
          className="colorfedab3 svgShape"
        ></path>
        <path
          fill="#b694ee"
          d="M37.3 47.5h3v2h-3zm23 0h3v2h-3z"
          className="coloreeb694 svgShape"
        ></path>
        <path
          fill="#c19dfc"
          d="M60.3 48v-2c0-.8.7-1.5 1.5-1.5s1.5.7 1.5 1.5v2"
          className="colorfcc49d svgShape"
        ></path>
        <path
          fill="#4c4459"
          d="M82.3 91h-64c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h64c.3 0 .5.2.5.5s-.2.5-.5.5z"
          className="color594454 svgShape"
        ></path>
        <path
          fill="#4c4459"
          d="M78.3 91h-56c-.3 0-.5-.2-.5-.5v-22c0-.3.2-.5.5-.5s.5.2.5.5V90h55V68.5c0-.3.2-.5.5-.5s.5.2.5.5v22c0 .3-.2.5-.5.5zm0-25c-.3 0-.5-.2-.5-.5V61h-55v4.5c0 .3-.2.5-.5.5s-.5-.2-.5-.5v-5c0-.3.2-.5.5-.5h56c.3 0 .5.2.5.5v5c0 .3-.2.5-.5.5z"
          className="color594454 svgShape"
        ></path>
        <path
          fill="#4c4459"
          d="M40.8 69H20.3c-.2 0-.4-.1-.5-.3l-1-3c-.1-.2 0-.3.1-.5.1-.1.2-.2.4-.2h22c.3 0 .5.2.5.5s-.2.5-.5.5H20l.7 2h20.1c.3 0 .5.2.5.5s-.2.5-.5.5zm39.5 0H59.8c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h20.1l.7-2H59.8c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h21.5c.2 0 .3.1.4.2.1.1.1.3.1.5l-1 3c-.1.2-.3.3-.5.3z"
          className="color594454 svgShape"
        ></path>
        <path
          fill="#4c4459"
          d="M41.3 91h-5c-.3 0-.5-.2-.5-.5v-37c0-.3.2-.5.5-.5h5c.3 0 .5.2.5.5v37c0 .3-.2.5-.5.5zm-4.5-1h4V54h-4v36z"
          className="color594454 svgShape"
        ></path>
        <path
          fill="#4c4459"
          d="M40.3 54h-3c-.3 0-.5-.2-.5-.5v-6c0-.3.2-.5.5-.5h3c.3 0 .5.2.5.5v6c0 .3-.2.5-.5.5zm-2.5-1h2v-5h-2v5z"
          className="color594454 svgShape"
        ></path>
        <path
          fill="#4c4459"
          d="M40.3 48.5c-.3 0-.5-.2-.5-.5v-2c0-.6-.4-1-1-1s-1 .4-1 1v2c0 .3-.2.5-.5.5s-.5-.2-.5-.5v-2c0-1.1.9-2 2-2s2 .9 2 2v2c0 .3-.2.5-.5.5zM31.8 91h-4c-.3 0-.5-.2-.5-.5v-7c0-2.6 2.3-3.4 2.4-3.5h.3c.1 0 2.3.9 2.3 3.5v7c0 .3-.2.5-.5.5zm-3.5-1h3v-6.5c0-1.5-1-2.2-1.5-2.5-.4.2-1.5.9-1.5 2.5V90zm29 .5c-.3 0-.5-.2-.5-.5V79c0-3-1.5-5.8-4.1-7.5L50.3 70l-2.4 1.6c-2.5 1.6-4.1 4.4-4.1 7.4v11c0 .3-.2.5-.5.5s-.5-.2-.5-.5V79.1c0-3.4 1.7-6.5 4.5-8.3l2.7-1.7c.2-.1.4-.1.5 0l2.7 1.7c2.8 1.8 4.6 4.9 4.6 8.3v11c0 .2-.2.4-.5.4zM33.8 79h-8c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h8c.3 0 .5.2.5.5s-.2.5-.5.5z"
          className="color594454 svgShape"
        ></path>
        <path
          fill="#4c4459"
          d="M38.8 44.9c-.3 0-.5-.2-.5-.5v-1.9c0-.3.2-.5.5-.5s.5.2.5.5v1.9c0 .3-.2.5-.5.5zM64.3 91h-5c-.3 0-.5-.2-.5-.5v-37c0-.3.2-.5.5-.5h5c.3 0 .5.2.5.5v37c0 .3-.2.5-.5.5zm-4.5-1h4V54h-4v36z"
          className="color594454 svgShape"
        ></path>
        <path
          fill="#4c4459"
          d="M63.3 54h-3c-.3 0-.5-.2-.5-.5v-6c0-.3.2-.5.5-.5h3c.3 0 .5.2.5.5v6c0 .3-.2.5-.5.5zm-2.5-1h2v-5h-2v5z"
          className="color594454 svgShape"
        ></path>
        <path
          fill="#4c4459"
          d="M63.3 48.5c-.3 0-.5-.2-.5-.5v-2c0-.6-.4-1-1-1s-1 .4-1 1v2c0 .3-.2.5-.5.5s-.5-.2-.5-.5v-2c0-1.1.9-2 2-2s2 .9 2 2v2c0 .3-.2.5-.5.5z"
          className="color594454 svgShape"
        ></path>
        <path
          fill="#4c4459"
          d="M61.8 44.9c-.3 0-.5-.2-.5-.5v-1.9c0-.3.2-.5.5-.5s.5.2.5.5v1.9c0 .3-.2.5-.5.5zM44.3 66c-.3 0-.5-.2-.5-.5v-5c0-.3.2-.5.5-.5s.5.2.5.5v5c0 .3-.2.5-.5.5zm3 0c-.3 0-.5-.2-.5-.5v-5c0-.3.2-.5.5-.5s.5.2.5.5v5c0 .3-.2.5-.5.5zm3 0c-.3 0-.5-.2-.5-.5v-5c0-.3.2-.5.5-.5s.5.2.5.5v5c0 .3-.2.5-.5.5zm3 0c-.3 0-.5-.2-.5-.5v-5c0-.3.2-.5.5-.5s.5.2.5.5v5c0 .3-.2.5-.5.5zm3 0c-.3 0-.5-.2-.5-.5v-5c0-.3.2-.5.5-.5s.5.2.5.5v5c0 .3-.2.5-.5.5zM33.8 91c-.3 0-.5-.2-.5-.5V78.6c0-2.4-1.3-4.5-3.4-5.5h-.2c-2.1 1.1-3.4 3.2-3.4 5.5v11.9c0 .3-.2.5-.5.5s-.5-.2-.5-.5V78.6c0-2.7 1.5-5.2 4-6.4.3-.2.7-.2 1.1 0 2.5 1.2 4 3.7 4 6.4v11.9c-.1.3-.3.5-.6.5zm39 0h-4c-.3 0-.5-.2-.5-.5v-7c0-2.6 2.3-3.4 2.4-3.5h.3c.1 0 2.3.9 2.3 3.5v7c0 .3-.2.5-.5.5zm-3.5-1h3v-6.5c0-1.5-1-2.2-1.5-2.5-.4.2-1.5.9-1.5 2.5V90zm5.5-11h-7.9c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h7.9c.3 0 .5.2.5.5s-.3.5-.5.5z"
          className="color594454 svgShape"
        ></path>
        <path
          fill="#4c4459"
          d="M74.8 91c-.3 0-.5-.2-.5-.5V78.6c0-2.4-1.3-4.5-3.4-5.5h-.2c-2.1 1.1-3.4 3.2-3.4 5.5v11.9c0 .3-.2.5-.5.5s-.5-.2-.5-.5V78.6c0-2.7 1.5-5.2 4-6.4.3-.2.7-.2 1.1 0 2.5 1.2 4 3.7 4 6.4v11.9c-.1.3-.3.5-.6.5zM59.6 54H41.3c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h18.3c.3 0 .5.2.5.5s-.2.5-.5.5zm0 12H41.3c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h18.3c.3 0 .5.2.5.5s-.2.5-.5.5zm.2-15H40.3c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h19.5c.3 0 .5.2.5.5s-.2.5-.5.5z"
          className="color594454 svgShape"
        ></path>
      </svg>
    </svg>
  );
}

export default MumbaiIcon;