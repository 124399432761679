import axios from "axios";
import { backendurl } from "../utils/constants";
import {
    ALL_TRAININGSCHEDULES_FAIL,
    ALL_TRAININGSCHEDULES_REQUEST,
    ALL_TRAININGSCHEDULES_SUCCESS,
    TRAININGSCHEDULE_DETAILS_REQUEST,
    TRAININGSCHEDULE_DETAILS_SUCCESS,
    TRAININGSCHEDULE_DETAILS_FAIL,
    ADMIN_TRAININGSCHEDULES_REQUEST,
    ADMIN_TRAININGSCHEDULES_SUCCESS,
    ADMIN_TRAININGSCHEDULES_FAIL,
    CLEAR_ERRORS,
    NEW_TRAININGSCHEDULE_REQUEST,
    NEW_TRAININGSCHEDULE_SUCCESS,
    NEW_TRAININGSCHEDULE_FAIL,
    UPDATE_TRAININGSCHEDULE_REQUEST,
    UPDATE_TRAININGSCHEDULE_SUCCESS,
    UPDATE_TRAININGSCHEDULE_FAIL,
    DELETE_TRAININGSCHEDULE_REQUEST,
    DELETE_TRAININGSCHEDULE_SUCCESS,
    DELETE_TRAININGSCHEDULE_FAIL,
    SLIDER_TRAININGSCHEDULES_REQUEST,
    SLIDER_TRAININGSCHEDULES_SUCCESS,
    SLIDER_TRAININGSCHEDULES_FAIL,
} from "../constants/trainingscheduleConstants";
const config = {
    headers: {
        "Content-Type": "application/json",
        "Accept":"application/json"
    },
    withCredentials : true
}
// Get All Trainingschedules --- Filter/Search/Sort
export const getTrainingschedules =
    (keyword = "", category, price = [0, 200000], currentPage = 1) => async (dispatch) => {
        try {
            dispatch({ type: ALL_TRAININGSCHEDULES_REQUEST });

            let url = `/api/v1/trainingschedules?keyword=${keyword}&price[gte]=${price[0]}&price[lte]=${price[1]}&page=${currentPage}`;
            if (category) {
                url = `/api/v1/trainingschedules?keyword=${keyword}&category=${category}&price[gte]=${price[0]}&price[lte]=${price[1]}&page=${currentPage}`;
            }
            const { data } = await axios.get(url,config);

            dispatch({
                type: ALL_TRAININGSCHEDULES_SUCCESS,
                payload: data,
            });
        } catch (error) {
            dispatch({
                type: ALL_TRAININGSCHEDULES_FAIL,
                payload: error.response.data.message,
            });
        }
    };

// Get All Trainingschedules Of Same Category
export const getSimilarTrainingschedules = (category) => async (dispatch) => {
    try {
        dispatch({ type: ALL_TRAININGSCHEDULES_REQUEST });

        const { data } = await axios.get(backendurl+`/api/v1/trainingschedules?category=${category}`,config);

        dispatch({
            type: ALL_TRAININGSCHEDULES_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: ALL_TRAININGSCHEDULES_FAIL,
            payload: error.response.data.message,
        });
    }
};

// Get Trainingschedule Details
export const getTrainingscheduleDetails = (slug) => async (dispatch) => {
    try {
        dispatch({ type: TRAININGSCHEDULE_DETAILS_REQUEST });
        const { data } = await axios.get(backendurl+`/api/v1/trainingschedule/${slug}`,config);

        dispatch({
            type: TRAININGSCHEDULE_DETAILS_SUCCESS,
            payload: data.trainingschedule,
        });
    } catch (error) {
        dispatch({
            type: TRAININGSCHEDULE_DETAILS_FAIL,
            payload: error.response.data.message,
        });
    }
};



// Get All Trainingschedules ---TRAININGSCHEDULE SLIDER
export const getSliderTrainingschedules = () => async (dispatch) => {
    try {
        dispatch({ type: SLIDER_TRAININGSCHEDULES_REQUEST });

        const { data } = await axios.get(backendurl+'/api/v1/trainingschedules/all',config);

        dispatch({
            type: SLIDER_TRAININGSCHEDULES_SUCCESS,
            payload: data.trainingschedules,
        });
    } catch (error) {
        dispatch({
            type: SLIDER_TRAININGSCHEDULES_FAIL,
            payload: error.response.data.message,
        });
    }
};

// Get All Trainingschedules ---ADMIN
export const getAdminTrainingschedules = () => async (dispatch) => {
    try {
        dispatch({ type: ADMIN_TRAININGSCHEDULES_REQUEST });

        const { data } = await axios.get(backendurl+'/api/v1/admin/trainingschedules',config);

        dispatch({
            type: ADMIN_TRAININGSCHEDULES_SUCCESS,
            payload: data.trainingschedules,
        });
    } catch (error) {
        dispatch({
            type: ADMIN_TRAININGSCHEDULES_FAIL,
            payload: error.response.data.message,
        });
    }
};

// New Trainingschedule ---ADMIN
export const createTrainingschedule = (trainingscheduleData) => async (dispatch) => {
    try {
        dispatch({ type: NEW_TRAININGSCHEDULE_REQUEST });
        
        const { data } = await axios.post(backendurl+"/api/v1/admin/trainingschedule/new", trainingscheduleData, config);

        dispatch({
            type: NEW_TRAININGSCHEDULE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: NEW_TRAININGSCHEDULE_FAIL,
            payload: error.response.data.message,
        });
    }
}

// Update Trainingschedule ---ADMIN
export const updateTrainingschedule = (slug, trainingscheduleData) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_TRAININGSCHEDULE_REQUEST });
        
        const { data } = await axios.put(backendurl+`/api/v1/admin/trainingschedule/${slug}`, trainingscheduleData, config);

        dispatch({
            type: UPDATE_TRAININGSCHEDULE_SUCCESS,
            payload: data.success,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_TRAININGSCHEDULE_FAIL,
            payload: error.response.data.message,
        });
    }
}

// Delete Trainingschedule ---ADMIN
export const deleteTrainingschedule = (slug) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_TRAININGSCHEDULE_REQUEST });
        const { data } = await axios.delete(backendurl+`/api/v1/admin/trainingschedule/${slug}`,config);

        dispatch({
            type: DELETE_TRAININGSCHEDULE_SUCCESS,
            payload: data.success,
        });
    } catch (error) {
        dispatch({
            type: DELETE_TRAININGSCHEDULE_FAIL,
            payload: error.response.data.message,
        });
    }
}



// Clear All Errors
export const clearErrors = () => (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
}