import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearErrors, loginUser } from '../../actions/userAction';
import { useSnackbar } from 'notistack';
import BackdropLoader from '../Layouts/BackdropLoader';
import MetaData from '../Layouts/MetaData';
import jwt_decode from "jwt-decode";
import LockIcon from '@mui/icons-material/Lock';

const Login = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation();

    const {  loading, isAuthenticated, error } = useSelector((state) => state.user);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    

    const handleLogin = (e) => {
        e.preventDefault();
        dispatch(loginUser(email, password));
    }

    const redirect = location.search ? location.search.split("=")[1] : "";

    useEffect(() => {
        if (error) {
            enqueueSnackbar(error, { variant: "error" });
            dispatch(clearErrors());
        }
        if (isAuthenticated) {
            navigate(`/${redirect}`)
        }
    }, [dispatch, isAuthenticated, redirect, navigate, enqueueSnackbar]);


    //goolge auth
   
async function handleCallbackResponse(response){
    //console.log("Encoded JWT Token: ",response.credential);
    var userObject = jwt_decode(response.credential);
    dispatch(loginUser(userObject.email, "",true));
  }
  
  useEffect(() => {
    /* global google */
    google && google.accounts.id.initialize({
      client_id:"678938896955-8v8pvov2gjkfukeh8o80gtbofkl8mhdh.apps.googleusercontent.com",
      callback:handleCallbackResponse
    })
  
  google.accounts.id.renderButton(
    document.getElementById("signInDiv"),
    {theme: "outline", size: "large" , 
   // type:"icon",shape:"square" , width:"400px"
}
  );
  })
  
  //google auth end

    return (
        <>
            <MetaData title="Login | Lyriclious" />

            {google && loading && <BackdropLoader />}
            <main className="w-full mt-12 sm:pt-20 sm:mt-0">

                {/* <!-- row --> */}
                <div className="flex sm:w-4/6 sm:mt-4 m-auto mb-7 bg-white shadow-lg">
                    {/* <!-- sidebar column  --> */}
                    <div className="loginSidebar bg-purple-900 p-10 pr-12 hidden sm:flex flex-col gap-4 w-2/5">
                        <h1 className="font-medium text-white text-3xl">Login</h1>
                        <p className="text-gray-200 text-lg">Get access to your Trainings, Internships, Jobs and other Services</p>
                    </div>
                    {/* <!-- sidebar column  --> */}

                    {/* <!-- login column --> */}
                    <div className="flex-1 overflow-hidden">

                        {/* <!-- edit info container --> */}
                        <div className="text-center py-10 px-4 sm:px-14">
                        <p className='font-small inline text-gray-500'>Continue with</p>
                                <div className='w-full'>
                                    <div id="signInDiv" className="m-auto" ></div>
                                    </div>
                                <hr className='my-5'/>
                        
                        {/* https://developers.google.com/identity/sign-in/web/sign-in */}
                            {/* <!-- input container --> */}
                            <form onSubmit={handleLogin}>
                            
                                <div className="flex flex-col w-full gap-4">
                                   
                                <p className='font-small inline text-gray-500'>Or, sign-in with your email</p>
                                    <TextField
                                        fullWidth
                                        id="email"
                                        label="Email"
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                    <TextField
                                        fullWidth
                                        id="password"
                                        label="Password"
                                        type="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                    />
                                    <Link to="/password/forgot" className="font-medium text-sm text-purple-900 text-right"><p className='text-lg inline text-gray-300'><LockIcon fontSize="small"/></p>  Forgot Password ?</Link>
                                    {/* <span className="text-xxs text-red-500 font-medium text-left mt-0.5">Please enter valid Email ID/Mobile number</span> */}

                                    {/* <!-- button container --> */}
                                    <div className="flex flex-col gap-2.5 mt-2 mb-14">
                                        <p className="text-xs text-primary-grey text-left">By continuing, you agree to Lyriclious's <a href="https://www.lyriclious.com/pages/terms" className="text-purple-900"> Terms of Use</a> and <a href="https://www.lyriclious.com/pages/privacypolicy" className="text-purple-900"> Privacy Policy.</a></p>
                                        <button type="submit" className="text-white py-4 w-full bg-purple-900 shadow hover:shadow-lg rounded-md font-medium">Login</button>
                                        <p className='text-sm m-auto'>Don't have an account ? <Link to="/register" className="text-purple-900 text-center font-normal"> Sign Up</Link></p>
                                    </div>
                                    {/* <!-- button container --> */}

                                </div>
                            </form>
                            {/* <!-- input container --> */}
                            
                            
                        </div>
                        {/* <!-- edit info container --> */}

                    </div>
                    {/* <!-- login column --> */}
                </div>
                {/* <!-- row --> */}

            </main>
        </>
    );
};

export default Login;
