import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CoverPic from "../../../../assets/images/Profile/coverPic.jpeg";
import CollegePic from "../../../../assets/images/Profile/collegePic.png"
import EditIcon from '@mui/icons-material/Edit'

const ProfileTop = ({ activeTab, children }) => {

    const navigate = useNavigate();

    const { user, loading, isAuthenticated } = useSelector(state => state.user)

    useEffect(() => {
        if (isAuthenticated === false) {
            navigate("/login")
        }
    }, [isAuthenticated, navigate]);

   

    

    return (
        <> 
                        {/* <!-- row --> */}
                        <div className="gap-3.5 sm:w-11/12 sm:mt-4  mb-7 border-gray-300 border-[1.2px] mx-auto rounded-xl ">
                            <img src={CoverPic} alt='cover pic' className='rounded-t-xl'/>
                            <EditIcon className='ml-[94%] text-gray-600'/>
                            <div className='flex mx-6 mt-8'>
                                <div className='w-3/4'>
                                <h2 className='mx-[0%] text-xl text-gray-900'>{user.user_name}</h2>
                                <p className='text-gray-500'>Full Stach Developer || Java || JavaScript || MySQL || Adobe XD <br>
                                </br>
                                || coding || content writting
                                </p>
                                <br />
                                <p className='text-gray-400 text-sm'>New Delhi, Delhi, India. <a href='#' className='text-purple-900 font-medium'>Contact Info</a></p>
                                </div>

                                <div className='flex w-1/4  pt-4 '>
                                   
                                        <img  src={CollegePic} alt='collegePic' className='inline-block w-1/5 h-1/3 rounded-[50%]'/>
                                        <div className='flex inline-block pl-2'>
                                       <span className='whitespace-pre-line font-normal text-xs text-gray-500'> Dr APJ Abdul kalam Technical University </span>
                                       </div>
                                      
                                </div>
                             
                            </div>
                           
                        </div>
                   
            
        </>
    );
};

export default ProfileTop;
