import React, {Component} from "react";
import Stack from '@mui/material/Stack';
import TrapFocus from '@mui/material/Unstable_TrapFocus';
import Paper from '@mui/material/Paper';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { purple } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { backendurl } from './../../utils/constants';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { NEW_COOKIE_RESET } from '../../constants/utilConstants';
import { createCookie,getAllCookies, clearErrors } from '../../actions/utilAction';

const CookieConsent = () => {
const [bannerOpen, setBannerOpen] = React.useState(true);
const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const { loading, success, error } = useSelector((state) => state.setcookie);
    const { loading:getcookieloading, cookies, error:getAllcookieError } = useSelector((state) => state.getallcookies);
    
  const closeBanner = () => {
    setBannerOpen(false);}

    const config = {
        headers: {
            "Content-Type": "application/json",
            "Accept":"application/json",   
        },
        withCredentials : true
    }
   

   
    
    
    const ColorButton = styled(Button)(({ theme }) => ({
      color: theme.palette.getContrastText(purple[900]),
      backgroundColor: purple[900],
      '&:hover': {
        backgroundColor: purple[900],
      },
    }));

    const newCookieSubmitHandler = (e) => {
      e.preventDefault();

        
      const formData = new FormData();
      formData.set("cookiename","cookieconsent");
      formData.set("cookievalue", 1);
      formData.set("durationInDays", 354);
      

      dispatch(createCookie(formData));
  }

  useEffect(() => {
    
      if (error) {
          enqueueSnackbar(error, { variant: "error" });
          dispatch(clearErrors());
      }
      if (success) {
          enqueueSnackbar("Cookie Consent Success", { variant: "success" });
          dispatch({ type: NEW_COOKIE_RESET });
          closeBanner();
      }
      dispatch(getAllCookies())
  }, [dispatch, error, success, navigate, enqueueSnackbar]);


    return (
        <>
        {cookies && console.log(cookies.cookieconsent)}
        {!(cookies && cookies.cookieconsent) && <TrapFocus open disableAutoFocus disableEnforceFocus>
        <Fade appear={false} in={bannerOpen} >
          <Paper
          
            role="dialog"
            aria-modal="false"
            aria-label="Cookie banner"
            square
            variant="outlined"
            tabIndex={-1}
            sx={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              right: 0,
              m: 0,
              p: 2,
              borderWidth: 0,
              borderTopWidth: 1,
            }}
          >
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              justifyContent="space-between"
              gap={2}
              
            >
              <Box
              
                sx={{
                  flexShrink: 1,
                  alignSelf: { xs: 'flex-start', sm: 'center' },
                }}
              >
                <Typography fontWeight="bold" class="text-gray-600 text-sm font-semibold">This website uses cookies</Typography>
                <Typography variant="body2" class="text-gray-600 text-xs">
                We, and third parties, use cookies for technical and analytical purposes, for marketing purposes and for integration with social media. For more information, refer to our Privacy Policy and Terms of Consent. By clicking on 'I agree', you consent to the use of these cookies.
                </Typography>
              </Box>
              <Stack
                gap={2}
                direction={{
                  xs: 'row-reverse',
                  sm: 'row',
                }}
                sx={{
                  flexShrink: 0,
                  alignSelf: { xs: 'flex-end', sm: 'center' },
                }}
              >
                <ColorButton onClick={newCookieSubmitHandler} variant="contained">I Agree</ColorButton>
                {/* <Button size="small" onClick={closeBanner} class="text-purple-900">
                  X
                </Button> */}
              </Stack>
            </Stack>
          </Paper>
        </Fade>
      </TrapFocus> }
        </>
    )

}
export default CookieConsent;