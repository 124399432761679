import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CollegePic from "../../../../assets/images/Profile/collegePic.png"
import EditIcon from '@mui/icons-material/Edit'
import AddIcon from "@mui/icons-material/Add"

const Skills = ({ activeTab, children }) => {

    const navigate = useNavigate();

    const { user, loading, isAuthenticated } = useSelector(state => state.user)

    useEffect(() => {
        if (isAuthenticated === false) {
            navigate("/login")
        }
    }, [isAuthenticated, navigate]);

    

    

    return (
        <> 
                        {/* <!-- row --> */}
                        <div className="pt-4 gap-3.5 sm:w-11/12 sm:mt-4  mb-7 border-gray-300 border-[1.2px] mx-auto rounded-xl ">
                           <span >
                            <h1 className='inline-block mx-[2%]  text-xl'>Skills</h1>
                            <button class="rounded-full border-2 border-purple-900 text-purple-900 font-normal text-sm px-4 py-[0.5%] mt-2 ml-[58%]">Take skill quiz</button>
                            <AddIcon className='ml-[2%] text-gray-600'  />
                            <EditIcon className='ml-[2%] text-gray-600' />
                           </span>

                            <div>
                            <h2 className='ml-4 mt-2'>Data Structures</h2>
                            <div className='flex mx-[2%] my-2'>
                            <img  src={CollegePic} alt='collegePic' className='w-[4%] h-[2%] inline-block rounded-[50%]'/>
                                        <div className='ml-4'>
                                       <p className='font-normal text-xs text-gray-500'>Endrosed by 3 colleagues at Sparks foundation </p> 
                                       </div>
                            </div>

                            <div className='flex mx-[2%] my-2'>
                            <img  src={CollegePic} alt='collegePic' className='w-[4%] h-[2%] inline-block rounded-[50%]'/>
                                        <div className='ml-4'>
                                       <p className='font-normal text-xs text-gray-500'>6 Endrosments</p> 
                                       </div>
                            </div>
                            
                        </div>

                        <hr className='mx-4' />
                    
                        <div>
                            <h2 className='ml-4 mt-2'>Web Development</h2>
                            <div className='flex mx-[2%] my-2'>
                            <img  src={CollegePic} alt='collegePic' className='w-[4%] h-[2%] inline-block rounded-[50%]'/>
                                        <div className='ml-4'>
                                       <p className='font-normal text-xs text-gray-500'>Endrosed by 3 colleagues at Sparks foundation </p> 
                                       </div>
                            </div>

                            <div className='flex mx-[2%] my-2'>
                            <img  src={CollegePic} alt='collegePic' className='w-[4%] h-[2%] inline-block rounded-[50%]'/>
                                        <div className='ml-4'>
                                       <p className='font-normal text-xs text-gray-500'>6 Endrosments</p> 
                                       </div>
                            </div>
                            
                        </div>
                    </div>
                   
            
        </>
    );
};

export default Skills;
