import React from "react";

function ComingsoonIcon({height, width}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 691.088 756.357"
    >
      <circle cx="179.108" cy="181.971" r="143.892" fill="#ff6884"></circle>
      <path
        fill="#41228a"
        d="M394.08 55.602L116.93 208.265c-32.015 41.047 576.323 43.585 571.302.15L419.482 55.754c-7.65-4.345-17.68-4.405-25.402-.151z"
      ></path>
      <path
        d="M394.08 55.602L116.93 208.265c-32.015 41.047 576.323 43.585 571.302.15L419.482 55.754c-7.65-4.345-17.68-4.405-25.402-.151z"
        opacity="0.2"
      ></path>
      <path
        fill="#f2f2f2"
        d="M687.083 756.357l-70.499-1.502h-483.42L0 751.85c441.478-104.337 305.737-95.354 687.083 4.507z"
        opacity="0.8"
      ></path>
      <path
        fill="#e6e6e6"
        d="M622.18 751.85l-5.596 3.005h-483.42C463.62 686.642 265.5 692.648 622.18 751.85z"
      ></path>
      <path
        fill="#3f3d56"
        d="M388.597 42.857h20.806L402.387 3.31C401.953.866 400.292 0 399 0s-2.953.866-3.387 3.31l-7.016 39.547z"
      ></path>
      <path fill="#3f3d56" d="M386.624 173.344H409.564V732.858H386.624z"></path>
      <path
        fill="#3f3d56"
        d="M398.16 260.688c-9.787 0-18.988-3.79-25.943-10.695l-57.897-57.481 4.73-4.765 57.896 57.481c5.757 5.716 13.357 8.836 21.508 8.745 8.112-.077 15.69-3.326 21.338-9.148l55.324-57.033 4.82 4.675L424.61 249.5c-6.908 7.12-16.175 11.093-26.094 11.187l-.357.001z"
      ></path>
      <path
        fill="#41228a"
        d="M406.182 216.805c-28.489 0-57.202-.098-85.07-.295-59.047-.42-109.459-1.25-145.786-2.4-61.919-1.96-62.578-4.187-62.86-5.137-.065-.22-.087-.647.496-1.03L389.35 26.182a19.518 19.518 0 0121.786.157l279.43 181.752c.583.395.554.823.486 1.043-.349 1.117-1.408 4.516-124.098 6.513-46.68.76-103.256 1.157-160.773 1.157z"
      ></path>
      <path
        fill="#3f3d56"
        d="M459.394 486.494l-35.029.039c-3.922 8.717 1.437 18.912 8.29 25.576s15.446 11.776 20.506 19.885c11.23 17.995.648 41.66 4.357 62.543 4.994 28.122 34.476 45.82 43.454 72.933 5.078 15.339 2.77 32.944-6.092 46.454-1.636 2.494-3.525 4.956-4.097 7.884-.778 3.98 1.317 8.352 4.907 10.239s8.38 1.134 11.217-1.764c-2.771 3.149-1.863 8.465 1.248 11.28s7.745 3.413 11.85 2.547c13.3-2.809 19.996-18.173 20.534-31.757.538-13.583-2.871-27.346-.211-40.677 1.696 19.132 23.343 31.282 25.04 50.415.314 3.546-19.28 14.21-17.457 17.267 4.61 7.728 15.596 7.755 24.546 6.828 15.41-1.594 32.522-4.077 42.187-16.183 6.512-8.156 8.2-19.133 8.413-29.568.56-27.242-7.556-54.597-22.88-77.126-20.397-29.985-52.822-51.237-68.833-83.775-5.442-11.059-8.772-23.05-14.067-34.18-5.294-11.13-13.083-21.78-24.35-26.774-11.269-4.995-26.439-2.532-32.94 7.939"
      ></path>
      <path
        fill="#2f2e41"
        d="M568.011 725.878a4.76 4.76 0 01-2.023-.45 1 1 0 11.844-1.812c1.104.513 2.166.177 2.863-.197 1.43-.767 2.55-2.337 2.788-3.909.46-3.035-1.062-6.137-2.404-8.874-3.915-7.98-7.785-16.525-8.366-25.555-.655-10.183 3.548-19.667 10.708-24.163 5.323-3.342 12.36-4.216 19.814-2.464 6.386 1.502 12.27 4.652 18.236 8.023a1 1 0 11-.984 1.74c-6.058-3.422-11.572-6.373-17.71-7.816-6.93-1.63-13.427-.844-18.292 2.21-6.548 4.112-10.385 12.88-9.776 22.341.557 8.665 4.34 17.004 8.165 24.803 1.399 2.851 3.14 6.4 2.586 10.055-.332 2.197-1.832 4.305-3.82 5.372-.863.463-1.757.695-2.629.695zM509.734 503.898c5.141 10.2 2.429 23.749-6.244 31.183-1.862 1.597-3.959 2.933-5.624 4.735s-2.887 4.24-2.467 6.657c-5.682-5.636-11.475-11.427-14.91-18.654-3.437-7.228-4.108-16.3.364-22.937 2.134-3.166 5.244-5.54 7.665-8.492 2.421-2.952 4.168-6.971 2.858-10.558"
      ></path>
      <path
        fill="#3f3d56"
        d="M611.905 699.811c-2.717 8.308 1.756 17.868 9.096 22.614s16.824 5.239 25.297 3.091c16.384-4.153 29.84-18.18 33.308-34.723 1.442-6.88 1.34-14.254 4.606-20.48 2.052-3.912 5.44-7.448 5.537-11.865.08-3.59-2.25-6.997-5.43-8.667s-7.05-1.728-10.472-.64c-8.156 2.591-13.162 10.851-15.757 19.007-2.595 8.155-3.563 16.875-7.335 24.557-3.772 7.682-11.568 14.39-20.045 13.218-7.331-1.013-13.537-7.623-20.883-6.723"
      ></path>
      <g>
        <path
          fill="#ffb6b6"
          d="M202.232 596.143c-9.917 1.52-17.17 7.87-16.202 14.18.968 6.311 9.791 10.193 19.711 8.671a25.108 25.108 0 0011.026-4.364l71.738-10.808 2.703-24.782-77.145 17.963a25.109 25.109 0 00-11.83-.86z"
        ></path>
        <path
          fill="#e6e6e6"
          d="M382.94525 544.47235L366.68433 578.97766 284.93851 606.49622 284.93851 606.49622 288.92178 578.45148 288.92178 578.45148 364.35733 542.80377 382.94525 544.47235z"
        ></path>
        <path
          fill="#ffb6b6"
          d="M232.17526 675.48987L217.57631 712.41669 198.68349 699.53522 209.8474 660.89087 232.17526 675.48987z"
        ></path>
        <path
          fill="#ffb6b6"
          d="M171.20306 661.74963L132.5587 698.67645 148.01645 714.99298 187.51958 684.07745 171.20306 661.74963z"
        ></path>
        <path
          fill="#ffb6b6"
          d="M390.18777 541.5227L389.75839 538.51703 379.8826 519.19489 347.2496 527.78247 353.26093 554.40417 390.18777 541.5227z"
        ></path>
        <path
          fill="#2f2e41"
          d="M376.448 670.337l1.003 12.899s4.15 54.085-29.343 58.379-48.09 8.587-67.842-24.904L249.35 661.75l-18.057 21.486-24.022-18.91s17.175-66.984 30.916-69.56a31.346 31.346 0 015.125-.518c11.375-.237 22.098 5.327 28.904 14.444l38.966 52.199 14.17-6.44 51.096 15.886z"
        ></path>
        <path
          fill="#ffb6b6"
          d="M190.955 710.7l8.677-12.805 19.915 9.454s6.617 23.102-1.112 25.678-29.198-.859-29.198-.859-39.503 8.588-40.362.859 18.034-9.446 18.034-9.446l24.046-12.882z"
        ></path>
        <path
          fill="#2f2e41"
          d="M275.113 700.394l-59.684-37.606-30.486 27.3-20.61-24.045s37.786-55.82 48.95-58.395c11.163-2.577 29.197-4.294 29.197-4.294l54.961 50.667-22.328 46.373z"
        ></path>
        <path
          fill="#ffb6b6"
          d="M136.72 695.38l12.62 18.004-.493 5.555s-5.124 22.317-17.147 14.768-12.882-9.268-12.882-9.268-34.35 7.73-37.785-6.011 6.011-11.164 6.011-11.164l8.578 5.153 33.435-11.177 7.663-5.86z"
        ></path>
        <circle cx="357.555" cy="509.748" r="27.48" fill="#ffb6b6"></circle>
        <path
          fill="#e6e6e6"
          d="M351.114 548.822l39.503-12.022s15.458 36.068 12.882 52.384c-2.577 16.317-26.048 94.052-26.048 94.052l-52.1-28.786s-.859-17.175-3.435-24.904-10.734-31.345 7.3-51.096c18.034-19.752 21.898-29.628 21.898-29.628z"
        ></path>
        <path
          fill="#ffb6b6"
          d="M264.17 729.147c-6.429 7.702-7.666 17.262-2.763 21.353 4.902 4.09 14.086 1.162 20.516-6.544a25.107 25.107 0 005.378-10.568l60.857-68.239-18.859-10.194-55.69 67.008a25.109 25.109 0 00-9.439 7.184z"
        ></path>
        <path
          fill="#e6e6e6"
          d="M381.4147 566.14783L390.75183 603.13232 345.05267 676.28448 349.17725 675.34314 324.20197 656.99396 330.32925 652.08545 365.99234 576.65717 381.4147 566.14783z"
        ></path>
        <path
          fill="#2f2e41"
          d="M332.221 505.025c7.048-.017 7.448.03 8.588 0 8.741-.23 11.027-3.67 15.458-3.435 7.908.419-2.53 40.067-6.87 58.396-4.258 17.977 10.13 34.085 11.163 35.21 14.604 15.881 40.255 18.785 44.656 12.022 3.563-5.474-9.063-13.798-6.011-25.763 2.983-11.696 16.76-10.45 20.61-21.469 4.033-11.539-9.52-17.359-18.034-45.514-4.89-16.17-4.025-19.183-8.588-29.198-12.196-26.77-71.977-15.372-65.266 9.446 1.299 4.801-2.156 10.32 4.294 10.305z"
        ></path>
      </g>
    </svg>
  );
}

export default ComingsoonIcon;
