import DurationIcon from '@mui/icons-material/AccessTime';
import CertificateIcon from '@mui/icons-material/VerifiedOutlined';
import MiniProjectIcon from '@mui/icons-material/FeaturedPlayListOutlined';
import MajorProjectIcon from '@mui/icons-material/ChromeReaderModeOutlined';
import { baseurl, backendurl } from "../../../../utils/constants";
import { daystoGeneralForm, getFormatedDate } from '../../../../utils/functions';

const LearnersCard = (props) => {
 
  
 
    return (
      <>       
<a href={baseurl+"/trainingandinternshipschedule/"+props.trainingandinternship.trainingscheduledetails.slug} className="lg:col-span-3 sm:col-span-6 xs:col-span-6 w-300 border-[1px] border-gray-300 rounded-xl transition ease-in-out delay-10 hover:shadow-md hover:-translate-y-0.5 duration-300 pb-6" >
  <img src={props.trainingandinternship.trainingdetails.image.url} className="w-full border-t-[1px] border-gray-200 rounded-t-xl" alt={props.trainingandinternship.trainingdetails.training_name}/>
   
  <div className="xs:p-2 lg:p-6 pb-0">
    <h2 className="xs:text-md lg:text-lg font-normal xs:truncate">{props.trainingandinternship.trainingdetails.training_name}</h2>
    <p className="bg-gradient-to-r from-purple-900/30 to-gray-50/0 text-purple-900 px-2 text-xs py-0.5 my-3">Status : {props.trainingandinternship.trainingscheduledetails.status} </p>
    <span className="my-4 xs:text-xs lg:text-sm font-small text-gray-600 leading-7"><MiniProjectIcon fontSize='small' />  <span className="ml-2">Code : {props.trainingandinternship.trainingscheduledetails.slug}</span> </span><br />
    <span className="my-4 xs:text-xs lg:text-sm font-small text-gray-600 leading-7"><DurationIcon fontSize='small'/>  <span className="ml-2"> Duration : {props.trainingandinternship.trainingdetails.duration.d_num+" "+props.trainingandinternship.trainingdetails.duration.d_type}</span>  </span><br />
    <span className="my-4 xs:text-xs lg:text-sm font-small text-gray-600 leading-7"><MiniProjectIcon fontSize='small' />  <span className="ml-2">Days : {daystoGeneralForm(props.trainingandinternship.trainingscheduledetails.scheduled_days)}</span> </span><br />
    <span className="my-4 xs:text-xs lg:text-sm font-small text-gray-600 leading-7"><MiniProjectIcon fontSize='small' />  <span className="ml-2">Days : {props.trainingandinternship.trainingscheduledetails.scheduled_time}</span> </span><br />
    <span className="my-6 text-sm font-small text-gray-600 leading-7"><MajorProjectIcon fontSize='small' />  <span className="ml-2">Amount Paid : {props.trainingandinternship.orderItems.offerPrice*1.18 }</span>  </span><br />
    
    <span className="my-4 xs:text-xs lg:text-sm font-small text-gray-600 leading-7"><CertificateIcon  fontSize='small'/>  <span className="ml-2">Start Date : {getFormatedDate(props.trainingandinternship.trainingscheduledetails.start_date)}</span>  </span>
   <br /><span className="my-4 xs:text-xs lg:text-sm font-small text-gray-600 leading-7"><CertificateIcon  fontSize='small'/>  <span className="ml-2">Instructor : {props.trainingandinternship.instructordetails.user_name}</span>  </span>
    
<br />
<a href={baseurl+"/trainingandinternship/"+props.trainingandinternship.trainingdetails.slug} className="text-blue-500 leading-[3rem] xs:text-xs lg:text-sm font-small">Know More &#62;</a>
  </div>


</a>



      </>
    );
  };
  
  export default LearnersCard;