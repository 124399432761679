import logo from '../../assets/images/lylogo-long-color.png';
import { Link } from 'react-router-dom';
import './Certificate.css';


const Certificate = () => {

    const duration = "1 Month";
    const training_name = "Technology/Field Name";
    const duration_date = "January 2023 - March 2023";
    const project_name = "Project name here";
    const certificate_no = "LREDUINT000XX";
    const typeofcerti="INTERNSHIP";
    
  return (
    <div className="certificate-back mx-auto my-12 pl-20 py-10 border-4 ">
       
        <div className='flex  h-8 w-full pt-[100px] justify-between'>
        <Link className="h-8 xs:m-auto lg:m-0 mt-5" to="/">
            <img draggable="false" className="h-full object-contain" src={logo} alt="Lyriclious Logo" />
          </Link>

        <div className='xs:text-xs lg:text-sm xs:font-normal lg:font-normal inline-block float-right mr-[140px] text-gray-300'>
            <p className='text-center'>CERTIFICATE OF <br></br> {typeofcerti}</p>
        </div>

       </div>
        <p className='xs:text-xs lg:text-sm xs:font-extralight lg:font-light mt-10'>XX/XX/20XX</p>
        <p className='xs:text-xs lg:text-lg xs:font-normal lg:font-medium mt-4'>This is to certify that</p>
        <p className='xs:text-xs lg:text-lg xs:font-medium lg:font-semibold mt-4'>Name Here</p>

        <p className='xs:text-xs lg:text-sm xs:font-extralight lg:font-normal mt-4 w-1/2'>has completed {duration} "<b>{training_name}" Training </b> from {duration_date} at Lyriclious- Learn with us. During the training , the intern worked on a major project namely "{project_name}" and some other minor projects. We found the intern extremly hardworking, punctual and responsive towards work demonstrating a wide variety of skills in the domain.We appricate the efforts put in and wish all the best for future endeavours.</p>
       

       <div className='relative h-[10px] w-full pt-[200px]'>
        <div className=' xs:text-xs lg:text-sm xs:font-extralight lg:font-normal inline-block'>
            <p>Nishant kumar Singh</p>
            <p>Founder & CEO</p>
        </div>

        <div className='xs:text-xs lg:text-sm xs:font-extralight lg:font-normal inline-block float-right mr-[100px]'>
            <p>Certificate No. :{certificate_no}</p>
            <p>Verify at: <a className='underline' href='https://lyriclious.com/certificate/'>https://lyriclious.com/certificate/</a></p>
        </div>

       </div>
    </div>
  );
};


export default Certificate;
