import axios from "axios";
import {
    ALL_TRAININGS_FAIL,
    ALL_TRAININGS_REQUEST,
    ALL_TRAININGS_SUCCESS,
    TRAINING_DETAILS_REQUEST,
    TRAINING_DETAILS_SUCCESS,
    TRAINING_DETAILS_FAIL,
    ADMIN_TRAININGS_REQUEST,
    ADMIN_TRAININGS_SUCCESS,
    ADMIN_TRAININGS_FAIL,
    CLEAR_ERRORS,
    NEW_TRAINING_REQUEST,
    NEW_TRAINING_SUCCESS,
    NEW_TRAINING_FAIL,
    UPDATE_TRAINING_REQUEST,
    UPDATE_TRAINING_SUCCESS,
    UPDATE_TRAINING_FAIL,
    DELETE_TRAINING_REQUEST,
    DELETE_TRAINING_SUCCESS,
    DELETE_TRAINING_FAIL,
    SLIDER_TRAININGS_REQUEST,
    SLIDER_TRAININGS_SUCCESS,
    SLIDER_TRAININGS_FAIL,
} from "../constants/trainingConstants";
import { backendurl } from "../utils/constants";
const config = {
    headers: {
        "Content-Type": "application/json",
        "Accept":"application/json"
    },
    withCredentials : true
}
// Get All Trainings --- Filter/Search/Sort
export const getTrainings =
    (keyword = "", category, price = [0, 200000], ratings=0, currentPage = 1) => async (dispatch) => {
        try {
            dispatch({ type: ALL_TRAININGS_REQUEST });

            let url = backendurl+`/api/v1/trainings?keyword=${keyword}&price[gte]=${price[0]}&price[lte]=${price[1]}&page=${currentPage}`;
            if (category) {
                url = backendurl+`/api/v1/trainings?keyword=${keyword}&category=${category}&price[gte]=${price[0]}&price[lte]=${price[1]}&page=${currentPage}`;
            }
            const { data } = await axios.get(url,config);

            dispatch({
                type: ALL_TRAININGS_SUCCESS,
                payload: data,
            });
        } catch (error) {
            dispatch({
                type: ALL_TRAININGS_FAIL,
                payload: error.response.data.message,
            });
        }
    };

// Get All Trainings Of Same Category
export const getSimilarTrainings = (category) => async (dispatch) => {
    try {
        dispatch({ type: ALL_TRAININGS_REQUEST });

        const { data } = await axios.get(backendurl+`/api/v1/trainings?category=${category}`,config);

        dispatch({
            type: ALL_TRAININGS_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: ALL_TRAININGS_FAIL,
            payload: error.response.data.message,
        });
    }
};

// Get Training Details
export const getTrainingDetails = (slug, userid) => async (dispatch) => {
    try {
        dispatch({ type: TRAINING_DETAILS_REQUEST });
        
        let { data } = await axios.get(backendurl+`/api/v1/training/${slug}`, userid ,config);
        data.training.isbought=data.isproductbought;
        dispatch({
            type: TRAINING_DETAILS_SUCCESS,
            payload: data.training,
            //isbought : data.isproductbought
        });
    } catch (error) {
        dispatch({
            type: TRAINING_DETAILS_FAIL,
            payload: error.response.data.message,
        });
    }
};



// Get All Trainings ---TRAINING SLIDER
export const getSliderTrainings = () => async (dispatch) => {
    try {
        dispatch({ type: SLIDER_TRAININGS_REQUEST });

        const { data } = await axios.get(backendurl+`/api/v1/trainings/all`,config);

        dispatch({
            type: SLIDER_TRAININGS_SUCCESS,
            payload: data.trainings,
        });
    } catch (error) {
        dispatch({
            type: SLIDER_TRAININGS_FAIL,
            payload: error.response.data.message,
        });
    }
};

// Get All Trainings ---ADMIN
export const getAdminTrainings = () => async (dispatch) => {
    try {
        dispatch({ type: ADMIN_TRAININGS_REQUEST });

        const { data } = await axios.get(backendurl+'/api/v1/admin/trainings',config);

        dispatch({
            type: ADMIN_TRAININGS_SUCCESS,
            payload: data.trainings,
        });
    } catch (error) {
        dispatch({
            type: ADMIN_TRAININGS_FAIL,
            payload: error.response.data.message,
        });
    }
};

// New Training ---ADMIN
export const createTraining = (trainingData) => async (dispatch) => {
    try {
        dispatch({ type: NEW_TRAINING_REQUEST });
        
        const { data } = await axios.post(backendurl+"/api/v1/admin/training/new", trainingData, config);

        dispatch({
            type: NEW_TRAINING_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: NEW_TRAINING_FAIL,
            payload: error.response.data.message,
        });
    }
}

// Update Training ---ADMIN
export const updateTraining = (slug, trainingData) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_TRAINING_REQUEST });
        
        const { data } = await axios.put(backendurl+`/api/v1/admin/training/${slug}`, trainingData, config);

        dispatch({
            type: UPDATE_TRAINING_SUCCESS,
            payload: data.success,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_TRAINING_FAIL,
            payload: error.response.data.message,
        });
    }
}

// Delete Training ---ADMIN
export const deleteTraining = (slug) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_TRAINING_REQUEST });
        const { data } = await axios.delete(backendurl+`/api/v1/admin/training/${slug}`,config);

        dispatch({
            type: DELETE_TRAINING_SUCCESS,
            payload: data.success,
        });
    } catch (error) {
        dispatch({
            type: DELETE_TRAINING_FAIL,
            payload: error.response.data.message,
        });
    }
}



// Clear All Errors
export const clearErrors = () => (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
}