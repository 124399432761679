import {
    NEW_COOKIE_FAIL,
    NEW_COOKIE_REQUEST,
    NEW_COOKIE_SUCCESS,
    NEW_COOKIE_RESET,
    ALL_COOKIES_REQUEST,
    ALL_COOKIES_SUCCESS,
    ALL_COOKIES_FAIL,
    CLEAR_ERRORS
    
} from "../constants/utilConstants";


// New cookie Reducer
export const newCookieReducer = (state = { cookie: {} }, { type, payload }) => {
    switch (type) {
        case NEW_COOKIE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case NEW_COOKIE_SUCCESS:
            return {
                loading: false,
                success: payload.success,
                cookie: payload.cookie,
            };
        case NEW_COOKIE_FAIL:
            return {
                ...state,
                loading: false,
                error: payload,
            };
        case NEW_COOKIE_RESET:
            return {
                ...state,
                success: false,
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
}


export const getAllCookiesReducer = (state = { cookies: "" }, { type, payload }) => {

    switch (type) {
        case ALL_COOKIES_REQUEST:
            return {
                loading: true,
                cookies: "",
            };
        case ALL_COOKIES_SUCCESS:
            return {
                loading: false,
                cookies: payload.cookies,
            };
        case ALL_COOKIES_FAIL:
            return {
                loading: false,
                error: payload,
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
}