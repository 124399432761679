
import MetaData from '../Layouts/MetaData.jsx';


import React from 'react';


const Disclaimer = () => {
 
    
    return (
        
                <>
                    <MetaData title={"About Us"} />
                    
                    <main className="mt-12 sm:mt-0">

                    <div >
    
        <h1 className='text-4xl font-bold'> Disclaimer</h1>
     <h4 >INTRODUCTION</h4>
    <p >
       

The information provided by Lyriclious (“we,” “us” or “our”) on www.lyriclious.com (the “Site”) is for general informational purposes only. All information on the Site is provided in good faith, however we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability or completeness of any information on the Site.
<br/>
Under no circumstance shall we have any liability to you for any loss or damage of any kind incurred as a result of the use of the site  or reliance on any information provided on the site . Your use of the site  and your reliance on any information on the site is solely at your own risk.
</p><br/><br/>

<h4 >EXTERNAL LINKS DISCLAIMER FOR WEBSITE</h4>
<p >
The Sitemay contain or you may be sent through the Site  links to other websites or content belonging to or originating from third parties or links to websites and features in banners or other advertising. Such external links are not investigated, monitored, or checked for accuracy, adequacy, validity, reliability, availability or completeness by us.
<br/>
We do not warrant, endorse, guarantee, or assume responsibility for the accuracy or reliability of any information offered by third-party websites linked through the site or any website or feature linked in any banner or other advertising. We will not be a party to or in any way be responsible for monitoring any transaction between you and third-party providers of products or services.
</p><br/><br/>


<h4 >PROFESSIONAL DISCLAIMER FOR WEBSITE</h4>
<p >
The educational information is provided for general informational and educational purposes only and is not a substitute for professional advice.
<br/>
Accordingly, before taking any actions based upon such information, we encourage you to consult with the appropriate professionals.  The use or reliance of any information contained on this site is solely at your own risk.
<br/>
</p><br/>
<br/>

<h4 >AFFILIATES DISCLAIMER FOR WEBSITE</h4>
<p >
The Site  may contain links to affiliate websites, and we receive an affiliate commission for any purchases made by you on the affiliate website using such links.
<br/>
</p>
<br/><br/>

<h4 >TESTIMONIALS DISCLAIMER FOR WEBSITE</h4>
<p >
The Site may contain testimonials by users of our products and/or services. These testimonials reflect the real-life experiences and opinions of such users. However, the experiences are personal to those particular users, and may not necessarily be representative of all users of our products and/or services. We do not claim, and you should not assume, that all users will have the same experiences. Your individual results may vary.
<br/>
The testimonials on the Site are submitted in various forms such as text, audio and/or video, and are reviewed by us before being posted. They appear on the Site verbatim as given by the users, except for the correction of grammar or typing errors. Some testimonials may have been shortened for the sake of brevity where the full testimonial contained extraneous information not relevant to the general public.
<br/>
The views and opinions contained in the testimonials belong solely to the individual user and do not reflect our views and opinions. We are not affiliated with users who provide testimonials, and users are not paid or otherwise compensated for their testimonials.
<br/>
The testimonials on the Site are not intended, nor should they be construed, as claims that our products and/or services can be used to diagnose, treat, mitigate, cure, prevent or otherwise be used for any disease or medical condition. No testimonials have been clinically proven or evaluated.
       </p>

    
</div>



                    </main>
                </>
           
    );
};

export default Disclaimer;