import "./TrainingDetails.css";
import frontendDeveloperIcon from "../../assets/images/Training/frontendDeveloper.png"
import backendDeveloperIcon from "../../assets/images/Training/backendDeveloper.png"
import fullStackDeveloperIcon from "../../assets/images/Training/fullStackDeveloper.png"
import softwareDevelopmentIcon from "../../assets/images/Training/softwareDevelopment.png"
import { getSliderCareers,clearErrors } from '../../actions/careerAction';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, useContext } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

const CareerOptions = (props) => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    console.log(props);
    const { loading, careers, error } = useSelector((state) => state.careers);
    const getCareerName=(e)=>{
        return (careers.find((c) => c._id === e).career_name);
    }
    const getCareerImage=(e)=>{
       
        return (careers.find((c) => c._id === e).career_image.url);
    }
    const getCareerHeadline=(e)=>{
        return (careers.find((c) => c._id === e).headline);
    }
    const getCareerDescription=(e)=>{
        return (careers.find((c) => c._id === e).description);
    }
    


useEffect(() => {
    if(careers=="" || careers==null ){
        dispatch(getSliderCareers());
        }
    if (error) {
        enqueueSnackbar(error, { variant: "error" });
        dispatch(clearErrors());
    }
   
}, [dispatch,error, enqueueSnackbar]);
  
    return (
        <>
       <div className="mt-20 xs:ml-4 lg:mx-auto xs:w-[100%] lg:w-[80%]">
        <h1 className="xs:text-base xs:ml-4 lg:text-2xl font-medium">Top career options in {props.trainingname} </h1>
        <div className="flex mt-12 xs:w-[100%] lg:w-[97%] lg:ml-3 grid grid-cols-12 xs:gap-2 lg:gap-4">
        {careers.length && props.careerArray && props.careerArray.map((item, index) => {
            if(careers && careers.find((career) => career._id === item)){
          const { icon, label, desciption} = item;
          return (
            <div className="xs:px-2 lg:px-4 border-[1px] border-purple-300 rounded-lg xs:col-span-5 lg:col-span-3 pb-4 shadow-md shadow-purple-200">
                <span className="px-2 mx-2 "><img src={getCareerImage(item)} width="" /></span>
                <p className="mx-2  xs:text-sm font-medium my-auto">{getCareerName(item)}</p>
                <p className="mx-2 xs:mt-1 lg:mt-3 xs:text-sm xs:font-normal">{getCareerDescription(item)}</p>
            </div>

        )
          }})
          }

            
        </div>
        </div>
         
    

        </>
    );
};

export default CareerOptions;
