// import { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
import paymentProcessed from "../../assets/images/Payments/PaymentLeft.png";
import "./TrainingPayments.css";
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import offerLetter1 from "../../assets/images/Payments/offerLetter1.png";
import Certificate from "../../assets/images/Payments/Certificate.png";
import offerLetter2 from "../../assets/images/Payments/offerLetter2.png";

const TrainingPayments = ({}) => {
  
    return (
        <> 
                        {/* <!-- row --> */}
                      
                            <div className="grid grid-cols-12 gap-12 mt-10 h-auto" style={{  }}> 

                            <div className="shadow-2xl xs:ml-10 lg:col-start-2 xs:col-span-8 lg:col-span-4  bg-purple-200 rounded-md h-auto " style={{  }} >
                            <h1 className="xs:text-base lg:text-xl font-normal text-center xs:px-2 lg:px-16 mt-10 mb-4 tracking-wide">Welcome to Lyriclious Payment Page</h1>

                                <div className='rounded-full  bg-white  mx-auto xs:w-[120px] lg:w-[280px] xs:h-[120px] lg:h-[280px]' >
                                    <img src={paymentProcessed} className='xs:w-[100px] lg:w-[230px] mx-auto'  />
                                </div>
                                 
                                
                                <table className="table-auto mt-8 mb-[40px] mx-auto px-8 xs:w-[85%]lg:w-[70%]">
                                    <tbody>
                                        <tr className='xs:text-xs lg:text-sm font-normal '>
                                        <td className='text-left'>Order ID</td>
                                        <td className='text-right'>ORD23423420</td>
                                        </tr>

                                        <tr className='xs:text-xs lg:text-sm ont-normal'>
                                        <td className='text-left'>Student Email</td>
                                        <td className='text-right'>xyz@gmail.com</td>
                                        </tr>
                                        
                                        <tr className='xs:text-xs lg:text-sm font-normal '>
                                        <td className='text-left'>Referral Code</td>
                                        <td className='text-right'>
                                            <input type="text" />
                                        </td>
                                        </tr>
                                        <tr className='xs:text-xs lg:text-sm font-normal '>
                                        <td className='text-left'></td>
                                        <td className='text-right'>
                                        <a href='#' className='text-purple-800 xs:text-xs lg:text-sm font-thin'>See Available offers &#62;&#62;</a>                                        </td>
                                        </tr>

                                        <tr className='xs:text-xs lg:text-sm font-normal'>
                                        <td className='text-left'>Price</td>
                                        <td className='text-right'>1400</td>
                                        </tr>

                                         <tr className='xs:text-xs lg:text-sm font-normal'>
                                        <td className='text-left'>GST &#x28;18%&#41;</td>
                                        <td className='text-right'>1400</td>
                                        </tr>

                                        <tr>
                                            <td><hr width="100%" /></td>
                                            <td><hr width="100%" /></td>
                                        </tr>
                                        
                                        <tr className='xs:text-xs lg:text-sm font-normal'>
                                        <td className='text-left'>Total Amount</td>
                                        <td className='text-right'>1400</td>
                                        </tr>  
                                    </tbody>
                                 </table>
                                 <button className='rounded-md bg-purple-900 xs:w-[60%] lg:w-[70%] xs:h-[30px] lg:h-[45px] text-white checkoutBtn' > Checkout</button>
                                 <div className='text-center xs:text-xs lg:text-sm font-normal my-6 '> 
                                    <p>Note: Complete Payment by Clicking Checkout Button</p>
                                 </div>
                            </div>
                            
                            <div className="xs:ml-2 xs:col-span-9 lg:col-span-6  bg-white-500 shadow-2xl rounded-md" style={{  }} >
                            <h1 className="xs:text-xl lg:text-4xl font-medium  px-16 mt-10 mb-2 tracking-wide">What you will get?</h1>
                            <h1 className="xs:text-normal lg:text-2xl font-medium  px-16 mt-6 mb-4 tracking-wide">Full Stack Development</h1>
                            <button className="rounded-full border-[1px] border-black mb-12 ml-16 xs:px-2 lg:px-6 xs:py-1 lg:py-2 font-extralight text-sm">
                                      Training Program
                                      <CheckCircleOutlineIcon className="xs:ml-1 lg:ml-4 xs:font-[1px] lg:font-[2px]"/></button>

                            <div className='bg-purple-200 lg:py-4 '>

                                <span className='w-[40%] xs:ml-8 lg:ml-14 pt-4 pb-4 leading-relaxed border-r-[1px] border-gray-400' style={{ display: 'inline-block'}}>
                                    <h3 className='xs:text-normal lg:text-xl mb-2 ' >
                                        <span className="bg-white rounded-full p-[4px]"><TextSnippetIcon className="text-xs text-purple-800"></TextSnippetIcon></span>
                                        <span className="ml-2">Features</span></h3>
                                    <ul className='list-disc ml-14 text-gray-600 xs:text-sm lg:text-md font-normal'>
                                        <li>4 Weeks</li>
                                        <li>Online</li>
                                        <li>Batch: 1 May 2023</li>
                                        <li>Job Oriented</li>
                                    </ul>
                                </span>
                            
                                <span className='xs:pl-2 lg:pl-4 leading-relaxed border-l-[1px]border-gray-400' style={{ display: 'inline-block'}}>
                                    <h3 className='xs:text-normal lg:text-xl mb-2'>
                                    <span className="bg-white rounded-full p-[4px]"><ScatterPlotIcon className="text-xs text-purple-800"></ScatterPlotIcon></span>
                                       <span className="ml-2">Skills</span></h3>
                                    <ul className='list-disc ml-14 text-gray-600 xs:text-sm lg:text-md font-normal '>
                                        <li>4 Weeks</li>
                                        <li>Online</li>
                                        <li>Batch: 1 May 2023</li>
                                        <li>Job Oriented</li>
                                    </ul>
                                </span>
                            </div>

                            <h1 className="xs:text-xl lg:text-2xl font-medium  xs:px-10 lg:px-16 mt-6 mb-4 tracking-wide">Verified Documents :</h1>
                            <div > 
                                <span className='inline-block'>
                                    <img src={offerLetter1} className='inline-block xs:lg-[50px] lg:w-[130px] xs:h-[100px] lg:h-[180px] xs:ml-6 lg:ml-16'/>
                                    <p className='xs:ml-7 lg:ml-16 mt-6 mb-6 xs:text-xs lg:text-sm  font-light '>Offer Letter</p>
                                    </span>
                                <span className='inline-block'>
                                <img src={Certificate} className='inline-block xs:w-[150px] lg:w-[220px] xs:h-[100px] lg:h-[180px] xs:ml-4 lg:ml-12' />
                                <p className='xs:ml-6 lg:ml-12 mt-6 mb-6 xs:text-xs lg:text-sm font-light'>Certificate</p>
                                </span>

                                <span className='inline-block'>
                                <img src={offerLetter2} className='inline-block xs:w-[70px] lg:w-[130px] xs:h-[100px] lg:h-[180px] xs:ml-4 lg:ml-12' />
                                <p className='xs:ml-5 lg:ml-12 mt-6 mb-6 xs:text-xs lg:text-sm font-light'>Offer Letter</p>
                                </span>
                             </div>
                            
                            </div>

                            </div>

        </>
    )};

    export default TrainingPayments;
