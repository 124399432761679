export const ALL_TRAININGS_REQUEST = "ALL_TRAININGS_REQUEST";
export const ALL_TRAININGS_SUCCESS = "ALL_TRAININGS_SUCCESS";
export const ALL_TRAININGS_FAIL = "ALL_TRAININGS_FAIL";

export const TRAINING_DETAILS_REQUEST = "TRAINING_DETAILS_REQUEST";
export const TRAINING_DETAILS_SUCCESS = "TRAINING_DETAILS_SUCCESS";
export const TRAINING_DETAILS_FAIL = "TRAINING_DETAILS_FAIL";

export const ADMIN_TRAININGS_REQUEST = "ADMIN_TRAININGS_REQUEST";
export const ADMIN_TRAININGS_SUCCESS = "ADMIN_TRAININGS_SUCCESS";
export const ADMIN_TRAININGS_FAIL = "ADMIN_TRAININGS_FAIL";

export const NEW_TRAINING_REQUEST = "NEW_TRAINING_REQUEST";
export const NEW_TRAINING_SUCCESS = "NEW_TRAINING_SUCCESS";
export const NEW_TRAINING_RESET = "NEW_TRAINING_RESET";
export const NEW_TRAINING_FAIL = "NEW_TRAINING_FAIL";

export const UPDATE_TRAINING_REQUEST = "UPDATE_TRAINING_REQUEST";
export const UPDATE_TRAINING_SUCCESS = "UPDATE_TRAINING_SUCCESS";
export const UPDATE_TRAINING_RESET = "UPDATE_TRAINING_RESET";
export const UPDATE_TRAINING_FAIL = "UPDATE_TRAINING_FAIL";

export const DELETE_TRAINING_REQUEST = "DELETE_TRAINING_REQUEST";
export const DELETE_TRAINING_SUCCESS = "DELETE_TRAINING_SUCCESS";
export const DELETE_TRAINING_RESET = "DELETE_TRAINING_RESET";
export const DELETE_TRAINING_FAIL = "DELETE_TRAINING_FAIL";


export const SLIDER_TRAININGS_REQUEST = "SLIDER_TRAININGS_REQUEST";
export const SLIDER_TRAININGS_SUCCESS = "SLIDER_TRAININGS_SUCCESS";
export const SLIDER_TRAININGS_FAIL = "SLIDER_TRAININGS_FAIL";

export const REMOVE_TRAINING_DETAILS = "REMOVE_TRAINING_DETAILS";

export const CLEAR_ERRORS = "CLEAR_ERRORS";