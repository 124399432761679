export const ALL_TRAININGSCHEDULES_REQUEST = "ALL_TRAININGSCHEDULES_REQUEST";
export const ALL_TRAININGSCHEDULES_SUCCESS = "ALL_TRAININGSCHEDULES_SUCCESS";
export const ALL_TRAININGSCHEDULES_FAIL = "ALL_TRAININGSCHEDULES_FAIL";

export const TRAININGSCHEDULE_DETAILS_REQUEST = "TRAININGSCHEDULE_DETAILS_REQUEST";
export const TRAININGSCHEDULE_DETAILS_SUCCESS = "TRAININGSCHEDULE_DETAILS_SUCCESS";
export const TRAININGSCHEDULE_DETAILS_FAIL = "TRAININGSCHEDULE_DETAILS_FAIL";

export const ADMIN_TRAININGSCHEDULES_REQUEST = "ADMIN_TRAININGSCHEDULES_REQUEST";
export const ADMIN_TRAININGSCHEDULES_SUCCESS = "ADMIN_TRAININGSCHEDULES_SUCCESS";
export const ADMIN_TRAININGSCHEDULES_FAIL = "ADMIN_TRAININGSCHEDULES_FAIL";

export const NEW_TRAININGSCHEDULE_REQUEST = "NEW_TRAININGSCHEDULE_REQUEST";
export const NEW_TRAININGSCHEDULE_SUCCESS = "NEW_TRAININGSCHEDULE_SUCCESS";
export const NEW_TRAININGSCHEDULE_RESET = "NEW_TRAININGSCHEDULE_RESET";
export const NEW_TRAININGSCHEDULE_FAIL = "NEW_TRAININGSCHEDULE_FAIL";

export const UPDATE_TRAININGSCHEDULE_REQUEST = "UPDATE_TRAININGSCHEDULE_REQUEST";
export const UPDATE_TRAININGSCHEDULE_SUCCESS = "UPDATE_TRAININGSCHEDULE_SUCCESS";
export const UPDATE_TRAININGSCHEDULE_RESET = "UPDATE_TRAININGSCHEDULE_RESET";
export const UPDATE_TRAININGSCHEDULE_FAIL = "UPDATE_TRAININGSCHEDULE_FAIL";

export const DELETE_TRAININGSCHEDULE_REQUEST = "DELETE_TRAININGSCHEDULE_REQUEST";
export const DELETE_TRAININGSCHEDULE_SUCCESS = "DELETE_TRAININGSCHEDULE_SUCCESS";
export const DELETE_TRAININGSCHEDULE_RESET = "DELETE_TRAININGSCHEDULE_RESET";
export const DELETE_TRAININGSCHEDULE_FAIL = "DELETE_TRAININGSCHEDULE_FAIL";


export const SLIDER_TRAININGSCHEDULES_REQUEST = "SLIDER_TRAININGSCHEDULES_REQUEST";
export const SLIDER_TRAININGSCHEDULES_SUCCESS = "SLIDER_TRAININGSCHEDULES_SUCCESS";
export const SLIDER_TRAININGSCHEDULES_FAIL = "SLIDER_TRAININGSCHEDULES_FAIL";

export const REMOVE_TRAININGSCHEDULE_DETAILS = "REMOVE_TRAININGSCHEDULE_DETAILS";

export const CLEAR_ERRORS = "CLEAR_ERRORS";