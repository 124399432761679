import {TextField, Switch,FormControlLabel, FormGroup,Checkbox, FormControl, Radio, FormLabel, RadioGroup, } from '@mui/material';
import { useState, useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';
import { REMOVE_TRAININGSCHEDULE_DETAILS, UPDATE_TRAININGSCHEDULE_RESET } from '../../../constants/trainingscheduleConstants';
import { clearErrors, getTrainingscheduleDetails, updateTrainingschedule } from '../../../actions/trainingscheduleAction';
import BackdropLoader from '../../Layouts/BackdropLoader';
import MetaData from '../../Layouts/MetaData';
import { useTheme } from '@mui/material/styles';
import { weekdays } from '../../../utils/constants';
import { getAllUsers } from '../../../actions/userAction';
import { trainingScheduleConstants } from '../../../utils/constants';
import { getAdminTrainings } from '../../../actions/trainingAction';
import { set } from 'mongoose';




const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

const UpdateTrainingschedule = () => {

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const params = useParams();
    const { loading, trainingschedule, error } = useSelector((state) => state.trainingscheduleDetails);
    const { loading: updateLoading, isUpdated, error: updateError } = useSelector((state) => state.trainingschedule);
    const [status, setStatus] = useState([]);
    const [trainingAndInternship, setTrainingAndInternship]= useState("");
    const [instructor, setInstructor] = useState("");
    const [isTraineeAllowed,setIsTraineeAllowed]=useState(0);
    const [scheduledTime,setScheduledTime]=useState();
    const [startDate,setStartDate]=useState(new Date());
    const [weekGroup,setWeekGroup]=useState("");
    const { users } = useSelector((state) => state.users);
    const { trainings } = useSelector((state) => state.trainings);
    const [checkboxValues, setCheckboxValues] = useState([]);

        // const handleCheckboxChange = (event) => {
        //   const { value, checked } = event.target;
        //   if (checked) {
        //     setCheckboxValues((prevValues) => [...prevValues, value]);
        //   } else {
        //     setCheckboxValues((prevValues) =>
        //       prevValues.filter((item) => item !== value)
        //     );
        //   }
        //   console.log(checkboxValues && checkboxValues);
        // };
    const theme = useTheme();
    const newTrainingscheduleSubmitHandler = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.set("instructor", (users.find((user) => user.user_name === instructor))._id);
        formData.set("trainingandinternship", (trainings.find((training) => training.training_name === trainingAndInternship))._id);
        formData.set("status", status);
        formData.set("scheduled_time",scheduledTime);
        formData.set("start_date",startDate);
        
        checkboxValues.forEach((c) => {  
            formData.append("scheduled_days", c);  
    });
        formData.set("is_trainee_allow_message",isTraineeAllowed);
        dispatch(updateTrainingschedule(trainingschedule.slug, formData));
    }

    const trainingscheduleslug= params.id;
   
    const handleWeekGroupChange= (e) => {
        setWeekGroup(e)
        if(e=="alldays"){
            setCheckboxValues(["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"]);  
        }
        if(e=="weekends"){
            setCheckboxValues(["Sunday","Saturday"]); 
        }
        if(e=="weekdays"){
            setCheckboxValues(["Monday","Tuesday","Wednesday","Thursday","Friday"]); 
        }
        
    }
    useEffect(() => {
        
        if (trainingschedule && trainingschedule.slug !== trainingscheduleslug) {
            dispatch(getTrainingscheduleDetails(trainingscheduleslug));
        } else {
        if(users=="" || trainings=="" || users==null || trainings==null){
         dispatch(getAllUsers());
         dispatch(getAdminTrainings());
        }
        else {
        setInstructor((users.find((user) => user._id === trainingschedule.instructor)).user_name);
        setTrainingAndInternship((trainings.find((training)=>training._id===trainingschedule.trainingandinternship).training_name));
        setStatus(trainingschedule.status);
        setCheckboxValues(trainingschedule.scheduled_days);
        setScheduledTime(trainingschedule.scheduled_time);
        setIsTraineeAllowed(trainingschedule.is_trainee_allow_message);
        setStartDate(trainingschedule.start_date.substr(0,10));
        
        
         if(trainingschedule.scheduled_days.length==2 && trainingschedule.scheduled_days.includes("Sunday","Saturday")){
            setWeekGroup("weekends");
            
        }else if(trainingschedule.scheduled_days.includes("Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday")){
            setWeekGroup("alldays");
            
        }
        else{
            setWeekGroup("weekdays");
            
        }


        }
         }

        if (error) {
            enqueueSnackbar(error, { variant: "error" });
            dispatch(clearErrors());
        }
        if (updateError) {
            enqueueSnackbar(updateError, { variant: "error" });
            dispatch(clearErrors());
        }
        if (isUpdated) {
            enqueueSnackbar("Trainingschedule Updated Successfully", { variant: "success" });
            dispatch({ type: UPDATE_TRAININGSCHEDULE_RESET });
            dispatch({ type: REMOVE_TRAININGSCHEDULE_DETAILS });
            navigate('/admin/trainingschedules');
        }
        
    }, [dispatch, error, updateError, isUpdated, trainingschedule, trainingscheduleslug, navigate, enqueueSnackbar]); //trainingschedule is removed due to continuous refresh


    
    
    return (
        <>
            <MetaData 
            title="Admin: Update Trainingschedule | Lyriclious" 
            />

            {loading && <BackdropLoader />}
            {updateLoading && <BackdropLoader />}
            <form onSubmit={newTrainingscheduleSubmitHandler} encType="multipart/form-data" className="flex flex-col sm:flex-row bg-white rounded-lg shadow p-4" id="mainform">

                <div className="flex flex-col gap-3 m-2 sm:w-1/2">
                 
                <TextField
                            label="Instructor"
                            select
                            fullWidth
                            variant="outlined"
                            size="small"
                            required
                            value={instructor}
                            //value={(users.length && instructor && users.find((user) => user._id === instructor)).user_name}
                           onChange={(e) => setInstructor(e.target.value)}
                        >
                            {users.length && users.map((el, i) => (
                                (el.role=="admin" || el.role=="instructor")?<MenuItem value={el.user_name} key={el._id}>
                                    {el.user_name}
                                </MenuItem>:""
                            ))}
                        </TextField>
                        <TextField
                            label="Training & Internship"
                            select
                            fullWidth
                            variant="outlined"
                            size="small"
                            required
                            value={trainingAndInternship}
                            //value={(users.length && instructor && users.find((user) => user._id === instructor)).user_name}
                           onChange={(e) => setTrainingAndInternship(e.target.value)}
                        >
                            {trainings.length && trainings.map((el, i) => (
                                <MenuItem value={el.training_name} key={el.training_name}>
                                    {el.training_name}
                                </MenuItem>
                            ))}
                        </TextField>
                    <div className="flex justify-between">
                    </div>
                    <div className="flex justify-between gap-4">  
                        <TextField
                            label="Status"
                            select
                            fullWidth
                            variant="outlined"
                            size="small"
                            required
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                        >
                            {trainingScheduleConstants.map((el, i) => (
                                <MenuItem value={el} key={i}>
                                    {el}
                                </MenuItem>
                            ))}
                        </TextField>

                        </div>
                        <TextField
        id="date"
        label="Start Date"
        type="date"
        //defaultValue="2017-05-24"
        value={startDate}
        onChange={(e) => setStartDate(e.target.value)}
        InputLabelProps={{
        //min: "2019-01-24", max: "2020-05-31",
        shrink: true,
        
        }}
      />
                        <TextField
                            label="Schedule Time"
                            select
                            fullWidth
                            variant="outlined"
                            size="small"
                            required
                            value={Number(scheduledTime)}
                            onChange={(e) => setScheduledTime(e.target.value)}
                        >
                            {new Array(23).fill().map((_, i) => (
                                <MenuItem value={i+1} key={i+1}>
                                    {i+1}
                                </MenuItem>
                            ))}
                        </TextField>
        <FormControl component="fieldset" >
        <FormLabel component="legend">Scheduled Days</FormLabel>
        <FormGroup row>
            {weekdays.map((week,i)=>(
            <FormControlLabel
            control={
              <Checkbox
                
                value={week}
                disabled
                checked={checkboxValues.includes(week)}
                //onChange={handleCheckboxChange}
                
                name={week}
                color="secondary"
              />
            }
            label={week}
            labelPlacement="bottom"
          />
            ))}
        </FormGroup>
        {/* <FormHelperText>
          Ice cream may be harmful, consult your doctor.
        </FormHelperText> */}
      </FormControl>



<FormControl component="fieldset">
        <FormLabel component="legend">Schedule Days</FormLabel>
        <RadioGroup aria-label="Days" name="Days" value={weekGroup}
        onChange={(e) => handleWeekGroupChange((e.target.value))}
        row>
          <FormControlLabel value="alldays" control={<Radio color="primary" />} label="All Days" labelPlacement="bottom" />
          <FormControlLabel value="weekdays" control={<Radio color="primary" />} label="Weekdays" labelPlacement="bottom" />
          <FormControlLabel value="weekends" control={<Radio color="primary" />} label="Weekends" labelPlacement="bottom" />
          
        </RadioGroup>
      </FormControl>
                        
                        <div className="flex justify-between gap-4">
                        <FormControlLabel 
                        control={<Switch 
                        checked={isTraineeAllowed}
                        //value={isTraineeAllowed}
                        onChange={(e) => setIsTraineeAllowed(Number(e.target.checked))}
                        color="secondary"
                         />} 
                        label="Trainee Message Allowed" 
                        labelPlacement="start"
                        
                        />
                        
                       
                        
                    </div>

                 


                   

                      

                    

                </div>

                <div className="flex flex-col gap-2 m-2 sm:w-1/2">
                    <div className="flex justify-end">
                        <input form="mainform" type="submit" className="bg-purple-900 uppercase w-1/3 p-3 text-white font-medium rounded shadow hover:shadow-lg cursor-pointer" value="Submit" />
                    </div>

                </div>

            </form>
        </>
    );
};

export default UpdateTrainingschedule;