import {
    ADMIN_TRAININGS_FAIL,
    ADMIN_TRAININGS_REQUEST,
    ADMIN_TRAININGS_SUCCESS,
    ALL_TRAININGS_FAIL,
    ALL_TRAININGS_REQUEST,
    ALL_TRAININGS_SUCCESS,
    CLEAR_ERRORS,
    DELETE_TRAINING_FAIL,
    DELETE_TRAINING_REQUEST,
    DELETE_TRAINING_RESET,
    DELETE_TRAINING_SUCCESS,
    NEW_TRAINING_FAIL,
    NEW_TRAINING_REQUEST,
    NEW_TRAINING_RESET,
    NEW_TRAINING_SUCCESS,
    TRAINING_DETAILS_FAIL,
    TRAINING_DETAILS_REQUEST,
    TRAINING_DETAILS_SUCCESS,
    UPDATE_TRAINING_FAIL,
    UPDATE_TRAINING_REQUEST,
    UPDATE_TRAINING_RESET,
    UPDATE_TRAINING_SUCCESS,
    REMOVE_TRAINING_DETAILS,
    SLIDER_TRAININGS_FAIL,
    SLIDER_TRAININGS_REQUEST,
    SLIDER_TRAININGS_SUCCESS,
} from "../constants/trainingConstants";

export const trainingsReducer = (state = { trainings: [] }, { type, payload }) => {

    switch (type) {
        case ALL_TRAININGS_REQUEST:
        case ADMIN_TRAININGS_REQUEST:
        case SLIDER_TRAININGS_REQUEST:
            return {
                loading: true,
                trainings: [],
            };
        case ALL_TRAININGS_SUCCESS:
            return {
                loading: false,
                trainings: payload.trainings,
                trainingsCount: payload.trainingsCount,
                resultPerPage: payload.resultPerPage,
                filteredTrainingsCount: payload.filteredTrainingsCount,
            };
        case ADMIN_TRAININGS_SUCCESS:
        case SLIDER_TRAININGS_SUCCESS:
            return {
                loading: false,
                trainings: payload,
            };
        case ALL_TRAININGS_FAIL:
        case ADMIN_TRAININGS_FAIL:
        case SLIDER_TRAININGS_FAIL:
            return {
                loading: false,
                error: payload,
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
}

export const trainingDetailsReducer = (state = { training: {} }, { type, payload }) => {

    switch (type) {
        case TRAINING_DETAILS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case TRAINING_DETAILS_SUCCESS:
            return {
                loading: false,
                training: payload,
            };
        case TRAINING_DETAILS_FAIL:
            return {
                loading: false,
                error: payload,
            };
        case REMOVE_TRAINING_DETAILS:
            return {
                ...state,
                training: {},
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
}



// New Training Reducer
export const newTrainingReducer = (state = { training: {} }, { type, payload }) => {
    switch (type) {
        case NEW_TRAINING_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case NEW_TRAINING_SUCCESS:
            return {
                loading: false,
                success: payload.success,
                training: payload.training,
            };
        case NEW_TRAINING_FAIL:
            return {
                ...state,
                loading: false,
                error: payload,
            };
        case NEW_TRAINING_RESET:
            return {
                ...state,
                success: false,
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
}

// Training Reducer
export const trainingReducer = (state = {}, { type, payload }) => {
    switch (type) {
        case UPDATE_TRAINING_REQUEST:
        case DELETE_TRAINING_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_TRAINING_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdated: payload,
            };
        case DELETE_TRAINING_SUCCESS:
            return {
                ...state,
                loading: false,
                isDeleted: payload,
            };
        case UPDATE_TRAINING_FAIL:
        case DELETE_TRAINING_FAIL:
            return {
                ...state,
                loading: false,
                error: payload,
            };
        case UPDATE_TRAINING_RESET:
            return {
                ...state,
                isUpdated: false,
            };
        case DELETE_TRAINING_RESET:
            return {
                ...state,
                isDeleted: false,
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
}

