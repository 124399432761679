import { useDispatch, useSelector } from 'react-redux';
import ProfileIcon from '@mui/icons-material/Person';
import CertificateIcon from '@mui/icons-material/Payment';
import InventoryIcon from '@mui/icons-material/Inventory';
import GroupIcon from '@mui/icons-material/Group';
import ReviewsIcon from '@mui/icons-material/Reviews';
import AddBoxIcon from '@mui/icons-material/AddBox';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import CloseIcon from '@mui/icons-material/Close';
import Avatar from '@mui/material/Avatar';
import { Link, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { logoutUser } from '../../actions/userAction';
import "./Sidebar.css"
import axios from "axios";
import { baseurl, backendurl } from "../../utils/constants";

import React, { useState } from 'react'


const Sidebar = ({ activeTab , setToggleSidebar}) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const config = {
        headers: {
            "Content-Type": "application/json",
            "Accept":"application/json"
        },
        withCredentials : true
    }
    const { user } = useSelector(state => state.user);
    const [trainingandInternshipCount, setTrainingandInternshipCount]=useState(0);
const navMenu = [
    {
        icon: <ProfileIcon />,
        label: "Profile",
        count: "",
        ref: "/account",
    },
    {
        icon: <CertificateIcon />,
        label: "Certificates",
        count: "",
        ref: "/profile/mycertificates",
    },
    {
        icon: <InventoryIcon />,
        label: "Trainings",
        count: "",
        ref: "/profile/mytrainings",
    },
    {
        icon: <AddBoxIcon />,
        label: "Trainings & Internships",
        count: trainingandInternshipCount,
        ref: "/profile/trainingandinternships",
    },
    
    {
        icon: <AddBoxIcon />,
        label: "Applied Internships",
        count: "",
        ref: "/profile/appliedinternships",
    },
    {
        icon: <AddBoxIcon />,
        label: "Applied Jobs",
        count: "",
        ref: "/profile/appliedjobs",
    },
    {
        icon: <GroupIcon />,
        label: "Mock Interviews",
        count: "",
        ref: "/profile/mockinterviews",
    },
    {
        icon: <GroupIcon />,
        label: "Assessments",
        count: "",
        ref: "/profile/assessments",
    },
    {
        icon: <ReviewsIcon />,
        label: "My Notes",
        count: "",
        ref: "/profile/mynotes",
    },
    {
        icon: <AccountBoxIcon />,
        label: "Give Feedback",
        count: "",
        ref: "/profile/givefeedback",
    },
    {
        icon: <AccountBoxIcon />,
        label: "Refferal Earnings",
        count: "",
        ref: "/profile/refferalearning",
    },
    {
        icon: <AccountBoxIcon />,
        label: "Settings",
        count: "",
        ref: "/account",
    },
    {
        icon: <LogoutIcon />,
        label: "Logout",
    },
];

    const handleLogout = () => {
        dispatch(logoutUser());
        enqueueSnackbar("Logout Successfully", { variant: "success" });
        navigate("/login");
    }
    React.useEffect(() => {
        if(user)
         axios.get(backendurl+'/api/v1/profile/trainingandinternships',config).then((response) => {  
              //console.log(prodArr)
              setTrainingandInternshipCount(response.data.allproducts.length);
        })

   
       
      }, []);
    return (
        <aside className="sidebar z-10 sm:z-0 block min-h-screen fixed left-0 pb-14 max-h-screen w-3/4 sm:w-1/5 bg-purple-900 text-white overflow-x-hidden">
            <div className="flex items-center gap-3 bg-purple-800/75 p-2 rounded-lg shadow-lg my-4 mx-3.5">
                <Avatar
                    alt="Avatar"
                    src={user.avatar?(user.avatar.url):("")}
                />
                <div className="flex flex-col gap-0">
                    <span className="font-medium text-lg">{user.name}</span>
                    <span className="text-gray-300 text-sm">{user.email}</span>
                </div>
                <button onClick={()=>setToggleSidebar(false)} className="sm:hidden bg-purple-800 ml-auto rounded-full w-10 h-10 flex items-center justify-center">
                    <CloseIcon/>
                </button>
            </div>

            <div className="flex flex-col w-full gap-0 my-8">
                {navMenu.map((item, index) => {
                    const { icon, label,count, ref } = item;
                    return (
                        <>
                            {label === "Logout" ? (
                                <button onClick={handleLogout} className="hover:bg-purple-800 flex gap-3 items-center py-3 px-4 font-normal text-sm">
                                    <span>{icon}</span>
                                    <span>{label}</span>
                                </button>
                            ) : (
                                <Link to={ref} className={`${activeTab === index ? "bg-purple-800" : "hover:bg-purple-800"} flex gap-3 items-center py-3 px-4 font-normal text-sm`}>
                                    <span>{icon}</span>
                                    <span>{label}</span>
                                    <span className='txtrounded'>{count}</span>
                                </Link>
                            )}
                        </>
                    )
                }
                )}
            </div>

            
        </aside>    );
};

export default Sidebar;
