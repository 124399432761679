import axios from "axios";
import { backendurl, baseurl } from "../utils/constants";
import {
    NEW_COOKIE_FAIL,
    NEW_COOKIE_REQUEST,
    NEW_COOKIE_SUCCESS,
    ALL_COOKIES_REQUEST,
    ALL_COOKIES_SUCCESS,
    ALL_COOKIES_FAIL,
    CLEAR_ERRORS
} from "../constants/utilConstants";
const config = {
    headers: {
        "Content-Type": "application/json",
        "Accept":"application/json"
    },
    withCredentials : true
}



// New Cookie 
export const createCookie = (cookieData) => async (dispatch) => {
    try {
        dispatch({ type: NEW_COOKIE_REQUEST });
        
        const { data } = await axios.post(backendurl+"/api/v1/addremovecookie", cookieData, config);

        dispatch({
            type: NEW_COOKIE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: NEW_COOKIE_FAIL,
            payload: error.response.data.message,
        });
    }
}


// Get  All Cookies
export const getAllCookies = () => async (dispatch) => {

    
    try {
        dispatch({ type: ALL_COOKIES_REQUEST });
        const { data } = await axios.get(backendurl+`/api/v1/getallcookies`,config);

        dispatch({
            type: ALL_COOKIES_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: ALL_COOKIES_FAIL,
            payload: error.response.data.message,
        });
    }
};

// Clear All Errors
export const clearErrors = () => (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
}