import axios from "axios"
import { ADD_TO_CART, EMPTY_CART, REMOVE_FROM_CART, SAVE_SHIPPING_INFO } from "../constants/cartConstants";
import { backendurl } from "../utils/constants";
// add to cart
const config = {
    headers: {
        "Content-Type": "application/json",
        "Accept":"application/json"
    },
    withCredentials : true
}

export const addItemsToCart = (type , id, quantity = 1) => async (dispatch, getState) => {
    const { data } = await axios.get(backendurl+`/api/v1/`+type+`/${id}`,config);
    if(type=="product"){
        dispatch({
            type: ADD_TO_CART,
            payload: {
                product: data.product._id,
                name: data.product.name,
                order_type: "product",
                seller: data.product.brand.name,
                price: data.product.price,
                offerPrice: data.product.cuttedPrice,
                image: data.product.images[0].url,
                stock: data.product.stock,
                quantity,
            },
        });
    }
    if(type=="trainingschedule"){
        dispatch({
            type: ADD_TO_CART,
            payload: {
                product: data.trainingschedule._id,
                order_type: "trainingschedule",
                product_status:"ENROLLED",
                name: data.maintraining.training_name,
                product_code: data.trainingschedule.slug,
                price: data.maintraining.price,
                offerPrice: data.maintraining.offerPrice,
                image: data.maintraining.image.url,
                slug:data.trainingschedule.slug,
                stock: 1,
                quantity,
            },
        });
    }

    localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
}

// remove cart item
export const removeItemsFromCart = (id) => async (dispatch, getState) => {

    dispatch({
        type: REMOVE_FROM_CART,
        payload: id,
    });

    localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
}

// empty cart
export const emptyCart = () => async (dispatch, getState) => {

    dispatch({ type: EMPTY_CART });

    localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
}

// save shipping info
export const saveShippingInfo = (data) => async (dispatch) => {

    dispatch({
        type: SAVE_SHIPPING_INFO,
        payload: data,
    });

    localStorage.setItem('shippingInfo', JSON.stringify(data));
}