import { useEffect, useState } from 'react';
import Sidebar from '../../Sidebar';
import { useSelector } from 'react-redux';
import {  useNavigate, useParams } from 'react-router-dom';
import Loader from '../../../Layouts/Loader';
import MetaData from '../../../Layouts/MetaData';
import axios from "axios";
import React from "react";
import { baseurl, backendurl } from "../../../../utils/constants";
import CircleIcon from '@mui/icons-material/Circle';
import EditIcon from '@mui/icons-material/Edit';
import DurationIcon from '@mui/icons-material/AccessTime';
import CertificateIcon from '@mui/icons-material/VerifiedOutlined';
import MiniProjectIcon from '@mui/icons-material/FeaturedPlayListOutlined';
//import MajorProjectIcon from '@mui/icons-material/ChromeReaderModeOutlined';
import { daystoGeneralForm, getFormatedDate } from '../../../../utils/functions';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';


const TrainingandInternshipScheduleDetails = ({ activeTab, children }) => {

    const navigate = useNavigate();
    const params = useParams();
    //console.log(params.slug)
    const { user, loading, isAuthenticated } = useSelector(state => state.user)
    const config = {
        headers: {
            "Content-Type": "application/json",
            "Accept":"application/json"
        },
        withCredentials : true
    }
    useEffect(() => {
        if (isAuthenticated === false) {
            navigate("/login")
        }
    }, [isAuthenticated, navigate]);

    

    const [onMobile, setOnMobile] = useState(false);
    const [moreLoading,setMoreLoading]=useState(true);
    const [boughtTrainings, setBoughtTrainings]=useState([]);
    useEffect(() => {
        if (window.innerWidth < 600) {
            setOnMobile(true);
        }
    }, [])
  
    useEffect(() => {
        async function fetchData() {
            try {
                await axios.get(backendurl+'/api/v1/profile/trainingandinternships',config).then((response) => {  
             
                    let allprodobj=response.data.allproducts.filter(obj => {
                        return obj.trainingscheduledetails.slug === params.slug
                      })
                      
                      setBoughtTrainings(allprodobj[0])
                      setMoreLoading(allprodobj.length?false:true);
                    });
                      
            } catch (e) {
                console.error(e);
            }
        };
        fetchData();
    }, [user,loading]);

  
    

    return (
        <>
            <MetaData title="Enrolled Training and Internships" />
            {moreLoading ? <Loader /> :
                <>
                {console.log(boughtTrainings)}
                   
    <main className="w-full mt-12 sm:mt-0 grid grid-cols-12">
        {/* Training and Internship / instructor / meeting details */}
    <div className='xs:col-span-12 md:col-span-4 lg:col-span-3 p-6'>
       
    <div className="lg:col-span-3 sm:col-span-6 xs:col-span-6 w-300 border-[1px] border-gray-300  transition ease-in-out delay-10 hover:shadow-md hover:-translate-y-0.5 duration-300 text-md" >
  <img src={boughtTrainings.trainingdetails.image.url} className="w-full border-t-[1px] border-gray-200 rounded-t-lg" alt={boughtTrainings.trainingdetails.training_name}/>
   
  <div className="xs:p-2 lg:p-6 pb-0">
    <h2 className="xs:text-xl lg:text-2xl font-normal xs:truncate">{boughtTrainings.trainingdetails.training_name}</h2>
    <p className="bg-gradient-to-r from-purple-900/30 to-gray-50/0 text-purple-900 px-2 text-xs py-0.5 my-3">Status : {boughtTrainings.trainingscheduledetails.status} </p>
    <span className="my-4 xs:text-xs lg:text-sm font-small text-gray-600 leading-7"><MiniProjectIcon fontSize='small' />  <span className="ml-2">Code : {boughtTrainings.trainingscheduledetails.slug}</span> </span><br />
    <span className="my-4 xs:text-xs lg:text-sm font-small text-gray-600 leading-7"><DurationIcon fontSize='small'/>  <span className="ml-2"> Duration : {boughtTrainings.trainingdetails.duration.d_num+" "+boughtTrainings.trainingdetails.duration.d_type}</span>  </span><br />
    <span className="my-4 xs:text-xs lg:text-sm font-small text-gray-600 leading-7"><MiniProjectIcon fontSize='small' />  <span className="ml-2">Days : {daystoGeneralForm(boughtTrainings.trainingscheduledetails.scheduled_days)}</span> </span><br />
    <span className="my-4 xs:text-xs lg:text-sm font-small text-gray-600 leading-7"><MiniProjectIcon fontSize='small' />  <span className="ml-2">Days : {boughtTrainings.trainingscheduledetails.scheduled_time}</span> </span><br />
    
    <span className="my-4 xs:text-xs lg:text-sm font-small text-gray-600 leading-7"><CertificateIcon  fontSize='small'/>  <span className="ml-2">Start Date : {getFormatedDate(boughtTrainings.trainingscheduledetails.start_date)}</span>  </span>

    
<br />
{/* <a href={baseurl+"/trainingandinternship/"+boughtTrainings.trainingdetails.slug} className="text-blue-500 leading-[3rem] xs:text-xs lg:text-sm font-small">Know More &#62;</a> */}
  </div>

  <div className="w-full mb-0 text-center p-2 xs:text-lg border-2 border-gray-400 lg:text-xl font-small text-gray-600 leading-7">Status : {boughtTrainings.trainingscheduledetails.status}</div> 


</div>
<br />

<div className="w-full bg-[#52AB51] mb-0 text-center rounded-xl p-3 xs:text-md  lg:text-lg font-small text-gray-100 leading-7">JOIN TRAINING SESSION </div> 
<div className='border-2 px-2 py-2 border-gray-400 w-[200px] h-[200px] mx-auto my-4 rounded-full'>
    <img src={boughtTrainings.instructordetails.avatar.url} className='mx-auto my-auto w-full rounded-full'></img>
</div>

<div className="w-full mb-0 text-center rounded-xl p-3 xs:text-md lg:text-lg font-small text-gray-900 leading-7">
    {boughtTrainings.instructordetails.user_name} 
    <p className='xs:text-sm lg:text:lg'>&#40; Instructor 	&#41;</p>

     </div> 


</div>

{/* Chatbox */}
<div className='xs:col-span-12 md:col-span-8 lg:col-span-6 p-7'>
<div className='mt-2'>
<div className="xs:p-2 lg:p-4 w-[180px] text-center pb-0 text-white bg-purple-900 absolute z-10 top-[60px] left-[360px]" data-tip="hello">
    Important Note :
</div>
<div className="xs:p-2 lg:p-6 pb-0  shadow-lg shadow-gray-500/50 relative ">
<EditIcon className='float-right'></EditIcon>
   <p className='pt-8'>Documentation Link - Documentation Link - Documentation Link </p>
    <p>Software Links</p>
</div>
</div>

<div className="xs:p-2 lg:p-6 pb-0  bg-gray-200 mt-4 inline-block box-border w-full">
    {/* messages div starts here */}

<div className=' xs:p-1 lg:p-2 '>
<div className='border-2  border-gray-400 w-[50px] h-[50px] my-4 mx-2 rounded-full col-span-2 inline-block'>
    <img src={boughtTrainings.instructordetails.avatar.url} className='mx-auto my-auto w-full rounded-full ' />
    </div>
    <div className='inline-block'>
    <h3 className='text-xs text-purple-900 py-2 font-medium'>Nishant Kumar Singh &#40;Instructor&#41;</h3>
    <div className='xs:w-[60%] lg:w-[80%] bg-purple-200 xs:p-1 lg:p-2 rounded-md'>
   <p> Hi, Good Morning Everyone...!
    From Today We will began </p>
    <p className='text-gray-500 text-xs p-2 bottom-0 right-0'>2:11 am</p>
    </div>
 </div>
 </div>  



<div className=' xs:p-1 lg:p-2'>
    
<div className='chat-bubble border-2 border-gray-400 w-[50px] h-[50px] my-4 mx-2 rounded-full col-span-2 inline-block'>
    <img src={boughtTrainings.instructordetails.avatar.url} className='mx-auto my-auto w-full rounded-full ' />
    </div>
    <div className='inline-block '>
    <h3 className='text-xs text-gray-900 py-2 font-medium'>Shubhangi Singh </h3>
    <div className='xs:w-[60%] lg:w-[80%]  bg-green-200 xs:p-1 lg:p-2 rounded-md'>
    <p> Hi, Good Morning Everyone...! </p>
    <p className='text-gray-500 text-xs p-2 bottom-0 right-0'>2:11 am</p>
    </div>
</div>
  </div>  

 

<div className=' xs:p-1 lg:p-2 inline-block float-right'>
<h3 className='text-xs text-gray-900 p-2 font-medium'>You </h3>
        <div className='inline-block'>
        
        <div className='xs:w-[60%] lg:w-[80%] bg-green-200 xs:p-1 lg:p-2 rounded-md'>
       <p> Hi, Good Morning Everyone...! </p>
        <p className='text-gray-500 text-xs p-2 bottom-0 right-0'>2:11 am</p>
        </div>
    </div>
      </div>  

  
  {/* message div ends here */}
  </div>
  <div className='grid grid-cols-12 shadow hover:shadow-lg'>
  <input type="text" placeholder="Start Typing....."  className="col-span-11 inline-block outline-none border-0 rounded p-2 w-full " />
  <PlayCircleIcon className='col-span-1 text-purple-900 inline-block my-auto' fontSize='large'></PlayCircleIcon>
  </div>
</div>

<div className='xs:col-span-12 lg:col-span-3 grid grid-cols-8'>
{/* Attendence */}
<div className='xs:col-span-8 md:col-span-4 lg:col-span-8 w-full p-4'>
<h2 className="xs:text-lg lg:text-xl font-normal xs:truncate">Attendance</h2>
<div className="xs:p-2 lg:p-6 pb-0 shadow-lg shadow-gray-500/50">

<div className="w-full bg-gray-200 rounded-full h-[15px] dark:bg-gray-300">
  <div className="bg-[#52AB51] h-[15px] rounded-full w-[45%] " ><p className='ml-2 xs:text-xs lg:text:sm text-white'>70%</p></div>
  </div>
  <div className='p-5 '>
    <span className='inline-block w-1/2'>
        <p className='py-2'><CircleIcon className='text-[#52AB51] '  fontSize='small'/> 23-08-2023</p>
        <p className='py-2'><CircleIcon className='text-[#EE5467] '  fontSize='small'/> 23-08-2023</p>
        <p className='py-2'><CircleIcon className='text-[#52AB51] '  fontSize='small'/> 23-08-2023</p>
        <p className='py-2'><CircleIcon className='text-[#52AB51] '  fontSize='small'/> 23-08-2023</p>

    </span>
    <span className='inline-block w-1/2'>
    <p className='py-2'><CircleIcon className='text-[#52AB51] '  fontSize='small'/> 23-08-2023</p>
    <p className='py-2'><CircleIcon className='text-[#EE5467] '  fontSize='small'/> 23-08-2023</p>
    <p className='py-2'><CircleIcon className='text-[#EE5467] '  fontSize='small'/> 23-08-2023</p>
    <p className='py-2'><CircleIcon className='text-[#52AB51] '  fontSize='small'/> 23-08-2023</p>

    </span>
  </div>


</div>

</div>
{/* Other Trainees */}
<div className='xs:col-span-8 md:col-span-4 lg:col-span-8 w-full p-5'>
<div className="w-full mb-0  rounded-xl p-3 xs:text-lg lg:text-xl font-small text-gray-900 leading-7">
        Messages Allowed ? No 
        <p className='xs:text-sm lg:text-md text-gray-400'>&#40; Ask your Instructor to Enable &#41;</p>
    </div>
    <h3 className='text-md px-4 text-gray-800'>Other Trainees</h3>
    <div className="xs:p-2 lg:p-6 pb-0 shadow-lg shadow-gray-500/50 grid grid-cols-7">
    <div className='border-2  border-gray-400 w-[50px] h-[50px] my-4 rounded-full col-span-2'>
    <img src={boughtTrainings.instructordetails.avatar.url} className='mx-auto my-auto w-full rounded-full ' /></div>
    <span className='inline-block col-span-5 my-auto'>Shubhangi Sonker</span>

    <div className='border-2  border-gray-400 w-[50px] h-[50px] my-4 rounded-full col-span-2'>
    <img src={boughtTrainings.instructordetails.avatar.url} className='mx-auto my-auto w-full rounded-full ' /></div>
    <span className='inline-block col-span-5 my-auto'>Shubhangi Sonker</span>


    <div className='border-2  border-gray-400 w-[50px] h-[50px] my-4 rounded-full col-span-2'>
    <img src={boughtTrainings.instructordetails.avatar.url} className='mx-auto my-auto w-full rounded-full ' /></div>
    <span className='inline-block col-span-5 my-auto'>Shubhangi Sonker</span>
    </div>

</div>
                    </div>
                       
                    </main>
                </>
            }
        </>
    );
};

export default TrainingandInternshipScheduleDetails;
;
