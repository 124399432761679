// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bannerImg {
    border-radius: 50%;
}

.checkoutBtn{
    margin: 0 auto;
    display: block;
    
}

.vl {
    border-left: 6px solid green;
    height: 500px;
  }`, "",{"version":3,"sources":["webpack://./src/components/Payments/TrainingPayments.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,cAAc;;AAElB;;AAEA;IACI,4BAA4B;IAC5B,aAAa;EACf","sourcesContent":[".bannerImg {\n    border-radius: 50%;\n}\n\n.checkoutBtn{\n    margin: 0 auto;\n    display: block;\n    \n}\n\n.vl {\n    border-left: 6px solid green;\n    height: 500px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
