import axios from "axios";
import {
    ALL_CAREERS_FAIL,
    ALL_CAREERS_REQUEST,
    ALL_CAREERS_SUCCESS,
    CAREER_DETAILS_REQUEST,
    CAREER_DETAILS_SUCCESS,
    CAREER_DETAILS_FAIL,
    ADMIN_CAREERS_REQUEST,
    ADMIN_CAREERS_SUCCESS,
    ADMIN_CAREERS_FAIL,
    CLEAR_ERRORS,
    NEW_CAREER_REQUEST,
    NEW_CAREER_SUCCESS,
    NEW_CAREER_FAIL,
    UPDATE_CAREER_REQUEST,
    UPDATE_CAREER_SUCCESS,
    UPDATE_CAREER_FAIL,
    DELETE_CAREER_REQUEST,
    DELETE_CAREER_SUCCESS,
    DELETE_CAREER_FAIL,
    SLIDER_CAREERS_REQUEST,
    SLIDER_CAREERS_SUCCESS,
    SLIDER_CAREERS_FAIL,
} from "../constants/careerConstants";
import { backendurl } from "../utils/constants";
const config = {
    headers: {
        "Content-Type": "application/json",
        "Accept":"application/json"
    },
    withCredentials : true
}
// Get All Careers --- Filter/Search/Sort
export const getCareers =
    (keyword = "", category, price = [0, 200000], currentPage = 1) => async (dispatch) => {
        try {
            dispatch({ type: ALL_CAREERS_REQUEST });

            // let url = `/api/v1/careers?keyword=${keyword}&price[gte]=${price[0]}&price[lte]=${price[1]}&page=${currentPage}`;
            // if (category) {
            //     url = `/api/v1/careers?keyword=${keyword}&category=${category}&price[gte]=${price[0]}&price[lte]=${price[1]}&page=${currentPage}`;
            // }
            const { data } = await axios.get(backendurl+`/api/v1/careers`,config);

            dispatch({
                type: ALL_CAREERS_SUCCESS,
                payload: data,
            });
        } catch (error) {
            dispatch({
                type: ALL_CAREERS_FAIL,
                payload: error.response.data.message,
            });
        }
    };

// Get All Careers Of Same Category
export const getSimilarCareers = (category) => async (dispatch) => {
    try {
        dispatch({ type: ALL_CAREERS_REQUEST });

        const { data } = await axios.get(backendurl+`/api/v1/careers?category=${category}`,config);

        dispatch({
            type: ALL_CAREERS_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: ALL_CAREERS_FAIL,
            payload: error.response.data.message,
        });
    }
};

// Get Career Details
export const getCareerDetails = (slug) => async (dispatch) => {
    try {
        dispatch({ type: CAREER_DETAILS_REQUEST });

        const { data } = await axios.get(backendurl+`/api/v1/career/${slug}`,config);

        dispatch({
            type: CAREER_DETAILS_SUCCESS,
            payload: data.career,
        });
    } catch (error) {
        dispatch({
            type: CAREER_DETAILS_FAIL,
            payload: error.response.data.message,
        });
    }
};



// Get All Careers ---CAREER SLIDER
export const getSliderCareers = () => async (dispatch) => {
    try {
        dispatch({ type: SLIDER_CAREERS_REQUEST });

        const { data } = await axios.get(backendurl+'/api/v1/careers/all',config);

        dispatch({
            type: SLIDER_CAREERS_SUCCESS,
            payload: data.careers,
        });
    } catch (error) {
        dispatch({
            type: SLIDER_CAREERS_FAIL,
            payload: error.response.data.message,
        });
    }
};

// Get All Careers ---ADMIN
export const getAdminCareers = () => async (dispatch) => {
    try {
        dispatch({ type: ADMIN_CAREERS_REQUEST });

        const { data } = await axios.get(backendurl+'/api/v1/admin/careers',config);

        dispatch({
            type: ADMIN_CAREERS_SUCCESS,
            payload: data.careers,
        });
    } catch (error) {
        dispatch({
            type: ADMIN_CAREERS_FAIL,
            payload: error.response.data.message,
        });
    }
};

// New Career ---ADMIN
export const createCareer = (careerData) => async (dispatch) => {
    try {
        dispatch({ type: NEW_CAREER_REQUEST });
        
        const { data } = await axios.post(backendurl+"/api/v1/admin/career/new", careerData, config);

        dispatch({
            type: NEW_CAREER_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: NEW_CAREER_FAIL,
            payload: error.response.data.message,
        });
    }
}

// Update Career ---ADMIN
export const updateCareer = (slug, careerData) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_CAREER_REQUEST });
        
        const { data } = await axios.put(backendurl+`/api/v1/admin/career/${slug}`, careerData, config);

        dispatch({
            type: UPDATE_CAREER_SUCCESS,
            payload: data.success,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_CAREER_FAIL,
            payload: error.response.data.message,
        });
    }
}

// Delete Career ---ADMIN
export const deleteCareer = (slug) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_CAREER_REQUEST });
        const { data } = await axios.delete(backendurl+`/api/v1/admin/career/${slug}`,config);

        dispatch({
            type: DELETE_CAREER_SUCCESS,
            payload: data.success,
        });
    } catch (error) {
        dispatch({
            type: DELETE_CAREER_FAIL,
            payload: error.response.data.message,
        });
    }
}



// Clear All Errors
export const clearErrors = () => (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
}