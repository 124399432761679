import Headings from '../../Layouts/Headings/Headings.jsx';
import "../TechStack/TechStack.css"
import nodejs from '../../../assets/images/TechStack/nodejs.png'
import java from '../../../assets/images/TechStack/java.png'
import php from '../../../assets/images/TechStack/php.png'
import react from '../../../assets/images/TechStack/react.png'
import angular from '../../../assets/images/TechStack/angular.png'
import flutter from '../../../assets/images/TechStack/flutter.png'
import python from '../../../assets/images/TechStack/python.png'
import jquery from '../../../assets/images/TechStack/jquery.png'

const TechStack = ({ title }) => {

  
    return (
      <>
        <Headings title={"Our Tech Stacks"} type={"firstLevel"}/>
<p className="mx-auto xs:text-[14px] lg:text-[16px] xs:mx-6 lg:mx-[30%] text-center mt-4">We focus on emerging technologies for building high performing web & mobile products that can be securely deployed in the cloud.</p>
<div className="container mx-auto grid grid-cols-12 lg:grid-rows-1 md:grid-rows-1 sm:grid-rows-2 sm:gap-2 xs:gap-2 ">
<img className="lg:col-start-3 sm:col-start-3 xs:col-start-3 lg:col-span-1 sm:col-span-2 xs:col-span-2  w-20 lg:py-6 mx-auto lg:my-auto" src={nodejs} alt="nodejs" />
<img className="lg:col-span-1 sm:col-span-2 xs:col-span-2 w-20 lg:py-6 mx-auto lg:my-auto" src={java} alt="java" />
<img className="lg:col-span-1 sm:col-span-2 xs:col-span-2 w-20 xs:pt-2 lg:py-6 mx-auto lg:my-auto" src={php} alt="php" />
<img className="lg:col-span-1 sm:col-span-2 xs:col-span-2 w-20 lg:py-6  mx-auto lg:my-auto" src={react} alt="react" />

<img className="sm:col-start-3 xs:col-start-3 lg:col-start-7 lg:col-span-1 sm:col-span-2 xs:col-span-2 w-20 lg:py-6 mx-auto lg:my-auto" src={python} alt="python" />
<img className="lg:col-span-1 sm:col-span-2 xs:col-span-2 w-20 lg:py-6 mx-auto lg:my-auto" src={flutter} alt="flutter" />
<img className="lg:col-span-1 sm:col-span-2 xs:col-span-2 w-20 lg:py-6 mx-auto lg:my-auto" src={jquery} alt="jquery" />
<img className="lg:col-span-1 sm:col-span-2 xs:col-span-2 w-20 lg:py-6 mx-auto lg:my-auto" src={angular} alt="angular" />

</div>
      </>
    );
  };
  
  export default TechStack;