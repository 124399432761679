import Headings from '../../Layouts/Headings/Headings.jsx';
import LearnersCard from '../../Home/Learners/LearnersCard.jsx';
import "../TechStack/TechStack.css"
import ViewAll from '../../Layouts/ViewAll/ViewAll.jsx';
import { getRandomTrainings } from '../../../utils/functions';

import { useSelector } from 'react-redux';
import { baseurl, backendurl } from '../../../utils/constants.js';
    
    
const Learners = ({ title }) => {

  const { loading, trainings } = useSelector((state) => state.trainings);
    return (
      <>
        <Headings  title={"For Learners"} type={"firstLevel"} id={"learners"}/>

        <Headings title={"Training & Internship Programs"} type={"secondLevel"}/>

        <div className="container mx-auto grid grid-cols-12 xs:gap-2 lg:gap-6 py-6 lg:px-9 xs:px-0  xs:w-[92%] lg:w-[83%]">
        
        {loading ? null : 
          <>

        {trainings && getRandomTrainings(trainings, 4).map((training) => (
        <LearnersCard type={"internship"} {...training} key={training.slug} />
        ))}
                 
                
           </> 
          }

        
       
        </div>
        <ViewAll title={"Internship & Training Programs"} url={baseurl+"/trainingandinternships"} />




        {/* <Headings title={"Training Programs"} type={"secondLevel"}/> */}

        {/* <h2 class="px-10 ml-10 mt-8">Training Programs</h2> */}
        {/* <div className="container mx-auto grid grid-cols-12 xs:gap-2 lg:gap-6 py-6 lg:px-9 xs:px-0  xs:w-[92%] lg:w-[83%]">
        
        {loading ? null : 
          <>

        {trainings && getRandomTrainings(trainings, 4).map((training) => (
        <LearnersCard type="trainings" {...training} key={training._id} />
        ))}
                 
                
           </> 
          }
          </div> */}
        {/* <ViewAll title={"Training Programs"} url={"#"} /> */}

      </>
    );
  };
  
  export default Learners;