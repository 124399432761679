// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.headingstyleContainer h1 {
  text-align:center;

  text-transform:uppercase;
  letter-spacing:1px;
}

.firstHeading{
    position: relative;
    padding: 0;
    margin: 0;
    font-family: "Roboto", sans-serif;
    font-weight: 450;
    font-size: 1.5rem;
    color: #46208F;
    transition: all 0.4s ease 0s;
    margin-top: 50px;
  }
  @media (min-width: 640px){
    .container {
      max-width: none !important;
  }
  
  }
  @media (min-width: 200px) and (max-width: 640px){
    .container {
      max-width: none !important;
  }
  .headingstyleContainer h1 {
    font-size: 1.2rem;
  }
  .headingstyleContainer h2 {
    font-size: 1rem;
  }

 
  }

  
  /* .headingstyle h1:after,.headingstyle h1:before {
    content: " ";
    display: block;
    border-bottom: 2px solid #ccc;
    width: 150px;
  }
  .headingstyle h1:before{
    margin-left: 70%;
  } */
  

  .secondHeading {
    position: relative;
    padding: 0;
    margin: 0;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 20px;
    transition: all 0.4s ease 0s;
    margin-top: 30px;
    margin-left:8%;
  }`, "",{"version":3,"sources":["webpack://./src/components/Layouts/Headings/Headings.css"],"names":[],"mappings":";AACA;EACE,iBAAiB;;EAEjB,wBAAwB;EACxB,kBAAkB;AACpB;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,SAAS;IACT,iCAAiC;IACjC,gBAAgB;IAChB,iBAAiB;IACjB,cAAc;IAGd,4BAA4B;IAC5B,gBAAgB;EAClB;EACA;IACE;MACE,0BAA0B;EAC9B;;EAEA;EACA;IACE;MACE,0BAA0B;EAC9B;EACA;IACE,iBAAiB;EACnB;EACA;IACE,eAAe;EACjB;;;EAGA;;;EAGA;;;;;;;;KAQG;;;EAGH;IACE,kBAAkB;IAClB,UAAU;IACV,SAAS;IACT,iCAAiC;IACjC,gBAAgB;IAChB,eAAe;IAGf,4BAA4B;IAC5B,gBAAgB;IAChB,cAAc;EAChB","sourcesContent":["\n.headingstyleContainer h1 {\n  text-align:center;\n\n  text-transform:uppercase;\n  letter-spacing:1px;\n}\n\n.firstHeading{\n    position: relative;\n    padding: 0;\n    margin: 0;\n    font-family: \"Roboto\", sans-serif;\n    font-weight: 450;\n    font-size: 1.5rem;\n    color: #46208F;\n    -webkit-transition: all 0.4s ease 0s;\n    -o-transition: all 0.4s ease 0s;\n    transition: all 0.4s ease 0s;\n    margin-top: 50px;\n  }\n  @media (min-width: 640px){\n    .container {\n      max-width: none !important;\n  }\n  \n  }\n  @media (min-width: 200px) and (max-width: 640px){\n    .container {\n      max-width: none !important;\n  }\n  .headingstyleContainer h1 {\n    font-size: 1.2rem;\n  }\n  .headingstyleContainer h2 {\n    font-size: 1rem;\n  }\n\n \n  }\n\n  \n  /* .headingstyle h1:after,.headingstyle h1:before {\n    content: \" \";\n    display: block;\n    border-bottom: 2px solid #ccc;\n    width: 150px;\n  }\n  .headingstyle h1:before{\n    margin-left: 70%;\n  } */\n  \n\n  .secondHeading {\n    position: relative;\n    padding: 0;\n    margin: 0;\n    font-family: \"Roboto\", sans-serif;\n    font-weight: 500;\n    font-size: 20px;\n    -webkit-transition: all 0.4s ease 0s;\n    -o-transition: all 0.4s ease 0s;\n    transition: all 0.4s ease 0s;\n    margin-top: 30px;\n    margin-left:8%;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
